<template>
    <div class="global-protest-tracker">
        <div class="zone-title zone-title--bg banded landing__zone-title--short">
            <div class="container-title container-headline container-headline--no-image">
                <Typography as="h2" variant="h2">{{ pageData.data.title }}</Typography>
                <RichTextElements :elements="pageData.data.dek" class="interactive-dek" bodyStyle="sans-large" />
            </div>
            <div class="cols cols--equal-heights">
                <div class="col col-25">
                    <div class="padded">
                        Over <strong>800</strong> significant antigovernment protests have erupted worldwide.
                    </div>
                </div>
                <div class="col col-25">
                    <div class="padded">
                        More than <strong>150</strong> countries have experienced significant protests.
                    </div>
                </div>
                <div class="col col-25">
                    <div class="padded">
                        <strong>18%</strong> of protests have lasted more than three months.
                    </div>
                </div>
                <div class="col col-25">
                    <div class="padded">
                        <strong>283</strong> significant economic antigovernment protests have occurred since 2017.
                    </div>
                </div>
            </div>
        </div>
        <div class="section map">
            <div id="chartdiv"></div>
            <div id="info"></div>
            <div id="aboutTab">
                <div id="aboutTabContent">
                    <div class="top-section">
                        <div class="close-button">
                            <button class="close-panel" id="close-about">
                                <span class="ceip-icon icon-cancel-circle">X</span>
                            </button>
                        </div>
                        <div class="foreground">
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <div class="tab-clicks" @click="openTab('aboutTracker', $event)">About</div>
                                </li>
                                <li>
                                    <div class="tab-clicks" @click="openTab('definitions', $event)">Definitions</div>
                                </li>
                                <li>
                                    <div class="tab-clicks" @click="openTab('limitations', $event)">Limitations</div>
                                </li>
                                <li>
                                    <div class="tab-clicks" @click="openTab('sources', $event)">Data Sources</div>
                                </li>
                                <li>
                                    <div class="tab-clicks" @click="openTab('related', $event)">
                                        Related Publications
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section about-overlay">
                        <div class="foreground">
                            <div class="tab-content">
                                <div class="tab-pane fade in active" role="tabpanel" id="aboutTracker">
                                    <div class="component">
                                        <div class="cols gutter-bottom">
                                            <div class="col col-30">
                                                <div class="gutter-right">
                                                    <p>
                                                        Use Carnegie’s Global Protest Tracker to analyze and compare the
                                                        triggers, motivations, and other aspects of many of the most
                                                        significant antigovernment protests since 2017. Designed for
                                                        researchers, decisionmakers, and journalists, this comprehensive
                                                        resource helps illustrate how protests impact today’s global
                                                        politics.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col col-70">
                                                <div class="gutter-left">
                                                    <div class="videoEmbed">
                                                        <iframe
                                                            width="560"
                                                            height="315"
                                                            src="https://www.youtube.com/embed/jcnWgBkK8D0?wmode=transparent&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fcarnegieendowment.org"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen=""
                                                            data-gtm-yt-inspected-8="true"
                                                            id="723338973"
                                                            data-gtm-yt-inspected-13="true"
                                                            data-gtm-yt-inspected-37160727_28="true"
                                                            title="Global Protest Tracker: Measure Society's Impact On Global Politics"
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade in" role="tabpanel" id="definitions">
                                    <div class="component">
                                        <h4>Filters</h4>
                                        <div class="cols gutter-bottom">
                                            <div class="col col-50">
                                                <div class="gutter-right">
                                                    <strong>Active</strong>
                                                    <p>Ongoing protests only.</p>
                                                    <strong>All protests</strong>
                                                    <p>Resets the table to include all protests.</p>
                                                    <strong>COVID-19</strong>
                                                    <p>
                                                        Protests related to COVID-19, the disease caused by the new
                                                        coronavirus. See the note under “Protest significance” on the
                                                        “Limitations” tab.
                                                    </p>
                                                    <strong>Large</strong>
                                                    <p>Protests with a peak size of 100,000 people or more.</p>
                                                </div>
                                            </div>
                                            <div class="col col-50">
                                                <div class="gutter-left">
                                                    <strong>Long</strong>
                                                    <p>Protests with a duration of at least three months</p>
                                                    <strong>Outcome</strong>
                                                    <p>
                                                        Protests that have resulted in a significant government policy
                                                        or leadership change.
                                                    </p>
                                                    <strong>Violent</strong>
                                                    <p>Protests that have experienced violent government crackdowns.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <h4>Key Terms</h4>
                                        <div class="cols gutter-bottom">
                                            <div class="col col-50">
                                                <div class="gutter-right">
                                                    <strong>Duration </strong>
                                                    <p>Time span between the first and last reports of the protests.</p>
                                                    <strong>Freedom rating (Status)</strong>
                                                    <p>
                                                        Rating of a country in the Freedom House’s annual
                                                        <em>Freedom in the World</em> report at the time the protest
                                                        started, reflecting the status of political rights and civil
                                                        liberties in that country.
                                                    </p>
                                                    <strong>Key participants </strong>
                                                    <p>Notable groups protesting, although not exhaustive.</p>
                                                    <strong>Motivations </strong>
                                                    <p>
                                                        Systemic concerns that underlie the protest movement. To
                                                        encapsulate a broad range of antigovernment grievances, the
                                                        three overarching categories of motivations are economic,
                                                        political, and corruption. Corruption is considered separately
                                                        because it can be economic and/or political in nature and often
                                                        stems from government officials’ conduct rather than government
                                                        policies. These categories of motivations are not exclusive:
                                                        protests are often driven by a diverse range of concerns.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col col-50">
                                                <div class="gutter-left">
                                                    <strong>Peak Size</strong>
                                                    <p>Estimated peak number of protesters.</p>
                                                    <strong>Outcomes </strong>
                                                    <p>
                                                        Reported near-term policy changes or leadership changes in
                                                        response to the protests.
                                                    </p>
                                                    <strong>Protest name </strong>
                                                    <p>
                                                        Protests’ commonly used name, if any (for example, the Yellow
                                                        Vest protests), or an abbreviated description of the protests.
                                                    </p>
                                                    <strong>Significant protests </strong>
                                                    <p>
                                                        Sizable street protests that express opposition to the national
                                                        government as a whole or to its recent policies or actions.
                                                    </p>
                                                    <strong>Start date </strong>
                                                    <p>
                                                        When the protests are first reported in local or national news.
                                                    </p>
                                                    <strong>Triggers </strong>
                                                    <p>
                                                        Specific issue(s), event(s), or policy change(s) that sparked
                                                        the protest.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade in" role="tabpanel" id="limitations">
                                    <div class="component">
                                        <div class="cols gutter-bottom">
                                            <div class="col col-50">
                                                <div class="gutter-right">
                                                    <strong>Comprehensiveness </strong>
                                                    <p>
                                                        The tracker focuses on antigovernment protests. It excludes
                                                        rallies in support of a political cause, party, or political
                                                        figure.
                                                    </p>
                                                    <strong>Duration </strong>
                                                    <p>
                                                        Protests may be intermittent rather than constant, stopping and
                                                        starting over weeks or months.
                                                    </p>
                                                    <strong>Outcomes </strong>
                                                    <p>
                                                        The tracker focuses on outcomes that are direct responses to the
                                                        protests—specifically, policy or leadership changes that occur
                                                        in the short term during or after a protest. As a result, it
                                                        excludes events or changes that occur later, of which the
                                                        protests may only be one of many causal factors.
                                                    </p>
                                                    <strong>Protest size </strong>
                                                    <p>
                                                        Reliable and accurate information on the number of protesters is
                                                        not always available. In many cases, the only sources of
                                                        information on a protest’s size are local authorities, who often
                                                        underestimate the size of protests, or protest organizers, who
                                                        may overestimate the size of protests.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col col-50">
                                                <div class="gutter-left">
                                                    <strong>Protest significance </strong>
                                                    <p>
                                                        There is no scientifically precise way to define a significant
                                                        protest. The word significant here is understood in terms of
                                                        political importance: the impact of a protest on a country’s
                                                        political life. While a protest’s size can give some indication
                                                        of its importance, it is not determinative on its own. A large
                                                        protest in a country where protesting is legal and occurs
                                                        frequently may not be as significant as a small protest in a
                                                        country where public demonstrations are banned and authorities
                                                        are known to use violence against protesters. To account for the
                                                        varying environments for protests across regime types, this
                                                        tracker generally considers protests in contexts with a Freedom
                                                        in the World rating of “free” or “partly free” to be significant
                                                        if the protest’s peak size reaches or exceeds 10,000 protesters,
                                                        and protests with a Freedom in the World rating of “not free” to
                                                        be significant if the protest’s peak size reaches or exceeds
                                                        1,000 protesters.
                                                    </p>
                                                    <p>
                                                        Although most protests directly related to the coronavirus
                                                        outbreak are so far quite small, they have been included in the
                                                        tracker because of their potential impact on governance and
                                                        policy at the local, national, and international levels and
                                                        because of the overriding importance of the pandemic crisis.
                                                    </p>
                                                    <strong>Reliance on English-language sources</strong>
                                                    <p>
                                                        Data for this tracker are drawn from English-language news
                                                        sources.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade in" role="tabpanel" id="sources">
                                    <div class="component">
                                        <p>
                                            The data are based on reporting from credible news sources, with some
                                            cross-referencing for verification when possible. Publications and
                                            television outlets used to compile this data include (but are not limited
                                            to) Al Jazeera, the <em>Atlantic</em>, Balkan Insight, BBC, Bloomberg, CNN,
                                            DW News, the <em>Economist</em>, Euronews, <em>Financial Times</em>,
                                            <em>Foreign Affairs</em>, <em>Foreign Policy</em>, France24, the
                                            <em>Guardian</em>, the <em>Nation</em>, NBC News, <em>New York Times</em>,
                                            NPR, Reuters, Radio Free Europe/Radio Liberty, <em>Vox</em>,
                                            <em>Wall Street Journal</em>, <em>Washington Post</em>, and
                                            <em>World Politics Review</em>.
                                        </p>
                                        <p>
                                            Information on the status of political freedoms and civil liberties in each
                                            country is drawn from the annual <em>Freedom in the World</em> reports.
                                        </p>
                                        <p>
                                            Judy Lee, a program assistant with Carnegie’s Democracy,
                                            Conflict, and Governance Program, is primarily responsible for the compilation of the information in the tracker.
                                        </p>
                                    </div>
                                </div>
                                <div class="tab-pane fade in" role="tabpanel" id="related">
                                    <div class="component">
                                        <strong>Recent publications from Carnegie scholars</strong>
                                        <p>
                                            Thomas Carothers and Judy Lee, “<a href="https://carnegieendowment.org/emissary/2024/12/election-antigovernment-gaza-global-protest-tracker-2024?lang=en" target="_blank">Election-Related Protests Surged in 2024</a>,” Carnegie Endowment for International Peace, December 16, 2024.
                                        </p>
                                        <p>
                                            Thomas Carothers and Brendan Hartnett, “<a href="https://carnegieendowment.org/posts/2023/12/protests-in-2023-widespread-citizen-anger-continues-with-sources-multiplying?lang=en" target="_blank">Protests in 2023: Widespread Citizen Anger Continues, With Sources Multiplying</a>,” Carnegie Endowment for International Peace, December 18, 2023.
                                        </p>
                                        <p>
                                            Thomas Carothers and Benjamin Feldman, “<a href="https://carnegieendowment.org/posts/2022/12/economic-anger-dominated-global-protests-in-2022?lang=en" target="_blank">Economic Anger Dominated Global Protests in 2022</a>,” Carnegie Endowment for International Peace, December 8, 2022.
                                        </p>
                                        <p>
                                            Thomas Carothers, “<a href="https://www.foreignaffairs.com/articles/2020-02-06/dictators-trouble" target="_blank">Dictators in Trouble</a>,” <em>Foreign Affairs</em>, February 6, 2020.
                                        </p>
                                        <p>
                                            Thomas Carothers and David Wong, “<a
                                                href="https://carnegieendowment.org/posts/2020/04/misunderstanding-global-protests?lang=en"
                                                target="_blank"
                                                >Misunderstanding Global Protests</a
                                            >,” Carnegie Endowment for International Peace, April 1, 2020.
                                        </p>
                                        <p>
                                            Thomas Carothers and David Wong, “<a
                                                href="https://carnegieendowment.org/posts/2020/05/the-coronavirus-pandemic-is-reshaping-global-protests?lang=en"
                                                target="_blank"
                                                >The Coronavirus Pandemic Is Reshaping Global Protests</a
                                            >,” Carnegie Endowment for International Peace, May 4, 2020.
                                        </p>
                                        <p>
                                            Moisés Naím and Brian Winter, “<a
                                                href="https://www.foreignaffairs.com/articles/central-america-caribbean/2019-10-29/why-latin-america-was-primed-explode"
                                                target="_blank"
                                                >Why Latin America Was Primed to Explode</a
                                            >,” <em>Foreign Affairs</em>, October 29, 2019.
                                        </p>
                                        <p>
                                            Richard Youngs, ed.,
                                            <em
                                                ><a
                                                    href="https://carnegieendowment.org/files/Youngs_AfterProtest_final2.pdf"
                                                    target="_blank"
                                                    >After Protest: Pathways Beyond Mass Mobilization</a
                                                ></em
                                            >
                                            (Washington, DC: Carnegie Endowment for International Peace, 2019).
                                        </p>
                                        <p>
                                            Richard Youngs, “<a
                                                href="https://www.opendemocracy.net/en/transformation/after-protest-pathways-beyond-mass-mobilization/"
                                                target="_blank"
                                                >After Protest: Pathways Beyond Mass Mobilization</a
                                            >,” openDemocracy, November 10, 2019.
                                        </p>
                                        <strong>Additional publications</strong>
                                        <p>
                                            Cynthia Arnson, Benjamin Gedan, Michael Penfold, Rossana Castiglioni,
                                            Catalina Lobo-Guerrero, Augusto de la Torre, and Jim Schultz, “<a
                                                href="https://www.wilsonquarterly.com/quarterly/the-power-of-protest/postcards-from-the-edge/?emci=40ed83cb-6b3d-ea11-a1cc-2818784d084f&amp;emdi=2359b98b-e43d-ea11-a1cc-2818784d084f&amp;ceid=43550"
                                                target="_blank"
                                                >Postcards From the Edge</a
                                            >,” <em>Wilson Quarterly</em>, Winter 2020.
                                        </p>
                                        <p>
                                            S. Erdem Aytaç and Susan Stokes, “<a
                                                href="https://www.wilsonquarterly.com/quarterly/the-power-of-protest/why-protest/"
                                                target="_blank"
                                                >Why Protest?</a
                                            >” <em>Wilson Quarterly</em>, Winter 2020.
                                        </p>
                                        <p>
                                            Samuel Brannen, Christian Stirling Haig and Katherine Schmidt, “<a
                                                href="https://www.csis.org/analysis/age-mass-protests-understanding-escalating-global-trend"
                                                target="_blank"
                                                >The Age of Mass Protests: Understanding an Escalating Global Trend</a
                                            >,” Center for Strategic and International Studies, March 2, 2020.
                                        </p>
                                        <p>
                                            Erica Chenoweth, Sirianne Dahlum, Sooyeon Kang, Zoe Marks, Christopher Wiley
                                            Shay, and Tore Wig, “<a
                                                href="https://www.washingtonpost.com/politics/2019/11/16/this-may-be-largest-wave-nonviolent-mass-movements-world-history-what-comes-next/"
                                                target="_blank"
                                                >This May Be the Largest Wave of Nonviolent Mass Movements in World
                                                History. What Comes Next?</a
                                            >” <em>Washington Post</em>, November 16, 2019.
                                        </p>
                                        <p>
                                            Jonathan Pinckney and Miranda Rivers, “<a
                                                href="https://www.usip.org/publications/2020/03/nonviolent-action-time-coronavirus"
                                                >Nonviolent Action in the Time of Coronavirus</a
                                            >,” United States Institute of Peace, March 25, 2020.
                                        </p>
                                        <p>
                                            Robin Wright, “<a
                                                href="https://www.newyorker.com/news/our-columnists/the-story-of-2019-protests-in-every-corner-of-the-globe"
                                                target="_blank"
                                                >The Story of 2019: Protests in Every Corner of the Globe</a
                                            >,” <em>New Yorker</em>, December 30, 2019.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="active" id="aboutTabClick">About <span class="hide-mobile">the Tracker</span></h3>
            </div>
        </div>
        <div class="section filter-area">
            <div class="filters">
                <form
                    class="clean-form surveillance-radio toggle-wrapper padded-less"
                    name="surveillance-radio"
                    id="surveillance-radio"
                    action=""
                    method="get"
                    enctype="multipart/form-data"
                    autocomplete="off"
                >
                    <fieldset>
                        <div class="control-group">
                            <label class="table-search">
                                <input id="tableSearch" type="text" placeholder="Match protests to keywords"
                            /></label>
                            <strong>Filter protests by:</strong>
                            <label class="pub-type-label active" id="all">
                                <span class="name">All</span>
                                <input
                                    class="checkradios"
                                    type="radio"
                                    value="all"
                                    name="radio-group"
                                    id="radio-all"
                                    @click="allMotivationInput"
                                />
                            </label>
                            <label class="pub-type-label" id="current">
                                <span class="name">Active</span>
                                <input
                                    class="checkradios"
                                    type="radio"
                                    value="active"
                                    name="radio-group"
                                    id="radio-active"
                                    @click="activeMotivationInput"
                            /></label>
                            <label class="pub-type-label" id="large">
                                <span class="name">Large</span>
                                <input
                                    class="checkradios"
                                    type="radio"
                                    value="large"
                                    name="radio-group"
                                    id="radio-large"
                                    @click="largeMotivationInput"
                            /></label>
                            <label class="pub-type-label" id="violent">
                                <span class="name">Violent</span>
                                <input
                                    class="checkradios"
                                    type="radio"
                                    value="violent"
                                    name="radio-group"
                                    id="radio-violent"
                                    @click="violentMotivationInput"
                            /></label>
                            <label class="pub-type-label" id="violent">
                                <span class="name">Long</span>
                                <input
                                    class="checkradios"
                                    type="radio"
                                    value="long"
                                    name="radio-group"
                                    id="radio-long"
                                    @click="longMotivationInput"
                            /></label>
                            <label class="pub-type-label" id="significant">
                                <span class="name">Outcome</span>
                                <input
                                    class="checkradios"
                                    type="radio"
                                    value="significant"
                                    name="radio-group"
                                    id="radio-significant"
                                    @click="significantMotivationInput"
                            /></label>
                            <label class="pub-type-label special" id="covid">
                                <span class="name">COVID-19</span>
                                <input
                                    class="checkradios"
                                    type="radio"
                                    value="covid"
                                    name="radio-group"
                                    id="radio-covid"
                                    @click="covidMotivationInput"
                            /></label>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
        <div class="section results-table">
            <table id="protestTable">
                <thead id="headings">
                    <tr>
                        <th class="protest-name sortable active" data-attr="country" data-asc="true">Country</th>
                        <th class="responsive-hide">Protest Name</th>
                        <th class="sortable" data-attr="date">Start Date</th>
                        <th class="sortable" data-attr="size">Peak Size</th>
                        <th class="motivation responsive-hide">Motivation</th>
                        <th class="reponsive-mobile-hide" data-attr="active">Duration</th>
                        <th class="sortable responsive-hide" data-attr="freedom">Status</th>
                        <th class="compare reponsive-mobile-hide">
                            <a id="compare-button" @click="compareTool">Compare</a>
                            <a class="inactive reset" id="compare-reset" @click="compareReset">
                                <span class="visuallyhidden">Reset</span>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody id="table-results" class="old-class"></tbody>
            </table>
        </div>
        <div class="section download-buttons">
            <div class="foreground">
                <a
                    class="button"
                    href="https://docs.google.com/spreadsheets/d/1mEzzfbv40pYeb1_5an2hBdxfMuGRdq7V76rmuFvhHNY/edit?usp=sharing"
                    target="_blank"
                    >Download Spreadsheet</a
                >
                <a class="button" @click="printWindow()">Download Page</a>
            </div>
        </div>
    </div>
</template>

<script setup>
useHead({
    script: [
        { innerHTML: '', src: 'https://code.jquery.com/jquery-3.6.0.js' },
        { innerHTML: '', src: 'https://code.jquery.com/ui/1.13.2/jquery-ui.js' },
        { innerHTML: '', src: 'https://cdn.amcharts.com/lib/5/index.js' },
        { innerHTML: '', src: 'https://cdn.amcharts.com/lib/5/map.js' },
        { innerHTML: '', src: 'https://cdn.amcharts.com/lib/5/geodata/worldLow.js' },
        { innerHTML: '', src: 'https://cdn.amcharts.com/lib/5/themes/Animated.js' },
    ],
    link: [{ rel: 'stylesheet', href: '//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css' }],
});
import { onMounted, ref } from 'vue';
import FeatureFragment from '@fragments/pages/FeatureFragment.ts';

const pageData = await usePageData(FeatureFragment, 'features');

let usedDataArray;
let responsive = false;
let mobile = false;

// does this need to happen any time other than on load?  We could repeat with each button, just not sure it's worth it ...
function getResponsive() {
    const windowWidth = ref(window.innerWidth);
    if (windowWidth.value <= 767) {
        mobile = true;
    } else if (windowWidth.value <= 1024) {
        responsive = true;
    }
}
// anything that needs the DOM to exist (like calling document or window) has to be called here
let trackedCountry = '#00a79d';
let activeCountry = '#EF4136';
let inactiveCountry = '#99dcd8';
let activeCapital = '#fbb040';
let clickedCountry = '#072a42';

onMounted(() => {
    trackedCountry = am5.color(0x00a79d);
    activeCountry = am5.color(0xef4136);
    inactiveCountry = am5.color(0x99dcd8);
    activeCapital = am5.color(0xfbb040);
    clickedCountry = am5.color(0x072a42);
    getResponsive();
    renderResults();
    compareClick();
    searchProtests();
    aboutTab();
    callMap();
    // I need to catch any time a sortable is clicked, so it looks for all clicks on anything and acts if the className includes sortable ....
    document.onclick = function (e) {
        if (e.target.className && e.target.className.indexOf('sortable') != -1) {
            sortFunction(e);
        } else if (e.target.className && e.target.className.indexOf('content-toggle') != -1) {
            openNextRow(e);
        }
    };
});

const AFCapitalDataActive = {
	"id": "AF", 
	"title": "Afghanistan", 
	"latitude": 34.5553,
	"longitude": 69.2075,
	settings: { fill: activeCapital, radius: 4 },
}
const AFCapitalDataInctive = {
	"id": "AF", 
	"title": "Afghanistan", 
	"latitude": 34.5553,
	"longitude": 69.2075,
	settings: { radius: 0 },
}
const ALCapitalDataActive = {
	"id": "AL", 
	"title": "Albania", 
	"latitude": 41.327953,
	"longitude": 19.819025,
	settings: { fill: activeCapital, radius: 4 },
}
const ALCapitalDataInctive = {
	"id": "AL", 
	"title": "Albania", 
	"latitude": 41.327953,
	"longitude": 19.819025,
	settings: { radius: 0 },
}
const DZCapitalDataActive = {
  id: "DZ",
  title: "Algeria",
  latitude: 36.7538,
  longitude: 3.0588,
  settings: { fill: activeCapital, radius: 4 },
};
const DZCapitalDataInctive = {
  id: "DZ",
  title: "Algeria",
  latitude: 36.7538,
  longitude: 3.0588,
  settings: { radius: 0 },
};
const AOCapitalDataActive = {
  id: "AO",
  title: "Angola",
  latitude: -8.8147,
  longitude: 13.2302,
  settings: { fill: activeCapital, radius: 4 },
};
const AOCapitalDataInctive = {
  id: "AO",
  title: "Angola",
  latitude: -8.8147,
  longitude: 13.2302,
  settings: { radius: 0 },
};
const ARCapitalDataActive = {
  id: "AR",
  title: "Argentina",
  latitude: -34.6037,
  longitude: -58.3816,
  settings: { fill: activeCapital, radius: 4 },
};
const ARCapitalDataInctive = {
  id: "AR",
  title: "Argentina",
  latitude: -34.6037,
  longitude: -58.3816,
  settings: { radius: 0 },
};
const AMCapitalDataActive = {
  id: "AM",
  title: "Armenia",
  latitude: 40.1872,
  longitude: 44.5152,
  settings: { fill: activeCapital, radius: 4 },
};
const AMCapitalDataInctive = {
  id: "AM",
  title: "Armenia",
  latitude: 40.1872,
  longitude: 44.5152,
  settings: { radius: 0 },
};
const AUCapitalDataActive = {
  id: "AU",
  title: "Australia",
  latitude: -35.4735,
  longitude: 149.0124,
  settings: { fill: activeCapital, radius: 4 },
};
const AUCapitalDataInctive = {
  id: "AU",
  title: "Australia",
  latitude: -35.4735,
  longitude: 149.0124,
  settings: { radius: 0 },
};
const ATCapitalDataActive = {
  id: "AT",
  title: "Austria",
  latitude: 48.2082,
  longitude: 16.3738,
  settings: { fill: activeCapital, radius: 4 },
};
const ATCapitalDataInctive = {
  id: "AT",
  title: "Austria",
  latitude: 48.2082,
  longitude: 16.3738,
  settings: { radius: 0 },
};
const AZCapitalDataActive = {
  id: "AZ",
  title: "Azerbaijan",
  latitude: 40.4093,
  longitude: 49.8671,
  settings: { fill: activeCapital, radius: 4 },
};
const AZCapitalDataInctive = {
  id: "AZ",
  title: "Azerbaijan",
  latitude: 40.4093,
  longitude: 49.8671,
  settings: { radius: 0 },
};
const BHCapitalDataActive = {
  id: "BH",
  title: "Bahrain",
  latitude: 26.2235,
  longitude: 50.5876,
  settings: { fill: activeCapital, radius: 4 },
};
const BHCapitalDataInctive = {
  id: "BH",
  title: "Bahrain",
  latitude: 26.2235,
  longitude: 50.5876,
  settings: { radius: 0 },
};
const BDCapitalDataActive = {
  id: "BD",
  title: "Bangladesh",
  latitude: 23.8103,
  longitude: 90.4125,
  settings: { fill: activeCapital, radius: 4 },
};
const BDCapitalDataInctive = {
  id: "BD",
  title: "Bangladesh",
  latitude: 23.8103,
  longitude: 90.4125,
  settings: { radius: 0 },
};
const BYCapitalDataActive = {
  id: "BY",
  title: "Belarus",
  latitude: 53.9006,
  longitude: 27.559,
  settings: { fill: activeCapital, radius: 4 },
};
const BYCapitalDataInctive = {
  id: "BY",
  title: "Belarus",
  latitude: 53.9006,
  longitude: 27.559,
  settings: { radius: 0 },
};
const BECapitalDataActive = {
  id: "BE",
  title: "Belgium",
  latitude: 50.8476,
  longitude: 4.3572,
  settings: { fill: activeCapital, radius: 4 },
};
const BECapitalDataInctive = {
  id: "BE",
  title: "Belgium",
  latitude: 50.8476,
  longitude: 4.3572,
  settings: { radius: 0 },
};
const BJCapitalDataActive = {
  id: "BJ",
  title: "Benin",
  latitude: 6.4969,
  longitude: 2.6289,
  settings: { fill: activeCapital, radius: 4 },
};
const BJCapitalDataInctive = {
  id: "BJ",
  title: "Benin",
  latitude: 6.4969,
  longitude: 2.6289,
  settings: { radius: 0 },
};
const BOCapitalDataActive = {
  id: "BO",
  title: "Bolivia",
  latitude: -16.4897,
  longitude: -68.1193,
  settings: { fill: activeCapital, radius: 4 },
};
const BOCapitalDataInctive = {
  id: "BO",
  title: "Bolivia",
  latitude: -16.4897,
  longitude: -68.1193,
  settings: { radius: 0 },
};
const BACapitalDataActive = {
  id: "BA",
  title: "Bosnia and Herzegovina",
  latitude: 43.8563,
  longitude: 18.4131,
  settings: { fill: activeCapital, radius: 4 },
};
const BACapitalDataInctive = {
  id: "BA",
  title: "Bosnia and Herzegovina",
  latitude: 43.8563,
  longitude: 18.4131,
  settings: { radius: 0 },
};
const BWCapitalDataActive = {
  id: "BW",
  title: "Botswana",
  latitude: -24.653257,
  longitude: 25.906792,
  settings: { fill: activeCapital, radius: 4 },
};
const BWCapitalDataInctive = {
  id: "BW",
  title: "Botswana",
  latitude: -24.653257,
  longitude: 25.906792,
  settings: { radius: 0 },
};
const BRCapitalDataActive = {
  id: "BR",
  title: "Brazil",
  latitude: -15.8267,
  longitude: -47.9218,
  settings: { fill: activeCapital, radius: 4 },
};
const BRCapitalDataInctive = {
  id: "BR",
  title: "Brazil",
  latitude: -15.8267,
  longitude: -47.9218,
  settings: { radius: 0 },
};
const BGCapitalDataActive = {
  id: "BG",
  title: "Bulgaria",
  latitude: 42.7339,
  longitude: 25.4858,
  settings: { fill: activeCapital, radius: 4 },
};
const BGCapitalDataInctive = {
  id: "BG",
  title: "Bulgaria",
  latitude: 42.7339,
  longitude: 25.4858,
  settings: { radius: 0 },
};
const BFCapitalDataActive = {
  id: "BF",
  title: "Burkina Faso",
  latitude: 12.3714,
  longitude: 1.5197,
  settings: { fill: activeCapital, radius: 4 },
};
const BFCapitalDataInctive = {
  id: "BF",
  title: "Burkina Faso",
  latitude: 12.3714,
  longitude: 1.5197,
  settings: { radius: 0 },
};
const KHCapitalDataActive = {
  id: "KH",
  title: "Cambodia",
  latitude: 11.562360,
  longitude: 104.912369,
  settings: { fill: activeCapital, radius: 4 },
};
const KHCapitalDataInctive = {
  id: "KH",
  title: "Cambodia",
  latitude: 11.562360,
  longitude: 104.912369,
  settings: { radius: 0 },
};
const CMCapitalDataActive = {
  id: "CM",
  title: "Cameroon",
  latitude: 3.848,
  longitude: 11.5021,
  settings: { fill: activeCapital, radius: 4 },
};
const CMCapitalDataInctive = {
  id: "CM",
  title: "Cameroon",
  latitude: 3.848,
  longitude: 11.5021,
  settings: { radius: 0 },
};
const CACapitalDataActive = {
  id: "CA",
  title: "Canada",
  latitude: 45.4215,
  longitude: -75.6972,
  settings: { fill: activeCapital, radius: 4 },
};
const CACapitalDataInctive = {
  id: "CA",
  title: "Canada",
  latitude: 45.4215,
  longitude: -75.6972,
  settings: { radius: 0 },
};
const CFCapitalDataActive = {
  id: "CF",
  title: "Central African Republic",
  latitude: 4.3947,
  longitude: 18.5582,
  settings: { fill: activeCapital, radius: 4 },
};
const CFCapitalDataInctive = {
  id: "CF",
  title: "Central African Republic",
  latitude: 4.3947,
  longitude: 18.5582,
  settings: { radius: 0 },
};
const TDCapitalDataActive = {
  id: "TD",
  title: "Chad",
  latitude: 12.1348,
  longitude: 15.0557,
  settings: { fill: activeCapital, radius: 4 },
};
const TDCapitalDataInctive = {
  id: "TD",
  title: "Chad",
  latitude: 12.1348,
  longitude: 15.0557,
  settings: { radius: 0 },
};
const CLCapitalDataActive = {
  id: "CL",
  title: "Chile",
  latitude: -33.4489,
  longitude: -70.6693,
  settings: { fill: activeCapital, radius: 4 },
};
const CLCapitalDataInctive = {
  id: "CL",
  title: "Chile",
  latitude: -33.4489,
  longitude: -70.6693,
  settings: { radius: 0 },
};
const CNCapitalDataActive = {
  id: "CN",
  title: "China",
  latitude: 39.9042,
  longitude: 116.4074,
  settings: { fill: activeCapital, radius: 4 },
};
const CNCapitalDataInctive = {
  id: "CN",
  title: "China",
  latitude: 39.9042,
  longitude: 116.4074,
  settings: { radius: 0 },
};
const COCapitalDataActive = {
  id: "CO",
  title: "Colombia",
  latitude: 4.711,
  longitude: -74.0721,
  settings: { fill: activeCapital, radius: 4 },
};
const COCapitalDataInctive = {
  id: "CO",
  title: "Colombia",
  latitude: 4.711,
  longitude: -74.0721,
  settings: { radius: 0 },
};
const KMCapitalDataActive = {
  id: "KM",
  title: "Comoros",
  latitude: -11.7172,
  longitude: 43.2473,
  settings: { fill: activeCapital, radius: 4 },
};
const KMCapitalDataInctive = {
  id: "KM",
  title: "Comoros",
  latitude: -11.7172,
  longitude: 43.2473,
  settings: { radius: 0 },
};
const CRCapitalDataActive = {
  id: "CR",
  title: "Costa Rica",
  latitude: 9.9281,
  longitude: -84.0907,
  settings: { fill: activeCapital, radius: 4 },
};
const CRCapitalDataInctive = {
  id: "CR",
  title: "Costa Rica",
  latitude: 9.9281,
  longitude: -84.0907,
  settings: { radius: 0 },
};
const HRCapitalDataActive = {
  id: "HR",
  title: "Croatia",
  latitude: 45.815,
  longitude: 15.9819,
  settings: { fill: activeCapital, radius: 4 },
};
const HRCapitalDataInctive = {
  id: "HR",
  title: "Croatia",
  latitude: 45.815,
  longitude: 15.9819,
  settings: { radius: 0 },
};
const CUCapitalDataActive = {
  id: "CU",
  title: "Cuba",
  latitude: 23.1136,
  longitude: -82.3666,
  settings: { fill: activeCapital, radius: 4 },
};
const CUCapitalDataInctive = {
  id: "CU",
  title: "Cuba",
  latitude: 23.1136,
  longitude: -82.3666,
  settings: { radius: 0 },
};
const CYCapitalDataActive = {
  id: "CY",
  title: "Cyprus",
  latitude: 35.1856,
  longitude: 33.3823,
  settings: { fill: activeCapital, radius: 4 },
};
const CYCapitalDataInctive = {
  id: "CY",
  title: "Cyprus",
  latitude: 35.1856,
  longitude: 33.3823,
  settings: { radius: 0 },
};
const CZCapitalDataActive = {
  id: "CZ",
  title: "Czech Republic",
  latitude: 50.0755,
  longitude: 14.4378,
  settings: { fill: activeCapital, radius: 4 },
};
const CZCapitalDataInctive = {
  id: "CZ",
  title: "Czech Republic",
  latitude: 50.0755,
  longitude: 14.4378,
  settings: { radius: 0 },
};
const CDCapitalDataActive = {
  id: "CD",
  title: "Democratic Republic of the Congo",
  latitude: -4.4419,
  longitude: 15.2663,
  settings: { fill: activeCapital, radius: 4 },
};
const CDCapitalDataInctive = {
  id: "CD",
  title: "Democratic Republic of the Congo",
  latitude: -4.4419,
  longitude: 15.2663,
  settings: { radius: 0 },
};
const DKCapitalDataActive = {
  id: "DK",
  title: "Denmark",
  latitude: 55.6761,
  longitude: 12.5683,
  settings: { fill: activeCapital, radius: 4 },
};
const DKCapitalDataInctive = {
  id: "DK",
  title: "Denmark",
  latitude: 55.6761,
  longitude: 12.5683,
  settings: { radius: 0 },
};
const DOCapitalDataActive = {
  id: "DO",
  title: "Dominican Republic",
  latitude: 18.4861,
  longitude: -69.9312,
  settings: { fill: activeCapital, radius: 4 },
};
const DOCapitalDataInctive = {
  id: "DO",
  title: "Dominican Republic",
  latitude: 18.4861,
  longitude: -69.9312,
  settings: { radius: 0 },
};
const ECCapitalDataActive = {
  id: "EC",
  title: "Ecuador",
  latitude: -0.1807,
  longitude: -78.4678,
  settings: { fill: activeCapital, radius: 4 },
};
const ECCapitalDataInctive = {
  id: "EC",
  title: "Ecuador",
  latitude: -0.1807,
  longitude: -78.4678,
  settings: { radius: 0 },
};
const EGCapitalDataActive = {
  id: "EG",
  title: "Egypt",
  latitude: 30.0444,
  longitude: 31.2357,
  settings: { fill: activeCapital, radius: 4 },
};
const EGCapitalDataInctive = {
  id: "EG",
  title: "Egypt",
  latitude: 30.0444,
  longitude: 31.2357,
  settings: { radius: 0 },
};
const SVCapitalDataActive = {
  id: "SV",
  title: "El Salvador",
  latitude: 13.6929,
  longitude: -89.2182,
  settings: { fill: activeCapital, radius: 4 },
};
const SVCapitalDataInctive = {
  id: "SV",
  title: "El Salvador",
  latitude: 13.6929,
  longitude: -89.2182,
  settings: { radius: 0 },
};
const SZCapitalDataActive = {
  id: "SZ",
  title: "Eswatini",
  latitude: -26.3054,
  longitude: 31.1367,
  settings: { fill: activeCapital, radius: 4 },
};
const SZCapitalDataInctive = {
  id: "SZ",
  title: "Eswatini",
  latitude: -26.3054,
  longitude: 31.1367,
  settings: { radius: 0 },
};
const ETCapitalDataActive = {
  id: "ET",
  title: "Ethiopia",
  latitude: 9.145,
  longitude: 40.4897,
  settings: { fill: activeCapital, radius: 4 },
};
const ETCapitalDataInctive = {
  id: "ET",
  title: "Ethiopia",
  latitude: 9.145,
  longitude: 40.4897,
  settings: { radius: 0 },
};
const FICapitalDataActive = {
  id: "FI",
  title: "Finland",
  latitude: 48.8566,
  longitude: 2.3522,
  settings: { fill: activeCapital, radius: 4 },
};
const FICapitalDataInctive = {
  id: "FI",
  title: "Finland",
  latitude: 48.8566,
  longitude: 2.3522,
  settings: { radius: 0 },
};
const FRCapitalDataActive = {
  id: "FR",
  title: "France",
  latitude: 48.8566,
  longitude: 2.3522,
  settings: { fill: activeCapital, radius: 4 },
};
const FRCapitalDataInctive = {
  id: "FR",
  title: "France",
  latitude: 48.8566,
  longitude: 2.3522,
  settings: { radius: 0 },
};
const PFCapitalDataActive = {
  id: "PF",
  title: "French Polynesia",
  latitude: -17.5325,
  longitude: -149.569595,
  settings: { fill: activeCapital, radius: 4 },
};
const PFCapitalDataInctive = {
  id: "PF",
  title: "French Polynesia",
  latitude: -17.5325,
  longitude: -149.569595,
  settings: { radius: 0 },
};
const GACapitalDataActive = {
  id: "GA",
  title: "Gabon",
  latitude: 0.4162,
  longitude: 9.4673,
  settings: { fill: activeCapital, radius: 4 },
};
const GACapitalDataInctive = {
  id: "GA",
  title: "Gabon",
  latitude: 0.4162,
  longitude: 9.4673,
  settings: { radius: 0 },
};
const GMCapitalDataActive = {
  id: "GM",
  title: "Gambia",
  latitude: 13.4549,
  longitude: -16.579,
  settings: { fill: activeCapital, radius: 4 },
};
const GMCapitalDataInctive = {
  id: "GM",
  title: "Gambia",
  latitude: 13.4549,
  longitude: -16.579,
  settings: { radius: 0 },
};
const PSCapitalDataActive = {
  id: "PS",
  title: "West Bank",
  latitude: 31.5017,
  longitude: 34.4668,
  settings: { fill: activeCapital, radius: 4 },
};
const PSCapitalDataInctive = {
  id: "PS",
  title: "West Bank",
  latitude: 31.5017,
  longitude: 34.4668,
  settings: { radius: 0 },
};
const GECapitalDataActive = {
  id: "GE",
  title: "Georgia",
  latitude: 41.7151,
  longitude: 44.8271,
  settings: { fill: activeCapital, radius: 4 },
};
const GECapitalDataInctive = {
  id: "GE",
  title: "Georgia",
  latitude: 41.7151,
  longitude: 44.8271,
  settings: { radius: 0 },
};
const DECapitalDataActive = {
  id: "DE",
  title: "Germany",
  latitude: 52.52,
  longitude: 13.405,
  settings: { fill: activeCapital, radius: 4 },
};
const DECapitalDataInctive = {
  id: "DE",
  title: "Germany",
  latitude: 52.52,
  longitude: 13.405,
  settings: { radius: 0 },
};
const GHCapitalDataActive = {
  id: "GH",
  title: "Ghana",
  latitude: 5.6037,
  longitude: -0.187,
  settings: { fill: activeCapital, radius: 4 },
};
const GHCapitalDataInctive = {
  id: "GH",
  title: "Ghana",
  latitude: 5.6037,
  longitude: -0.187,
  settings: { radius: 0 },
};
const GRCapitalDataActive = {
  id: "GR",
  title: "Greece",
  latitude: 37.9838,
  longitude: 23.7275,
  settings: { fill: activeCapital, radius: 4 },
};
const GRCapitalDataInctive = {
  id: "GR",
  title: "Greece",
  latitude: 37.9838,
  longitude: 23.7275,
  settings: { radius: 0 },
};
const GTCapitalDataActive = {
  id: "GT",
  title: "Guatemala",
  latitude: 14.6349,
  longitude: -90.5069,
  settings: { fill: activeCapital, radius: 4 },
};
const GTCapitalDataInctive = {
  id: "GT",
  title: "Guatemala",
  latitude: 14.6349,
  longitude: -90.5069,
  settings: { radius: 0 },
};
const GNCapitalDataActive = {
  id: "GN",
  title: "Guinea",
  latitude: 9.6412,
  longitude: -13.5784,
  settings: { fill: activeCapital, radius: 4 },
};
const GNCapitalDataInctive = {
  id: "GN",
  title: "Guinea",
  latitude: 9.6412,
  longitude: -13.5784,
  settings: { radius: 0 },
};
const HTCapitalDataActive = {
  id: "HT",
  title: "Haiti",
  latitude: 18.5944,
  longitude: -72.3074,
  settings: { fill: activeCapital, radius: 4 },
};
const HTCapitalDataInctive = {
  id: "HT",
  title: "Haiti",
  latitude: 18.5944,
  longitude: -72.3074,
  settings: { radius: 0 },
};
const HNCapitalDataActive = {
  id: "HN",
  title: "Honduras",
  latitude: 14.065,
  longitude: -87.1715,
  settings: { fill: activeCapital, radius: 4 },
};
const HNCapitalDataInctive = {
  id: "HN",
  title: "Honduras",
  latitude: 14.065,
  longitude: -87.1715,
  settings: { radius: 0 },
};
const HKCapitalDataActive = {
  id: "HK",
  title: "Hong Kong",
  latitude: 22.3193,
  longitude: 114.1694,
  settings: { fill: activeCapital, radius: 4 },
};
const HKCapitalDataInctive = {
  id: "HK",
  title: "Hong Kong",
  latitude: 22.3193,
  longitude: 114.1694,
  settings: { radius: 0 },
};
const HUCapitalDataActive = {
  id: "HU",
  title: "Hungary",
  latitude: 47.4979,
  longitude: 19.0402,
  settings: { fill: activeCapital, radius: 4 },
};
const HUCapitalDataInctive = {
  id: "HU",
  title: "Hungary",
  latitude: 47.4979,
  longitude: 19.0402,
  settings: { radius: 0 },
};
const INCapitalDataActive = {
  id: "IN",
  title: "India",
  latitude: 28.6139,
  longitude: 77.209,
  settings: { fill: activeCapital, radius: 4 },
};
const INCapitalDataInctive = {
  id: "IN",
  title: "India",
  latitude: 28.6139,
  longitude: 77.209,
  settings: { radius: 0 },
};
const IDCapitalDataActive = {
  id: "ID",
  title: "Indonesia",
  latitude: -6.2088,
  longitude: 106.8456,
  settings: { fill: activeCapital, radius: 4 },
};
const IDCapitalDataInctive = {
  id: "ID",
  title: "Indonesia",
  latitude: -6.2088,
  longitude: 106.8456,
  settings: { radius: 0 },
};
const IRCapitalDataActive = {
  id: "IR",
  title: "Iran",
  latitude: 35.6892,
  longitude: 51.389,
  settings: { fill: activeCapital, radius: 4 },
};
const IRCapitalDataInctive = {
  id: "IR",
  title: "Iran",
  latitude: 35.6892,
  longitude: 51.389,
  settings: { radius: 0 },
};
const IQCapitalDataActive = {
  id: "IQ",
  title: "Iraq",
  latitude: 33.3152,
  longitude: 44.3661,
  settings: { fill: activeCapital, radius: 4 },
};
const IQCapitalDataInctive = {
  id: "IQ",
  title: "Iraq",
  latitude: 33.3152,
  longitude: 44.3661,
  settings: { radius: 0 },
};
const IECapitalDataActive = {
  id: "IE",
  title: "Ireland",
  latitude: 53.3498,
  longitude: -6.2603,
  settings: { fill: activeCapital, radius: 4 },
};
const IECapitalDataInctive = {
  id: "IE",
  title: "Ireland",
  latitude: 53.3498,
  longitude: -6.2603,
  settings: { radius: 0 },
};
const ILCapitalDataActive = {
  id: "IL",
  title: "Israel",
  latitude: 31.7683,
  longitude: 35.2137,
  settings: { fill: activeCapital, radius: 4 },
};
const ILCapitalDataInctive = {
  id: "IL",
  title: "Israel",
  latitude: 31.7683,
  longitude: 35.2137,
  settings: { radius: 0 },
};
const ITCapitalDataActive = {
  id: "IT",
  title: "Italy",
  latitude: 41.9028,
  longitude: 12.4964,
  settings: { fill: activeCapital, radius: 4 },
};
const ITCapitalDataInctive = {
  id: "IT",
  title: "Italy",
  latitude: 41.9028,
  longitude: 12.4964,
  settings: { radius: 0 },
};
const CICapitalDataActive = {
  id: "CI",
  title: "Ivory Coast",
  latitude: 6.8276,
  longitude: -5.2893,
  settings: { fill: activeCapital, radius: 4 },
};
const CICapitalDataInctive = {
  id: "CI",
  title: "Ivory Coast",
  latitude: 6.8276,
  longitude: -5.2893,
  settings: { radius: 0 },
};
const JPCapitalDataActive = {
  id: "JP",
  title: "Japan",
  latitude: 35.6762,
  longitude: 139.6503,
  settings: { fill: activeCapital, radius: 4 },
};
const JPCapitalDataInctive = {
  id: "JP",
  title: "Japan",
  latitude: 35.6762,
  longitude: 139.6503,
  settings: { radius: 0 },
};
const JOCapitalDataActive = {
  id: "JO",
  title: "Jordan",
  latitude: 31.9539,
  longitude: 35.9106,
  settings: { fill: activeCapital, radius: 4 },
};
const JOCapitalDataInctive = {
  id: "JO",
  title: "Jordan",
  latitude: 31.9539,
  longitude: 35.9106,
  settings: { radius: 0 },
};
const KZCapitalDataActive = {
  id: "KZ",
  title: "Kazakhstan",
  latitude: 51.1605,
  longitude: 71.4704,
  settings: { fill: activeCapital, radius: 4 },
};
const KZCapitalDataInctive = {
  id: "KZ",
  title: "Kazakhstan",
  latitude: 51.1605,
  longitude: 71.4704,
  settings: { radius: 0 },
};
const KECapitalDataActive = {
  id: "KE",
  title: "Kenya",
  latitude: -1.2921,
  longitude: 36.8219,
  settings: { fill: activeCapital, radius: 4 },
};
const KECapitalDataInctive = {
  id: "KE",
  title: "Kenya",
  latitude: -1.2921,
  longitude: 36.8219,
  settings: { radius: 0 },
};
const XKCapitalDataActive = {
  id: "XK",
  title: "Kosovo",
  latitude: 42.6629,
  longitude: 21.1655,
  settings: { fill: activeCapital, radius: 4 },
};
const XKCapitalDataInctive = {
  id: "XK",
  title: "Kosovo",
  latitude: 42.6629,
  longitude: 21.1655,
  settings: { radius: 0 },
};
const KWCapitalDataActive = {
  id: "KW",
  title: "Kuwait",
  latitude: 29.3759,
  longitude: 47.9774,
  settings: { fill: activeCapital, radius: 4 },
};
const KWCapitalDataInctive = {
  id: "KW",
  title: "Kuwait",
  latitude: 29.3759,
  longitude: 47.9774,
  settings: { radius: 0 },
};
const KGCapitalDataActive = {
  id: "KG",
  title: "Kyrgyzstan",
  latitude: 42.8746,
  longitude: 74.5698,
  settings: { fill: activeCapital, radius: 4 },
};
const KGCapitalDataInctive = {
  id: "KG",
  title: "Kyrgyzstan",
  latitude: 42.8746,
  longitude: 74.5698,
  settings: { radius: 0 },
};
const LBCapitalDataActive = {
  id: "LB",
  title: "Lebanon",
  latitude: 33.8938,
  longitude: 35.5018,
  settings: { fill: activeCapital, radius: 4 },
};
const LBCapitalDataInctive = {
  id: "LB",
  title: "Lebanon",
  latitude: 33.8938,
  longitude: 35.5018,
  settings: { radius: 0 },
};
const LRCapitalDataActive = {
  id: "LR",
  title: "Liberia",
  latitude: 6.3156,
  longitude: -10.8074,
  settings: { fill: activeCapital, radius: 4 },
};
const LRCapitalDataInctive = {
  id: "LR",
  title: "Liberia",
  latitude: 6.3156,
  longitude: -10.8074,
  settings: { radius: 0 },
};
const LYCapitalDataActive = {
  id: "LY",
  title: "Libya",
  latitude: 32.8872,
  longitude: 13.1913,
  settings: { fill: activeCapital, radius: 4 },
};
const LYCapitalDataInctive = {
  id: "LY",
  title: "Libya",
  latitude: 32.8872,
  longitude: 13.1913,
  settings: { radius: 0 },
};
const LTCapitalDataActive = {
  id: "LT",
  title: "Lithuania",
  latitude: 54.687157,
  longitude: 25.279652,
  settings: { fill: activeCapital, radius: 4 },
};
const LTCapitalDataInctive = {
  id: "LT",
  title: "Lithuania",
  latitude: 54.687157,
  longitude: 25.279652,
  settings: { radius: 0 },
};
const MGCapitalDataActive = {
  id: "MG",
  title: "Madagascar",
  latitude: -18.8792,
  longitude: 47.5079,
  settings: { fill: activeCapital, radius: 4 },
};
const MGCapitalDataInctive = {
  id: "MG",
  title: "Madagascar",
  latitude: -18.8792,
  longitude: 47.5079,
  settings: { radius: 0 },
};
const MWCapitalDataActive = {
  id: "MW",
  title: "Malawi",
  latitude: -13.9626,
  longitude: 33.7741,
  settings: { fill: activeCapital, radius: 4 },
};
const MWCapitalDataInctive = {
  id: "MW",
  title: "Malawi",
  latitude: -13.9626,
  longitude: 33.7741,
  settings: { radius: 0 },
};
const MYCapitalDataActive = {
  id: "MY",
  title: "Malaysia",
  latitude: 3.139,
  longitude: 101.6869,
  settings: { fill: activeCapital, radius: 4 },
};
const MYCapitalDataInctive = {
  id: "MY",
  title: "Malaysia",
  latitude: 3.139,
  longitude: 101.6869,
  settings: { radius: 0 },
};
const MVCapitalDataActive = {
  id: "MV",
  title: "Maldives",
  latitude: 4.1755,
  longitude: 73.5093,
  settings: { fill: activeCapital, radius: 4 },
};
const MVCapitalDataInctive = {
  id: "MV",
  title: "Maldives",
  latitude: 4.1755,
  longitude: 73.5093,
  settings: { radius: 0 },
};
const MLCapitalDataActive = {
  id: "ML",
  title: "Mali",
  latitude: 12.6392,
  longitude: -8.0029,
  settings: { fill: activeCapital, radius: 4 },
};
const MLCapitalDataInctive = {
  id: "ML",
  title: "Mali",
  latitude: 12.6392,
  longitude: -8.0029,
  settings: { radius: 0 },
};
const MTCapitalDataActive = {
  id: "MT",
  title: "Malta",
  latitude: 35.8989,
  longitude: 14.5146,
  settings: { fill: activeCapital, radius: 4 },
};
const MTCapitalDataInctive = {
  id: "MT",
  title: "Malta",
  latitude: 35.8989,
  longitude: 14.5146,
  settings: { radius: 0 },
};
const MRCapitalDataActive = {
  id: "MR",
  title: "Mauritania",
  latitude: 18.0735,
  longitude: -15.9582,
  settings: { fill: activeCapital, radius: 4 },
};
const MRCapitalDataInctive = {
  id: "MR",
  title: "Mauritania",
  latitude: 18.0735,
  longitude: -15.9582,
  settings: { radius: 0 },
};
const MUCapitalDataActive = {
  id: "MU",
  title: "Mauritius",
  latitude: -20.1609,
  longitude: 57.5012,
  settings: { fill: activeCapital, radius: 4 },
};
const MUCapitalDataInctive = {
  id: "MU",
  title: "Mauritius",
  latitude: -20.1609,
  longitude: 57.5012,
  settings: { radius: 0 },
};
const MXCapitalDataActive = {
  id: "MX",
  title: "Mexico",
  latitude: 19.4326,
  longitude: -99.1332,
  settings: { fill: activeCapital, radius: 4 },
};
const MXCapitalDataInctive = {
  id: "MX",
  title: "Mexico",
  latitude: 19.4326,
  longitude: -99.1332,
  settings: { radius: 0 },
};
const MDCapitalDataActive = {
  id: "MD",
  title: "Moldova",
  latitude: 47.0105,
  longitude: 28.8638,
  settings: { fill: activeCapital, radius: 4 },
};
const MDCapitalDataInctive = {
  id: "MD",
  title: "Moldova",
  latitude: 47.0105,
  longitude: 28.8638,
  settings: { radius: 0 },
};
const MNCapitalDataActive = {
  id: "MN",
  title: "Mongolia",
  latitude: 47.8864,
  longitude: 106.9057,
  settings: { fill: activeCapital, radius: 4 },
};
const MNCapitalDataInctive = {
  id: "MN",
  title: "Mongolia",
  latitude: 47.8864,
  longitude: 106.9057,
  settings: { radius: 0 },
};
const MECapitalDataActive = {
  id: "ME",
  title: "Montenegro",
  latitude: 42.4304,
  longitude: 19.2594,
  settings: { fill: activeCapital, radius: 4 },
};
const MECapitalDataInctive = {
  id: "ME",
  title: "Montenegro",
  latitude: 42.4304,
  longitude: 19.2594,
  settings: { radius: 0 },
};
const MACapitalDataActive = {
  id: "MA",
  title: "Morocco",
  latitude: 33.9716,
  longitude: -6.8498,
  settings: { fill: activeCapital, radius: 4 },
};
const MACapitalDataInctive = {
  id: "MA",
  title: "Morocco",
  latitude: 33.9716,
  longitude: -6.8498,
  settings: { radius: 0 },
};
const MZCapitalDataActive = {
  id: "MZ",
  title: "Mozambique",
  latitude: -25.9692,
  longitude: 32.5732,
  settings: { fill: activeCapital, radius: 4 },
};
const MZCapitalDataInctive = {
  id: "MZ",
  title: "Mozambique",
  latitude: -25.9692,
  longitude: 32.5732,
  settings: { radius: 0 },
};
const MMCapitalDataActive = {
  id: "MM",
  title: "Myanmar",
  latitude: 19.7633,
  longitude: 96.0785,
  settings: { fill: activeCapital, radius: 4 },
};
const MMCapitalDataInctive = {
  id: "MM",
  title: "Myanmar",
  latitude: 19.7633,
  longitude: 96.0785,
  settings: { radius: 0 },
};
const NACapitalDataActive = {
  id: "NA",
  title: "Namibia",
  latitude: -22.56092,
  longitude: 17.0658,
  settings: { fill: activeCapital, radius: 4 },
};
const NACapitalDataInctive = {
  id: "NA",
  title: "Namibia",
  latitude: -22.56092,
  longitude: 17.0658,
  settings: { radius: 0 },
};
const NPCapitalDataActive = {
  id: "NP",
  title: "Nepal",
  latitude: 27.7172,
  longitude: 85.324,
  settings: { fill: activeCapital, radius: 4 },
};
const NPCapitalDataInctive = {
  id: "NP",
  title: "Nepal",
  latitude: 27.7172,
  longitude: 85.324,
  settings: { radius: 0 },
};
const NLCapitalDataActive = {
  id: "NL",
  title: "Netherlands",
  latitude: 52.3676,
  longitude: 4.9041,
  settings: { fill: activeCapital, radius: 4 },
};
const NLCapitalDataInctive = {
  id: "NL",
  title: "Netherlands",
  latitude: 52.3676,
  longitude: 4.9041,
  settings: { radius: 0 },
};
const NZCapitalDataActive = {
  id: "NZ",
  title: "New Zealand",
  latitude: -41.2865,
  longitude: 174.7762,
  settings: { fill: activeCapital, radius: 4 },
};
const NZCapitalDataInctive = {
  id: "NZ",
  title: "New Zealand",
  latitude: -41.2865,
  longitude: 174.7762,
  settings: { radius: 0 },
};
const NICapitalDataActive = {
  id: "NI",
  title: "Nicaragua",
  latitude: 12.115,
  longitude: -86.2362,
  settings: { fill: activeCapital, radius: 4 },
};
const NICapitalDataInctive = {
  id: "NI",
  title: "Nicaragua",
  latitude: 12.115,
  longitude: -86.2362,
  settings: { radius: 0 },
};
const NECapitalDataActive = {
  id: "NE",
  title: "Niger",
  latitude: 13.5116,
  longitude: 2.1254,
  settings: { fill: activeCapital, radius: 4 },
};
const NECapitalDataInctive = {
  id: "NE",
  title: "Niger",
  latitude: 13.5116,
  longitude: 2.1254,
  settings: { radius: 0 },
};
const NGCapitalDataActive = {
  id: "NG",
  title: "Nigeria",
  latitude: 9.0765,
  longitude: 7.3986,
  settings: { fill: activeCapital, radius: 4 },
};
const NGCapitalDataInctive = {
  id: "NG",
  title: "Nigeria",
  latitude: 9.0765,
  longitude: 7.3986,
  settings: { radius: 0 },
};
const MKCapitalDataActive = {
  id: "MK",
  title: "North Macedonia",
  latitude: 41.9981,
  longitude: 21.4254,
  settings: { fill: activeCapital, radius: 4 },
};
const MKCapitalDataInctive = {
  id: "MK",
  title: "North Macedonia",
  latitude: 41.9981,
  longitude: 21.4254,
  settings: { radius: 0 },
};
const NOCapitalDataActive = {
  id: "NO",
  title: "Norway",
  latitude: 59.9139,
  longitude: 10.7522,
  settings: { fill: activeCapital, radius: 4 },
};
const NOCapitalDataInctive = {
  id: "NO",
  title: "Norway",
  latitude: 59.9139,
  longitude: 10.7522,
  settings: { radius: 0 },
};
const OMCapitalDataActive = {
  id: "OM",
  title: "Oman",
  latitude: 23.588,
  longitude: 58.3829,
  settings: { fill: activeCapital, radius: 4 },
};
const OMCapitalDataInctive = {
  id: "OM",
  title: "Oman",
  latitude: 23.588,
  longitude: 58.3829,
  settings: { radius: 0 },
};
const PKCapitalDataActive = {
  id: "PK",
  title: "Pakistan",
  latitude: 33.6844,
  longitude: 73.0479,
  settings: { fill: activeCapital, radius: 4 },
};
const PKCapitalDataInctive = {
  id: "PK",
  title: "Pakistan",
  latitude: 33.6844,
  longitude: 73.0479,
  settings: { radius: 0 },
};
const PACapitalDataActive = {
  id: "PA",
  title: "Panama",
  latitude: 8.9824,
  longitude: -79.5199,
  settings: { fill: activeCapital, radius: 4 },
};
const PACapitalDataInctive = {
  id: "PA",
  title: "Panama",
  latitude: 8.9824,
  longitude: -79.5199,
  settings: { radius: 0 },
};
const PGCapitalDataActive = {
  id: "PG",
  title: "Papua New Guinea",
  latitude: 8.9824,
  longitude: -79.5199,
  settings: { fill: activeCapital, radius: 4 },
};
const PGCapitalDataInctive = {
  id: "PG",
  title: "Papua New Guinea",
  latitude: 8.9824,
  longitude: -79.5199,
  settings: { radius: 0 },
};
const PYCapitalDataActive = {
  id: "PY",
  title: "Paraguay",
  latitude: -25.2637,
  longitude: -57.5759,
  settings: { fill: activeCapital, radius: 4 },
};
const PYCapitalDataInctive = {
  id: "PY",
  title: "Paraguay",
  latitude: -25.2637,
  longitude: -57.5759,
  settings: { radius: 0 },
};
const PECapitalDataActive = {
  id: "PE",
  title: "Peru",
  latitude: -12.0464,
  longitude: -77.0428,
  settings: { fill: activeCapital, radius: 4 },
};
const PECapitalDataInctive = {
  id: "PE",
  title: "Peru",
  latitude: -12.0464,
  longitude: -77.0428,
  settings: { radius: 0 },
};
const PLCapitalDataActive = {
  id: "PL",
  title: "Poland",
  latitude: 52.2297,
  longitude: 21.0122,
  settings: { fill: activeCapital, radius: 4 },
};
const PLCapitalDataInctive = {
  id: "PL",
  title: "Poland",
  latitude: 52.2297,
  longitude: 21.0122,
  settings: { radius: 0 },
};
const PTCapitalDataActive = {
  id: "PT",
  title: "Portugal",
  latitude: 38.7223,
  longitude: -9.1393,
  settings: { fill: activeCapital, radius: 4 },
};
const PTCapitalDataInctive = {
  id: "PT",
  title: "Portugal",
  latitude: 38.7223,
  longitude: -9.1393,
  settings: { radius: 0 },
};
const ROCapitalDataActive = {
  id: "RO",
  title: "Romania",
  latitude: 44.4268,
  longitude: 26.1025,
  settings: { fill: activeCapital, radius: 4 },
};
const ROCapitalDataInctive = {
  id: "RO",
  title: "Romania",
  latitude: 44.4268,
  longitude: 26.1025,
  settings: { radius: 0 },
};
const RUCapitalDataActive = {
  id: "RU",
  title: "Russia",
  latitude: 55.7558,
  longitude: 37.6173,
  settings: { fill: activeCapital, radius: 4 },
};
const RUCapitalDataInctive = {
  id: "RU",
  title: "Russia",
  latitude: 55.7558,
  longitude: 37.6173,
  settings: { radius: 0 },
};
const SNCapitalDataActive = {
  id: "SN",
  title: "Senegal",
  latitude: 14.7167,
  longitude: -17.4677,
  settings: { fill: activeCapital, radius: 4 },
};
const SNCapitalDataInctive = {
  id: "SN",
  title: "Senegal",
  latitude: 14.7167,
  longitude: -17.4677,
  settings: { radius: 0 },
};
const RSCapitalDataActive = {
  id: "RS",
  title: "Serbia",
  latitude: 44.7866,
  longitude: 20.4489,
  settings: { fill: activeCapital, radius: 4 },
};
const RSCapitalDataInctive = {
  id: "RS",
  title: "Serbia",
  latitude: 44.7866,
  longitude: 20.4489,
  settings: { radius: 0 },
};
const SLCapitalDataActive = {
  id: "SL",
  title: "Sierra Leone",
  latitude: 8.4657,
  longitude: -13.2317,
  settings: { fill: activeCapital, radius: 4 },
};
const SLCapitalDataInctive = {
  id: "SL",
  title: "Sierra Leone",
  latitude: 8.4657,
  longitude: -13.2317,
  settings: { radius: 0 },
};
const SKCapitalDataActive = {
  id: "SK",
  title: "Slovakia",
  latitude: 48.1486,
  longitude: 17.1077,
  settings: { fill: activeCapital, radius: 4 },
};
const SKCapitalDataInctive = {
  id: "SK",
  title: "Slovakia",
  latitude: 48.1486,
  longitude: 17.1077,
  settings: { radius: 0 },
};
const SICapitalDataActive = {
  id: "SI",
  title: "Slovenia",
  latitude: 46.0569,
  longitude: 14.5058,
  settings: { fill: activeCapital, radius: 4 },
};
const SICapitalDataInctive = {
  id: "SI",
  title: "Slovenia",
  latitude: 46.0569,
  longitude: 14.5058,
  settings: { radius: 0 },
};
const SBCapitalDataActive = {
  id: "SB",
  title: "Solomon Islands",
  latitude: -9.4456,
  longitude: 159.9729,
  settings: { fill: activeCapital, radius: 4 },
};
const SBCapitalDataInctive = {
  id: "SB",
  title: "Solomon Islands",
  latitude: -9.4456,
  longitude: 159.9729,
  settings: { radius: 0 },
};
const SOCapitalDataActive = {
  id: "SO",
  title: "Somalia",
  latitude: 2.0469,
  longitude: 45.3182,
  settings: { fill: activeCapital, radius: 4 },
};
const SOCapitalDataInctive = {
  id: "SO",
  title: "Somalia",
  latitude: 2.0469,
  longitude: 45.3182,
  settings: { radius: 0 },
};
const ZACapitalDataActive = {
  id: "ZA",
  title: "South Africa",
  latitude: -33.9249,
  longitude: 18.4241,
  settings: { fill: activeCapital, radius: 4 },
};
const ZACapitalDataInctive = {
  id: "ZA",
  title: "South Africa",
  latitude: -33.9249,
  longitude: 18.4241,
  settings: { radius: 0 },
};
const KRCapitalDataActive = {
  id: "KR",
  title: "South Korea",
  latitude: 37.5665,
  longitude: 126.978,
  settings: { fill: activeCapital, radius: 4 },
};
const KRCapitalDataInctive = {
  id: "KR",
  title: "South Korea",
  latitude: 37.5665,
  longitude: 126.978,
  settings: { radius: 0 },
};
const SSCapitalDataActive = {
  id: "SS",
  title: "South Sudan",
  latitude: 4.859363,
  longitude: 31.571251,
  settings: { fill: activeCapital, radius: 4 },
};
const SSCapitalDataInctive = {
  id: "SS",
  title: "South Sudan",
  latitude: 4.859363,
  longitude: 31.571251,
  settings: { radius: 0 },
};
const ESCapitalDataActive = {
  id: "ES",
  title: "Spain",
  latitude: 40.4168,
  longitude: -3.7038,
  settings: { fill: activeCapital, radius: 4 },
};
const ESCapitalDataInctive = {
  id: "ES",
  title: "Spain",
  latitude: 40.4168,
  longitude: -3.7038,
  settings: { radius: 0 },
};
const LKCapitalDataActive = {
  id: "LK",
  title: "Sri Lanka",
  latitude: 6.8868,
  longitude: 79.9187,
  settings: { fill: activeCapital, radius: 4 },
};
const LKCapitalDataInctive = {
  id: "LK",
  title: "Sri Lanka",
  latitude: 6.8868,
  longitude: 79.9187,
  settings: { radius: 0 },
};
const SDCapitalDataActive = {
  id: "SD",
  title: "Sudan",
  latitude: 15.5007,
  longitude: 32.5599,
  settings: { fill: activeCapital, radius: 4 },
};
const SDCapitalDataInctive = {
  id: "SD",
  title: "Sudan",
  latitude: 15.5007,
  longitude: 32.5599,
  settings: { radius: 0 },
};
const SRCapitalDataActive = {
  id: "SR",
  title: "Suriname",
  latitude: 5.852,
  longitude: -55.2038,
  settings: { fill: activeCapital, radius: 4 },
};
const SRCapitalDataInctive = {
  id: "SR",
  title: "Suriname",
  latitude: 5.852,
  longitude: -55.2038,
  settings: { radius: 0 },
};
const SECapitalDataActive = {
  id: "SE",
  title: "Sweden",
  latitude: 59.3293,
  longitude: 18.0686,
  settings: { fill: activeCapital, radius: 4 },
};
const SECapitalDataInctive = {
  id: "SE",
  title: "Sweden",
  latitude: 59.3293,
  longitude: 18.0686,
  settings: { radius: 0 },
};
const CHCapitalDataActive = {
  id: "CH",
  title: "Switzerland",
  latitude: 46.2044,
  longitude: 6.1432,
  settings: { fill: activeCapital, radius: 4 },
};
const CHCapitalDataInctive = {
  id: "CH",
  title: "Switzerland",
  latitude: 46.2044,
  longitude: 6.1432,
  settings: { radius: 0 },
};
const SYCapitalDataActive = {
  id: "SY",
  title: "Syria",
  latitude: 33.5138,
  longitude: 36.2765,
  settings: { fill: activeCapital, radius: 4 },
};
const SYCapitalDataInctive = {
  id: "SY",
  title: "Syria",
  latitude: 33.5138,
  longitude: 36.2765,
  settings: { radius: 0 },
};
const TWCapitalDataActive = {
  id: "TW",
  title: "Taiwan",
  latitude: 25.033,
  longitude: 121.5654,
  settings: { fill: activeCapital, radius: 4 },
};
const TWCapitalDataInctive = {
  id: "TW",
  title: "Taiwan",
  latitude: 25.033,
  longitude: 121.5654,
  settings: { radius: 0 },
};
const TJCapitalDataActive = {
  id: "TJ",
  title: "Tajikistan",
  latitude: 38.5598,
  longitude: 68.787,
  settings: { fill: activeCapital, radius: 4 },
};
const TJCapitalDataInctive = {
  id: "TJ",
  title: "Tajikistan",
  latitude: 38.5598,
  longitude: 68.787,
  settings: { radius: 0 },
};
const TZCapitalDataActive = {
  id: "TZ",
  title: "Tanzania",
  latitude: -6.776012,
  longitude: 39.178326,
  settings: { fill: activeCapital, radius: 4 },
};
const TZCapitalDataInctive = {
  id: "TZ",
  title: "Tanzania",
  latitude: -6.776012,
  longitude: 39.178326,
  settings: { radius: 0 },
};
const THCapitalDataActive = {
  id: "TH",
  title: "Thailand",
  latitude: 15.87,
  longitude: 100.9925,
  settings: { fill: activeCapital, radius: 4 },
};
const THCapitalDataInctive = {
  id: "TH",
  title: "Thailand",
  latitude: 15.87,
  longitude: 100.9925,
  settings: { radius: 0 },
};
const TGCapitalDataActive = {
  id: "TG",
  title: "Togo",
  latitude: 6.1256,
  longitude: 1.2254,
  settings: { fill: activeCapital, radius: 4 },
};
const TGCapitalDataInctive = {
  id: "TG",
  title: "Togo",
  latitude: 6.1256,
  longitude: 1.2254,
  settings: { radius: 0 },
};
const TNCapitalDataActive = {
  id: "TN",
  title: "Tunisia",
  latitude: 36.8065,
  longitude: 10.1815,
  settings: { fill: activeCapital, radius: 4 },
};
const TNCapitalDataInctive = {
  id: "TN",
  title: "Tunisia",
  latitude: 36.8065,
  longitude: 10.1815,
  settings: { radius: 0 },
};
const TRCapitalDataActive = {
  id: "TR",
  title: "Türkiye",
  latitude: 39.9334,
  longitude: 32.8597,
  settings: { fill: activeCapital, radius: 4 },
};
const TRCapitalDataInctive = {
  id: "TR",
  title: "Türkiye",
  latitude: 39.9334,
  longitude: 32.8597,
  settings: { radius: 0 },
};
const TMCapitalDataActive = {
  id: "TM",
  title: "Turkmenistan",
  latitude: 37.9601,
  longitude: 58.3261,
  settings: { fill: activeCapital, radius: 4 },
};
const TMCapitalDataInctive = {
  id: "TM",
  title: "Turkmenistan",
  latitude: 37.9601,
  longitude: 58.3261,
  settings: { radius: 0 },
};
const UGCapitalDataActive = {
  id: "UG",
  title: "Uganda",
  latitude: 0.3476,
  longitude: 32.5825,
  settings: { fill: activeCapital, radius: 4 },
};
const UGCapitalDataInctive = {
  id: "UG",
  title: "Uganda",
  latitude: 0.3476,
  longitude: 32.5825,
  settings: { radius: 0 },
};
const UACapitalDataActive = {
  id: "UA",
  title: "Ukraine",
  latitude: 50.4501,
  longitude: 30.5234,
  settings: { fill: activeCapital, radius: 4 },
};
const UACapitalDataInctive = {
  id: "UA",
  title: "Ukraine",
  latitude: 50.4501,
  longitude: 30.5234,
  settings: { radius: 0 },
};
const AECapitalDataActive = {
  id: "AE",
  title: "United Arab Emirates",
  latitude: 24.4539,
  longitude: 54.366669,
  settings: { fill: activeCapital, radius: 4 },
};
const AECapitalDataInctive = {
  id: "AE",
  title: "United Arab Emirates",
  latitude: 24.4539,
  longitude: 54.366669,
  settings: { radius: 0 },
};
const GBCapitalDataActive = {
  id: "GB",
  title: "United Kingdom",
  latitude: 51.5074,
  longitude: -0.1278,
  settings: { fill: activeCapital, radius: 4 },
};
const GBCapitalDataInctive = {
  id: "GB",
  title: "United Kingdom",
  latitude: 51.5074,
  longitude: -0.1278,
  settings: { radius: 0 },
};
const USCapitalDataActive = {
  id: "US",
  title: "United States",
  latitude: 38.9072,
  longitude: -77.0369,
  settings: { fill: activeCapital, radius: 4 },
};
const USCapitalDataInctive = {
  id: "US",
  title: "United States",
  latitude: 38.9072,
  longitude: -77.0369,
  settings: { radius: 0 },
};
const UYCapitalDataActive = {
  id: "UY",
  title: "Uruguay",
  latitude: -34.9011,
  longitude: -56.1645,
  settings: { fill: activeCapital, radius: 4 },
};
const UYCapitalDataInctive = {
  id: "UY",
  title: "Uruguay",
  latitude: -34.9011,
  longitude: -56.1645,
  settings: { radius: 0 },
};
const UZCapitalDataActive = {
  id: "UZ",
  title: "Uzbekistan",
  latitude: 41.2995,
  longitude: 69.2401,
  settings: { fill: activeCapital, radius: 4 },
};
const UZCapitalDataInctive = {
  id: "UZ",
  title: "Uzbekistan",
  latitude: 41.2995,
  longitude: 69.2401,
  settings: { radius: 0 },
};
const VECapitalDataActive = {
  id: "VE",
  title: "Venezuela",
  latitude: 10.4806,
  longitude: -66.9036,
  settings: { fill: activeCapital, radius: 4 },
};
const VECapitalDataInctive = {
  id: "VE",
  title: "Venezuela",
  latitude: 10.4806,
  longitude: -66.9036,
  settings: { radius: 0 },
};
const VNCapitalDataActive = {
  id: "VN",
  title: "Vietnam",
  latitude: 21.0278,
  longitude: 105.8342,
  settings: { fill: activeCapital, radius: 4 },
};
const VNCapitalDataInctive = {
  id: "VN",
  title: "Vietnam",
  latitude: 21.0278,
  longitude: 105.8342,
  settings: { radius: 0 },
};
const YECapitalDataActive = {
  id: "YE",
  title: "Yemen",
  latitude: 15.3694,
  longitude: 44.191,
  settings: { fill: activeCapital, radius: 4 },
};
const YECapitalDataInctive = {
  id: "YE",
  title: "Yemen",
  latitude: 15.3694,
  longitude: 44.191,
  settings: { radius: 0 },
};
const ZWCapitalDataActive = {
  id: "ZW",
  title: "Zimbabwe",
  latitude: -17.8252,
  longitude: 31.0335,
  settings: { fill: activeCapital, radius: 4 },
};
const ZWCapitalDataInctive = {
  id: "ZW",
  title: "Zimbabwe",
  latitude: -17.8252,
  longitude: 31.0335,
  settings: { radius: 0 },
};

let dataArray = [
		    {
		        id: "AF",
		        arrayItem: true,
		        country: 'Afghanistan',
		        protest_name: '<span class="protest">Women’s rights protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Not free',
		        triggers: 'Growing repression of women’s rights under Taliban rule.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand equal rights for men and women. Call on the international community to denounce the Taliban.',
		        size: 11,
		        size_text: '>10',
		        participants: 'Afghan Women’s History Transformation Movement',
		        timespan: '1 day (30 minutes)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "AF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AFCapitalDataActive,
		        inactiveCapitalData: AFCapitalDataInctive
		    },
		    {
		        id: "AF",
		        arrayItem: true,
		        country: 'Afghanistan',
		        protest_name: '<span class="protest">Protest against pension system elimination</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Not free',
		        triggers: 'The Taliban government abolished the country’s pension system amid an economic crisis. The Taliban has promised to create an Islamic system in the country and used this promise to justify ending pensions because they involve interest, which the Taliban says is forbidden by Islam. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand pensions be restored, as retirees claim they cannot survive without state assistance. ',
		        size: 11,
		        size_text: '>10',
		        participants: 'Retired civil servants and members of the armed forces',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "AF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AFCapitalDataActive,
		        inactiveCapitalData: AFCapitalDataInctive
		    },
		    {
		        id: "AF",
		        arrayItem: true,
		        country: 'Afghanistan',
		        protest_name: '<span class="protest">Anti-Taliban protests</span>',
		        date: '2021-08-01',
		        date_text: "August 2021",
		        freedom: 'Not free',
		        triggers: 'The Taliban’s takeover of Afghanistan in the wake of the U.S. withdrawal.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over changes under Taliban rule, including altered national symbols, declining women’s rights, increased repression, and weakened civil society. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "AF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AFCapitalDataActive,
		        inactiveCapitalData: AFCapitalDataInctive
		    },
		    {
		        id: "AF",
		        arrayItem: true,
		        country: 'Afghanistan',
		        protest_name: '<span class="protest">Electoral protests</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Not free',
		        triggers: 'Delays in the announcement of final vote counts from the September 2019 presidential election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns about electoral fraud and controversy over an electoral recount, which looked to favor incumbent President Ashraf Ghani.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of opposition presidential candidate Abdullah Abdullah.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "AF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AFCapitalDataActive,
		        inactiveCapitalData: AFCapitalDataInctive
		    },
		    {
		        id: "AF",
		        arrayItem: true,
		        country: 'Afghanistan',
		        protest_name: '<span class="protest">“Commander Sword” protests</span>',
		        date: '2018-11-01',
		        date_text: "November 2018",
		        freedom: 'Not free',
		        triggers: 'The arrest of a popular ethnic Hazara militia commander known as Commander Sword.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Conflict between the national government and ethnic minorities; allegations that the Hazara ethnic group had been unfairly targeted by authorities.',
		        size: 999,
		        size_text: '<1,000',
		        participants: 'Ethnic Hazaras.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The arrested commander was released after two days.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "AF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AFCapitalDataActive,
		        inactiveCapitalData: AFCapitalDataInctive
		    },
		    {
		        id: "AF",
		        arrayItem: true,
		        country: 'Afghanistan',
		        protest_name: '<span class="protest">Truck bombing protests</span>',
		        date: '2017-06-01',
		        date_text: "June 2017",
		        freedom: 'Not free',
		        triggers: 'A truck bombing on May 31, 2017, which killed over 150 people. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger at the inability of President Ashraf Ghani’s national unity government to curb violence. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Ghani fired two ministers but also engaged in the bloodiest crackdown in decades. Half a dozen people were killed by security forces, and dozens were wounded. ',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "AF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AFCapitalDataActive,
		        inactiveCapitalData: AFCapitalDataInctive
		    },
		    {
		        id: "AL",
		        arrayItem: true,
		        country: 'Albania',
		        protest_name: '<span class="protest">Anti-Rama protests</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'Lawmakers of the conservative Democratic Party of Albania have been protesting the imprisonment of their colleague, Ervin Salianji, on charges of slander. They were also protesting the arrest of former prime minister (and current Democratic Party leader) Sali Berisha.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Call for Prime Minister Edi Rama to step down and for the government to be replaced by a caretaker cabinet before the 2025 parliamentary election. The opposition has repeatedly accused Rama and the Socialist Party of Albania of corruption.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the Democratic Party',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Police used water guns and tear gas against protesters to keep them away from government buildings. In November 2024, Berisha was released from house arrest.',
		        multiple: true,
		        mapData: {
		        	"id": "AL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ALCapitalDataActive,
		        inactiveCapitalData: ALCapitalDataInctive
		    },
		    {
		        id: "AL",
		        arrayItem: true,
		        country: 'Albania',
		        protest_name: '<span class="protest">Anti-Rama corruption protests</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'The government is investigating opposition leader Sali Berisha of the Democratic Party for corruption while he was Prime Minister between 2005 and 2013. February 20 was the anniversary of when pro-democracy activists tore down the statue of longtime communist dictator Enver Hoxha. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protesters accuse Prime Minister Edi Rama of corruption and nepotism. They allege that the investigation into Berisha is driven by a political vendetta meant to silence opponents. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'The Democratic Party, Sali Berisha ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "AL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ALCapitalDataActive,
		        inactiveCapitalData: ALCapitalDataInctive
		    },
		    {
		        id: "AL",
		        arrayItem: true,
		        country: 'Albania',
		        protest_name: '<span class="protest">Corruption and rising price protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Partly free',
		        triggers: 'Increasing costs and rising poverty.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with perceived government failure to combat inflation and corruption.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "AL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ALCapitalDataActive,
		        inactiveCapitalData: ALCapitalDataInctive
		    },
		    {
		        id: "AL",
		        arrayItem: true,
		        country: 'Albania',
		        protest_name: '<span class="protest">Rising price protests</span>',
		        date: '2022-03-01',
		        date_text: "March 2022",
		        freedom: 'Partly free',
		        triggers: 'Rapid increases in food and fuel prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with high poverty and low wages and perceived government inaction.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ALCapitalDataActive,
		        inactiveCapitalData: ALCapitalDataInctive
		    },
		    {
		        id: "AL",
		        arrayItem: true,
		        country: 'Albania',
		        protest_name: '<span class="protest">Police violence protests</span>',
		        date: '2020-12-01',
		        date_text: "December 2020",
		        freedom: 'Partly free',
		        triggers: 'The fatal shooting of Klodian Rasha by an officer enforcing a coronavirus curfew.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns about excessive force being used by law enforcement to impose strict coronavirus measures.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public, especially youth.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "AL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ALCapitalDataActive,
		        inactiveCapitalData: ALCapitalDataInctive
		    },
		    {
		        id: "AL",
		        arrayItem: true,
		        country: 'Albania',
		        protest_name: '<span class="protest">Local elections protests</span>',
		        date: '2019-02-01',
		        date_text: "February 2019",
		        freedom: 'Partly free',
		        triggers: 'Suspicions that Prime Minister Edi Rama engaged in electoral fraud to win the country’s 2017 parliamentary elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Electoral fraud and corruption.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition parties.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ALCapitalDataActive,
		        inactiveCapitalData: ALCapitalDataInctive
		    },
		    {
		        id: "DZ",
		        arrayItem: true,
		        country: 'Algeria',
		        protest_name: '<span class="protest">Protests against “Le Pouvoir”</span>',
		        date: '2019-02-01',
		        date_text: "February 2019",
		        freedom: 'Not free',
		        triggers: 'Then president Abdelaziz Bouteflika’s decision to seek a fifth term as president.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Economic downturn, corruption and nepotism, and desire for removal of entrenched military and political elites. ',
		        size: 1000000,
		        size_text: '>1,000,000',
		        participants: 'Opposition parties; a wide range of everyday citizens, such as bankers, truck drivers, teachers, students.',
		        timespan: '1 year (first round);<br /> 2 months (second wave) ',
		        timespan_text: '',
		        outcomes: 'Bouteflika resigned. Military and political elites retained power in subsequent elections, triggering further unrest.',
		        significant: true,
		        large: true,
		        long: true,
		        multiple: false,
		        mapData: {
		        	"id": "DZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DZCapitalDataActive,
		        inactiveCapitalData: DZCapitalDataInctive
		    },
		    {
		        id: "AO",
		        arrayItem: true,
		        country: 'Angola',
		        protest_name: '<span class="protest">Fuel price hike protests</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Not free',
		        triggers: 'The government of João Lourenço cut fuel subsidies on June 2, causing the price of fuel nearly to double.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the rising price of fuel in Angola. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Young people, motorcycle taxi drivers.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'On June 8, Lourenço fired economic coordination minister Manuel Nunes Junior and replaced him with the central bank governor.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "AO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AOCapitalDataActive,
		        inactiveCapitalData: AOCapitalDataInctive
		    },
		    {
		        id: "AO",
		        arrayItem: true,
		        country: 'Angola',
		        protest_name: '<span class="protest">2022 election protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Not free',
		        triggers: 'The Constitutional Court threw out a complaint by the runner-up of the 2022 election which claimed that the vote was flawed.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceived unfairness of electoral processes; frustrations with nearly five decades of uninterrupted MPLA rule. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "AO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AOCapitalDataActive,
		        inactiveCapitalData: AOCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Pension protest</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Free',
		        triggers: 'President Javier Milei announced his plan to veto a bill that would increase pensions in line with the inflation rate.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over Milei’s decision, especially amid the severe economic struggles that citizens are facing as a result of Milei’s austerity laws.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Pensioners',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Congress upheld Milei’s veto.',
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Protest against cuts to higher education budget</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Free',
		        triggers: 'President Javier Milei has implemented a number of spending cuts that have led to mass layoffs, including cuts to fuel and transportation subsidies. Milei has now cut the budget of public universities.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand that Milei increase funding for higher education, which is largely dependent upon government subsidies. Protesters believe that the budget cuts threaten not only the livelihoods of university faculty, but also the sustainability of the public education system.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public, university students and teachers',
		        timespan: '1 day in April 2024;<br />active since October 2024',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. On October 3, Milei vetoed a law passed by Congress that would have increased funding for universities, sparking another wave of protests.',
				active: true,
				large: true,
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Day of Remembrance for Truth and Justice protest</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Free',
		        triggers: 'The fiftieth anniversary of the 1976 coup, which brought to power Argentina’s military dictatorship, after which the junta embarked on a dirty war targeting suspected political dissidents on the left. An estimated 30,000 died or disappeared as a result between 1976 and 1983, though President Javier Milei estimates around 9,000 victims in what his critics say is an effort to whitewash the military dictatorship.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Remember the victims of the coup and the subsequent dirty wars. Express discontent with Milei’s downplaying of the tragedy. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public; labor unions',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Anti-austerity protests</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'President Javier Milei announced austerity measures including a 54 percent devaluation of the peso, cuts to subsidies, and closures of multiple government agencies. Milei’s proposed labor reforms include extending the legal job probation period from three to eight months, reducing compensation in case of dismissal, and cutting pregnancy leave. The sovereign bond market rallied in early 2024 as the government recorded its first surplus in years—reached by slashing social security by 30 percent, when adjusted for inflation. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters denounced the austerity measures put forward by the new president. As Milei has implemented his austerity policies, protesters have continued to express discontent as they struggle with currency devaluation amid rising costs. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General Confederation of Labor; general public',
		        timespan: '7 months',
		        timespan_text: '',
		        outcomes: 'On the same day thousands marched against austerity in December 2023, Milei announced wide-reaching deregulation of the economy and the establishment of a framework for privatizing state-run industries. Milei’s labor reforms were temporarily frozen by judges of Argentina’s labor appeals chamber. The bill was approved by the Senate on June 2024. In September 2024, Argentina’s poverty rate rose to over 50 percent as a result of Milei’s austerity policies.',
		        violent: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Kirchner prosecution protest</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Free',
		        triggers: 'Prosecutors requested Vice President Cristina Fernández de Kirchner be sentenced to prison for 12 years for corruption.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perceived political and judicial persecution against Kirchner.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially supporters of Kirchner.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Economic crisis protests</span>',
		        date: '2022-04-01',
		        date_text: "April 2022",
		        freedom: 'Free',
		        triggers: 'The government’s adoption of interventionist policies to curb rising prices for agricultural goods; rising inflation across many sectors.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with high tax rates and persistently high inflation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '8 months',
		        timespan_text: '',
		        outcomes: 'Economy Minister Martín Guzmán resigned. He was replaced by Silvina Batakis. She resigned after one month and was replaced by Sergio Massa. In October 2022, then president Alberto Fernández announced a cabinet reshuffle, appointing Victoria Tolosa Paz to head the Ministry of Social Development; Ayelén Mazzina to the Ministry of Women, Gender and Diversity; and Raquel Olmos to the Ministry of Labor. In November 2022, Argentina’s government announced a plan to freeze or tightly regulate the prices of approximately 1,500 products in an attempt to contain inflation. Libertarian Javier Milei won the November 2023 presidential election, promising to enact radical fiscal and monetary reforms. Markets reacted positively, with the sovereign bonds rallying.  ',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">IMF deal protest</span>',
		        date: '2021-12-01',
		        date_text: "December 2021",
		        freedom: 'Free',
		        triggers: 'Ongoing government negotiations with the International Monetary Fund over debt restructuring.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over rising economic insecurity; fear that restructuring IMF debt would lead to fiscal austerity measures.',
		        size: 2001,
		        size_text: '>2,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The government adopted an agreement with the IMF to restructure the country’s debt.',
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Coronavirus lockdown and judicial reform protests</span>',
		        date: '2020-08-01',
		        date_text: "August 2020",
		        freedom: 'Free',
		        triggers: 'Announcement of continued lockdown measures and introduction of judicial reform bill. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Stringent lockdown measures, economic strain, and possible impunity for corrupt government officials.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, supporters of the former government of president Mauricio Macri.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        significant: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Violence against women protests</span>',
		        date: '2020-04-01',
		        date_text: "April 2020",
		        freedom: 'Free',
		        triggers: 'Increasing rates of violence and femicide against women under quarantine.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Violence against women and lack of resources/institutions to combat it.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women.',
		        timespan: '1 month in 2020,<br />1 day in November 2022',
		        timespan_text: '',
		        outcomes: 'The Supreme Court of Mendoza established several protocols for domestic violence victims to file complaints.',
		        significant: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AR",
		        arrayItem: true,
		        country: 'Argentina',
		        protest_name: '<span class="protest">Austerity protests</span>',
		        date: '2018-09-01',
		        date_text: "September 2018",
		        freedom: 'Free',
		        triggers: 'Proposal of austerity measures mandated by the International Monetary Fund.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Rising inflation and unemployment, as well as higher utility costs.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Teachers, social organizations, leftist groups. ',
		        timespan: 'Sporadic',
		        timespan_text: '1 year',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ARCapitalDataActive,
		        inactiveCapitalData: ARCapitalDataInctive
		    },
		    {
		        id: "AM",
		        arrayItem: true,
		        country: 'Armenia',
		        protest_name: '<span class="protest">Protest against ceding territory to Azerbaijan</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Partly free',
		        triggers: 'On April 19, 2024, the government announced a border delimitation agreement which will cede four territories in Armenia near Azerbaijan’s Qazax region to Azerbaijan. These villages were part of Azerbaijan during the Soviet era but have been controlled by Armenia since the 1990s. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against deal to cede territory to Azerbaijan, as many Armenians see the country as a political rival and threat. Protesters also call for the resignation of Armenian Prime Minister Nikol Pashinyan because of his acceptance of the deal ceding border villages to Azerbaijan. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests. Protesters scuffled with riot police in Yerevan and dozens were arrested in May 2024. ',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "AM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AMCapitalDataActive,
		        inactiveCapitalData: AMCapitalDataInctive
		    },
		    {
		        id: "AM",
		        arrayItem: true,
		        country: 'Armenia',
		        protest_name: '<span class="protest">Nagorno-Karabakh crisis protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Partly free',
		        triggers: 'On September 19, Azerbaijan launched a military operation against the breakaway Nagorno-Karabakh region and won decisively by September 20.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fury over the inability of Armenian leaders and Russian peacekeepers to prevent Azerbaijan from capturing the majority-Armenian breakaway region of Nagorno-Karabakh.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "AM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AMCapitalDataActive,
		        inactiveCapitalData: AMCapitalDataInctive
		    },
		    {
		        id: "AM",
		        arrayItem: true,
		        country: 'Armenia',
		        protest_name: '<span class="protest">Lachin corridor blockade protests</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'Azerbaijan banned Russian peacekeepers from delivering humanitarian aid to Nagorno-Karabakh on June 15, exacerbating a humanitarian crisis for the people of the region.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Outrage over the condition of ethnic Armenians in Nagorno-Karabkh at the hands of the Azerbaijani government.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'On September 19, Azerbaijan launched a military operation against Nagorno-Karabakh, decisively ending the breakaway region’s quest for statehood. Within ten days, an estimated 80 percent of Nagorno-Karabakh’s population fled across the Lachin Corridor to Armenia.',
		        multiple: true,
		        mapData: {
		        	"id": "AM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AMCapitalDataActive,
		        inactiveCapitalData: AMCapitalDataInctive
		    },
		    {
		        id: "AM",
		        arrayItem: true,
		        country: 'Armenia',
		        protest_name: '<span class="protest">Peace plan protests</span>',
		        date: '2020-11-01',
		        date_text: "November 2020",
		        freedom: 'Partly free',
		        triggers: 'Announcement of a Russian-brokered peace deal that ceded a sizable part of the contested Nagorno-Karabakh region to Azerbaijan; in May 2022, the government’s announcement that it may have to reduce territorial claims as part of the peace process. In September 2022, a new offensive that resulted in further territorial losses for Armenia.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Nationalist sentiment and a belief in Armenian territorial claims to the Nagorno-Karabakh regions, and anger over concessions to Azerbaijan. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Coalition of seventeen opposition parties and their adherents.',
		        timespan: '6 months in 2020–2021;<br />2 months from April–June 2022;<br />2 months from September–November 2022.',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AMCapitalDataActive,
		        inactiveCapitalData: AMCapitalDataInctive
		    },
		    {
		        id: "AM",
		        arrayItem: true,
		        country: 'Armenia',
		        protest_name: '<span class="protest">“#RejectSerzh” protests</span>',
		        date: '2018-04-01',
		        date_text: "April 2018",
		        freedom: 'Partly free',
		        triggers: 'Election of then president Serzh Sargsyan as prime minister, allowing him to subvert presidential term limits.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Democratic backslide, poverty, and corruption.',
		        size: 100000,
		        size_text: '>100,000',
		        participants: 'Students, opposition leaders.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'Sargsyan resigned as prime minister and was succeeded by Nikol Pashinyan, an opposition leader and leading figure in the protests.',
		        significant: true,
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "AM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AMCapitalDataActive,
		        inactiveCapitalData: AMCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">"Australia Day" protests</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Free',
		        triggers: '"Australia Day" on January 26, which commemorates the day that the first British colony was established in Sydney Cove.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize Britain’s colonization of Australia and the ongoing mistreatment of indigenous Australian communities. Demand that "Australia Day" be moved to a different date and that January 26 be commemorated as a day of mourning instead.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Indigenous activists, general public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">Farmers’ protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'Australian government adopted new farming and environmental policies, including a ban on exports of live sheep, water usage restrictions, and construction of renewable power sources in rural areas.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the new policies, which many farmers argue are hurting them. Protesters also feel that the government is ignoring the demands of farmers and prioritizing the demands of activists instead.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers, National Farmers’ Federation (NFF)',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'On September 23, Tony Mahar, the CEO of the NFF, was appointed as the new Australian energy infrastructure commissioner. No policy changes have been made.',
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">Protest against Cambodia-Laos-Vietnam Development Triangle Area (CLV-DTA)</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Free',
		        triggers: 'Arrest of three Cambodian activists who shared concerns over the CLV-DTA, an economic agreement between Cambodia, Laos, and Vietnam.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Overseas Cambodians are demanding that Cambodia withdraw from the CLV-DTA, which they believe will cause Cambodia to lose territorial sovereignty to Vietnamese or Lao investors. They are also concerned about potential influx of immigrants from Vietnam, which Cambodia has historically had negative relations with.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Cambodians in Australia',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The Cambodian government threatened further arrests of activists in Cambodia and blocked a similar protest from occuring in Phnom Penh, Cambodia. On September 20, Prime Minister Hun Manet announced that Cambodia had withdrawn from the CLV-DTA.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">Australia Day protests</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'Australia Day, an annual holiday held on January 26 marking the anniversary of when Britain established the state of New South Wales.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Indigenous community members feel that the holiday celebrates their loss of sovereignty and injustices that have followed the arrival of Europeans. ',
		        size: 1001,
		        size_text: '>1,0000',
		        participants: 'Indigenous community ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Free',
		        triggers: 'The imposition of lockdowns and vaccine mandates across many Australian states.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with ongoing coronavirus restrictions and mandates. ',
		        size: 4001,
		        size_text: '>4,000',
		        participants: 'General public.',
		        timespan: '8 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">Violence against women protest</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Free',
		        triggers: 'Widespread sexual harassment and perceptions of impunity over a rape allegation made by former political aide Brittany Higgins. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over a perceived culture of impunity for violence and discrimination against women, especially in elite institutions. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially women.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">Racial equality protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Free',
		        triggers: 'Death of George Floyd in the United States, sparking protests in Australia against local police brutality and racism. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Local issues of racism, institutional racism, and police violence against Aboriginal people.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AU",
		        arrayItem: true,
		        country: 'Australia',
		        protest_name: '<span class="protest">Wildfire protests</span>',
		        date: '2020-01-01',
		        date_text: "January 2020",
		        freedom: 'Free',
		        triggers: 'Government response to the Australian bushfires.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Climate change and weak government policies on climate.',
		        size: 30000,
		        size_text: '30,000',
		        participants: 'Students, activists.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'Prime Minister Scott Morrison admitted some regret over government policies, increased funding to respond to the bushfires, and promised that a royal commission would review the government response. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "AU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AUCapitalDataActive,
		        inactiveCapitalData: AUCapitalDataInctive
		    },
		    {
		        id: "AT",
		        arrayItem: true,
		        country: 'Austria',
		        protest_name: '<span class="protest">Protest against Freedom Party</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Free',
		        triggers: 'Austria’s far-right Freedom Party (FPÖ) won the general elections with 29 percent of the vote. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Urge other political parties not to form a coalition government with the FPÖ, which protesters believe is a danger to Austria’s democracy. Concern over issues relating to human rights and democracy under potential FPÖ rule.',
		        size: 49999,
		        size_text: '<50,000',
		        participants: 'Opposition, general public',
		        timespan: '1 day in October 2024;<br />1 day in January 2025',
		        timespan_text: '',
		        outcomes: 'As political party agreed to form a coalition government with the FPÖ, President Alexander Van der Bellen initially asked incumbent Chancellor Karl Nehammer and his party, the conservative Austrian People’s Party, to form a government. However, after coalition talks collapsed, Van der Bellen tasked Herbert Kickl, the leader of the FPÖ, with forming a coalition government.',
		        multiple: true,
		        mapData: {
		        	"id": "AT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ATCapitalDataActive,
		        inactiveCapitalData: ATCapitalDataInctive
		    },
		    {
		        id: "AT",
		        arrayItem: true,
		        country: 'Austria',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-11-01',
		        date_text: "November 2021",
		        freedom: 'Free',
		        triggers: 'The imposition of vaccine mandates and public health restrictions across Austria.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with ongoing coronavirus restrictions and mandates. ',
		        size: 40001,
		        size_text: '>40,000',
		        participants: 'General public, especially unvaccinated populations. ',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ATCapitalDataActive,
		        inactiveCapitalData: ATCapitalDataInctive
		    },
		    {
		        id: "AZ",
		        arrayItem: true,
		        country: 'Azerbaijan',
		        protest_name: '<span class="protest">Lachin corridor blockade protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Not free',
		        triggers: 'Dozens of Azerbaijanis blocked the Lachin corridor, which connects Armenia to Nagorno-Karabakh.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceptions that the Azerbaijani government was supporting the people blocking the Lachin corrider; anger and fear over resulting humanitarian crisis.',
		        size: 6001,
		        size_text: '>6,000',
		        participants: 'Residents of Nagorno-Karabakh.',
		        timespan: '9 months',
		        timespan_text: '',
		        outcomes: 'On September 19, Azerbaijan launched a military operation against Nagorno-Karabakh, decisively ending the breakaway region’s quest for statehood. Within ten days, an estimated 80 percent of Nagorno-Karabakh’s population fled across the Lachin Corridor to Armenia.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "AZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AZCapitalDataActive,
		        inactiveCapitalData: AZCapitalDataInctive
		    },
		    {
		        id: "AZ",
		        arrayItem: true,
		        country: 'Azerbaijan',
		        protest_name: '<span class="protest">Pro-war protest</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Not free',
		        triggers: 'Brief conflict with Armenia over the contested Nagorno-Karabakh region, which resulted in the deaths of eleven Azerbaijani soldiers, including then major general Polad Hashimov.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the government’s refusal to escalate the territorial conflict in Nagorno-Karabakh.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The Azerbaijani military initiated a campaign to take back Nagorno-Karabakh in September 2020.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "AZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AZCapitalDataActive,
		        inactiveCapitalData: AZCapitalDataInctive
		    },
		    {
		        id: "AZ",
		        arrayItem: true,
		        country: 'Azerbaijan',
		        protest_name: '<span class="protest">Political prisoner protest</span>',
		        date: '2019-01-01',
		        date_text: "January 2019",
		        freedom: 'Not free',
		        triggers: 'Opening of a new criminal case against  political prisoner and anticorruption blogger Mehman Huseynov, who had run a Facebook page critical of the authorities.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Imprisonment of journalists and civil society members, political prosecutions, police brutality, corruption, and lack of democratic reform.',
		        size: 5000,
		        size_text: '5,000',
		        participants: 'Umbrella group of opposition parties.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Government dropped the new charges against Huseynov.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "AZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AZCapitalDataActive,
		        inactiveCapitalData: AZCapitalDataInctive
		    },
		    {
		        id: "AZ",
		        arrayItem: true,
		        country: 'Azerbaijan',
		        protest_name: '<span class="protest">Anticorruption protest</span>',
		        date: '2017-10-01',
		        date_text: "October 2017",
		        freedom: 'Not free',
		        triggers: 'The detention of six opposition activists.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration over rampant official corruption, crackdowns on political prisoners, and the entrenchment of the regime of President Ilham Aliyev.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the National Council of Democratic Forces, an opposition umbrella group.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "AZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "AZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: AZCapitalDataActive,
		        inactiveCapitalData: AZCapitalDataInctive
		    },
		    {
		        id: "BH",
		        arrayItem: true,
		        country: 'Bahrain',
		        protest_name: '<span class="protest">Political prisoner death protest</span>',
		        date: '2021-06-01',
		        date_text: "June 2021",
		        freedom: 'Not free',
		        triggers: 'Death of political prisoner Husain Barakat from COVID-19 while in police custody.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the detention of political prisoners, poor prison conditions, and ineffectual vaccines.',
		        size: 501,
		        size_text: '>500',
		        participants: 'Residents of the town of Diah; general public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: false,
		        mapData: {
		        	"id": "BH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BHCapitalDataActive,
		        inactiveCapitalData: BHCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">"March for Unity"</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Partly free',
		        triggers: 'The Anti-Discrimination Student Movement organized a march at the Central Shaheed Minar in Dhaka. In December 2024, Foreign Affairs Adviser Md. Touhid Hossain announced that Bangladesh had sent a formal request to India to extradite former prime minister Sheikh Hasina.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for prosecution of Hasina and others responsible for the death of protesters in July 2024. Demand that the interim government ban the Awami League party (Hasina’s party) and issue a new constitution.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Anti-Discrimination Student Movement (student protesters)',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. The interim government has continued its efforts towards prosecuting Hasina and reforming the constitution.',
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Anti-India protests</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Partly free',
		        triggers: 'On December 2, Bangladesh’s consulate office in Agartala, India, was attacked by a far-right Hindu group. There were also reports that Bangladeshi flags were being desecrated.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce the attack on Bangladesh’s consulate office and the desecration of Bangladeshi flags. Part of growing tension between India and Bangladesh since the end of former prime minister Sheikh Hasina’s rule. Protesters accuse India of instigating riots in Bangladesh for their political gain.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Bangladesh Nationalist Party youth and student groups',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'The Bangladeshi and Indian foreign secretaries met in Dhaka to discuss ways to reduce the growing tensions between the two countries.',
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Anti–Awami League protest</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Partly free',
		        triggers: 'The Awami League, the political party of former prime minister Sheikh Hasina, planned to hold a rally on November 10. The day before the planned rally, Chief Adviser Press Secretary Shafiqul Alam announced that the Awami League would not be permitted to hold its demonstration.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Stop Awami League supporters from holding the rally. Condemn Hasina and her party. Demand that Hasina be tried for the deaths of protesters in July.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Anti-discrimination Students Movement and supporters of various political parties (e.g., Bangladesh Nationalist Party, Jamaat-e-Islami, Gono Odhikar Parishad)',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. The Awami League failed to hold its planned rally as a result of the counterprotests. Several of the party’s supporters were attacked and arrested.',
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Protest for new elections</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Partly free',
		        triggers: 'The current interim government of Bangladesh has not yet announced a timeframe for the next elections. The Bangladesh Nationalist Party called for a rally in Dhaka.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for the government to develop a clear timeline for the next elections and to implement political reforms quickly.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'BNP supporters',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The government appointed a new election commission to oversee the electoral reforms. In December 2024, interim leader Muhammad Yunus announced that the next general elections would be held in late 2025 or early 2026, depending on how quickly the government would implement the full list of reforms.',
				signficant: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Protest for the resignation of President Shahabuddin</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'On October 21, President Mohammed Shahabuddin revealed that he had not received a resignation letter from former prime minister Sheikh Hasina before she fled the country.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over Shahabuddin’s statement, which contradicts his words in the past and threatens the legitimacy of the interim government. Accuse Shahabuddin of collaborating with Hasina’s regime and demand that he resign from his position.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Anti-Discrimination Student Movement (student protesters)',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Protest over violence against Hindus</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'Growing number of attacks against Hindus and the destruction of Hindu houses, businesses, and temples after former prime minister Sheikh Hasina’s resignation. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand action against the attacks and vandalism. Call for the creation of a ministry for minority affairs, reserved seats for minorities, and a minority protection law. Also demand to meet with student protest leaders. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Bangladeshi Hindus',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'On August 11, the interim government announced that it would work with representatives of the Hindu community to resolve the violence. On September 11, Chief Advisor Muhammad Yunus made a televised speech calling for "religious harmony." ',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Job quota protest</span>',
		        date: '2024-07-01',
		        date_text: "July 2024",
		        freedom: 'Partly free',
		        triggers: 'Dhaka court reinstated quota for government jobs (previously abolished in 2018 due to student protests).',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Pushing the government to abolish the quota, which reserves 30 percent of government jobs to family members of those who fought in Bangladesh’s independence movement, and demanding that the government select candidates on merit. Protesters believe that the quota is discriminatory because it disproportionally benefits supporters of the Awami League party, which led the independence movement. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Students',
		        timespan: '1 month in July 2024;<br />1 day in September 2024 to mark the one-month anniversary of former prime minister Sheikh Hasina’s resignation.',
		        timespan_text: '',
		        outcomes: 'On July 22, the Supreme Court ruled that the war veterans’ quota be reduced to five percent. The government accepted the ruling the next day on July 23. More than 250 people, including protestors and police were killed during the protests. On August 5, protestors overran the prime minister’s palace and Prime Minister Sheikh Hasina resigned from her position and fled the country, ending her fifteen-year rule.',
				significant: true,
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Rohingyha repatriation protest</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Partly free',
		        triggers: 'The World Food Programme cut the monthly food allocation for refugees from $10 to $8, having reduced the ration from $12 to $10 in March due to a reduction in global aid for the refugees. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Desire to leave the squalid camps in which the refugees have been living since 2017; hopelessness over prospects of returning to Myanmar.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Rohingya refugees.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Economic protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Partly free',
		        triggers: 'The opposition Bangladesh Nationalist Party (BNP) called for massive demonstrations to demand the resignation of the government led by Prime Minister Sheikh Hasina. In February 2023, the government shut down the BNP’s newspaper.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceived failure to combat rising fuel prices and cost-of-living issues. Call for Hasina to resign. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Members of the opposition BNP party.',
		        timespan: '2 years',
		        timespan_text: '',
		        outcomes: 'On August 7, Law, Justice and Parliamentary Affairs Minister Anisul Huq announced that the government would tone down its Digital Security Act; critics argued that the law had been used to silence journalists. BNP leader Mirza Fakhrul Islam Alamgir was arrested after a police officer was killed during clashes with protesters on October 28. Over 8,000 opposition activists were also arrested. Hasina’s party won the January 7, 2024, general election in a landslide victory.',
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Fuel price hike protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Partly free',
		        triggers: 'The government increased the price of fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears over worsening economic conditions due to high inflation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'The government reduced the price of fuel by 5 taka per liter.',
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Anti-rape protests</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Partly free',
		        triggers: 'Circulation of a video of sexual assault.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'An ongoing sense of impunity for rape and sexual assault perpetrators, especially directed at the state for failing to prosecute or impose significant penalties.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, especially women and students.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The government approved measures to impose the death penalty or lifetime "rigorous imprisonment" for rape.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Student murder protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Murder of Abrar Fahad, a student who posted anti-government content on his Facebook feed. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over the increasing levels of political violence, especially from the Chhatra League, the youth wing of the ruling Bangladesh Awami League.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Students.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Blasphemy protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Circulation of a social media post that allegedly blasphemed the Prophet Muhammad.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over secularism and blasphemy in Bangladeshi society, as well as simmering tensions between Muslims and Hindus.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Muslims.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Garment worker protests</span>',
		        date: '2019-01-01',
		        date_text: "January 2019",
		        freedom: 'Partly free',
		        triggers: 'Failure by garment industry employers to raise wages to match the cost of living or provide adequate working conditions.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic inequality and low wages.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Garment workers.',
		        timespan: '1 year, intermittently.<br />Reactivated November 2023.',
		        timespan_text: 'Intermittently',
		        outcomes: 'The government announced a pay raise for low-income workers.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Road safety protests</span>',
		        date: '2018-07-01',
		        date_text: "July 2018",
		        freedom: 'Partly free',
		        triggers: 'Two students killed by a bus driver.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Lack of road safety, corruption in transportation sector, arrests or disappearances of opposition activists in recent years, and lack of accountable governance.',
		        size: 15000,
		        size_text: '15,000',
		        participants: 'Middle school, high school, university students.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Installation of legal reforms to raise penalties related to dangerous driving.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BD",
		        arrayItem: true,
		        country: 'Bangladesh',
		        protest_name: '<span class="protest">Job quota protests</span>',
		        date: '2018-02-01',
		        date_text: "February 2018",
		        freedom: 'Partly free',
		        triggers: 'The failure of the government to end quotas for jobs in government ministries.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over lack of economic opportunity and unequal government hiring practices.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially those seeking work in the public sector.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'The government agreed to a piecemeal plan to end hiring quotas over the following two years.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BDCapitalDataActive,
		        inactiveCapitalData: BDCapitalDataInctive
		    },
		    {
		        id: "BY",
		        arrayItem: true,
		        country: 'Belarus',
		        protest_name: '<span class="protest">Anti-war protest</span>',
		        date: '2022-02-01',
		        date_text: "February 2022",
		        freedom: 'Not free',
		        triggers: 'The holding of a referendum on whether to adopt a new constitution that would renounce Belarus’s non-nuclear status and allow President Alexander Lukashenko to stay in power until 2035.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over the change in nuclear status; frustration with entrenchment of the Lukashenko regime; and disagreement with the Belarusian government’s support for Russia’s invasion of Ukraine.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "BY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BYCapitalDataActive,
		        inactiveCapitalData: BYCapitalDataInctive
		    },
		    {
		        id: "BY",
		        arrayItem: true,
		        country: 'Belarus',
		        protest_name: '<span class="protest">“Slipper uprising”</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Not free',
		        triggers: 'Arrests of opposition presidential candidates; anger over the perceived fraudulent August 2020 presidential election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition to President Alexander Lukashenko’s reelection campaign; frustration over the lack of genuine political pluralism.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '11 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BYCapitalDataActive,
		        inactiveCapitalData: BYCapitalDataInctive
		    },
		    {
		        id: "BY",
		        arrayItem: true,
		        country: 'Belarus',
		        protest_name: '<span class="protest">Russian integration protests</span>',
		        date: '2019-07-01',
		        date_text: "July 2019",
		        freedom: 'Not free',
		        triggers: 'A series of meetings between Belarusian President Aleksandr Lukashenko and Russian President Vladimir Putin.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition to deepening ties between Russia and Belarus, especially in the wake of Russia’s annexation of Crimea.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BYCapitalDataActive,
		        inactiveCapitalData: BYCapitalDataInctive
		    },
		    {
		        id: "BY",
		        arrayItem: true,
		        country: 'Belarus',
		        protest_name: '<span class="protest">“Parasite” tax protests</span>',
		        date: '2017-02-01',
		        date_text: "February 2017",
		        freedom: 'Not free',
		        triggers: 'Labor law that would require citizens who work less than half the year and fail to register with state labor exchanges to pay $250.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic downturn, poverty, continued political restrictions, and detention of opposition activists. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'President Alexander Lukashenko suspended the tax for a year.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "BY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BYCapitalDataActive,
		        inactiveCapitalData: BYCapitalDataInctive
		    },
		    {
		        id: "BE",
		        arrayItem: true,
		        country: 'Belgium',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'Farmers’ frustration about rising costs, EU environmental policies, and a plan for cheap food imports from outside the EU, which they fear will undercut their prices. The G20 Summit in November 2024 brought renewed attention to a prospective trade agreement between the EU and Mercosur trade bloc of South America.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand financial assistance to ensure the price of food reflects the additional costs European farmers face to comply with environmental regulation. As of November 2024, opposition to EU-Mercosur trade deal, which farmers argue will bring large agricultural imports that aren’t bound by EU regulations and create unfair competition for EU farmers.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers',
		        timespan: '6 months;<br />active since November 2024',
		        timespan_text: '',
		        outcomes: 'In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers. Despite opposition from farmers and certain EU countries (namely France), EU and South American officials reached a trade deal on December 6, 2024.',
				active: true,
		        violent: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BECapitalDataActive,
		        inactiveCapitalData: BECapitalDataInctive
		    },
		    {
		        id: "BE",
		        arrayItem: true,
		        country: 'Belgium',
		        protest_name: '<span class="protest">Farmer protest</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Free',
		        triggers: 'The Flemish regional government introduced plans to limit nitrogen emissions.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the proposed legislation would disproportionately impact farmers’ livelihoods.',
		        size: 2001,
		        size_text: '>2,000',
		        participants: 'Farmers',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "BE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BECapitalDataActive,
		        inactiveCapitalData: BECapitalDataInctive
		    },
		    {
		        id: "BE",
		        arrayItem: true,
		        country: 'Belgium',
		        protest_name: '<span class="protest">Cost of living protest</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Free',
		        triggers: 'The country’s three largest trade unions called for a protest.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Surging gas and electricity prices.',
		        size: 16500,
		        size_text: '16,500',
		        participants: 'Public sector employees.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "BE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BECapitalDataActive,
		        inactiveCapitalData: BECapitalDataInctive
		    },
		    {
		        id: "BE",
		        arrayItem: true,
		        country: 'Belgium',
		        protest_name: '<span class="protest">Fuel price protest</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices, especially of fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations over falling standard of living, rising profits for energy companies, and stagnating wages.',
		        size: 10000,
		        size_text: '10,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "BE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BECapitalDataActive,
		        inactiveCapitalData: BECapitalDataInctive
		    },
		    {
		        id: "BE",
		        arrayItem: true,
		        country: 'Belgium',
		        protest_name: '<span class="protest">Cost of living strike and protest</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Free',
		        triggers: 'National strike over wages and working conditions, especially among airport and other public transit workers.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations over stagnating wages and a rising cost of living.',
		        size: 70001,
		        size_text: '>70,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "BE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BECapitalDataActive,
		        inactiveCapitalData: BECapitalDataInctive
		    },
		    {
		        id: "BE",
		        arrayItem: true,
		        country: 'Belgium',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-01-01',
		        date_text: "January 2021",
		        freedom: 'Free',
		        triggers: 'The imposition of lockdowns and vaccine mandates across Belgium.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with ongoing coronavirus restrictions and mandates. ',
		        size: 30001,
		        size_text: '>30,000',
		        participants: 'General public.',
		        timespan: '14 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BECapitalDataActive,
		        inactiveCapitalData: BECapitalDataInctive
		    },
		    {
		        id: "BJ",
		        arrayItem: true,
		        country: 'Benin',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Partly free',
		        triggers: 'Prices of staple crops such as corn, garri flour, beans, and cowpeas have risen in recent months. The IMF reported in April 2024 that incomes in sub-Saharan Africa are falling further behind the rest of the world amid tepid economic growth.  ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand government assistance to help residents facing rising costs.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Trade unions',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "BJ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BJ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BJCapitalDataActive,
		        inactiveCapitalData: BJCapitalDataInctive
		    },
		    {
		        id: "BJ",
		        arrayItem: true,
		        country: 'Benin',
		        protest_name: '<span class="protest">Reelection protests</span>',
		        date: '2021-04-01',
		        date_text: "April 2021",
		        freedom: 'Partly free',
		        triggers: 'Incumbent President Patrice Talon’s decision to seek another five-year term in office.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with declining political pluralism and Talon’s decision to seek reelection despite having said he would leave after a single term.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "BJ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BJ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BJCapitalDataActive,
		        inactiveCapitalData: BJCapitalDataInctive
		    },
		    {
		        id: "BJ",
		        arrayItem: true,
		        country: 'Benin',
		        protest_name: '<span class="protest">Election protests</span>',
		        date: '2019-03-01',
		        date_text: "March 2019",
		        freedom: 'Partly free',
		        triggers: 'Exclusion of five opposition parties from legislative elections and consequent electoral victory of government-aligned political parties.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Growing authoritarianism and crackdown on political opponents and media.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Political supporters of former president Thomas Yayi.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BJ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BJ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BJCapitalDataActive,
		        inactiveCapitalData: BJCapitalDataInctive
		    },
		    {
		        id: "BO",
		        arrayItem: true,
		        country: 'Bolivia',
		        protest_name: '<span class="protest">Protest against Morales’ potential arrest</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'A Bolivian prosecutor announced that she would issue an arrest warrant against former president Evo Morales, who is under investigation for alleged statutory rape and human trafficking.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest the potential arrest of Morales. Protesters have stated that they would maintain blockades on major roads if Morales is arrested.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Supporters of Morales ',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. President Luis Arce called for the end of the highway blockades, which he claimed have cost the country over $1 billion USD. On November 2, the government accused Morales’ supporters of seizing three military barracks amidst their ongoing demonstrations.',
		        active: true,
		        multiple: true,
		        mapData: {
		        	"id": "BO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BOCapitalDataActive,
		        inactiveCapitalData: BOCapitalDataInctive
		    },
		    {
		        id: "BO",
		        arrayItem: true,
		        country: 'Bolivia',
		        protest_name: '<span class="protest">March against President Luis Arce</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Partly free',
		        triggers: 'Arce accused former president Evo Morales of attempting to overthrow his government in a coup. In response, Morales called for his supporters to march against Arce.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize Arce’s government for its failure to fix Bolivia’s economic crisis. Call for Arce’s resignation. Show support for Morales.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of Morales ',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. On September 24, Morales made a twenty-four-hour ultimatum to reshuffle the cabinet, which the government rejected.',
		        active: true,
		        multiple: true,
		        mapData: {
		        	"id": "BO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BOCapitalDataActive,
		        inactiveCapitalData: BOCapitalDataInctive
		    },
		    {
		        id: "BO",
		        arrayItem: true,
		        country: 'Bolivia',
		        protest_name: '<span class="protest">Census protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Partly free',
		        triggers: 'The government planned to delay the country’s census until after the 2025 general election. In December 2022, Bolivian police arrested opposition leader Luis Fernando Camacho on terrorism charges. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Desire to secure greater representation and increased funding for the city of Santa Cruz, an opposition stronghold; desire for greater local autonomy.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Residents of Santa Cruz.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'The government agreed to hold the census in 2024 ahead of the general election but could not do so in 2023, as protesters demanded.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "BO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BOCapitalDataActive,
		        inactiveCapitalData: BOCapitalDataInctive
		    },
		    {
		        id: "BO",
		        arrayItem: true,
		        country: 'Bolivia',
		        protest_name: '<span class="protest">Political arrest protests</span>',
		        date: '2021-03-01',
		        date_text: "March 2021",
		        freedom: 'Partly free',
		        triggers: 'Arrest of former interim president Jeanine Añez and other leaders of the political opposition on coup-related offenses.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns that the judiciary is being politically manipulated and that the administration of President Luis Arce is cracking down on dissent.',
		        size: 50001,
		        size_text: '>50,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "BO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BOCapitalDataActive,
		        inactiveCapitalData: BOCapitalDataInctive
		    },
		    {
		        id: "BO",
		        arrayItem: true,
		        country: 'Bolivia',
		        protest_name: '<span class="protest">2020 election protests</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Partly free',
		        triggers: 'A second delay to the general elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Ongoing resentment over the resignation of former president Evo Morales; widespread frustration over an ineffective response to the coronavirus pandemic; anger over the caretaker government’s extension of its own mandate. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Supporters of the former president; trade unions.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'The government set a hard deadline for holding nationwide elections (October 18, 2020).',
		        significant: true,
		        violent: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "BO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BOCapitalDataActive,
		        inactiveCapitalData: BOCapitalDataInctive
		    },
		    {
		        id: "BO",
		        arrayItem: true,
		        country: 'Bolivia',
		        protest_name: '<span class="protest">Wildfire protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Then president Evo Morales’s refusal to declare the wildfires in Bolivia a national disaster.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Morales’s environmental policies and growing popular discontent with the president.',
		        size: 1500000,
		        size_text: '1,500,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "BO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BOCapitalDataActive,
		        inactiveCapitalData: BOCapitalDataInctive
		    },
		    {
		        id: "BO",
		        arrayItem: true,
		        country: 'Bolivia',
		        protest_name: '<span class="protest">2019 election protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Electoral irregularities in the 2019 presidential election, in which then president Evo Morales narrowly avoided a runoff contest in suspicious circumstances. After Morales resigned, his supporters led additional protests, alleging he was the victim of a coup.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears over electoral fraud and growing authoritarian tendencies. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition parties, labor groups, middle-class citizens, some indigenous groups, and (later) Morales’s supporters.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Morales resigned and was replaced by an interim government. Elections are scheduled to be held in May 2020. ',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "BO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BOCapitalDataActive,
		        inactiveCapitalData: BOCapitalDataInctive
		    },
		    {
		        id: "BA",
		        arrayItem: true,
		        country: 'Bosnia and Herzegovina',
		        protest_name: '<span class="protest">Protest against UN Srebrenica genocide resolution </span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Partly free',
		        triggers: 'The United Nations is debating a resolution to commemorate the Srebrenica genocide—when 8,000 Muslims were killed in Srebrenica in 1995 by Bosnian Serb forces—and will vote on this resolution in the General Assembly in May 2024. Bosnian Serb lawmakers adopted a report denying that the killings constituted genocide. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against the United Nations moving to commemorate the tragedy and recognize it as a genocide. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Serbs',
		        timespan: '1 day in April 2024;<br />1 day in May 2024',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests. In May 2024, the United Nations voted to approve the resolute to commemorate the 1995 Srebrenica genocide, leading to additional protests in Belgrade on May 23, 2024. ',
		        multiple: true,
		        mapData: {
		        	"id": "BA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BACapitalDataActive,
		        inactiveCapitalData: BACapitalDataInctive
		    },
		    {
		        id: "BA",
		        arrayItem: true,
		        country: 'Bosnia and Herzegovina',
		        protest_name: '<span class="protest">Femicide protest</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Partly free',
		        triggers: 'A man shot his ex-wife dead and live-streamed the murder on Instagram ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger and fear over the prevalence of violence against women in Bosnia.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "BA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BACapitalDataActive,
		        inactiveCapitalData: BACapitalDataInctive
		    },
		    {
		        id: "BA",
		        arrayItem: true,
		        country: 'Bosnia and Herzegovina',
		        protest_name: '<span class="protest">Defamation law protests</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'On March 23, the parliament of the country’s Serb-majority entity, Republika Srpska, passed draft amendments to the criminal code that would recriminalize libel and introduce fines of up to €60,000 for damaging a person’s honor or reputation and for publishing damaging videos, photos, or documents without consent. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the draft amendments, if passed, would restrict freedom of expression and silence independent media.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Journalists, rights activists',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'On July 20, lawmakers approved the amendments.',
		        multiple: true,
		        mapData: {
		        	"id": "BA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BACapitalDataActive,
		        inactiveCapitalData: BACapitalDataInctive
		    },
		    {
		        id: "BA",
		        arrayItem: true,
		        country: 'Bosnia and Herzegovina',
		        protest_name: '<span class="protest">Election fraud protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Partly free',
		        triggers: 'Perceived fraud in October 2 elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Heightened tensions between the country’s Bosniak, Croat, and Serb populations.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the opposition.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The Central Election Commission ordered a recount of all ballots cast in Republika Srpska. On October 27, the Central Election Commission declared Dodik the winner of the election.',
		        multiple: true,
		        mapData: {
		        	"id": "BA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BACapitalDataActive,
		        inactiveCapitalData: BACapitalDataInctive
		    },
		    {
		        id: "BA",
		        arrayItem: true,
		        country: 'Bosnia and Herzegovina',
		        protest_name: '<span class="protest">“Justice for David” protests</span>',
		        date: '2018-03-01',
		        date_text: "March 2018",
		        freedom: 'Partly free',
		        triggers: 'Suspicious death of a young man named David Dragičević, triggering suspicions that the authorities in the Serb region of Bosnia are obstructing the investigation and protecting his killers.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Impunity, weak rule of law, and corruption.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Bosnian Serbs.',
		        timespan: '9 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "BA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BACapitalDataActive,
		        inactiveCapitalData: BACapitalDataInctive
		    },
		    {
		        id: "BW",
		        arrayItem: true,
		        country: 'Botswana',
		        protest_name: '<span class="protest">Protest against executive powers bill</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'Parliament voted on a constitutional amendment that would allow the president to appoint key civil servants. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest the passage of the amendment, which protesters argue will grant the president too much power.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition, civil society groups',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Opposition legislators walked out of Parliament in protest. The amendment failed to pass through Parliament as it did not receive enough votes.',
				significant: true,
				multiple: false,
		        mapData: {
		        	"id": "BW", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BW", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BWCapitalDataActive,
		        inactiveCapitalData: BWCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">Protest against ban on X</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'Brazil’s nationwide ban on X. Former president Jair Bolsonaro called for a protest in response.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest the ban on X. Claim that the ban is an example of the government oppressing the right. Push for freedom of speech.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of Bolsonaro',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.  On October 8, the Supreme Court authorized the restoration of X in Brazil after the platform complied with the court’s demands.',
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">Protest against abortion ban</span>',
		        date: '2024-06-01',
		        date_text: "June 2024",
		        freedom: 'Free',
		        triggers: 'The Brazilian legislature proposed a bill that would remove exceptions on second-trimester abortions and allow prosecutions of such abortions as homicide.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesting against the passage of the bill; demanding abortion rights.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women, feminist groups',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'The vote on the bill has been delayed and may not occur until 2025. ',
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">Free Land Camp protest against grain railway</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Free',
		        triggers: 'The government plans to build a railway to transport grain from farm states to Amazon ports for export, which members of the Indigenous community say will destroy the environment of tribal communities. This coincided with the week-long twentieth annual Free Land Camp, in which indigenous communities enact encampments in Brasilia as a form of protest against government wrongdoings. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against the proposed railroad. Criticize President Luiz Inácio Lula da Silva for not fulfilling his promises to officially recognize Indigenous reservations and expel illegal miners and land-grabbers from their territory.  ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Indigenous community',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">2022 election protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Free',
		        triggers: 'Opposition candidate Luiz Inácio Lula da Silva won the 2022 Brazilian presidential election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger at perceived fraud in the election.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Supporters of Jair Bolsonaro',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. On January 8, 2023, supporters of former president Jair Bolsonaro stormed the country’s presidential palace, the National Congress, and Supreme Court buildings. ',
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">Indigenous land rights protests</span>',
		        date: '2021-06-01',
		        date_text: "June 2021",
		        freedom: 'Free',
		        triggers: 'President Jair Bolsonaro’s administration’s support for loosening restrictions on land use and the imminent announcement of a verdict in a crucial Indigenous land rights case.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over economic exploitation of Indigenous lands.',
		        size: 4001,
		        size_text: '>4,000',
		        participants: 'Indigenous groups.',
		        timespan: '11 months, intermittently ',
		        timespan_text: '',
		        outcomes: 'The Brazilian Supreme Court suspended the land rights case. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">Coronavirus response protests</span>',
		        date: '2021-01-01',
		        date_text: "January 2021",
		        freedom: 'Free',
		        triggers: 'First round of protests, in January, was triggered by oxygen shortages at hospitals in the city of Manaus and a sluggish rollout of Brazil’s vaccination campaign. Second round of protests, starting in May, was triggered by the government’s slow and inept response amid a third wave of infections and allegations of corruption in vaccine procurement.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over inadequate resourcing of hospitals, underinvestment in vaccines,  pervasive corruption, an ineffectual government response, and misinformation and denialism being spread by President Jair Bolsonaro.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '11 months',
		        timespan_text: '',
		        outcomes: 'The Brazilian Senate launched an inquiry into the government’s handling of the pandemic.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">Coronavirus protests</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Free',
		        triggers: 'President Jair Bolsonaro’s poor and slow response to the coronavirus outbreak; and the death of George Floyd in the United States, sparking protests against local police brutality and racism. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of an effective government response to the spread of the coronavirus, racism in Brazil, and police brutality against Black Brazilians.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BR",
		        arrayItem: true,
		        country: 'Brazil',
		        protest_name: '<span class="protest">Education cuts protests</span>',
		        date: '2019-05-01',
		        date_text: "May 2019",
		        freedom: 'Free',
		        triggers: 'Cuts in education spending and postgraduate scholarships. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic challenges and government attacks on public education.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Students, teachers, teachers’ unions.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "BR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BRCapitalDataActive,
		        inactiveCapitalData: BRCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Protest against anti-LGBTQ+ amendments</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Free',
		        triggers: 'The Bulgarian Parliament passed two amendments to the education code that banned LGBTQ+ "propaganda" in schools.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand that President Rumen Radev veto the amendments, which protesters and international organizations argue will increase political attacks against LGTBQ+ people. Also demand Radev’s resignation.',
		        size: 101,
		        size_text: '>100',
		        participants: 'LGBTQ+ rights activists',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Radev did not veto the amendments.',
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Vazrazhdane protest</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'The ultranationalist Vazrazhdane (Revival) party called for a protest in Sofia.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the Bulgarian government’s continued support for Ukraine and its hosting of NATO bases.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Supporters of the Vazrazhdane Party',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Green energy transition protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'Bulgaria’s government was about to submit plans to the European Commission that would detail a phase-out of its coal power plants. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear that phasing out coal will cause energy shortages and eliminate jobs.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Coal miners, energy workers.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The government announced that it would pay thirty-six months’ salary to any energy sector workers who decided to quit, and promised that no coal mine would close before 2038. Protests still continued with demonstrators shutting down roadways throughout the country. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Domestic violence protest</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Free',
		        triggers: 'A Bulgarian man beat up his girlfriend, cut her more than 100 times with a knife, and shaved her head—injuries that required 400 stitches. The man was arrested but a court later released him after rating the woman’s injuries as &ldquo;light.&rdquo; ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the prevalence of violence against women in Bulgaria.',
		        size: 5000,
		        size_text: '5,000',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Parliament strengthened the penal code, increasing punishments for domestic violence and allowing people in “intimate relationships” to seek protection, as married couples already could.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Farmers’ protest</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Free',
		        triggers: 'European Commission policies to allow Ukrainian farmers to export grains through EU countries—intended to counteract the reduction in grain exports caused by Russian blockades of Ukrainian Black Sea ports—resulted in a glut of Ukrainian grains in Bulgarian markets.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over lowered prices of foreign grain imports and limited availability of local warehouses. Demand state compensation to offset high costs and falling income. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers',
		        timespan: '1 week in April 2023;<br />3 months between February 2024 to April 2024',
		        timespan_text: '',
		        outcomes: 'On April 28, 2023, the European Commission announced an agreement in principle with Bulgaria, Hungary, Poland, Romania, and Slovakia to provide €100 million in compensation to farmers and require that Ukrainian exports of wheat, maize, oilseed, and sunflower seed only enter those five countries in shipments that are already bound for another destination.  The Bulgarian caretaker government disbursed €150 million in funds to compensate farmers facing low-cost competition from Ukrainian agriculture imports. In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Wage protest</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Rising inflation.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of economic harship as wage increases were failing to compensate for price increases.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Free',
		        triggers: 'Revelations that National Security Service agents were reserving access of a public beach for a businessman’s private use; police raids on the office of President Rumen Radev; and fresh allegations of corruption against Prime Minister Boyko Borisov.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Widespread corruption that has weakened state institutions and Bulgaria’s rule of law.',
		        size: 400000,
		        size_text: '400,000',
		        participants: 'General public.',
		        timespan: '6 months',
		        timespan_text: '',
		        outcomes: 'Four ministers resigned, the head of the National Security Service resigned, the investigation against the president was blocked, and the government proposed constitutional reforms. ',
		        significant: true,
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BG",
		        arrayItem: true,
		        country: 'Bulgaria',
		        protest_name: '<span class="protest">Coronavirus lockdown protest</span>',
		        date: '2020-05-01',
		        date_text: "May 2020",
		        freedom: 'Free',
		        triggers: 'Enactment of lockdown measures to combat the spread of the coronavirus.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Skepticism of the coronavirus and vaccines, and other concerns rooted in conspiracy theories and misinformation. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Far-left activists, especially supporters of the Vazrazhdane group.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "BG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BGCapitalDataActive,
		        inactiveCapitalData: BGCapitalDataInctive
		    },
		    {
		        id: "BF",
		        arrayItem: true,
		        country: 'Burkina Faso',
		        protest_name: '<span class="protest">Nouna security protest</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Not free',
		        triggers: 'Jihadist insurgents killed six people in an attack in the city of Nouna; violence from unidentified assailants killed dozens of other people earlier in the month.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perpetual insecurity caused by jihadist insurgents.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "BF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BFCapitalDataActive,
		        inactiveCapitalData: BFCapitalDataInctive
		    },
		    {
		        id: "BF",
		        arrayItem: true,
		        country: 'Burkina Faso',
		        protest_name: '<span class="protest">Partiaga security protest</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Not free',
		        triggers: 'Jihadist insurgents killed at least 60 people in an attack in the town of Partiaga.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perpetual insecurity caused by jihadist insurgents.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "BF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BFCapitalDataActive,
		        inactiveCapitalData: BFCapitalDataInctive
		    },
		    {
		        id: "BF",
		        arrayItem: true,
		        country: 'Burkina Faso',
		        protest_name: '<span class="protest">Jihadist violence protests</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Partly free',
		        triggers: 'Rising jihadist terror attacks on civilian targets, including a June attack that killed over 130 people. November protests were triggered by a November 14 attack on a gendarmerie camp in the northern part of the country.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with rising violence and perceived government inaction in the face of an expanding insurgency, displacement, and a humanitarian crisis.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'President Roch Kaboré dismissed the defense minister and security minister in June 2021; in December 2021, Kaboré replaced the country’s prime minister. In January 2022, Kaboré was overthrown in a military coup.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "BF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "BF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: BFCapitalDataActive,
		        inactiveCapitalData: BFCapitalDataInctive
		    },
		    {
		        id: "KH",
		        arrayItem: true,
		        country: 'Cambodia',
		        protest_name: '<span class="protest">Water shortage protest</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Not free',
		        triggers: 'Severe water shortages that have damaged rice fields. Cambodia has been suffering from severe droughts for the past twenty years. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand that provincial authorities address the water shortages and bring water to the fields in Takeo, Cambodia.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers in Takeo',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The Minister of Agriculture, the Minister of Water Resources, and the Minister of Disaster Management met with protestors and promised to address the issue. After the protests, authorities distributed water to rice fields in the Bati district in Takeo.',
		        significant: true,
		        multiple: false,
		        mapData: {
		        	"id": "KH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KHCapitalDataActive,
		        inactiveCapitalData: KHCapitalDataInctive
		    },
		    {
		        id: "CM",
		        arrayItem: true,
		        country: 'Cameroon',
		        protest_name: '<span class="protest">Detained youth protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Not free',
		        triggers: 'The military detained about 30 youths for their suspected involvement in rebel activity.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Accusations of abuses committed by Cameroon’s military in the region.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Women.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The military released the youths.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "CM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CMCapitalDataActive,
		        inactiveCapitalData: CMCapitalDataInctive
		    },
		    {
		        id: "CM",
		        arrayItem: true,
		        country: 'Cameroon',
		        protest_name: '<span class="protest">Women’s representation in peace talks protest</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Not free',
		        triggers: 'Continual violence in separatist struggle in west and terrorism on the border with Nigeria.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Women’s exclusion from peace talks.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Women.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "CM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CMCapitalDataActive,
		        inactiveCapitalData: CMCapitalDataInctive
		    },
		    {
		        id: "CM",
		        arrayItem: true,
		        country: 'Cameroon',
		        protest_name: '<span class="protest">Women’s empowerment protests</span>',
		        date: '2022-03-01',
		        date_text: "March 2022",
		        freedom: 'Not free',
		        triggers: 'Call for protest by women’s groups; commemoration of International Women’s Day.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with pervasive barriers to women’s political participation and representation in senior political positions; anger over high costs of living and inflation in 2023.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Women.',
		        timespan: '1 day in March 2022;<br />1 day in March 2023',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CMCapitalDataActive,
		        inactiveCapitalData: CMCapitalDataInctive
		    },
		    {
		        id: "CM",
		        arrayItem: true,
		        country: 'Cameroon',
		        protest_name: '<span class="protest">Electoral protests</span>',
		        date: '2018-10-01',
		        date_text: "October 2018",
		        freedom: 'Not free',
		        triggers: 'The arrest of opposition leader Maurice Kamto.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over a lack of meaningful political pluralism and a crackdown against political opponents of longtime President Paul Biya. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially supporters of Maurice Kamto.',
		        timespan: '13 months',
		        timespan_text: 'Intermittently',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CMCapitalDataActive,
		        inactiveCapitalData: CMCapitalDataInctive
		    },
		    {
		        id: "CM",
		        arrayItem: true,
		        country: 'Cameroon',
		        protest_name: '<span class="protest">Ambazonia protests</span>',
		        date: '2017-10-01',
		        date_text: "October 2017",
		        freedom: 'Not free',
		        triggers: 'The breakdown of negotiations between representatives of the English-speaking minority and the French-speaking governing majority.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of political autonomy for residents of the English-speaking Northwest and Southwest regions of Cameroon. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'English-speakers, especially advocates of independence for English-speaking Ambazonia.',
		        timespan: '1 year',
		        timespan_text: 'Intermittently',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        long: true,
		        multiple: false,
		        mapData: {
		        	"id": "CM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CMCapitalDataActive,
		        inactiveCapitalData: CMCapitalDataInctive
		    },
		    {
		        id: "CA",
		        arrayItem: true,
		        country: 'Canada',
		        protest_name: '<span class="protest">Anti-India protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'On June 18, at least six men assassinated Hardeep Singh Nijjar, a Sikh separatist activist living in British Columbia. On September 18, Prime Minister Justin Trudeau told Parliament that Canadian security agencies were pursuing credible allegations of a link between the assassins and the government of India. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Outrage over what would amount to an extrajudicial assassination and infringement of Canadian sovereignty if allegations proved true.',
		        size: 401,
		        size_text: '>400',
		        participants: 'Sikhs',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CACapitalDataActive,
		        inactiveCapitalData: CACapitalDataInctive
		    },
		    {
		        id: "CA",
		        arrayItem: true,
		        country: 'Canada',
		        protest_name: '<span class="protest">Ontario education worker strike and protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'The Ontario government invoked the nothwithstanding clause of Canada’s constitution to force education workers not to strike.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic hardship resulting from stagnant wages that were not keeping up with the rate of inflation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Education workers. ',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Ontario’s government agreed to rescind the law that required the education workers to end their strike, to pay workers for the two work days on which they were protesting, and increase hourly wages for all workers by $1.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "CA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CACapitalDataActive,
		        inactiveCapitalData: CACapitalDataInctive
		    },
		    {
		        id: "CA",
		        arrayItem: true,
		        country: 'Canada',
		        protest_name: '<span class="protest">Coronavirus restrictions protests</span>',
		        date: '2020-04-01',
		        date_text: "April 2020",
		        freedom: 'Free',
		        triggers: 'Enactment of coronavirus restrictions, including mask mandates, vaccine mandates, and lockdowns. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Skepticism of coronavirus, vaccines, and concerns over the balance between public health restrictions and freedom.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public; truckers. ',
		        timespan: '2 years, intermittently.',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "CA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CACapitalDataActive,
		        inactiveCapitalData: CACapitalDataInctive
		    },
		    {
		        id: "CF",
		        arrayItem: true,
		        country: 'Central African Republic',
		        protest_name: '<span class="protest">Constitutional referendum protest</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Not free',
		        triggers: 'President Faustin-Archange Touadéra announced on May 30 that the country would hold a referendum on a new draft constitution on July 30. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the vote would be unfair, and that Touadéra would use the new constitution to stay in power for life.',
		        size: 500,
		        size_text: '500',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'On August 21, the Constitutional Court validated the results of the July 30 referendum, in which 95 percent of voters approved the new constitution. The opposition boycotted the vote.',
		        multiple: true,
		        mapData: {
		        	"id": "CF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CFCapitalDataActive,
		        inactiveCapitalData: CFCapitalDataInctive
		    },
		    {
		        id: "CF",
		        arrayItem: true,
		        country: 'Central African Republic',
		        protest_name: '<span class="protest">Constitution rewrite protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Not free',
		        triggers: 'President Faustin-Archange Touadera ordered the creation of a committee to rewrite the constitution.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the president was changing the constitution to pave the way for a third term in office.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The Constitutional Court ruled that the commission created by President Faustin Archange Touadera is unconstitutional.',
		        multiple: true,
		        mapData: {
		        	"id": "CF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CFCapitalDataActive,
		        inactiveCapitalData: CFCapitalDataInctive
		    },
		    {
		        id: "TD",
		        arrayItem: true,
		        country: 'Chad',
		        protest_name: '<span class="protest">Protest against French military</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Not free',
		        triggers: 'On November 28, 2024, the Chadian government announced that it would end its defense cooperation pact with France. However, the announcement did not specify when French troops would be leaving the country.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anti-French sentiments. Demand that French troops withdraw from Chad immediately.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'President Mahamat Idriss Déby ordered French troops to withdraw from Chad by the end of January 2025. The Chadian government also established a special commission to supervise the withdrawal of French troops.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "TD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "TD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: TDCapitalDataActive,
		        inactiveCapitalData: TDCapitalDataInctive
		    },
		    {
		        id: "TD",
		        arrayItem: true,
		        country: 'Chad',
		        protest_name: '<span class="protest">Protests for first anniversary of government crackdown</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Not free',
		        triggers: 'One year anniversary of the government crackdown on the opposition that killed 128 and injured more than 500. Pro-democracy opposition leader Succès Masra announced his plan to return to the country from exile on October 5, leading the military government to issue a warrant for his arrest.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest the crackdown on opposition protests on October 20, 2022, and demand that the arrest warrant for Masra be withdrawn.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Mahamat El Mahdi Abderrahmane; Collection Action of Youths for Peace, Development, and the Emergence of Chad',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Installed a civilian defense minister on October 21 in preparation for transition to civilian rule. In November, the government gave amnesty to police and soldiers who had opened fire on demonstrators in October 2022. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "TD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "TD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: TDCapitalDataActive,
		        inactiveCapitalData: TDCapitalDataInctive
		    },
		    {
		        id: "TD",
		        arrayItem: true,
		        country: 'Chad',
		        protest_name: '<span class="protest">Military rule protests</span>',
		        date: '2021-04-01',
		        date_text: "April 2021",
		        freedom: 'Not free',
		        triggers: 'The military’s seizure of power in the wake of the death of longtime president Idriss Déby.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with the military’s role in politics and the proposed long time frame to return the country to civilian rule.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '7 months, intermittently, in 2021;<br />1.5 months in 2022 beginning in September',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "TD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "TD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: TDCapitalDataActive,
		        inactiveCapitalData: TDCapitalDataInctive
		    },
		    {
		        id: "TD",
		        arrayItem: true,
		        country: 'Chad',
		        protest_name: '<span class="protest">Re-election protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Not free',
		        triggers: 'President Idriss Déby’s nomination by the ruling Patriotic Salvation Movement for a sixth term as president.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over lack of genuine political pluralism and the entrenchment of Deby’s regime.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "TD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "TD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: TDCapitalDataActive,
		        inactiveCapitalData: TDCapitalDataInctive
		    },
		    {
		        id: "TD",
		        arrayItem: true,
		        country: 'Chad',
		        protest_name: '<span class="protest">Human rights judgment protests</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Not free',
		        triggers: 'Recurring delays in the receipt of compensation packages for human rights abuses ordered by the African Union. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the lack of reckoning with human rights abuses perpetrated by the former regime of president Hissène Habré.',
		        size: 501,
		        size_text: '>500',
		        participants: 'Relatives of those injured or killed by the Habré government. ',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "TD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "TD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: TDCapitalDataActive,
		        inactiveCapitalData: TDCapitalDataInctive
		    },
		    {
		        id: "CL",
		        arrayItem: true,
		        country: 'Chile',
		        protest_name: '<span class="protest">Student stipend protest</span>',
		        date: '2022-03-01',
		        date_text: "March 2022",
		        freedom: 'Free',
		        triggers: 'Rising costs of living; call for protest by the Confech student federation.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Belief that the government should raise student food and living benefits amid significant economic pressure.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Students.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CLCapitalDataActive,
		        inactiveCapitalData: CLCapitalDataInctive
		    },
		    {
		        id: "CL",
		        arrayItem: true,
		        country: 'Chile',
		        protest_name: '<span class="protest">Subway fare protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Free',
		        triggers: 'Increased price of metro fares.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Severe inequality, low wages and pensions, poor service delivery, discontent with government, militarized response to the protests, and government leaders’ corruption.',
		        size: 1000000,
		        size_text: '>1,000,000',
		        participants: 'Students, young people, professionals, civil society groups.',
		        timespan: '14 months ',
		        timespan_text: '',
		        outcomes: 'The subway fare hike was reversed, and legislators agreed to a 2020 referendum over whether to write a new constitution. The referendum passed overwhelmingly in October 2020. Lawmakers recently approved a bill that permits citizens to withdraw 10 percent of their pensions to help mitigate the economic impact of the pandemic.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CLCapitalDataActive,
		        inactiveCapitalData: CLCapitalDataInctive
		    },
		    {
		        id: "CN",
		        arrayItem: true,
		        country: 'China',
		        protest_name: '<span class="protest">Yunnan Muslims’ protest</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Not free',
		        triggers: 'Police detained Ma Yuwei, an imam of a local mosque in the Yunnan province.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over Ma’s arrest, which protesters argue was due to his religious beliefs. Demand that police release Ma from custody. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Muslims in Yunnan province',
		        timespan: '2 days (approx.)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "CN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CNCapitalDataActive,
		        inactiveCapitalData: CNCapitalDataInctive
		    },
		    {
		        id: "CN",
		        arrayItem: true,
		        country: 'China',
		        protest_name: '<span class="protest">Protest against company law</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Not free',
		        triggers: 'A new company law allowed companies to target former shareholders for unpaid sums, even after they had transferred their shares. The Supreme People’s Court ruled that former shareholders were also liable for unpaid sums after bankruptcy.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over new company law and court ruling, which brought concerns over potential liabilities after cashing out of investments. Growing economic discontent throughout the country.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1-2 weeks (approx.)',
		        timespan_text: '',
		        outcomes: 'The legislative affairs commission in the National People’s Congress announced that they would soften the conditions of the company law and urge courts to take appropriate measures. The commission also stated that the law would not apply to shareholders who had sold out before July 2024, when the law had taken effect.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "CN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CNCapitalDataActive,
		        inactiveCapitalData: CNCapitalDataInctive
		    },
		    {
		        id: "CN",
		        arrayItem: true,
		        country: 'China',
		        protest_name: '<span class="protest">Mosque renovation protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Not free',
		        triggers: 'Renovations were set to begin on a fourteenth century mosque in Nagu, Yunnan province.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger of the forced &ldquo;Sinicization&rdquo; of Islam in China.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Hui Muslims in Nagu.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'Protesters delayed the start of the renovations by occupying the mosque.',
		        multiple: true,
		        mapData: {
		        	"id": "CN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CNCapitalDataActive,
		        inactiveCapitalData: CNCapitalDataInctive
		    },
		    {
		        id: "CN",
		        arrayItem: true,
		        country: 'China',
		        protest_name: '<span class="protest">Retiree protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Not free',
		        triggers: 'The government restructured the medical insurance system, cutting some government-provided medical insurance for senior citizens.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over cuts to what the senior citizens believed was owed to them for their years in the labor force.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Retirees in Wuhan and Dalian.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "CN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CNCapitalDataActive,
		        inactiveCapitalData: CNCapitalDataInctive
		    },
		    {
		        id: "CN",
		        arrayItem: true,
		        country: 'China',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Not free',
		        triggers: 'A fire in an apartment building in Urumqi, the capital of the Xinjiang Uyghur Autonomous Region, killed 10 people. Demonstrators believed that COVID-19 restrictions, including makeshift barricades and blockaded emergency exits, hampered evacuation and rescue efforts.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over continued coronavirus restrictions and lockdowns; frustration with lack of freedoms. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'Municipal authorities quickly loosened coronavirus restrictions in various cities, including Guangzhou and Chongqing. The Chinese government promised to do more to vaccinate a greater share of senior citizens. Then, in early December, the Chinese government announced sweeping changes to coronavirus restrictions, including allowing home quarantine and largely scrapping the health QR code that had been mandatory for entering most public places.',
		        significant: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "CN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CNCapitalDataActive,
		        inactiveCapitalData: CNCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Coal miners’ strike</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'In January 2024, the government enacted a decree that allowed the Ministry of Environment to designate certain areas as natural resource reserve zones.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Oppose the decree, which demonstrators argue is an arbitrary measure that was taken without consultation from miners. Assert that the decree will harm the mining sector.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Coal miners',
		        timespan: '5 days',
		        timespan_text: '',
		        outcomes: 'Demonstrators reached an agreement with the Ministry of Environment, which included commitments to advance the formalization of the mining sector and promote sustainable mining practices.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Trucker road blockades</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'In an effort to phase out fuel subsidies, the government raised diesel prices by fifty cents per gallon.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest fuel price increase, which truckers argue would be a severe economic burden. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Truckers',
		        timespan: '6 days',
		        timespan_text: '',
		        outcomes: 'On September 6, the government came to an agreement with truckers’ unions to gradually implement a lower increase in fuel prices by the end of the year.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Anti-Petro protests</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Partly free',
		        triggers: 'Leftist Gustavo Petro was elected in 2022 on the promise to fight poverty and inequality and end Colombia’s six decades of domestic conflict which has killed 450,000. Petro is struggling to maintain his governing coalition in Congress and his approval rating has declined amid an uptick in violence by rebels. He has proposed legislation to reform Colombia’s healthcare system and turn a government agency into the sole administrator of insurance payouts, thereby sidelining private companies which make up a sizeable portion of insurance providers. Petro is also pushing a change to labor law to make it harder to hire workers on temporary contracts and require additional compensation for any work conducted after 6 p.m. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest political, economic, and social reforms backed by Petro, and demand action against deteriorating security situation. Express concerns with reforms to healthcare and labor laws which demonstrators say will set the country back decades.  ',
		        size: 70001,
		        size_text: '>70,000',
		        participants: 'General public',
		        timespan: '1 month;<br />1 day in November 2024',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Indigenous anti-violence protest</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Partly free',
		        triggers: 'More than 37,000 people across Colombia were affected by violence between January and September of 2023, with Indigenous people disproportionately affected.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears over violence, especially against land defenders.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Indigenous people ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Social and economic reform protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Partly free',
		        triggers: 'The president proposed social and economic reforms that aim to combat poverty, improve security, and combat climate change.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the proposals would harm the country’s economic stability or lead to increased poverty.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the opposition. ',
		        timespan: '1 week in February 2023;<br />1 day in June 2023.',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">2022 tax proposal protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Partly free',
		        triggers: 'President Gustavo Petro proposed economic and social reforms that were meant to increase taxes on high-earning individuals and combat inequality.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear of individual financial consequences of rising taxes.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public. ',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Petro’s proposal passed with slightly lower funding to expand the country’s social safety net and slightly lower taxes on high-earning individuals. ',
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">2021 tax proposal protests</span>',
		        date: '2021-04-01',
		        date_text: "April 2021",
		        freedom: 'Partly free',
		        triggers: 'The government’s introduction of a proposal to raise tax rates and eliminate exemptions; a call for protests and strikes by the country’s main labor unions.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with ongoing economic insecurity, a declining security situation, and pervasive inequality.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Indigenous groups, labor unions, teachers, and university students. ',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'President Ivan Duque withdrew the tax proposal, and Finance Minister Alberto Carrasquilla resigned. A pared down version of the reform was passed in September 2021. ',
		        significant: true,
		        violent: true,
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Police brutality protests</span>',
		        date: '2020-09-01',
		        date_text: "September 2020",
		        freedom: 'Partly free',
		        triggers: 'Circulation of a video of police killing law student Javier Ordóñez by shooting him repeatedly with a stun gun.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Failure of the government to effectively address police brutality and violence.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/eadership change in response to the protests.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Coronavirus protests</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Partly free',
		        triggers: 'President Ivan Duque’s undermining of local and regional authorities, citizens’ claims that they were not receiving sufficient social support, and rising number of deaths from the virus.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of an effective government response to the spread of the coronavirus, insufficient social support for marginalized and economically disadvantaged populations, and lack of protection for healthcare workers.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '8 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">Prison protest</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Partly free',
		        triggers: 'Unsanitary conditions in prisons, which could accelerate the spread of the coronavirus among inmates.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Unsanitary conditions in prisons, including overcrowding and lack of access to water.',
		        size: 1,
		        size_text: 'Unknown',
		        participants: 'Prisoners.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Colombia’s Ministry of Justice ordered the mass release of most nonviolent prisoners, so that they could temporarily serve their time at home.',
		        significant: true,
		        violent: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "CO",
		        arrayItem: true,
		        country: 'Colombia',
		        protest_name: '<span class="protest">National strikes</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Partly free',
		        triggers: 'Rumored pensions cuts, implementation challenges of the country’s peace deal, and rising violence.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Disapproval of President Iván Duque Márquez’s government, rising economic inequality, corruption, and militarized response to protests.',
		        size: 1000000,
		        size_text: '>1,000,000',
		        participants: 'Students, unions, leftist groups, indigenous groups.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: COCapitalDataActive,
		        inactiveCapitalData: COCapitalDataInctive
		    },
		    {
		        id: "KM",
		        arrayItem: true,
		        country: 'Comoros',
		        protest_name: '<span class="protest">2024 election results protests</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Partly free',
		        triggers: 'Incumbent President Azali Assoumani was declared the winner of the January 2024 election, which was denounced by the country’s opposition parties as fraudulent. Assoumani has been accused of sidestepping the constitution and cracking down on dissent because he has previously outlawed protests.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce election results that protesters claim are fraudulent.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "KM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KMCapitalDataActive,
		        inactiveCapitalData: KMCapitalDataInctive
		    },
		    {
		        id: "KM",
		        arrayItem: true,
		        country: 'Comoros',
		        protest_name: '<span class="protest">Electoral protests</span>',
		        date: '2019-03-01',
		        date_text: "March 2019",
		        freedom: 'Partly free',
		        triggers: 'Allegations of ballot stuffing and the improper transport of ballot boxes during the 2019 presidential election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns about the lack of electoral integrity and the entrenchment of President Azali Assoumani.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially supporters of the political opposition.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "KM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KMCapitalDataActive,
		        inactiveCapitalData: KMCapitalDataInctive
		    },
		    {
		        id: "KM",
		        arrayItem: true,
		        country: 'Comoros',
		        protest_name: '<span class="protest">Constitutional reform protests</span>',
		        date: '2018-06-01',
		        date_text: "June 2018",
		        freedom: 'Partly free',
		        triggers: 'Introduction of proposed constitutional reforms that would render President Azali Assoumani eligible for another five-year term and reduce the relative power of the archipelago’s islands by eliminating the rotating vice presidency.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over the entrenchment of President Azali Assoumani and lack of political pluralism.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "KM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KMCapitalDataActive,
		        inactiveCapitalData: KMCapitalDataInctive
		    },
		    {
		        id: "KM",
		        arrayItem: true,
		        country: 'Comoros',
		        protest_name: '<span class="protest">Mayotte protests</span>',
		        date: '2018-04-01',
		        date_text: "April 2018",
		        freedom: 'Partly free',
		        triggers: 'The expulsion of Comorian migrants in the French territory of Mayotte and the announcement of negotiations between the French and Comorian governments. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Nationalism rooted in a widespread belief in Comoros that the French-controlled island of Mayotte is an integral part of Comoros.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KMCapitalDataActive,
		        inactiveCapitalData: KMCapitalDataInctive
		    },
		    {
		        id: "CR",
		        arrayItem: true,
		        country: 'Costa Rica',
		        protest_name: '<span class="protest">Tax reform protests</span>',
		        date: '2018-09-01',
		        date_text: "September 2018",
		        freedom: 'Free',
		        triggers: 'Proposed tax reforms and salary cuts for government workers.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Impact of austerity measures on lower- and middle-class workers and growing inequality.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Trade unions, public sector workers.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: false,
		        mapData: {
		        	"id": "CR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CRCapitalDataActive,
		        inactiveCapitalData: CRCapitalDataInctive
		    },
		    {
		        id: "HR",
		        arrayItem: true,
		        country: 'Croatia',
		        protest_name: '<span class="protest">Protest against whistleblower jailing law </span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Free',
		        triggers: 'Draft law that would impose prison terms on those who leak details of police investigations. Police, prosecutors, judges, or any official who leaks information about police investigations can be sentenced to up to three years in jail. Prime Minister Andrej Plenković said the law does not apply if "the information leaked was in the interest of the public."',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Journalists say the law is designed to block reports about corruption. The Association of Journalists said that journalists reporting leaked information about an investigation could become part of that investigation and have their emails, phones, and notes seized. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Journalists',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HRCapitalDataActive,
		        inactiveCapitalData: HRCapitalDataInctive
		    },
		    {
		        id: "HR",
		        arrayItem: true,
		        country: 'Croatia',
		        protest_name: '<span class="protest">Abortion access protest</span>',
		        date: '2022-05-01',
		        date_text: "May 2022",
		        freedom: 'Free',
		        triggers: 'Revelations that a pregnant Croatian woman was forced to receive an abortion in Slovenia since local hospitals were not equipped to perfom the procedure.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with rising rates of conscientious objection by doctors to providing the procedure; increasing difficulty of accessing abortion; perception that government policies have not sufficiently ensured that doctors are willing to conduct the procedure.',
		        size: 8001,
		        size_text: '>8,000',
		        participants: 'General public, especially women.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "HR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HRCapitalDataActive,
		        inactiveCapitalData: HRCapitalDataInctive
		    },
		    {
		        id: "HR",
		        arrayItem: true,
		        country: 'Croatia',
		        protest_name: '<span class="protest">Coronavirus restriction protest</span>',
		        date: '2020-09-01',
		        date_text: "September 2020",
		        freedom: 'Free',
		        triggers: 'Imposition of coronavirus restrictions, including mask and vaccine mandates. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Skepticism of the coronavirus and a belief that public health restrictions jeopardized human rights.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially antivaccine advocates.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HRCapitalDataActive,
		        inactiveCapitalData: HRCapitalDataInctive
		    },
		    {
		        id: "HR",
		        arrayItem: true,
		        country: 'Croatia',
		        protest_name: '<span class="protest">Teachers’ strikes</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Free',
		        triggers: 'Government rejection of teachers’ unions’ salary demands.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Low compensation of education workers.',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'Members of teachers’ unions, teachers, children.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Government reached a wage agreement with teachers. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "HR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HRCapitalDataActive,
		        inactiveCapitalData: HRCapitalDataInctive
		    },
		    {
		        id: "CU",
		        arrayItem: true,
		        country: 'Cuba',
		        protest_name: '<span class="protest">Food and power shortages protests</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Not free',
		        triggers: 'The country is experiencing power cuts and shortages of food and medicine. There has also been a decline in living conditions on par with that following the collapse of the Soviet Union. The power outages were exacerbated by two hurricanes that struck Cuba in October 2024.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express discontent with the government due to its inability to provide services for its citizenry. Protesters also called for an end to communism and for President Miguel Díaz-Canel’s resignation. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '2 days;<br />3 weeks from October-November 2024',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. Cuban authorities announced in November 2024 that they had arrested several protestors for disorderly conduct.',
		        multiple: true,
		        mapData: {
		        	"id": "CU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CUCapitalDataActive,
		        inactiveCapitalData: CUCapitalDataInctive
		    },
		    {
		        id: "CU",
		        arrayItem: true,
		        country: 'Cuba',
		        protest_name: '<span class="protest">Hurricane Ian protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Not free',
		        triggers: 'Power outages caused by Hurricane Ian.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations over slow government response to Hurricane Ian, fears of consequences of prolonged power outage, and anger over underinvestments in country’s power grid. ',
		        size: 400,
		        size_text: '400',
		        participants: 'General public.',
		        timespan: '5 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "CU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CUCapitalDataActive,
		        inactiveCapitalData: CUCapitalDataInctive
		    },
		    {
		        id: "CU",
		        arrayItem: true,
		        country: 'Cuba',
		        protest_name: '<span class="protest">Economic crisis protests</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Not free',
		        triggers: 'Food and medicine shortages amid a severe economic crisis.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with economic mismanagement, falling standards of living, poor public services, and political repression under the one-party rule. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "CU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CUCapitalDataActive,
		        inactiveCapitalData: CUCapitalDataInctive
		    },
		    {
		        id: "CU",
		        arrayItem: true,
		        country: 'Cuba',
		        protest_name: '<span class="protest">Artistic freedom protests</span>',
		        date: '2020-11-01',
		        date_text: "November 2020",
		        freedom: 'Not free',
		        triggers: 'Arrest of rapper Denis Solis, who was later sentenced to eight months in prison for insulting a police officer.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Ongoing restrictions on artistic freedom and free expression. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Members of the San Isidro Movement.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "CU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CUCapitalDataActive,
		        inactiveCapitalData: CUCapitalDataInctive
		    },
		    {
		        id: "CY",
		        arrayItem: true,
		        country: 'Cyprus',
		        protest_name: '<span class="protest">RAF Akrotiri base protests</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'The British RAF Akrotiri base in Cyprus was used to launch strikes against the Houthi militia in Yemen. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Pro-Palestinian activists protested the base and the use of land in Cyprus to "support Israel in their onslaught of Gaza," which they claim the retaliatory strikes against the Houthis constitute. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Pro-Palestinian activists, United for Palestine (Cyprus)',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "CY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CYCapitalDataActive,
		        inactiveCapitalData: CYCapitalDataInctive
		    },
		    {
		        id: "CY",
		        arrayItem: true,
		        country: 'Cyprus',
		        protest_name: '<span class="protest">Anti-migrant protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'The Cypriot government struggled to accommodate the large number of migrants who had arrived.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear and anger over the allocation of finite resources to accommodate the migrants.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CYCapitalDataActive,
		        inactiveCapitalData: CYCapitalDataInctive
		    },
		    {
		        id: "CY",
		        arrayItem: true,
		        country: 'Cyprus',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Free',
		        triggers: 'Imposition of coronavirus lockdowns and revelations about corruption in a passport-for-investment scheme.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustrations with coronavirus restrictions and their social and economic impact, and concerns over the spread of corruption during the pandemic. ',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "CY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CYCapitalDataActive,
		        inactiveCapitalData: CYCapitalDataInctive
		    },
		    {
		        id: "CZ",
		        arrayItem: true,
		        country: 'Czech Republic',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Free',
		        triggers: 'The EU waived duties and quotas for imports from Ukraine which put pressure on prices for EU farmers who must follow EU environmental standards. Ongoing negotiations for a trade deal between the EU and the South American Mercosur bloc. New EU environmental regulations, including new subsidy rules which require 4 percent of farmland to be fallow. High energy prices are further undercutting farmers’ profits.  ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against EU environmental regulations, cheap food imports from Ukraine, high energy costs, and red tape. The Agrarian Chamber has called for subsidies matching 2022 levels, the creation of programs to support employment in agriculture, and a reduction in property tax levied on farmers. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "CZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CZCapitalDataActive,
		        inactiveCapitalData: CZCapitalDataInctive
		    },
		    {
		        id: "CZ",
		        arrayItem: true,
		        country: 'Czech Republic',
		        protest_name: '<span class="protest">Anti-austerity protests</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'Package of $6.7 billion of spending cuts and austerity measures put forward by Prime Minister Petr Fiala’s government, part of his aim to halve the country’s budget. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Teachers and labor unions went on strike and marched through Prague and other cities to protest austerity measures and demand more funding for education and healthcare. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Teachers, labor unions, ANO supporters.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest. Prime Minister Fiala affirmed his commitment to implementing austerity measures, calling them "absolutely necessary" to "stop the state indebtedness."',
		        multiple: true,
		        mapData: {
		        	"id": "CZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CZCapitalDataActive,
		        inactiveCapitalData: CZCapitalDataInctive
		    },
		    {
		        id: "CZ",
		        arrayItem: true,
		        country: 'Czech Republic',
		        protest_name: '<span class="protest">Pension protest</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Free',
		        triggers: 'The government considered a proposal to raise the retirement age by four years from 65 to 68.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the prospect of more years in the workforce before retirement.',
		        size: 2001,
		        size_text: '>2,000',
		        participants: 'Members of labor unions, opposition parties.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CZCapitalDataActive,
		        inactiveCapitalData: CZCapitalDataInctive
		    },
		    {
		        id: "CZ",
		        arrayItem: true,
		        country: 'Czech Republic',
		        protest_name: '<span class="protest">Economic protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'Skyrocketing energy prices; the government’s policy of allowing entrance for Ukrainian refugees.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears over rising prices; antiestablishment furore over the government’s policies relating to COVID-19 measures; euroskeptic anger over the government’s support for Ukraine, NATO, the EU, and other institutions.',
		        size: 70001,
		        size_text: '70,000',
		        participants: 'General public, especially the anti-establishment far right and far left.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CZCapitalDataActive,
		        inactiveCapitalData: CZCapitalDataInctive
		    },
		    {
		        id: "CZ",
		        arrayItem: true,
		        country: 'Czech Republic',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-01-01',
		        date_text: "January 2021",
		        freedom: 'Free',
		        triggers: 'Ongoing pandemic-related restrictions.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the economic and social impacts of pandemic-related restrictions. ',
		        size: 3001,
		        size_text: '>3,000',
		        participants: 'General public.',
		        timespan: '14 months ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CZCapitalDataActive,
		        inactiveCapitalData: CZCapitalDataInctive
		    },
		    {
		        id: "CZ",
		        arrayItem: true,
		        country: 'Czech Republic',
		        protest_name: '<span class="protest">“Million Moments for Democracy” protests</span>',
		        date: '2019-04-01',
		        date_text: "April 2019",
		        freedom: 'Free',
		        triggers: 'Corruption allegations against Prime Minister Andrej Babiš and his appointment of a close ally as justice minister.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Government corruption, threats to judicial independence, and democratic backslide. ',
		        size: 200000,
		        size_text: '250,000',
		        participants: 'General public.',
		        timespan: '8 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CZCapitalDataActive,
		        inactiveCapitalData: CZCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Kinshasa protests against M23</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Not free',
		        triggers: 'M23 rebel forces made advances toward the city of Goma in eastern DRC.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce the international community for their inaction towards the conflict in Goma and for their complicity in Rwanda’s alleged involvement with the M23 group.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '2 days (Jan 8, Jan 28)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. The Congolese government banned all protests in Kinshasa.',
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Protest for release of political prisoners</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Not free',
		        triggers: 'Arrest of Seth Kikuni, one of three key opposition figures to be imprisoned in the DRC. The DRC also applied to be a member of the UN Human Rights Council in August 2024.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for the release of political prisoners, including three opposition figures (Kikuni, Jean-Marc Kabund, and Mike Mukebayi). Also call on the UN to reject the DRC’s candidacy for the Human Rights Council.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Opposition',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Protest against the West</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Not free',
		        triggers: 'Widespread violence and a Tutsi-led March 23 (M23) rebellion advancing toward Goma. Western countries provide support for Rwanda, which is accused of backing the Tutsi-led M23 rebellion in the Congo. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express discontent with Western countries—namely, the United States, France, and Belgium—for their support of Rwanda. Demand that Westerners leave the country and stop financial assistance to Rwanda.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. The United Nations already plans to withdraw peacekeepers from the region by the end of the year. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Presidential election results protest</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Not free',
		        triggers: 'The incumbent President Felix Tshisekedi won the December 2023 presidential election after the voting period was extended by a day due to logistical issues. The Carter Center noted "serious irregularities" in the election’s administration.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition candidates marched to demand the election results be annulled. The government banned the opposition’s protests on December 26. Protesters clashed with police on December 27. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition candidates, Nobel Peace Prize laureate Denis Mukwege',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest. The government banned the opposition’s marches in December 2023, but they continued to occur until President Tshisekedi’s inauguration for his second term in January 2024.  ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Voter registration irregularity protest</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Not free',
		        triggers: 'Delays and alleged irregularities in a voter registration drive ahead of the country’s December 20 general election.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over problems surrounding the country’s general election, the rising cost of living, and prolonged insecurity in the east of the country',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Electoral commission protest</span>',
		        date: '2021-10-01',
		        date_text: "October 2021",
		        freedom: 'Not free',
		        triggers: 'A proposal by six religious groups to install Denis Kadima as head of the Independent National Electoral Commission (CENI). ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern that the CENI may come under political influence from the government.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, especially  opposition groups.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Judiciary reform protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Not free',
		        triggers: 'National Assembly’s proposed changes to the juidiciary that would include proposals to define the powers of judges, and its decision to appoint Ronsard Malonda as chairman of the Independent National Electoral Commission, despite his perceived past role in rigging elections in favor of former president Joseph Kabila.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Growing tensions within the ruling coalition, concern over the political influence of Kabila, and fears that the 2023 presidential election could be subject to manipulation with a new head of the national electoral commission.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of President Felix Tshisekedi and his party, the Union for Democracy and Social Progress (UDPS).',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "CD",
		        arrayItem: true,
		        country: 'Democratic Republic of the Congo',
		        protest_name: '<span class="protest">Election delay protests</span>',
		        date: '2017-12-01',
		        date_text: "December 2017",
		        freedom: 'Not free',
		        triggers: 'Continued attempts by then president Joseph Kabila to stay in power beyond his term limit by delaying elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of transition of power, rising authoritarianism, and violent government response to protests.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Religious leaders, civil society organizations.',
		        timespan: '6 months',
		        timespan_text: '',
		        outcomes: 'Kabila agreed to step down as president and hold new elections, which were suspected of being rigged. Despite leaving office, Kabila is seen as retaining significant influence in the new government led by President Félix Tshisekedi.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CDCapitalDataActive,
		        inactiveCapitalData: CDCapitalDataInctive
		    },
		    {
		        id: "DK",
		        arrayItem: true,
		        country: 'Denmark',
		        protest_name: '<span class="protest">Public holiday cancelation protest</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Free',
		        triggers: 'The government announced plans to cancel a public holiday to help finance increased defense spending.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the prospect of an increased number of work days.',
		        size: 40001,
		        size_text: '>40,000',
		        participants: 'Labor unions.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "DK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DKCapitalDataActive,
		        inactiveCapitalData: DKCapitalDataInctive
		    },
		    {
		        id: "DO",
		        arrayItem: true,
		        country: 'Dominican Republic',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2017-07-01',
		        date_text: "July 2017",
		        freedom: 'Partly free',
		        triggers: 'Indictment of leading political officials and businessmen in a bribery scandal.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Bribery of politicians.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Young people.',
		        timespan: '1 year, 6 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        long: true,
		        multiple: false,
		        mapData: {
		        	"id": "DO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DOCapitalDataActive,
		        inactiveCapitalData: DOCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Protest for "Guayaquil Four" boys</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Partly free',
		        triggers: 'Four teenage boys (the "Guayaquil Four") disappeared after they were approached by soldiers.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand justice for missing boys. Express anger over delayed investigations into the disappearances and the alleged military involvement and over growing military presence in the streets.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public, families of missing boys',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The nationwide protests pushed authorities to restart the delayed investigation. Soon after, the four boys’ charred bodies were found. A judge ordered sixteen soldiers to remain in custody while police continued their investigation.',
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Protest against electricity blackouts</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'Rolling blackouts occurring across the country since September 2024. Ongoing economic crisis and rising crime.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express frustration over continued blackouts. Anger at President Daniel Noboa’s government for its mismanagement of the electricity crisis and failure to adequately address problems regarding the economy and crime.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, trade unions, students',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Ten protesters were arrested and three police officers were injured. Though Noboa promised to end the blackouts by December 2024, the outages have continued.',
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Shutdown of Auca Petroecuador plant</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Partly free',
		        triggers: 'State-owned energy firm Petroecuador is extracting crude oil in Amazonian region of Ecuador without adequately compensating locals.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand Petroecuador pay for improved bridges and roads in the region.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Auca community.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. Petroecuador claims they are not responsible for goods provisions. ',
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Femicide protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Partly free',
		        triggers: 'The disappearance and murder of thirty-four-year-old lawyer María Belén Bernal.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over high rates of femicide in the country.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Women.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Rising price protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Partly free',
		        triggers: 'Rising prices for staple goods and the announcement of a strike and circulation of economic demands by the Confederation of Indigenous Nationalities of Ecuador.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with rising prices for agricultural goods and fuel and persistent economic inequality.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially Indigenous groups.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'The government offered significant concessions to protesters, including lowered fuel prices.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Fuel price protest</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Partly free',
		        triggers: 'The government’s announcement that it would be reducing subsidies on fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over economic inequality, rising cost of living, and persistent inequality for the country’s Indigenous groups.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially Indigenous groups.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Coronavirus protest</span>',
		        date: '2020-05-01',
		        date_text: "May 2020",
		        freedom: 'Partly free',
		        triggers: 'Announcement of the closure of some state-owned companies and cuts to salaries of public sector workers.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic impacts of the coronavirus pandemic response.',
		        size: 4000,
		        size_text: '4,000',
		        participants: 'Trade unions, social welfare organizations.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EC",
		        arrayItem: true,
		        country: 'Ecuador',
		        protest_name: '<span class="protest">Fuel subsidy protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Proposal of an austerity package that would increase fuel costs and cut salaries for public sector jobs.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic inequality and indigenous land rights.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Indigenous groups, transportation unions, student groups, labor unions.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'Government agreed to cancel austerity package.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "EC", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EC", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ECCapitalDataActive,
		        inactiveCapitalData: ECCapitalDataInctive
		    },
		    {
		        id: "EG",
		        arrayItem: true,
		        country: 'Egypt',
		        protest_name: '<span class="protest">Friday of Anger protests</span>',
		        date: '2020-09-01',
		        date_text: "September 2020",
		        freedom: 'Not free',
		        triggers: 'Call for protest by exiled opposition leader Mohamed Ali; the demolition of unlicensed homes; and increases in commodity pricing.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Opposition to the entrenchment of the al-Sisi regime, high costs of living, and the lack of political pluralism.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Rural citizens, especially those displaced by the government’s demolition campaign.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "EG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: EGCapitalDataActive,
		        inactiveCapitalData: EGCapitalDataInctive
		    },
		    {
		        id: "EG",
		        arrayItem: true,
		        country: 'Egypt',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2019-09-01',
		        date_text: "September 2019",
		        freedom: 'Not free',
		        triggers: 'Revelations that President Abdel Fattah el-Sisi and his inner circle used public funds for personal gain.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Corruption and rising levels of poverty despite economic growth.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Young people.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "EG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "EG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: EGCapitalDataActive,
		        inactiveCapitalData: EGCapitalDataInctive
		    },
		    {
		        id: "SV",
		        arrayItem: true,
		        country: 'El Salvador',
		        protest_name: '<span class="protest">Bitcoin protests</span>',
		        date: '2021-09-01',
		        date_text: "September 2021",
		        freedom: 'Partly free',
		        triggers: 'The government’s enactment of a provision to accept bitcoin as legal tender.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over rising inflation; economic mismagement; and perceived authoritarianism under President Nayib Bukele.',
		        size: 4001,
		        size_text: '>4,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: false,
		        mapData: {
		        	"id": "SV", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SV", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SVCapitalDataActive,
		        inactiveCapitalData: SVCapitalDataInctive
		    },
		    {
		        id: "SZ",
		        arrayItem: true,
		        country: 'Eswatini',
		        protest_name: '<span class="protest">Minibus driver protest</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Not free',
		        triggers: 'Authorities arrested four minibus drivers after accusing them of assaulting a female customer.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with repression by authorities in Eswatini. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Minibus drivers in Manzini.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "SZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SZCapitalDataActive,
		        inactiveCapitalData: SZCapitalDataInctive
		    },
		    {
		        id: "SZ",
		        arrayItem: true,
		        country: 'Eswatini',
		        protest_name: '<span class="protest">Pro-democracy protests</span>',
		        date: '2021-06-01',
		        date_text: "June 2021",
		        freedom: 'Not free',
		        triggers: 'Death of law student Thabani Nkomonye in May, the government’s rejection of petitions, and allegations of corruption against King Mswati III. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with the lack of political pluralism under Eswatini’s absolute monarchy, intolerance of dissent, and persistent poverty.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SZCapitalDataActive,
		        inactiveCapitalData: SZCapitalDataInctive
		    },
		    {
		        id: "SZ",
		        arrayItem: true,
		        country: 'Eswatini',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2019-09-01',
		        date_text: "September 2019",
		        freedom: 'Not free',
		        triggers: 'Low pay for teachers and other public servants.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration over a lack of political pluralism; allegations of corruption against the king and his family; and rising costs of living amid a stagnating economy.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially those employed in the public sector.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SZCapitalDataActive,
		        inactiveCapitalData: SZCapitalDataInctive
		    },
		    {
		        id: "SZ",
		        arrayItem: true,
		        country: 'Eswatini',
		        protest_name: '<span class="protest">Pro-democracy protests</span>',
		        date: '2019-05-01',
		        date_text: "May 2019",
		        freedom: 'Not free',
		        triggers: 'Calls for protests by pro-democracy opposition groups.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over the consolidation of power under King Mswati III and the lack of genuine political representation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public; opposition groups.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SZCapitalDataActive,
		        inactiveCapitalData: SZCapitalDataInctive
		    },
		    {
		        id: "SZ",
		        arrayItem: true,
		        country: 'Eswatini',
		        protest_name: '<span class="protest">Service delivery protest</span>',
		        date: '2018-06-01',
		        date_text: "June 2018",
		        freedom: 'Not free',
		        triggers: 'The suspension of parliament and the introduction of a bill that would impose a fine on those who married foreigners.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over corruption, misuse of state funds, and a nationalist crackdown.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SZCapitalDataActive,
		        inactiveCapitalData: SZCapitalDataInctive
		    },
		    {
		        id: "ET",
		        arrayItem: true,
		        country: 'Ethiopia',
		        protest_name: '<span class="protest">Withdrawal of outside forces protest</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Not free',
		        triggers: 'Although the two-year-long Tigray War ended with a truce in November 2022, millions of people were still displaced and Amhara and Eritrean troops remained inside Tigrayan territory, according to humanitarian workers.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the lasting effects of the war, especially the continued presence of outside forces in Tigray.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Tigrayans.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "ET", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ET", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ETCapitalDataActive,
		        inactiveCapitalData: ETCapitalDataInctive
		    },
		    {
		        id: "ET",
		        arrayItem: true,
		        country: 'Ethiopia',
		        protest_name: '<span class="protest">Special forces integration protests</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Not free',
		        triggers: 'The government ordered the integration of the eleven regions’ special forces into the federal military.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that dissolution of regional special forces would leave Amharas vulnerable to attacks from Tigray and Oromiya.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Amharas',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ET", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ET", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ETCapitalDataActive,
		        inactiveCapitalData: ETCapitalDataInctive
		    },
		    {
		        id: "ET",
		        arrayItem: true,
		        country: 'Ethiopia',
		        protest_name: '<span class="protest">Water shortage protest</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Not free',
		        triggers: 'Water shortages. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perceived government inaction to provide sufficient water.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Residents of Welkite.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "ET", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ET", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ETCapitalDataActive,
		        inactiveCapitalData: ETCapitalDataInctive
		    },
		    {
		        id: "ET",
		        arrayItem: true,
		        country: 'Ethiopia',
		        protest_name: '<span class="protest">Hundessa protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Not free',
		        triggers: 'Murder of activist and singer Hachalu Hundessa. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Desire for more autonomy for the subnational government, disputes over the construction of the Grand Ethiopian Renaissance Dam, and ethnic conflicts.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'Members of the Oromo ethnic group.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'Amid surging ethnic violence, Prime Minister Abiy Ahmed replaced ten ministers, including the minister of defense. ',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "ET", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ET", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ETCapitalDataActive,
		        inactiveCapitalData: ETCapitalDataInctive
		    },
		    {
		        id: "ET",
		        arrayItem: true,
		        country: 'Ethiopia',
		        protest_name: '<span class="protest">Oromia protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Not free',
		        triggers: 'Allegations circulated by opposition leader Jawar Mohammed that Prime Minister Abiy Ahmed had sent security forces to detain him.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Tensions between Ethiopia’s ethnic groups and a perception among the political opposition that the Abiy government was consolidating power and undermining the rule of law.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially supporters of Jawar Mohammed.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "ET", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ET", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ETCapitalDataActive,
		        inactiveCapitalData: ETCapitalDataInctive
		    },
		    {
		        id: "ET",
		        arrayItem: true,
		        country: 'Ethiopia',
		        protest_name: '<span class="protest">Ethnic violence protests</span>',
		        date: '2018-09-01',
		        date_text: "September 2018",
		        freedom: 'Not free',
		        triggers: 'Murder of twenty-three ethnic minorities in Ethiopia’s majority-Oromo Oromia region.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over persistent ethnic violence, especially acts committed by Oromo youth, and anger at the government for fostering a sense of impunity by inviting the formerly banned Oromo Liberation Front to return from exile.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Members of non-Oromo ethnic groups.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Prime Minister Abiy Ahmed denounced the violence and arrested thousands of people alleged to be involved with the violence.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ET", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ET", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ETCapitalDataActive,
		        inactiveCapitalData: ETCapitalDataInctive
		    },
		    {
		        id: "ET",
		        arrayItem: true,
		        country: 'Ethiopia',
		        protest_name: '<span class="protest">Political prisoner protests</span>',
		        date: '2018-02-01',
		        date_text: "February 2018",
		        freedom: 'Not free',
		        triggers: 'Citizens’ demands for the release of senior opposition leader Bekele Gerba from prison, after prime minister Hailemariam Desalegn had reneged on his promise to release all political prisoners. Prior protests had begun in 2015, led by the Oromo and Amhara ethnic groups over ethnic discrimination.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demands for increased political freedom, reforms, and ethnic representation in federal structures.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Oromo and Amhara ethnic groups.',
		        timespan: '3 days',
		        timespan_text: '',
		        outcomes: 'Opposition figures were freed, Desalegn stepped down, and the government declared a state of emergency.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ET", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ET", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ETCapitalDataActive,
		        inactiveCapitalData: ETCapitalDataInctive
		    },
		    {
		        id: "FI",
		        arrayItem: true,
		        country: 'Finland',
		        protest_name: '<span class="protest">Labor law change protest</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'Prime Minister Petteri Orpo, described as pro-business, sought to make the country’s terms of employment more flexible and cut unemployment. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Public transportation workers, manufacturing workers, and their unions went on a twenty-four-hour strike in December 2023 in protest of the law changes. This was followed by weeks-long strikes beginning in February 2024. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Public transit workers, unions',
		        timespan: '1 day in December 2023;<br />active February 2024 through April 2024',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest. The protesters suspended the March 2024 strike in early April 2024, in hopes that the suspension will lead to negotiations with the government. ',
		        large: true,
		        multiple: false,
		        mapData: {
		        	"id": "FI", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FI", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FICapitalDataActive,
		        inactiveCapitalData: FICapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Protest against gender-based violence</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'International Day for the Elimination of Violence. Pelicot mass rape trial, which had drawn public attention starting September 2024.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce gender-based violence and defend reproductive rights. Show solidarity with Gisèle Pelicot, the victim of the Pelicot rape case.',
		        size: 80001,
		        size_text: '>80,000',
		        participants: 'Women’s rights organizations, women',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Protest against the appointment of Michel Barnier</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'President Emmanuel Macron appointed Barnier, a center-right politician, as the new prime minister of France.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over Barnier’s appointment. Protesters feel that Macron ignored the recent election results and "stole" the election by appointing a conservative prime minister.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition, general public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Protest against National Rally</span>',
		        date: '2024-06-01',
		        date_text: "June 2024",
		        freedom: 'Free',
		        triggers: 'Snap parliamentary elections called by President Emmanuel Macron.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesting the National Rally (NR) and calling on citizens to block the party from gaining power in the elections. In a second protest, criticizing the NR’s attitudes toward women’s rights.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public, women’s rights groups',
		        timespan: '2 days (June 15 and June 23)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. In July 2024, the National Rally failed to win the majority in the parliament.',
				large: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">New Caledonia Kanak riots against electoral reform</span>',
		        date: '2024-05-01',
		        date_text: "May 2024",
		        freedom: 'Free',
		        triggers: 'Lawmakers in Paris backed a constitutional change that would give anyone over the age of ten on the Pacific archipelago of New Caledonia the right to vote in provincial elections. This would add more than 25,000 people to the voter roll, which has been frozen since 1998—which many say is undemocratic. Indigenous Kanak separatists say this will dilute their political power and benefit pro-France politicians. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Oppose the expansion of voter eligibility. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Kanak community members',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'France deployed troops to try to stop the riots and restore order. French President Emmanuel Macron suspended voting reform to allow “a return to order.” In June 2024, authorities detained seven pro-independence activists and sent them to France. On September 2024, French security forces killed two Kanaks, raising the death toll to thirteen people.',
				violent: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'Farmers cite frustration with government tax on tractor fuel, cheap agricultural imports, water storage issues, excessive restrictions, and red tape. The G20 Summit in November 2024 brought renewed attention to a prospective trade agreement between the EU and Mercosur trade bloc of South America.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the enforcement of a law designed to safeguard farmgate prices, the continuation of diesel tax breaks for agricultural vehicles, immediate payout of EU agricultural subsidies, guarantees on insurance payouts related to health and climate, and immediate aid for winemakers and organic farmers. As of November 2024, opposition to EU-Mercosur trade deal, which farmers argue will bring large agricultural imports that aren’t bound by EU regulations and create unfair competition for EU farmers.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'FNSEA farming union',
		        timespan: '7 months',
		        timespan_text: '',
		        outcomes: 'The government scrapped plans to eliminate the diesel tax break for agricultural vehicles and announced a series of measures to ease financial and administrative burdens placed upon farmers. In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.  Despite the continued opposition from farmers and the French government, EU and South American officials reached a trade deal on December 6, 2024.',
		        significant: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">"Islam out of Europe" protests</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'A teenager was stabbed to death in southeastern France on November 19, with eyewitnesses identifying the assailant as someone of Arab origin.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'According to French intelligence, riots occurred nationwide to convince the public that "immigration is the cause of crime," inspired by the riots that broke out in Dublin earlier in the month.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Far-right activists',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Protest for consent-based rape laws</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'Division within the European Union on legislation proposed by the European Commission to adopt consent-based rape laws throughout the bloc. French law does not define rape in terms of consent. Eve of International Day for the Elimination of Violence against Women.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Pressure French President Emmanuel Macron to adopt a consent-based definition of rape.',
		        size: 11,
		        size_text: '>10',
		        participants: 'Women, socialists.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">End violence against women</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'The Ministry of the Interior reported that the number of rapes and attempted rapes doubled between 2017 and 2021. International Day for the Elimination of Violence against Women.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce rarity of convictions for rape in France. Demand 2 billion euros be allocated each year to improve the country’s response to gender-based violence.  ',
		        size: 80001,
		        size_text: '>80,000',
		        participants: 'All of Us Against Gender and Sexual Violence',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Mayotte is Thirsty</span>',
		        date: '2023-09-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'Drought and water scarcity on the French island of Mayotte, where taps tend to work only one day out of three.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protesters demand accountability for alleged embezzling, leaks, and lack of investments made to maintain sustainable water supplies.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Mayotte residents',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The French minister for overseas territories visited in November 2023 and thanked the residents for "accepting the unacceptable."',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Nahel protests</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Free',
		        triggers: 'On June 27, a police officer fatally shot Nahel M., a 17-year-old French citizen of Algerian and Moroccan descent, during a traffic stop. In November 2023, the police officer suspected of shooting Nahel was released from custody pending further investigation.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over percieved racism among French police and police brutality. Call for justice for Nahel. ',
		        size: 30001,
		        size_text: '>30,000',
		        participants: 'General public.',
		        timespan: '2 weeks starting in June 2023,<br />1 day in September 2023,<br />1 day in November 2023',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">High-speed rail protest</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Free',
		        triggers: 'The governments of France and Italy are working to construct a high-speed freight and passenger railway line through the Alps to connect Lyon, France, with Turin, Italy.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the construction will be detrimental for the surrounding alpine environment.',
		        size: 3001,
		        size_text: '>3,000',
		        participants: 'Environmental activists.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Reservoir protest</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Free',
		        triggers: 'Plans supported by the French government to construct a reservoir in Sainte-Soline, western France; pension reform protests.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the reservoirs sustain ecologically unsustainable types of industrial farming and trap water that would otherwise naturally irrigate soils and feed rivers and streams.',
		        size: 6001,
		        size_text: '>6,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Anti-pesticide ban protest</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Free',
		        triggers: 'In accordance with a European Court of Justice ruling, the French government closed a loophole that allowed sugar beet farmers to use neonicotinoids, a pesticide that the European Union banned in 2018. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the ban on neonicotinoid pesticides will constrain farmers’ livelihoods.',
		        size: 2001,
		        size_text: '>2,000',
		        participants: 'Sugar beet farmers.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">2023 pension reform strikes and protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'Government plans for pension reform including a measure that would raise the retirement age from 62 to 64. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the prospect of more years in the work force before retirement.',
		        size: 1200001,
		        size_text: '>1,200,000',
		        participants: 'Unions, public sector employees, rail workers, teachers.',
		        timespan: '4.5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. In March 2023, French Prime Minister Elisabeth Borne used a special procedure to push the pensions bill through the National Assembly without a vote.',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Kurdish protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Free',
		        triggers: 'A gunman killed three people at a Kurdish cultural center and nearby Kurdish cafe in Paris. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear of insufficient protections for France’s Kurdish population; frustration over government inaction to prevent attacks; grief in commemoration of the 10th anniversary of a similar attack that killed three Kurdish women in 2013.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Kurds.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Anti-immigration bill protest</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Free',
		        triggers: 'The French government moved forward with plans to pass legislation that would allow migrants with a deportation order to be put on a "wanted list" in order to streamline deportations. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Solidarity with undocumented migrants.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Cost of living strikes and protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Free',
		        triggers: 'Strikes at oil refineries triggered fuel shortages and exacerbated rising fuel prices. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Worries over energy costs during winter; prices rising faster than wage increases.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Anti-immigration protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Free',
		        triggers: 'The murder of a 12-year-old girl by an illegal immigrant from Algeria.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with the government’s policies on illegal immigration and deportation.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Right-wing partisans.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">May Day protest</span>',
		        date: '2022-05-01',
		        date_text: "May 2022",
		        freedom: 'Free',
		        triggers: 'Commemoration of May Day; call for protest by labor organizations.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic discontent amid rising costs of living; opposition to President Emmanuel Macron’s economic agenda, including a plan to raise the retirement age.  ',
		        size: 120001,
		        size_text: '>120,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Health pass protest</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Free',
		        triggers: 'Introduction of a proposal that would require a “health pass” to access many amenities. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns with perceived government overreach and frustration with vaccine mandates.',
		        size: 150001,
		        size_text: '>150,000',
		        participants: 'General public.',
		        timespan: '8 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest. The bill was adopted by the French National Assembly.',
		        large: true,
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Police protest</span>',
		        date: '2021-05-01',
		        date_text: "May 2021",
		        freedom: 'Free',
		        triggers: 'A rise in attacks against police.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with rising violence, poor working conditions for law enforcement, and a perceived failure to address underlying social ills; and the desire for increased protections for police.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Police officers.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Climate law protest</span>',
		        date: '2021-05-01',
		        date_text: "May 2021",
		        freedom: 'Free',
		        triggers: 'The National Assembly’s consideration of a climate law.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Belief that government action has been insufficient on climate change. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Anti-Semitic murder protest</span>',
		        date: '2021-04-01',
		        date_text: "April 2021",
		        freedom: 'Free',
		        triggers: 'The Court of Cassation’s ruling that the killer of a Jewish woman could not be tried due to his “delirious state.” ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with the court’s determination amid rising anti-Semitic violence, and a sense of a lack of accountability for her death.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, including high-level politicians.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Police security bill protests</span>',
		        date: '2020-11-01',
		        date_text: "November 2020",
		        freedom: 'Free',
		        triggers: 'Approval in the Chamber of Deputies of a bill that limits the publication of images of on-duty police officers and the circulation of a video of police beating music producer Michel Zecler.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns that the bill would limit accountability for police and suppress media freedom.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'The government agreed to rewrite the bill in response to protest demands.',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Black Lives Matter protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Free',
		        triggers: 'Deaths of George Floyd in 2020 in the United States and Adama Traore in 2016 in France, both at the hands of the police.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Police brutality in France, France’s colonial legacy, and a lack of government recognition or societal discussion of issues of systemic racism.',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'General public.',
		        timespan: '2 months ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">2019 pension reform protests</span>',
		        date: '2018-12-01',
		        date_text: "December 2018",
		        freedom: 'Free',
		        triggers: 'Government plans for pension reform.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Pension reform.',
		        size: 800000,
		        size_text: '800,000 ',
		        participants: 'Unions, public sector employees, Yellow Vest protesters, students, lawyers, rail workers.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'The government scrapped plans to raise the retirement age but maintained its plans to install other pension reforms, such as reducing the pension categories and redistributing benefits toward citizens with lower incomes.',
		        significant: true,
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">“Yellow Vest” protests</span>',
		        date: '2018-11-01',
		        date_text: "November 2018",
		        freedom: 'Free',
		        triggers: 'Proposal of a new fuel tax.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economically regressive policies, declining standard of living, growing inequality, and perception of government as overly favorable to the rich.',
		        size: 300000,
		        size_text: '300,000',
		        participants: 'Initially mostly rural citizens, then later some urban working- and middle-class participants; some support from and participation by controversial far-right political groups.',
		        timespan: '1 year, 6 months',
		        timespan_text: '',
		        outcomes: 'The government canceled the fuel tax increase, increased minimum wage and public spending, promised tax cuts, and initiated the Grand Débat to try to rebuild public trust in the government.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Rail unions’ protests</span>',
		        date: '2018-03-01',
		        date_text: "March 2018",
		        freedom: 'Free',
		        triggers: 'Announcement of plans to reduce retirement benefits, reform unemployment insurance, and permit competition to the national rail service.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Threats to public services and public sector employees.',
		        size: 200000,
		        size_text: '>200,000',
		        participants: 'Public sector workers, trade unions, students.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "FR",
		        arrayItem: true,
		        country: 'France',
		        protest_name: '<span class="protest">Labor reform protests</span>',
		        date: '2017-09-01',
		        date_text: "September 2017",
		        freedom: 'Free',
		        triggers: 'Changes to private sector labor laws that would reduce damages for fired workers and favor companies in negotiations with workers. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceptions of President Emmanuel Macron’s administration as overly favorable to businesses.',
		        size: 200000,
		        size_text: '>200,000',
		        participants: 'Trade unions, general public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "FR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "FR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: FRCapitalDataActive,
		        inactiveCapitalData: FRCapitalDataInctive
		    },
		    {
		        id: "PF",
		        arrayItem: true,
		        country: 'French Polynesia',
		        protest_name: '<span class="protest">Olympic surf tower protest</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Free',
		        triggers: 'Surfing events for 2024 Paris Olympics will be held in the small village of Teahupo’o on the island of Tahiti. Paris 2024 plans to build a new three story aluminum surf tower on a reef, replacing the existing wooden surf tower that has been used for past surfing competitions. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call to stop construction due to fears it will damage the reef and affect the ecosystem and waves.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Surfers, Tahiti residents',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'Organizers of the Paris Olympics agreed to scale back the design for the surf tower in November 2023.',
		        significant: true,
		        multiple: false,
		        mapData: {
		        	"id": "PF", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PF", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PFCapitalDataActive,
		        inactiveCapitalData: PFCapitalDataInctive
		    },
		    {
		        id: "GA",
		        arrayItem: true,
		        country: 'Gabon',
		        protest_name: '<span class="protest">Education grant protests</span>',
		        date: '2019-04-01',
		        date_text: "April 2019",
		        freedom: 'Not free',
		        triggers: 'The announcement of government plans to reduce access to tuition grants.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the high cost of living and difficulties in accessing higher education.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Secondary school students.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The government suspended the reform plans.',
		        significant: true,
		        multiple: false,
		        mapData: {
		        	"id": "GA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GACapitalDataActive,
		        inactiveCapitalData: GACapitalDataInctive
		    },
		    {
		        id: "GM",
		        arrayItem: true,
		        country: 'Gambia',
		        protest_name: '<span class="protest">Female genital mutilation protests</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Partly free',
		        triggers: 'Lawmakers voted to advance a bill to repeal the state’s 2015 ban on female genital mutilation. The practice has increased in frequency in recent years—despite being outlawed and ongoing campaigns to end it. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Some protesters, particularly Muslims, expressed support for the practice and the need to repeal the ban, claiming it is a religious practice. Others expressed opposition to the bill that would lift the ban on the practice.  ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Human rights groups, Muslims, women, Fatou Baldeh, Abdoulie Fatty',
		        timespan: '1 day in March 2024;<br />1 day on July 2024',
		        timespan_text: '',
		        outcomes: 'In July 2024, Parliament voted to keep the ban on female genital mutilation. ',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "GM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GMCapitalDataActive,
		        inactiveCapitalData: GMCapitalDataInctive
		    },
		    {
		        id: "GM",
		        arrayItem: true,
		        country: 'Gambia',
		        protest_name: '<span class="protest">“Three Years Is Enough” protests</span>',
		        date: '2019-12-01',
		        date_text: "December 2019",
		        freedom: 'Partly free',
		        triggers: 'Concerns that President Adama Barrow would renege on his promise to rule for only three years in a provisional government before calling elections and not running.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of political reforms, democratic backslide, and lack of government transparency. ',
		        size: 10000,
		        size_text: '10,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "GM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GMCapitalDataActive,
		        inactiveCapitalData: GMCapitalDataInctive
		    },
		    {
		        id: "PS",
		        arrayItem: true,
		        country: 'Gaza Strip',
		        protest_name: '<span class="protest">Anti-Hamas protests</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Not free',
		        triggers: 'A July heat wave caused power outages',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Discontent with Hamas, the movement that has governed the Gaza Strip since 2007; limited access to electricity; high unemployment; and corruption.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PSCapitalDataActive,
		        inactiveCapitalData: PSCapitalDataInctive
		    },
		    {
		        id: "PS",
		        arrayItem: true,
		        country: 'Gaza Strip',
		        protest_name: '<span class="protest">Kader Adnan strike and protest</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Not free',
		        triggers: 'Khader Adnan, a high-profile former spokesman of Palestinian Islamic Jihad, died following an eighty-seven-day hunger strike.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the death of Adnan; anger of Israeli occupation.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Members of Palestinian Islamic Jihad (PIJ) and the Popular Front for the Liberation of Palestine (PFLP).',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Palestinian Islamic Jihad launched rocket attacks against Israel immediately following the death of Adnan, to which Israel responded by launching Operation Shield and Arrow from May 9–13.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PSCapitalDataActive,
		        inactiveCapitalData: PSCapitalDataInctive
		    },
		    {
		        id: "PS",
		        arrayItem: true,
		        country: 'Gaza Strip',
		        protest_name: '<span class="protest">Anti-flag march protest</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Not free',
		        triggers: 'Tens of thousands of Israelis marched through the Muslim Quarter of Jerusalem’s Old City to celebrate the anniversary of Israeli forces retaking East Jerusalem in 1967. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perceived Israeli provocations related to East Jerusalem.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "PS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PSCapitalDataActive,
		        inactiveCapitalData: PSCapitalDataInctive
		    },
		    {
		        id: "PS",
		        arrayItem: true,
		        country: 'Gaza Strip',
		        protest_name: '<span class="protest">Israeli occupation protests</span>',
		        date: '2021-05-01',
		        date_text: "May 2021",
		        freedom: 'Not free',
		        triggers: 'A court decision on whether to evict six Palestinian families from their homes in the East Jerusalem neighborhood of Sheikh Jarrah; Israeli moves to crack down on Palestinian assembly.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over Israeli raids and settlements in the West Bank; fear that Israel will change the status quo of al-Aqsa Mosque.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: 'Active, intermittently',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PSCapitalDataActive,
		        inactiveCapitalData: PSCapitalDataInctive
		    },
		    {
		        id: "PS",
		        arrayItem: true,
		        country: 'Gaza Strip',
		        protest_name: '<span class="protest">“Great March of Return” protests</span>',
		        date: '2018-03-01',
		        date_text: "March 2018",
		        freedom: 'Not free',
		        triggers: 'Israeli economic blockade of the Gaza Strip, and Palestinian demand for the right to return to land in what is currently considered Israel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic crisis caused by the blockade (including high unemployment along with electricity and food shortages), and Palestinians’ demand for the right to return.',
		        size: 40000,
		        size_text: '40,000',
		        participants: 'Palestinians.',
		        timespan: '1 year, 9 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PSCapitalDataActive,
		        inactiveCapitalData: PSCapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Pro-EU accession protests</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Partly free',
		        triggers: 'The newly elected Georgian government announced on November 28 that it would suspend accession talks with the EU until 2028. The decision was announced amid ongoing protests against the recent parliamentary elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over the suspension of EU accession talks. (EU accession is widely popular amongst Georgians, and is even included in the Georgian constitution.) Continuation of 2024 election protests; protesters are also calling for new parliamentary elections.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. More than 400 protesters were arrested, many of whom were reportedly abused or tortured while in custody.',
				active: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Abkhazia-Russia investment deal protests</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Partly free',
		        triggers: 'The Abkhazian parliament had been considering the ratification of an investment deal with Russia to allow Russians to buy land and residences in Abkhazia. On November 11, five opposition activists were arrested.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express concern that the deal will open the market to wealthy Russians and raise housing prices in the region; part of greater concern over growing Russian influence in Abkhazia. Call for the release of the arrested activists and for Aslan Bzhania, the leader of Abkhazia, to resign.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Abkhazian residents, opposition',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'After protesters seized the parliament building,  Bzhania reached a negotiation with the opposition to scrap the property bill and resign from his position on November 19. On December 3, the Abkhazian parliament voted to reject the investment deal.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">2024 election protests</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'Results of the parliamentary elections on October 26, which gave the ruling Georgian Dream party more than 54 percent of the vote. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Denounce the election results, which many—both within and outside Georgia—believe were the result of a flawed electoral process. Call for a new parliamentary election with international supervision.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition, general public',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Three out of the four main opposition parties refused to accept the election results and announced that they would not take their seats in Parliament. The Central Election Commission reaffirmed the election results on October 31 after conducting a partial recount of the votes. On November 25, the newly elected Parliament opened its inaugural session. On December 30, Mikheil Kavelashvili was formally inaugurated as president despite ongoing protests.',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Protest for the release of Mikheil Saakashvili</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Partly free',
		        triggers: 'Mikheil Saakashvili, who spearheaded the Rose Revolution in 2003, was detained in 2021, which rights groups claimed was politically motivated. Twenty-year anniversary of the Rose Revolution. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the release of Saakashvili, who has been on a hunger strike due to what he claims is mistreatment by prison guards.',
		        size: 101,
		        size_text: '>100',
		        participants: 'United National Movement supporters.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Russian direct flights protest</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Partly free',
		        triggers: 'Direct flights resumed between Russia and Georgia.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over Russian military presence in South Ossetia and Abkhazia and Russia’s invasion of Ukraine; frustration with the government for warming relations with Russia.',
		        size: 201,
		        size_text: '>200',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Pro-EU protest</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Partly free',
		        triggers: 'The deterioration of the health of former president Mikheil Saakashvili, an advocate of closer relations with Europe who was serving a six-year prison term.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters believed the Georgian Dream party to be taking Georgia in an authoritarian, pro-Russian direction that is hindering the country’s accession to the European Union.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Members of the opposition United National Movement (UNM) party.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Foreign agents bill protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'Parliament introduced a bill that would require non-governmental and media organizations that receive more than 20 percent of their funding from abroad to declare themselves as "foreign agents," or face fines and possible imprisonment. In 2024, the Georgian Dream party once again proposed a law forcing NGOs that receive more than 20 percent of their funding from abroad to be registered as "carrying the interests of foreign power."',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of rising authoritarianism and Russian influence in Georgia. Reject ties to Russia and demand a "European future."',
		        size: 99001,
		        size_text: '<100,000',
		        participants: 'General public; young people; United National Movement; Salome Zourabichvili; foreign ministers of Latvia, Estonia, Lithuania, and Iceland',
		        timespan: '1 week in 2023;<br />4 months from April 2024 to July 2024',
		        timespan_text: '',
		        outcomes: 'Parliament retracted the foreign agents bill in 2023. On April 3, 2024, Georgian Dream announced plans to bring back the bill but replace the label "foreign agent" with "[an] organization carrying the interests of a foreign power." Parliament voted to pass the law on its third reading on May 14. On May 18, President Salome Zourabichvili vetoed the bill. On May 28, Parliament voted eighty-four to four to override Zourabichvili’s veto—with the opposition abstaining from the vote in protest. On June 2024, the bill was signed into law.',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Local elections protests</span>',
		        date: '2021-10-01',
		        date_text: "October 2021",
		        freedom: 'Partly free',
		        triggers: 'The ongoing detention of former president Mikheil Saakashvili; the announcement of municipal election results favorable to the ruling party, Georgian Dream.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over electoral integrity, the independence of the judicial system, and the perceived entrenchment of Georgian Dream.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition party members, especially members of the United National Movement. ',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Journalist murder protest</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Partly free',
		        triggers: 'The murder of journalist Alexander Lashkarava.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with anti-LGBT attitudes and violence against journalists.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Opposition leader arrest protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Partly free',
		        triggers: 'The arrest of opposition United National Movement leader Nika Melia.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceived entrenchment of the ruling Georgia Dream party and a crackdown on political opposition and dissent.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Electoral protest</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Partly free',
		        triggers: 'Announcement of electoral results that showed the ruling Georgia Dream party with a significant lead.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Belief that the electoral results were politically manipulated, that opposition parties had been deliberately kept out of power, and that oligarch and Georgia Dream founder Bidzina Ivanishvili retains an outsized level of influence.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially opposition politicians.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Electoral reform protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Parliament’s failure to pass electoral reform that would change the political system to one based on proportional representation.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Democratic backslide, crackdown on political opponents, and discontent with the electoral system that advantages the ruling party.',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "GE",
		        arrayItem: true,
		        country: 'Georgia',
		        protest_name: '<span class="protest">Russian interference protests</span>',
		        date: '2019-06-01',
		        date_text: "June 2019",
		        freedom: 'Partly free',
		        triggers: 'Russian lawmaker’s entry into the Georgian Parliament, during which he took the speaker’s seat.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns that the ruling party is too friendly toward Russia.',
		        size: 10000,
		        size_text: '10,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Then parliament speaker Irakli Kobakhidze resigned, and a proposal was made to change to an electoral system based on proportional representation.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "GE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GECapitalDataActive,
		        inactiveCapitalData: GECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Anti-Merz protest</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Free',
		        triggers: 'Friedrich Merz, the conservative opposition leader,  worked with the Alternative for Germany (AfD) party to push an anti-immigration bill through parliament. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize Merz and the conservative opposition parties for working with the AfD.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'Merz’s anti-immigration bill ultimately failed to pass the Parliament. ',
				active: true,
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Anti-AfD protest</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Free',
		        triggers: 'The Alternative for Germany (AfD) party held a convention ahead of the upcoming February 23 elections. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce the AfD’s far-right and anti-immigrant policies. Concern over the rising far-right extremism and potential threats of facism. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public',
		        timespan: '2 days (Jan 11, Jan 25)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Anti-AfD protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'Reports that a group of officials from the Alternative for Germany (AfD) party met with neo-Nazis at a hotel in Potsdam to discuss the possibility of a mass deportation scheme. Holocaust Memorial Day. Regional elections. The AfD is polling in second place in Germany for the June 2024 European Parliament elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce the AfD and Nazism.',
		        size: 2400001,
		        size_text: '>2,400,000',
		        participants: 'General public',
		        timespan: '6 months;<br />1 week in August 2024',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. The AfD lost a runoff election in the Saale-Orla district of Thuringia on January 28, 2024. In the June 2024 elections, the AfD won the second-highest number of seats in the EU. In September 2024, the AfD earned nearly one-third of the votes in Thuringia’s state election and became the largest party in the state parliament.',
		        large: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Farmers’ protests</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'Germany proposed cuts to diesel subsidies and tax breaks for agricultural vehicles. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Farmers fear this will threaten their livelihood. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'In March 2024, Germany’s finance minister proposed a tax relief plan designed to appease farmers.  In June 2024, the ruling coalition of the parliament agreed to introduce this relief package. In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Autobahn rave</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'Berlin authorities were moving closer to approving a plan to extend the city’s autobahn into the former East Germany.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'The construction of the autobahn extension would require the demolition of several apartment buildings and a few trendy bars.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Green transport protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Free',
		        triggers: 'The Free Democratic Party stalled efforts by the coalition government to pass climate policies related to transportation, including a phase out of internal combustion engines.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'The conviction that green transportation policies will be necessary to combat the worst effects of climate change; anger that the Free Democratic Party was not supporting these measures.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Peace in Ukraine protest</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Free',
		        triggers: 'The German government continued to arm Ukraine one year after the Russian invasion. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that continued support for the war in Ukraine would escalate to affect Germans’ lives in greater ways.',
		        size: 13001,
		        size_text: '>13,000',
		        participants: 'Germany’s far-right and political left.',
		        timespan: '2 years',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Coal mine expansion protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'The state government of North Rhine-Westphalia and the national government reached a deal with utility company RWE to destroy the abandoned village of Luetzerath and build a coal mine there. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger that construction of additional coal mines will hinder Germany’s ability to meet its 2015 Paris Agreement commitments.',
		        size: 15001,
		        size_text: '>15,000',
		        participants: 'Climate activists.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Fuel price protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'Rising prices, especially of fuel. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with the government’s pro-Ukraine policies; fears of worsening economic conditions and rising fuel prices.',
		        size: 50001,
		        size_text: '>50,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Letzte Generation protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Free',
		        triggers: 'Perceived government inaction on climate change. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of catastrophic consequences of climate change.',
		        size: 50001,
		        size_text: '<100',
		        participants: 'Climate activists.',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Police brutality protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Free',
		        triggers: 'Death of George Floyd in the United States, sparking protests against local police brutality and racism in Germany.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Police brutality, systemic racism, and lack of government recognition of systemic racism. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Coronavirus restriction protests </span>',
		        date: '2020-04-01',
		        date_text: "April 2020",
		        freedom: 'Free',
		        triggers: 'Enactment of strict lockdown measures and vaccine mandates.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic damage brought on by restrictions and political contestation over the threat posed by the virus.',
		        size: 30001,
		        size_text: '>30,000',
		        participants: 'Right- and left-wing extremists, conspiracy theorists, antivaccine groups.',
		        timespan: '16 months, intermittently',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "DE",
		        arrayItem: true,
		        country: 'Germany',
		        protest_name: '<span class="protest">Far-right pact protests</span>',
		        date: '2020-02-01',
		        date_text: "February 2020",
		        freedom: 'Free',
		        triggers: 'Cooperation between the state branches of far-right party Alternative for Germany (AfD), the center-right Christian Democratic Union and left-leaning Free Democratic Party (FDP) to oust the state premier of Thuringia, Bodo Ramelow, and replace him with Thomas Kemmerich, a member of the FDP. The pact was a visible violation of mainstream political norms that opposed cooperating with far-right parties.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'State-level mainstream parties’ cooperation with far-right parties.',
		        size: 18000,
		        size_text: '18,000',
		        participants: 'Unions, antifascist groups, young people.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Leadership change: the manner of Ramelow’s dismissal prompted outrage that led to the resignation of the ruling Christian Democratic Union’s chair, Annegret Kramp-Karrenbauer, Chancellor Angela Merkel’s handpicked successor. Kemmerich eventually stepped down from his position as state premier, after which Ramelow reclaimed the post.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "DE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "DE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: DECapitalDataActive,
		        inactiveCapitalData: DECapitalDataInctive
		    },
		    {
		        id: "GH",
		        arrayItem: true,
		        country: 'Ghana',
		        protest_name: '<span class="protest">Voter register irregularity protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'The main opposition party, the National Democratic Congress (NDC), accused the electoral commission of tampering with the voter register by illegally transferring voters to different stations, removing the names of eligible voters, and including the names of deceased voters.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Call for a forensic audit of the voter register. Demand free and fair elections for upcoming presidential and parliamentary contests in December 2024.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Opposition, general public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. The election commission denied the allegations and stated that it had corrected all previously identified errors in the voter register.',
		        multiple: true,
		        mapData: {
		        	"id": "GH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GHCapitalDataActive,
		        inactiveCapitalData: GHCapitalDataInctive
		    },
		    {
		        id: "GH",
		        arrayItem: true,
		        country: 'Ghana',
		        protest_name: '<span class="protest">"Stop Galamsey" protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'Continued illegal mining (also known as galamsey) in Ghana.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against the government’s poor handling of galamsey, which has caused significant environmental harm in Ghana. Also protest economic hardships.',
		        size: 41,
		        size_text: '>40',
		        participants: 'Activist groups (Democracy Hub, FixTheCountry), Catholic parishioners',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. As of September 25, the Ghanaian police had arrested over forty protesters, including the leader of Democracy Hub, Oliver Barker-Vormawor. During a demonstration on October 11, protesters handed a petition for a ban on informal mining to the president’s office.',
		        multiple: true,
		        mapData: {
		        	"id": "GH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GHCapitalDataActive,
		        inactiveCapitalData: GHCapitalDataInctive
		    },
		    {
		        id: "GH",
		        arrayItem: true,
		        country: 'Ghana',
		        protest_name: '<span class="protest">2023 economic protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'Democracy Hub, a governance advocacy group, called for a protest. The opposition party, the National Democratic Congress, called for protests in Accra to oust the governor of the central bank and two deputies, blaming them for inflation and high cost of living caused by their reckless financing of the government.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over the high cost of living, lack of jobs, high public debt, and corruption. Protests against the Bank of Ghana governor called for his resignation.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public, National Democratic Congress party',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GHCapitalDataActive,
		        inactiveCapitalData: GHCapitalDataInctive
		    },
		    {
		        id: "GH",
		        arrayItem: true,
		        country: 'Ghana',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices. Inflation hit 37% in September 2022.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of economic hardship and implications of depreciating value of currency.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GHCapitalDataActive,
		        inactiveCapitalData: GHCapitalDataInctive
		    },
		    {
		        id: "GH",
		        arrayItem: true,
		        country: 'Ghana',
		        protest_name: '<span class="protest">Inflation protest</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Free',
		        triggers: 'The government introduced price hikes and a tax on electronic payments.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Worsening economic conditions; perceived failure of government to combat inflation.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GHCapitalDataActive,
		        inactiveCapitalData: GHCapitalDataInctive
		    },
		    {
		        id: "GH",
		        arrayItem: true,
		        country: 'Ghana',
		        protest_name: '<span class="protest">#FixTheCountry protest</span>',
		        date: '2021-08-01',
		        date_text: "August 2021",
		        freedom: 'Free',
		        triggers: 'Call for protests by activists on social media after tax hikes and increases in fuel prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with high cost of living, perceived corruption, and poor infrastructure and living conditions. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'Youth and general public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GHCapitalDataActive,
		        inactiveCapitalData: GHCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Farmers’ protest 2025</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Free',
		        triggers: 'The Greece-Bulgaria water deal, which provided crucial water resources to Greek farmers, expired in July 2024. The Greek government has not specified whether it would renew the deal.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over uncertainty of water deal, as well as continued concern over high energy and production costs since the 2024 farmers’ protests. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. The Greek government stated that the political instability in Bulgaria had stalled talks about a new water deal.',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Strike against high cost of living</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'Rising food and housing prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express discontent over high cost of living and low wages. Call for the government to raise public sector wages and more effectively combat inflation.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Unions for public and private sector workers (e.g., transportation, education, healthcare)',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'EU environmental regulations such as new legislation requiring 4 percent of farmland to be left fallow in order to receive subsidies. Tax on diesel amid surging energy prices. Delayed compensation for damage to crops and livestock caused by flooding in the Thessaly region in 2023. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand a diesel tax credit and changes to EU subsidy policies. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Prime Minister Kyriakos Mitsotakis offered farmers lower power bills for the next ten years and a rebate on fuel taxes. In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP).  In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Higher education reform protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'Prime Minister Kyriakos Mitsotakis is expected to submit a bill to parliament which will permit private universities to operate in Greece. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Oppose the decision to permit private universities to operate in Greece, claiming it will harm the public education system and devalue degrees from public universities.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Students',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. In March 2024, Greek lawmakers approved a bill to end the state’s monopoly on university education. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Police violence against Roma protest</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'A Roma youth was shot dead by police after a car chase.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters accuse the Greek police of using excessive force and violence against members of the Roma minority group.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Roma',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">ID card protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'The Greek government was planning to introduce new identity cards.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Conspiracy theories claim that the cards will be embedded with chips to track the holder’s location.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'Deeply religious Christians',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Migrant shipwreck protests</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Free',
		        triggers: 'A fishing boat that set sail from Tobruk, Libya, on June 10 sank off the coast of Pylos, Greece, on June 14. Authorities quickly confirmed eighty-two deaths with hundreds more missing and presumed dead.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the role of Greek and EU coast guard officials in the shipwreck. Protesters believed that authorities should have done more to save the migrants in distress.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Greece’s caretaker prime minister, Ioannis Sarmas, declared three days of national mourning.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Train crash strikes and protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Free',
		        triggers: 'A train crash killed 57 people.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Outrage over perceived government underinvestment and understaffing in the rail network that may have contributed to the crash.',
		        size: 60001,
		        size_text: '>60,000',
		        participants: 'General public.',
		        timespan: '2 weeks;<br />1 day on 2024 anniversary;<br />1 day in January 2025',
		        timespan_text: '',
		        outcomes: 'The prime minister apologized for any responsibility that the government had borne for the accident. He promised a swift investigation and a new safety improvement plan. Though the investigation has been ongoing for two years, a trial has yet to occur. After the January 2025 protests, three opposition parties vowed to submit a censure motion against the government over its handling of the crash.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Inflation strike and protest</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices, especially of fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic anxieties caused by rising prices.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Economic protest</span>',
		        date: '2022-04-01',
		        date_text: "April 2022",
		        freedom: 'Free',
		        triggers: 'A call for protest by two of the country’s largest labor unions.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic discontent as high inflation, especially for agricultural goods and fuel, strains household resources; frustration with the government for taking insufficient measures to address inflation.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Labor reform bill protests</span>',
		        date: '2021-06-01',
		        date_text: "June 2021",
		        freedom: 'Free',
		        triggers: 'Introduction and adoption of a labor reform bill that relaxed employer restrictions on overtime and employee hours.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with perceived erosion of worker’s rights and labor protections.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public; labor unions.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Protest law protests</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Free',
		        triggers: 'The Kryiakos Mitsotakis government’s enactment of a stringent law on demonstrations.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protection of the right to free assembly and discontent with the government’s move to stifle dissent.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GR",
		        arrayItem: true,
		        country: 'Greece',
		        protest_name: '<span class="protest">Pension reform strike</span>',
		        date: '2020-02-01',
		        date_text: "February 2020",
		        freedom: 'Free',
		        triggers: 'Twenty-four-hour strike in response to proposed pension reform.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Citizens’ fears of pension cuts, which could require them to retire later.',
		        size: 10000,
		        size_text: '10,000',
		        participants: 'Public sector trade unions.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GRCapitalDataActive,
		        inactiveCapitalData: GRCapitalDataInctive
		    },
		    {
		        id: "GT",
		        arrayItem: true,
		        country: 'Guatemala',
		        protest_name: '<span class="protest">Election interference protests</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'Guatemala’s top prosecutor ordered the suspension of the Seed Movement party, which came in second place in the first round of the country’s presidential election. Officials raided the Supreme Electoral Tribunal headquarters in search of evidence the following day. Anti-corruption candidate Bernardo Arévalo won the election but the incumbent has tried to block his transition. In November 2023, prosecutors announced they plan to strip Arévalo and his copartisans of immunity for allegedly making social media posts that encouraged students to take over San Carlos University in 2022. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over the perceived unfairness of the presidential race and attempts to undermine the Arévalo’s victory. Calls for public prosecutor to resign for trying to stop transfer of power.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the opposition.',
		        timespan: '6 months',
		        timespan_text: '',
		        outcomes: 'On July 13, Guatemala’s Supreme Electoral Tribunal overruled the suspension of the Seed Movement party. On August 28, the Supreme Electoral Tribunal certified that Arévalo won the election. The U.S. condemned the November 2023 allegations leveraged against Arévalo and said it would impose sanctions against those who interfered with the transition of power. Despite these efforts to obstruct the transfer of power, Arévalo was sworn into office on January 15, 2024.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "GT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GTCapitalDataActive,
		        inactiveCapitalData: GTCapitalDataInctive
		    },
		    {
		        id: "GT",
		        arrayItem: true,
		        country: 'Guatemala',
		        protest_name: '<span class="protest">Presidential candidate ban protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Partly free',
		        triggers: 'A court denied an indigenous candidate’s application to register for the country’s presidential election due to a dispute over the paperwork that her vice presidential candidate filed. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perceived unfairness of the country’s electoral tribunal.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the Peoples Liberation Movement party.',
		        timespan: '11 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "GT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GTCapitalDataActive,
		        inactiveCapitalData: GTCapitalDataInctive
		    },
		    {
		        id: "GT",
		        arrayItem: true,
		        country: 'Guatemala',
		        protest_name: '<span class="protest">Anti-corruption protests</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Partly free',
		        triggers: 'The firing of Juan Francisco Sandoval, the head of the Special Prosecutor’s Office Against Impunity, by Attorney General Maria Porras. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with impunity for pervasive corruption and the reduction of judicial independence.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, especially indigenous and student groups.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "GT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GTCapitalDataActive,
		        inactiveCapitalData: GTCapitalDataInctive
		    },
		    {
		        id: "GT",
		        arrayItem: true,
		        country: 'Guatemala',
		        protest_name: '<span class="protest">Budget protests</span>',
		        date: '2020-11-01',
		        date_text: "November 2020",
		        freedom: 'Partly free',
		        triggers: 'Adoption of a government budget bill that included spending cuts for public health, education, the judiciary, and social support programs while increasing stipends for lawmakers.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Widespread dissatisfaction with systemic corruption, weakening state support for vulnerable populations, and an ineffective government response to the coronavirus pandemic.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The govermnent withdrew the budget bill.',
		        significant: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "GT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GTCapitalDataActive,
		        inactiveCapitalData: GTCapitalDataInctive
		    },
		    {
		        id: "GN",
		        arrayItem: true,
		        country: 'Guinea',
		        protest_name: '<span class="protest">Anti-junta protest</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Not free',
		        triggers: 'Though the military junta agreed in 2022 to begin a democratic transition by December 31, 2024, the junta failed to announce any plans for new elections by the deadline.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the junta’s continued rule. Demand a return to democracy.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Opposition, Forces vives de Guinée',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. In his New Year’s address, Interim President Mamady Doumbouya announced his plans for a constitutional referendum to begin the democratic transition. Activists and opposition groups rejected this announcement, claiming that it was a tactic to extend the junta’s rule.',
		        multiple: true,
		        mapData: {
		        	"id": "GN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GNCapitalDataActive,
		        inactiveCapitalData: GNCapitalDataInctive
		    },
		    {
		        id: "GN",
		        arrayItem: true,
		        country: 'Guinea',
		        protest_name: '<span class="protest">Power cuts protest</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Partly free',
		        triggers: 'Months-long energy crisis leading to repeated power cuts. On March 13, 2024, power cuts in Conakry plunged the capital into darkness. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express frustrations with the cuts to power. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'After two children were killed as protesters clashed with security forces, Interim President Mamady Doumbouya told the press, "Those responsible for this situation owe us an explanation and everyone must take their share of responsibility," on March 14, 2024. On March 15, 2024, Doumbouya dismissed the head of Guinea’s state energy company along with his two deputies. ',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "GN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GNCapitalDataActive,
		        inactiveCapitalData: GNCapitalDataInctive
		    },
		    {
		        id: "GN",
		        arrayItem: true,
		        country: 'Guinea',
		        protest_name: '<span class="protest">Democratic transition protests</span>',
		        date: '2022-07-01',
		        date_text: "July 2022",
		        freedom: 'Partly free',
		        triggers: 'The arrest of three opposition leaders at the hands of the military government. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express frustration with the slow pace at which the military government has transitioned to civilian rule since the 2021 coup. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public, trade unions',
		        timespan: '3 months in 2022;<br />3 months beginning February 2023;<br />2 days in February 2024. ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "GN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GNCapitalDataActive,
		        inactiveCapitalData: GNCapitalDataInctive
		    },
		    {
		        id: "GN",
		        arrayItem: true,
		        country: 'Guinea',
		        protest_name: '<span class="protest">Term limit protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Suspicions that President Alpha Condé would seek a third term by amending the constitution.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Rising authoritarianism and repression of dissent and protests.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Coalition of politicians, activists.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "GN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GNCapitalDataActive,
		        inactiveCapitalData: GNCapitalDataInctive
		    },
		    {
		        id: "GN",
		        arrayItem: true,
		        country: 'Guinea',
		        protest_name: '<span class="protest">Municipal election protests</span>',
		        date: '2018-02-01',
		        date_text: "February 2018",
		        freedom: 'Partly free',
		        triggers: 'Delays in the announcement of final vote results for the 2018 municipal elections and a concurrent call for strikes by teachers to protest low pay.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns about electoral manipulation and frustration over poor teacher pay.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "GN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "GN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: GNCapitalDataActive,
		        inactiveCapitalData: GNCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">Anti-Henry protests</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Not free',
		        triggers: 'Under the unelected government of Prime Minister Ariel Henry who assumed office after the 2021 assassination of the country’s last president, Jovenel Moïse, Haiti has seen violent gangs form alliances to expand their control across the capital of Port-au-Prince and the surrounding area. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters demand Henry resign.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">Security protests</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Not free',
		        triggers: 'Gangs, which controlled an estimated 80 percent of the capital, Port-au-Prince, continued to kill and rape civilians.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Terror over the security situation, which had deteriorated since the start of 2023.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">Police protest</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Not free',
		        triggers: 'The killing of six police officers in an armed attack against a police substation in Haiti’s Artibonite valley.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Outrage over perceived government inaction to protect police officers in the wake of gang violence.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Police officers.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">Anti-foreign interference protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Not free',
		        triggers: 'The government requested security assistance from international actors to quell gang violence and economic crisis protests.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with previous failures of the international community to achieve stated objectived through interventions in Haiti.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2022-07-01',
		        date_text: "July 2022",
		        freedom: 'Partly free',
		        triggers: 'Rapidly rising prices, especially of fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Violence and poor quality of life under Prime Minister Ariel Henry.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">Presidential protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Partly free',
		        triggers: 'President Jovenel Moïse’s assertion that his five-year term as president would end in February 2022 rather than February 2021, the assassination of Moïse in July 2021, and rising levels of criminal violence.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Concerns over entrenchment of the Moïse government, lack of elections, systemic corruption, economic deterioration, and increasing political instability.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">“March for Life” protest</span>',
		        date: '2020-12-01',
		        date_text: "December 2020",
		        freedom: 'Partly free',
		        triggers: 'Anniversary of International Human Rights Day and enactment of a new law on public security.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over President Jovenel Moïse’s ongoing rule by decree; deepening economic and social insecurity; and concerns over perceived repression of antigovernment movements.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opponents of Moïse’s government.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">PetroCaribe protests</span>',
		        date: '2018-07-01',
		        date_text: "July 2018",
		        freedom: 'Partly free',
		        triggers: 'Revocation of fuel subsidies and revelations of President Jovenel Moïse’s direct involvement in a corruption scandal. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Poverty, corruption, lack of economic development despite economic aid, and inadequate public services and infrastructure.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Political opposition, young people, union workers, university professors, business associations, middle-class participants.',
		        timespan: '1 year, 5 months',
		        timespan_text: '',
		        outcomes: 'In July 2018, the fuel hike was reversed, and then prime minister Jack Guy Lafontant resigned.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HT",
		        arrayItem: true,
		        country: 'Haiti',
		        protest_name: '<span class="protest">Tax protests</span>',
		        date: '2017-09-01',
		        date_text: "September 2017",
		        freedom: 'Partly free',
		        triggers: 'Introduction of new taxes on cigarettes, passports, and other goods.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the increasing cost of living, lack of economic opportunity, and the failure of the government to provide basic services.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HTCapitalDataActive,
		        inactiveCapitalData: HTCapitalDataInctive
		    },
		    {
		        id: "HN",
		        arrayItem: true,
		        country: 'Honduras',
		        protest_name: '<span class="protest">Anti-Castro protest</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Partly free',
		        triggers: 'President Xiomara Castro’s Libre party pushed through the election of a new interim prosecutor without approval from the legislature.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Denounce what critics say is an attempt to engineer an unconstitutional power grab.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition members.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "HN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HNCapitalDataActive,
		        inactiveCapitalData: HNCapitalDataInctive
		    },
		    {
		        id: "HN",
		        arrayItem: true,
		        country: 'Honduras',
		        protest_name: '<span class="protest">Privatization reform protests</span>',
		        date: '2019-04-01',
		        date_text: "April 2019",
		        freedom: 'Partly free',
		        triggers: 'Proposed reforms to the education and healthcare sectors and revelations that President Juan Orlando Hernández received contributions from drug traffickers.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Government corruption, falling spending on public services, and widespread poverty despite economic growth.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Teachers, medical professionals, unions, trade groups, farmers, taxi and truck drivers, police officers.',
		        timespan: '6 months',
		        timespan_text: '',
		        outcomes: 'Hernández withdrew the proposed legislation.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HNCapitalDataActive,
		        inactiveCapitalData: HNCapitalDataInctive
		    },
		    {
		        id: "HN",
		        arrayItem: true,
		        country: 'Honduras',
		        protest_name: '<span class="protest">2017 election protests</span>',
		        date: '2017-12-01',
		        date_text: "December 2017",
		        freedom: 'Partly free',
		        triggers: 'Significant irregularities in the reporting of the results of the December 2017 presidential election, which President Juan Orlando Hernández won narrowly.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Electoral fraud and broader democratic erosion (in other words, subversion of term limits).',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Political opposition, religious institutions.',
		        timespan: '2 weeks ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "HN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HNCapitalDataActive,
		        inactiveCapitalData: HNCapitalDataInctive
		    },
		    {
		        id: "HK",
		        arrayItem: true,
		        country: 'Hong Kong',
		        protest_name: '<span class="protest">Reclamation project protest</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'Authorities planned to construct six facilities as part of a land reclamation and rubbish processing project.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the facilities could be intrusive and bring nuisance to the community. ',
		        size: 99,
		        size_text: '<100',
		        participants: 'Environmentalists and residents in the Tseung Kwan O neighborhood in the New Territories.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "HK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HKCapitalDataActive,
		        inactiveCapitalData: HKCapitalDataInctive
		    },
		    {
		        id: "HK",
		        arrayItem: true,
		        country: 'Hong Kong',
		        protest_name: '<span class="protest">Democracy activist arrest protest</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Partly free',
		        triggers: 'The arrest of forty-seven prominent pro-democracy activists.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger at decreasing political pluralism and increased repression by mainland Chinese authorities. ',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "HK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HKCapitalDataActive,
		        inactiveCapitalData: HKCapitalDataInctive
		    },
		    {
		        id: "HK",
		        arrayItem: true,
		        country: 'Hong Kong',
		        protest_name: '<span class="protest">Hong Kong autonomy protests</span>',
		        date: '2019-04-01',
		        date_text: "April 2019",
		        freedom: 'Partly free',
		        triggers: 'In 2019, introduction of a bill that would permit extradition of criminal suspects from Hong Kong to China. In 2020, introduction of a new national security law that would criminalize acts of secession, subversion of the central government, terrorism, or conspiracy with foreign influences, as well as permit Chinese law enforcement agencies to operate in Hong Kong.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Erosion of Hong Kong’s one country, two systems autonomy from China and restriction of civil liberties.',
		        size: 2000000,
		        size_text: '2,000,000',
		        participants: 'General public, especially younger citizens and students.',
		        timespan: '1 year, 4 months (sporadic)',
		        timespan_text: '',
		        outcomes: 'The Hong Kong government retracted the extradition bill. It also stepped back from postponing local council elections, which pro-democracy groups won.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HKCapitalDataActive,
		        inactiveCapitalData: HKCapitalDataInctive
		    },
		    {
		        id: "HK",
		        arrayItem: true,
		        country: 'Hong Kong',
		        protest_name: '<span class="protest">Sentencing protest</span>',
		        date: '2017-08-01',
		        date_text: "August 2017",
		        freedom: 'Partly free',
		        triggers: 'Sentencing of three leaders of the 2014 Umbrella Movement to six to eight months in prison on the charge of unlawful assembly.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Restriction of civil liberties, autonomy from China, and questions over judicial independence.',
		        size: 22000,
		        size_text: '22,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HKCapitalDataActive,
		        inactiveCapitalData: HKCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Media freedom protest</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Not free',
		        triggers: 'The leader of the opposition Tisza Party, Péter Magyar, called for a protest at the headquarters of Hungary’s state media corporation, Médiaszolgáltatás-támogató és Vagyonkezelő Alap (MTVA).',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Demand the end of state propaganda and the restoration of free, unbiased public media. Call for MTVA to be dismantled and for the MTVA CEO, Dániel Papp, to be fired.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition, general public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Protest against government interference in graft case</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Not free',
		        triggers: 'The former secretary of state for infrastructure in the Ministry of National Development, Pál Völner, was charged in 2022 for accepting bribes from the former head of court bailiffs, György Schadl, who was also arrested. Both have pleaded not guilty. In March 2024, lawyer and businessman Péter Magyar, formerly close to the Fidesz government, published a recording of a conversation with Judit Varga, then his wife and Hungary’s justice minister, in which Varga detailed an attempt by aides to Prime Minister Viktor Orbán’s cabinet chief to interfere in the cases facing Völner and Schadl.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Demand Orbán and the chief prosecutor resign. Magyar plans to launch a new political party to challenge Orbán. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Péter Magyar, opposition',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Protests against child sex abuse</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'Hungarian President Katalin Novák pardoned a man convicted of helping cover up sexual abuse in a children’s home. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protesters demanded that Novák and former justice minister Judit Varga quit. After they resigned in early February, protests continued calling for changes to Hungary’s child protection system and denouncing the government’s handling of the scandal. Protesters also rallied against Prime Minister Viktor Orbán. Thousands protested in Budapest in April 2024 demanding reforms to child-protection laws. ',
		        size: 50001,
		        size_text: '>50,000',
		        participants: 'General public, opposition',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'Novák and Varga resigned. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Trucker border blockade</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Partly free',
		        triggers: 'The EU waved entry restrictions on Ukrainian lorries following the Russian invasion of Ukraine. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration among Hungarian truck drivers who feel the markets they once supplied are being taken over by Ukrainian truck drivers due to their low prices.  ',
		        size: 11,
		        size_text: '>10',
		        participants: 'Truckers',
		        timespan: '10 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Contested anniversary of Hungary’s 1956 uprising</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Partly free',
		        triggers: 'Sixty-third anniversary of the Hungary uprisings of 1956. Prime Minister Viktor Orbán delivered a speech blasting the EU after returning from a visit with Russian President Vladimir Putin in China. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition to Orbán due to his destruction of liberal democracy and alignment with Russia. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Pride protest</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'The Hungarian government, led by Viktor Orbán’s Fidesz Party, passed laws to suppress queer visibility, including in 2021 banning the &ldquo;display and promotion of homosexuality&rdquo; among those under the age of eighteen in books and films.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over government policies that suppress LGBTQ+ visibility, fear of further laws that might restrict the place of queer people in Hungarian society.',
		        size: 10000,
		        size_text: '10,000',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Media bias protest</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Partly free',
		        triggers: 'The state media corporation, MTVA, devoted minimal coverage to teacher protests, which began in September 2022.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceived bias in media that favors the ruling party.',
		        size: 1500,
		        size_text: '1,500',
		        participants: 'General public, especially members of the opposition.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Teacher protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Partly free',
		        triggers: 'Teachers who joined early protests for higher wages were sacked.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Low wages and poor working conditions for teachers, as well as a shortage of teachers. Demand educational freedom, which Orbán’s government has usurped. ',
		        size: 80000,
		        size_text: '80,000',
		        participants: 'Teachers, students, parents.',
		        timespan: '9 months',
		        timespan_text: '',
		        outcomes: 'On July 4, 2023, Hungary’s parliament passed a law that revoked teachers’ status as public employees, increased allowable weekly working hours, and allowed for educators to be transferred to other schools experiencing teacher shortages.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Abortion law protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Partly free',
		        triggers: 'The government amended the law to require healthcare providers to present women with evidence of life of the fetus, widely interpreted as a heartbeat, before proceeding with the abortion. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Backlash over conservative social policies implemented by the Fidesz-led government, including restrictions on women’s rights, especially to safe abortion access.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Logging regulation protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Partly free',
		        triggers: 'Government loosening of logging regulations to increase supply of firewood in response to surging gas and electricity prices.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of deforestation and government inaction to combat climate change.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Environmentalists.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">Tax hike protests</span>',
		        date: '2022-07-01',
		        date_text: "July 2022",
		        freedom: 'Partly free',
		        triggers: 'The government increased the tax rate for hundreds of thousands of small businesses.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with rising inflation, increasing burdens on small businesses, and a weakening of the country’s currency.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">LGBT law protests</span>',
		        date: '2021-06-01',
		        date_text: "June 2021",
		        freedom: 'Partly free',
		        triggers: 'Introduction and adoption of a law that bars teaching about same-sex relationships or LGBT issues to youth.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Decreasing civic space, repression of LGBT groups, and increasing authoritarianism of Prime Minister Viktor Orban’s government.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially LGBT activists.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">University independence protests</span>',
		        date: '2020-09-01',
		        date_text: "September 2020",
		        freedom: 'Partly free',
		        triggers: 'The privatization of the University of Theater and Film Arts and the reorganization of its board under the control of an Orban ally.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over increasing government control over higher education, restrictions on free expression, and declining academic freedom.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially students.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "HU",
		        arrayItem: true,
		        country: 'Hungary',
		        protest_name: '<span class="protest">“Slave law” protests</span>',
		        date: '2018-12-01',
		        date_text: "December 2018",
		        freedom: 'Partly free',
		        triggers: 'Legislation that would increase the amount of overtime employers could demand from employees and the creation of a parallel court system.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Democratic backslide under Prime Minister Viktor Orbán and structural economic challenges (such as support for the country’s aging population).',
		        size: 100000,
		        size_text: '100,000',
		        participants: 'Trade unions, civil society groups, opposition parties.',
		        timespan: '10 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "HU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "HU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: HUCapitalDataActive,
		        inactiveCapitalData: HUCapitalDataInctive
		    },
		    {
		        id: "",
		        arrayItem: true,
		        country: 'India Kashmir',
		        protest_name: '<span class="protest">Custodial deaths protests</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Partly free',
		        triggers: 'The Supreme Court upheld the government’s decision to revoke Article 370 of India’s constitution, which gave limited autonomy to the region. The day after four Indian soldiers were killed in an ambush by rebels fighting against Indian rule, the Indian army detained eight civilians. Three of the detainees died in military custody, with locals accusing the Indian army of torturing the detainees to death.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition to Indian rule and demanding restraint from the Indian army in the region, which has operated with near-impunity. Demanding an end to the killing of and violence toward inhabitants of Kashmir.   ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Jammu & Kashmir National Conference',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: false,
		        mapData: {},
		        inactiveMapData: {},
		        capitalData:  {},
		        inactiveCapitalData: {},
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Mosque survey protest</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Partly free',
		        triggers: 'A local court in Sambhal ordered an archaeological survey of the the Shahi Jama Masjid, a mosque in Sambhal, after hearing a petition that claimed the mosque was built on the site of a destroyed Hindu temple.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Ongoing tensions between Hindu nationalist groups and Muslims. Muslims groups in Sambhal oppose the survey, claiming that the court proceedings were hurried and that they had not received any prior notice.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Muslims',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Five people died from injuries sustained during clashes between protesters and police forces. Although protesters maintain that the police were responsible for the five deaths, the police have denied these claims.',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Protest against Gautam Adani</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Partly free',
		        triggers: 'Gautam Adani, an Indian billionaire businessman, was indicted in the United States for allegedly defrauding investors and bribing Indian government officials.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Demand Adani’s immediate arrest and a parliamentary investigation into his companies. Accuse Prime Minister Narendra Modi and the Bharatiya Janata Party of shielding Adani against potential investigations. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Opposition',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. ',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Protest against ethnic violence</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Partly free',
		        triggers: 'Reignited ethnic violence between Hindu Meiteis and Christian Kukis in Manipur, India. Armed groups launched drone attacks that killed several civilians.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for the government to take action and end the ethnic conflict.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Students',
		        timespan: '2 days;<br />active since November 2024',
		        timespan_text: '',
		        outcomes: 'On September 11, authorities imposed a curfew and shut down internet services in certain areas of Manipur. They restored the internet on September 16 and partially relaxed the curfew. Amit Shah, the minister for home affairs, announced that the government is holding peace talks with the Meitei and Kuki communities. ',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">"Black Day" labor protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Partly free',
		        triggers: 'In July 2024, the Union Ministry of Labour & Employment began efforts to implement four labor codes, which had been passed by Parliament in 2020. The labor codes condense forty-four preexisting labor laws.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the repeal of the labor codes and the restoration of the original labor laws. Protesters are angry because they believe the government is acting in the interest of corporations and ignoring workers’ rights. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Labor unions',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Doctors’ protests</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'Rape and murder of a female trainee doctor at the R.G. Kar Medical College and Hospital in Kolkata.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand justice for the victim and increased security for health workers, particularly women. Also criticize local police and administration over how they handled the case.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Medical workers, women',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'The Supreme Court established a National Task Force to investigate security issues in health facilities and propose new safety protocols. In September 2024, the West Bengal Legislative Assembly passed the Aparajita Women and Child Bill, which set stricter penalties for people convicted of rape. Many criticized this bill for being a shallow and rushed solution to the issue of rape in the country. In January 2025, an Indian court gave Sanjay Roy, a police volunteer, a life sentence for the rape and murder of the trainee doctor.',
				active: true,
				significant: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Exam irregularities protest</span>',
		        date: '2024-06-01',
		        date_text: "June 2024",
		        freedom: 'Partly free',
		        triggers: 'Release of results from the National Eligibility-cum-Entrance Test (Undergraduate), or NEET (UG), which had an abnormally large number of high scores. (NEET (UG) is an entry exam for undergraduate medical schools.) Cancelation of the University Grants Commission–National Eligibility Test (UGC-NET), an exam used to determine the eligibility of PhD candidates or candidates for postdoctoral positions, due to leaking of paper questions. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Students are protesting the faulty grading of the NEET (UG) exams and the allegations of paper leaks and fraud, as well as the question leaks of the UGC-NET. Students are critizing the National Testing Agency and calling for the resignation of Union Education Minister Dharmendra Pradhan.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Students',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Opposition parties have criticized the ruling party and called for protests. The NTA has promised to create a reform committee to bring improvements to the agency. On July 23, the Supreme Court declined petitions to cancel NEET (UG) over a lack of proof that the paper leaks had impacted the exam results. As of September 20, the Central Bureau of Investigation had arrested forty-eight individuals in connection with the NEET (UG) paper leak.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Protest for the release of Arvind Kejriwal</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Partly free',
		        triggers: 'Arvind Kejriwal, New Delhi’s top elected official and leader of the opposition Aam Aadmi Party (which is part of the opposition coalition Indian National Developmental Inclusive Alliance challenging Prime Minister Narendra Modi’s Bharatiya Janata Party in the 2024 elections), was arrested based on accusations that he and his party had accepted $12 million in bribes from liquor contractors. Kejriwal’s party denies these accusations, claiming they were fabricated by allies of Modi’s government. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Demand the release of Kejriwal and protest against what the opposition claims is the government’s misuse of power to harass and weaken political opponents. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Aam Aadmi Party',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'In September 2024, Kejriwal was released from prison on bail.',
		        violent: true,
				significant: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Punjab farmers’ protest</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'Farmers claim that the cost of cultivation has jumped in recent years, while their income has remained constant, making farming a loss-making enterprise.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Farm union leaders seek legal guarantees of more state support for farmers and minimum purchase prices for crops. Farmers also insist that the government ensure at least a 50 percent profit for them over their overall cost of production.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Punjab farmers',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'The Supreme Court formed a judicial committee to facilitate discussion with the protesting farmers. Agriculture and Farmers Welfare Minister Gurmeet Singh Khudian offered government jobs to relatives of thirty farmers who had been killed in the protest, as well as to eight more youths.',
		        violent: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">"Our Tax, Our Right" protest</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'Frustration among lawmakers and ministers from India’s southern states against what they say is discrimination in the distribution of federal funds by Prime Minister Narendra Modi. India’s five southern states are ruled by regional parties or rivals of the Bharatiya Janata Party. Karnataka contributes the second highest taxes in the country, thanks to its booming tech industry, but has seen its transfers decline from 4.71 percent of total national taxes collected to 3.64 percent in the last four years. General tension between more developed southern states and less developed northern states. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Display opposition to the current policy for federal funds distribution. Rally against population-based distribution of federal funds, which protesters say is discriminatory to southern states that controlled their population growth.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Ministers and lawmakers from southern states; Karnataka Chief Minister Siddaramaiah',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Modi responded to the protests, telling parliament "Our tax, our money . . . what kind of language is this? I don’t discriminate against states."',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Protest against demolition of Muslim properties</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'A mosque and a seminary in the northern state of Uttarakhand were demolished because they were said to be built on public land. Amnesty International has documented "unjust" targeting of Muslim homes, businesses, and places of worship for demolition. During Prime Minister Narendra Modi’s tenure, Hinduism has increasingly become a marker of national identity, despite India’s founding as a multiethnic secular republic. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Oppose the demolition of Muslim property and denounce the rise of Hindu nationalism.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Muslims, Haldwani residents',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. At least five people were killed in deadly clashes between rioters and police.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Protests to protect democracy</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Partly free',
		        triggers: 'India has become increasingly autocratic under Prime Minister Narendra Modi, who has gone after the opposition on multiple occasions and suspended over a hundred opposition members of Parliament. Opposition leaders have faced arrests and investigation by federal agencies in the lead up to the 2024 election. They accuse Modi of election interference and using state agencies to further his political agenda.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Suspended opposition MPs expressed fear that India’s democracy is in danger ahead of 2024 elections. On March 31, 2024, tens of thousands joined a "Save Democracy" rally organized by the Indian National Developmental Inclusive Alliance (INDIA) to call for free and fair elections and denounce harassment of the opposition.  ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition MPs; INDIA',
		        timespan: '1 day in 2023;<br />1 week in March 2023',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">NewsClick raid protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Partly free',
		        triggers: 'Police raided the offices of NewsClick, a digital media outlet critical of Indian Prime Minister Narendra Modi on October 3. Under Modi, India’s freedom of the press has declined markedly, including a raid of the BBC’s New Dehli and Mumbai offices days after it broadcasted &ldquo;India: The Modi Question.&rdquo;',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Journalists and activists protest the raid of NewsClick and Modi’s government’s repeated attacks on the free press.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Journalists, rights activists',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Water-sharing protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Partly free',
		        triggers: 'The Indian Supreme Court ordered the government of the state of Karnataka to release 5,000 cusecs of water from the Kaveri River to the neighboring Tamil Nadu state.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear that the order to release water to the neighboring state will leave insufficient water for households and farmers in Karnataka. Demand state government call a special assembly to discuss the issue.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers, trade unionists.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Manipur video protests</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'One day after ethno-religious violence broke out in the state of Manipur in May 2023, a group of Meitei men stripped two Kuki women naked and paraded them through the streets while groping them. It is alleged that the men gang raped the two women and killed two of their male family members. Video of the incident went viral after the internet was restored to Manipur two months later.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Outrage over the incident and eagerness for the violence to end.',
		        size: 15001,
		        size_text: '>15,000',
		        participants: 'Women in Manipur.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Prime Minister Narendra Modi said his heart was "full of pain and anger" over the video. On July 27, the government announced that the case would be referred to the Central Bureau of Investigation. On October 16, unidentified men fired on a crowd of women affiliated with the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Wrestling Federation sexual abuse protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Partly free',
		        triggers: 'One of India’s most decorated female wrestlers accused Brij Bhushan, the president of the Wrestling Federation of India, of sexually harassing several young female wrestlers.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demands for the removal and arrest of Brij Bhushan and other officials.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Wrestlers, women.',
		        timespan: '3 days in January 2023;<br />2 months beginning April 2023',
		        timespan_text: '',
		        outcomes: 'Indian Sports Minister Anurag Thakur announced the creation of an oversight committee that would investigate the accusations and release a report after four weeks. Thakur stripped Brij Bhushan of his administrative powers and asked him to resign. On June 15, police filed charges of sexual harassment and intimidation against Brij Bhushan.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Kuki protest</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Partly free',
		        triggers: 'The Manipur High Court asked the state government to consider the petition of the Meitei community to be recognized as a scheduled tribe.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear that this designation would diminish the benefits that were enjoyed by other scheduled tribes, including the Kuki people.',
		        size: 50000,
		        size_text: '50,000',
		        participants: 'Kuki people.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The demonstration broke down into ethnic violence that displaced as least 60,000 people.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Pension strikes and protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'Several states increased their pension benefits for public employees.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over rising prices on household goods, food, and fuel.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Public sector workers.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Tax hike protest</span>',
		        date: '2022-07-01',
		        date_text: "July 2022",
		        freedom: 'Partly free',
		        triggers: 'The government introduced a tax hike on a range of products and services.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over rising prices on household goods, food, and fuel.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public; shopkeepers and traders affiliated with the Confederation of All India Traders (CAIT); opposition politicians.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Military reform protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Partly free',
		        triggers: 'The government’s announcement of a plan to recruit soldiers on four-year contracts.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern that the new contract scheme would reduce opportunities for long-term careers in the military. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Young men.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The government made limited concessions, including by guaranteeing places for soldiers who completed contract service in longer-term positions.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Economic policy protests</span>',
		        date: '2022-03-01',
		        date_text: "March 2022",
		        freedom: 'Partly free',
		        triggers: 'Call for protest and strike by major Indian labor unions after the government proposed a series of labor reforms. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with the government’s economic policies, which they allege have harmed workers, especially in the country’s informal economic sector; opposition to a proposed labor law. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Members of labor unions.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Hijab protest</span>',
		        date: '2022-01-01',
		        date_text: "January 2022",
		        freedom: 'Partly free',
		        triggers: 'The imposition of a ban on wearing headscarves in educational institutions in Karnataka state and the circulation of a video that showed a Muslim woman being harassed by a mob of Hindu men. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Religious tensions between Hindus and Muslims and the perception that the ruling Bharatiya Janata Party is promoting Hindu nationalism to the detriment of Muslims’ religious rights.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Muslim women.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Farm bill protests</span>',
		        date: '2020-09-01',
		        date_text: "September 2020",
		        freedom: 'Partly free',
		        triggers: 'Passage of bills on agricultural market reform and contract farming.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Disruption of the rural farm economy, which has traditionally relied on middlemen to facilitate agricultural transactions.',
		        size: 10000001,
		        size_text: '>1,000,000',
		        participants: 'Farmers.',
		        timespan: '2 years, intermittently.',
		        timespan_text: '',
		        outcomes: 'In November 2021, Prime Minister Narendra Modi announced that his government would move to repeal the agricultural reform laws. Organizers called off protests after the government agreed to consider guaranteed prices for agricultural products.',
		        significant: true,
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Migrant worker protest</span>',
		        date: '2020-04-01',
		        date_text: "April 2020",
		        freedom: 'Partly free',
		        triggers: 'Migrant workers’ demand to receive back wages and to be permitted to return home to local villages.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Extension of existing lockdown to combat coronavirus.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Migrant workers.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">24-hour strike</span>',
		        date: '2020-01-01',
		        date_text: "January 2020",
		        freedom: 'Partly free',
		        triggers: 'Publication of government statistics predicting slowing economic growth.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition to Modi’s economic policies (including privatization).',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Trade unions, students.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Citizenship law protests</span>',
		        date: '2019-12-01',
		        date_text: "December 2019",
		        freedom: 'Partly free',
		        triggers: 'Passage of a bill, the Citizenship Amendment Act, amending India’s citizenship law. It offers conditional amnesty and a path to citizenship for non-Muslim religious minorities from three neighboring countries (Afghanistan, Bangladesh, and Pakistan) who did not migrate to the country legally. The bill contains no such exemptions for Muslim migrants, fueling criticism that it discriminates against Muslims.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over rising Hindu nationalism under Prime Minister Narendra Modi, marginalization of Muslims, and opposition to migration (among citizens in northeastern India).',
		        size: 100000,
		        size_text: '>100,000',
		        participants: 'Students, young people (protesting discrimination against Muslims), citizens in northeastern India (protesting migration).',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'The citizenship law was finally implemented in March 2024 after being delayed by protests.  ',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">SC/ST Act protests</span>',
		        date: '2018-04-01',
		        date_text: "April 2018",
		        freedom: 'Partly free',
		        triggers: 'Recent Supreme Court decision stating that the Scheduled Caste and Scheduled Tribe Act (SC/ST Act), which had served to protect lower castes and Dalits from discrimination and violence, had been “rampantly misused.”',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'History of caste-based discrimination and violence against Dalits despite constitutional and legal protections, and impunity for those who commit crimes against Dalits.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Members of the lower castes, Dalits.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'After the protests began, the government asked the Supreme Court to review the case. In October 2019, the Supreme Court reversed its own previous ruling.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Reservations protests</span>',
		        date: '2017-08-01',
		        date_text: "August 2017",
		        freedom: 'Partly free',
		        triggers: 'Demands by the Maratha community, which has been historically reliant on farming, for reserved quotas for government jobs and college admissions.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Rural economic downturn, rising unemployment, and view of reservations as critical for broadening economic opportunity by attaining higher education or securing better jobs.',
		        size: 600000,
		        size_text: '800,000',
		        participants: 'Members of the Maratha community, young people, senior citizens.',
		        timespan: 'Sporadic',
		        timespan_text: '1 year',
		        outcomes: 'In November 2018, Maharashtra’s state legislature passed a bill giving Marathas a 16 percent share of reserved slots for university admissions and government jobs under their status as a socially and economically backward class. After the reservation decision was challenged in the courts, the Bombay High Court ruled in June 2019 that the reservation quota should be lowered to 12 percent for university admissions and 13 percent for government jobs.',
		        significant: true,
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "IN",
		        arrayItem: true,
		        country: 'India',
		        protest_name: '<span class="protest">Farmers’ protests</span>',
		        date: '2017-04-01',
		        date_text: "April 2017",
		        freedom: 'Partly free',
		        triggers: 'Challenges facing Indian farmers in different regions, including low crop prices, droughts, and mounting debt owed to banks or moneylenders.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Discontent with government policies on farming, lack of government assistance, and stagnating incomes for farmers.',
		        size: 100000,
		        size_text: '100,000',
		        participants: 'Farmers&rsquo; organizations.',
		        timespan: 'Sporadic',
		        timespan_text: '1 year, 8 months',
		        outcomes: 'There were a series of farmer protests in different states; in Maharashtra, the government promised to settle tribal farmers’ disputes over land ownership and to expand a loan-waiver scheme.',
		        significant: true,
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: INCapitalDataActive,
		        inactiveCapitalData: INCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Local election law protests</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'The House of Representatives’ Legislative Body (Baleg) drafted revisions to local election laws that would overturn two rulings from the Constitutional Court: one that lowered the threshold for parties to nominate candidates in local leadership positions and another that raised the minimum age of candidates for governorship or deputy governorship to thirty years of age.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest the Baleg’s revisions, which were seen as a way to increase outgoing President Joko Widodo’s political power and to exclude the opposition party from Jakarta’s regional government. Protesters also believe these revisions hold heavy implications for Indonesia’s democracy.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Youth, general public',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'In response to the widespread protests, the Indonesian Parliament postponed its scheduled vote for the revisions on August 22. On August 25, the House of Representatives and the General Elections Commission (KPU) revised the KPU election regulation to uphold the rulings of the Constitutional Court.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Protest for media freedom</span>',
		        date: '2024-05-01',
		        date_text: "May 2024",
		        freedom: 'Partly free',
		        triggers: 'A new broadcasting law restricts broadcasting of journalistic investigations, content portraying LGBTQ+ "behavior," and content that shows "negative behaviors or lifestyles that could potentially be imitated by the public."',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Journalists and members of the media protested against what they said was a suppression of "creativity, press freedom, and expression." They opposed efforts by the government to restrict broadcasting and dictate journalistic ethics. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Journalists',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">2024 election protests</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'While outgoing President Joko Widodo did not endorse a candidate for the February 2023 election, he made public appearances with the frontrunner Prabowo Subianto. Widodo’s son is on the ballot as vice president to Prabowo Subianto. Prabowo Subianto won, but protesters attribute this to election fraud.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Denounce what is seen by protesters as an abuse of power to sway the election in favor of Prabowo Subianto. Demand the Indonesian election authority block Prabowo Subianto from taking office due to claims of fraud. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Students, opposition',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Students’ anti-Rohingya protest</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Partly free',
		        triggers: 'Over 1,500 Rohingya refugees have arrived in Aceh province on the island of Sumatra since November. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Students rallied outside the provincial parliament calling on lawmakers to turn away Rohingya refugees, fearing they will cause social and economic disruption. Some protesters stormed a convention center housing over a hundred Rohingya refugees. ',
		        size: 201,
		        size_text: '>200',
		        participants: 'Students',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Rempang Eco-City protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Partly free',
		        triggers: 'The government announced plans to evict thousands from residents of Rempang Island to make way for a multibillion-dollar Chinese-owned glass factory and "eco-city." Residents were told to leave the island by the end of September.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears among residents that the evictions would severely limit their economic opportunities.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Residents of Rempang Island',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'President Joko Widodo scrapped the eviction deadline, but maintains that those who do not leave the island will still be moved in a &ldquo;peaceful way.&rdquo; The Constitutional Court rejected a lawsuit brought by the island’s residents in November 2023.',
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Anti-Israel protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'Indonesia was set to host the FIFA U-20 World Cup from May 20 to June 11. Israel was among the countries participating. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Strong anti-Israel sentiment.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Wayan Koster, the governor of Bali, wrote to Indonesia’s sports ministry earlier this month asking the agency to bar Israel’s team from entering the country. FIFA then stripped Indonesia of its hosting privileges.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Emergency presidential order protest</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Partly free',
		        triggers: 'The president signed an emergency presidential order to replace the country’s 2020 Jobs Creation law and urged parliament to make the order permanent.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over the order’s impact on labor rights and environmental protection. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Fuel price hike protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Partly free',
		        triggers: 'The government increased subsidized fuel prices by 30%. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Rising inflation and high poverty. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Papua protests</span>',
		        date: '2022-05-01',
		        date_text: "May 2022",
		        freedom: 'Partly free',
		        triggers: 'The government’s announcement of plans to split the provinces of West Papua and Papua into administrative divisions. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Desire for greater Papuan and West Papuan autonomy and perceived misrule of the region. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Residents of Papua and West Papua.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Jobs bill protests</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Partly free',
		        triggers: 'Enactment of an omnibus Jobs Creation Bill, which reformed labor and market regulations. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern that reforms would weaken protections for  workers, especially through reducing wages, insurance access, and pensions. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">Criminal code protests</span>',
		        date: '2019-09-01',
		        date_text: "September 2019",
		        freedom: 'Partly free',
		        triggers: 'Consideration of a new criminal code that could violate the rights of women, religious minorities, and LGBTQ groups, and passage of a bill to weaken the country’s anticorruption commission. In 2022, parliament resumed efforts to codify proposed changes.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Weakening of anticorruption body and growing skepticism of President Joko Widodo’s commitment to reforms.  ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'University students.',
		        timespan: '1 week in 2019;<br />1 week in 2022',
		        timespan_text: '',
		        outcomes: 'The vote on a new criminal code was delayed. On December 6, 2022, the parliament passed the law.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "ID",
		        arrayItem: true,
		        country: 'Indonesia',
		        protest_name: '<span class="protest">West Papua protests</span>',
		        date: '2019-08-01',
		        date_text: "August 2019",
		        freedom: 'Partly free',
		        triggers: 'Racist remarks made by military officers and nationalist militia directed against Papuan citizens.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Discrimination against and marginalization of Papuan citizens, tensions between Papuans and the central government, desires for Papuan independence, and the effects of extractive industries in West Papua.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Papuan citizens.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "ID", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ID", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IDCapitalDataActive,
		        inactiveCapitalData: IDCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Protest for education reform</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Not free',
		        triggers: 'Teachers say the education sector needs to be dramatically reformed. They also claim they face discriminatory and oppressive treatment by authorities. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the immediate release of all jailed educators, the provision of free education for all, the abolition of gender discrimination in education policies, and an end to the government’s repression of provocative teachers. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Teachers; Iranian Teachers’ Union’s Coordination Council',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest. Multiple teachers have been arrested at demonstrations in Tehran. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Protests for Armita Geravand</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Not free',
		        triggers: 'Death of Armita Geravand after an alleged confrontation with Iran’s morality police in the Tehran subway over her lack of a headscarf.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters voiced frustration and chanted &ldquo;Death to Khamenei, the murderer.&rdquo;',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, Tehran residents',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Reports of violence used to disperse protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Baluchi anti-government protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Not free',
		        triggers: 'Oppression of Baluchi minority groups in Zahedan Province as well as Sistan and Baluchestan Province.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Weekly protest for minority rights and the suppression of Women, Life, Freedom protesters in 2022. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Baluchi minority group',
		        timespan: 'Active (every Friday)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Schoolgirl poisoning protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Not free',
		        triggers: 'Thousands of Iranian schoolgirls from hundreds of schools fell ill over the course of several months in what protesters and some government officials characterized as deliberate attacks on the students.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears for safety of the schoolgirls.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Parents.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Iran’s supreme leader, Ayatollah Ali Khamenei, acknowledged the poisonings, saying that the incidents were a &ldquo;major and unforgivable crime&rdquo; and that the perpetrators must face the &ldquo;harshest punishment.&rdquo; Iranian authorities arrested 110 suspects. On April 28, Iran’s intelligence ministry accused foreign &ldquo;enemies&rdquo; and dissidents of fomenting fears over suspected poisonings of schoolgirls, saying its investigation found no actual poisoning.',
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Mahsa Amini protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Not free',
		        triggers: 'Morality police arrested twenty-two-year-old Mahsa Amini for violating the country’s hijab law; she died in custody.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with the country’s strict morality laws, the abuses of the morality police, and the lack of freedoms for women. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Women.',
		        timespan: '10 months',
		        timespan_text: '',
		        outcomes: 'The protests sparked an international women’s rights movement. ',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Building collapse protests</span>',
		        date: '2022-05-01',
		        date_text: "May 2022",
		        freedom: 'Not free',
		        triggers: 'The death of dozens of people in a residential building collapse in Abadan.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with corruption, poor enforcement of laws and safety standards, and unsatisfactory infrastructure and building quality. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'The mayor of Abadan and other senior officials were arrested. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Food price protests</span>',
		        date: '2022-05-01',
		        date_text: "May 2022",
		        freedom: 'Not free',
		        triggers: 'A reduction in government subsidies for eggs, chicken, dairy products, and cooking oil, as well as heightened wheat and flour prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration over rising cost of living and perceived government inability to address quality of life issues, including inflation and poor public services. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Public sector employees strikes and protests</span>',
		        date: '2022-01-01',
		        date_text: "January 2022",
		        freedom: 'Not free',
		        triggers: 'Rising costs of living and stagnating salaries for public sector employees.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with the government’s slow implementation of salary and pension reform; economic mismanagement.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Public sector employees, including nurses and teachers; retired public sector employees dependent on government pensions.',
		        timespan: '1.5 years',
		        timespan_text: '',
		        outcomes: 'In April 2023, Ayatollah Ali Khamenei said that some protests have been helpful to the country, although he also announced that 4,000 striking petrochemical workers would be fired and replaced.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Water shortage protests</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Not free',
		        triggers: 'Severe water shortages amid Iran’s worst drought in decades. The July 2021 protests were focused on water shortages in Khuzestan Province. The protests beginning in November 2021 started in Isfahan. The protests beginning in August 2022 started in Hamadan Province.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with government mismanagement of water resources, poor provision of services, and repression of public dissent.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '3 weeks in July 2021;<br />November 2021 to February 2022;<br />3 weeks beginning August 2022.',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Downed plane protests</span>',
		        date: '2020-01-01',
		        date_text: "January 2020",
		        freedom: 'Not free',
		        triggers: 'Iran’s military acknowledged that it had launched missiles that brought down a Ukraine International Airlines jet near Tehran, killing all 176 people on board.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of government transparency and accountability and popular discontent with political leadership. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'University students.',
		        timespan: '3 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Fuel hike protests</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Not free',
		        triggers: 'Increase in gasoline prices due to the growing budget deficit.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic challenges (growing budget deficit and high inflation) and discontent toward political leaders.',
		        size: 200000,
		        size_text: '200,000',
		        participants: 'Low-income and working-class families.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Water protests</span>',
		        date: '2018-06-01',
		        date_text: "June 2018",
		        freedom: 'Not free',
		        triggers: 'Undrinkable water in the cities of Khorramshahr and Abadan.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the degradation of infrastructure, and political repression amid the public outcry over basic service delivery failures.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IR",
		        arrayItem: true,
		        country: 'Iran',
		        protest_name: '<span class="protest">Budget protests</span>',
		        date: '2017-12-01',
		        date_text: "December 2017",
		        freedom: 'Not free',
		        triggers: 'Concerns over a weak economy and the high prices of basic goods, as well as the publication of a government budget that would slash cash subsidies and increase fuel prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Slow economic growth, lack of political freedoms and openness, and corruption. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Younger, working-class citizens, some of whom are from towns and rural cities.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IRCapitalDataActive,
		        inactiveCapitalData: IRCapitalDataInctive
		    },
		    {
		        id: "IQ",
		        arrayItem: true,
		        country: 'Iraq',
		        protest_name: '<span class="protest">Protest over arrested activists</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Not free',
		        triggers: 'Recent widespread arrests of activists involved in the 2019 Iraqi protests. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for the release of arrested activists and for the newly appointed police chief, Major General Najah Al-Abadi, to step down.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Activists',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "IQ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IQ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IQCapitalDataActive,
		        inactiveCapitalData: IQCapitalDataInctive
		    },
		    {
		        id: "IQ",
		        arrayItem: true,
		        country: 'Iraq',
		        protest_name: '<span class="protest">Kurdistan protest against IRGC strikes</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Not free',
		        triggers: 'The Islamic Revolutionary Guards Corps launched strikes against an Israeli "spy headquarters," killing an Israeli businessman and his family. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Condemn Iranian aggression and attacks on Iraqi territory. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Iraqi Kurds',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'Baghdad recalled its ambassador to Iran and lodged a complaint with the UN Security Council.',
		        multiple: true,
		        mapData: {
		        	"id": "IQ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IQ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IQCapitalDataActive,
		        inactiveCapitalData: IQCapitalDataInctive
		    },
		    {
		        id: "IQ",
		        arrayItem: true,
		        country: 'Iraq',
		        protest_name: '<span class="protest">Kurdistan civil servant protest</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Not free',
		        triggers: 'Civil servants in Iraqi Kurdistan were not being paid.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Accusations that the Iraqi government was not sending the necessary funds to the Kurdistan Regional Government to pay civil servants.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Civil servants in Dohuk',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'On September 18, the Iraqi government agreed to increase funding to the Kurdistan Regional Government to pay civil servants.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IQ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IQ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IQCapitalDataActive,
		        inactiveCapitalData: IQCapitalDataInctive
		    },
		    {
		        id: "IQ",
		        arrayItem: true,
		        country: 'Iraq',
		        protest_name: '<span class="protest">Quran desecration protests</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Not free',
		        triggers: 'On June 28, Salwan Momika, an Iraqi immigrant living in Sweden, tore pages out of a Quran and burned them in front of a mosque in Stockholm. The incident took place on Eid al-Adha, a major Islamic holiday.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the burning of the Quran, which is deeply offensive to Muslim people.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Followers of Muqtada al-Sadr.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The Iraqi government severed diplomatic relations with Sweden. Iraq also expelled Sweden’s ambassador in Baghdad, recalled the chargé d’affaires from the Iraqi embassy in Stockholm, and suspended the operating license of Swedish telecom giant Ericsson on Iraqi soil. On August 25, the Danish government announced that it would introduce a bill to criminalize the burning of Qurans.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IQ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IQ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IQCapitalDataActive,
		        inactiveCapitalData: IQCapitalDataInctive
		    },
		    {
		        id: "IQ",
		        arrayItem: true,
		        country: 'Iraq',
		        protest_name: '<span class="protest">Activist prison sentence protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Not free',
		        triggers: 'A court in Baghdad sentenced Hayder Hamid al-Zaidi, a 20-year-old Iraqi activist, to three years in prison for a tweet he allegedly posted that insults Abu Mahdi al-Muhandis, the former deputy commander of the Popular Mobilization Forces.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Outrage over the length of the prison sentence; anger over the lack of political pluralism in Iraq.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Residents of Nasiriyah.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'Prime Minister Mohammed Shia al-Sudani ordered a security delegation be sent to the province “to investigate the casualties among the demonstrators.”',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "IQ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IQ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IQCapitalDataActive,
		        inactiveCapitalData: IQCapitalDataInctive
		    },
		    {
		        id: "IQ",
		        arrayItem: true,
		        country: 'Iraq',
		        protest_name: '<span class="protest">Political crisis protests</span>',
		        date: '2021-10-01',
		        date_text: "October 2021",
		        freedom: 'Not free',
		        triggers: 'The inability of political parties to form a government after the 2021 parliamentary elections.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'For some groups, frustration with the influence of Moqtada al-Sadr; for others, frustration with Iran-backed political parties.  ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of Moqtada al-Sadr; supporters of the Iran-backed Coordination Framework.',
		        timespan: '1 year',
		        timespan_text: '',
		        outcomes: 'On October 27, 2022, members of Iraq’s parliament approved a new government led by President Abdul Latif Rashid and Prime Minister Mohammed Shia al-Sudani.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IQ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IQ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IQCapitalDataActive,
		        inactiveCapitalData: IQCapitalDataInctive
		    },
		    {
		        id: "IQ",
		        arrayItem: true,
		        country: 'Iraq',
		        protest_name: '<span class="protest">Tishreen ("October") protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Not free',
		        triggers: 'Demotion of a popular counterterrorism leader who was fighting government corruption; the violent response to antigovernment protests in early October 2019; and the killing of activists by militias. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Inadequate public services, government corruption, unemployment, discontent with the country’s sectarian political system (muhasasa), Iranian influence in Iraq, and government repression of protests.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Young people (especially young men), university students.',
		        timespan: '2.5 years, intermittently.',
		        timespan_text: '',
		        outcomes: 'Adel Abdul-Mahdi resigned as prime minister but remained as a caretaker until February 2020, when Mohammed Allawi was appointed prime minister. Allawi failed to assemble a government, however, and consequently never took office. He was replaced by Adnan al-Zurfi. In May 2020, al-Zurfi resigned and Mustafa al-Kadhimi became the  prime minister. Al-Kadhimi’s government held parlimentary elections in October 2021, meeting a key protester demand.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IQ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IQ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IQCapitalDataActive,
		        inactiveCapitalData: IQCapitalDataInctive
		    },
		    {
		        id: "IE",
		        arrayItem: true,
		        country: 'Ireland',
		        protest_name: '<span class="protest">Dublin stabbing riots</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Not free',
		        triggers: 'Three children and a woman were stabbed outside of a school in Dublin. Rumors circulated that a foreign national was responsible for the attack after Irish right-wing news platform Gript suggested the perpetrator was Algerian.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Stand against crime and in defense of Irish children. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Far-right activists, especially oppone.nts of immigration',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The taoiseach condemned the riots and pledged to modernize Ireland’s hate laws. ',
		        multiple: false,
		        mapData: {
		        	"id": "IE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: IECapitalDataActive,
		        inactiveCapitalData: IECapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Protest against dismissal of Yoav Gallant</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'Prime Minister Benjamin Netanyahu dismissed Gallant from his position as defense minister on November 5.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize Gallant’s dismissal, which protesters claim was a way for Netanyahu to maintain his hold on power. Call for the new defense minister to prioritize a hostage deal regarding the conflict in Gaza and for Netanyahu to resign.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Protest against ultra-Orthodox conscription</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Free',
		        triggers: 'The government announced on March 28, 2024, that it had not agreed on an extension to exempt ultra-Orthodox Jews from conscription, after the Supreme Court ruled that such exemptions were discriminatory. The court gave the government until April 1 to agree on an extension. Failing to meet this deadline, the Supreme Court instructed the government to suspend special education subsidies to those who fail to answer military call-ups. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against the suspension of education subsidies, which members of the ultra-Orthodox community feel will be fatal to the finances of their education system. Protesters say this jeopardizes their faith, which they say is maintained through seminary education. Demonstrators also express concerns that this is the first step toward mandatory military service for the ultra-Orthodox community. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Ultra-Orthodox community',
		        timespan: '1 month in March 2024;<br />active beginning June 2024',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. Members of the ultra-Orthodox community have volunteered to serve in the IDF in an effort to lessen the need for conscription.',
				active: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Protest for ultra-Orthodox conscription</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Free',
		        triggers: 'The Supreme Court ruled that the current system of conscription—which requires most Jewish men to serve nearly three years, and Jewish women two years, in the Israeli military, but exempts ultra-Orthodox from conscription if they are studying in religious seminary full-time—was discriminatory. The court cited the need for the burden of military service to be shared across society. It gave the government until April 1 to present a bill for a new system. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand an end to the exemption of ultra-Orthodox Jewish men from compulsory military service. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. The government announced on March 28 that it had not agreed on an extension to exempt ultra-Orthodox Jews from conscription. In June 2024, the Supreme Court ruled that there was no legal basis for the draft exemption. On July 2024, the government announced that it would begin drafting ultra-Orthodox men. In November 2024, the government began issuing the first draft orders despite ongoing protests.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Anti-Netanyahu protests</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'Ongoing Israel-Hamas war, which some Israelis assert Prime Minister Benjamin Netanyahu is mishandling.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand a change of government and the ousting of Netanyahu. In late March 2024, protests intensified as demonstrators called for immediate elections and for a ceasefire deal to secure the release of hostages held by Hamas in Gaza. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
		        violent: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Judicial reform strikes and protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'The government introduced plans to overhaul the country’s judicial system; the plan would weaken the Supreme Court by giving parliament the power to overturn court decisions with a simple majority vote, among other proposed changes.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of democratic backsliding. ',
		        size: 500001,
		        size_text: '>500,000',
		        participants: 'General public.',
		        timespan: '10 months',
		        timespan_text: '',
		        outcomes: 'On July 24, Israel’s Knesset passed the first piece of the overhaul by a vote of 64-0; it stripped the Supreme Court of the power to declare government decisions unreasonable. Unity government was formed after Oct. 7 attack by Hamas, and protests have paused.  ',
		        significant: true,
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Palestinian protests</span>',
		        date: '2021-05-01',
		        date_text: "May 2021",
		        freedom: 'Free',
		        triggers: 'A court decision on whether to evict six Palestinian families from their homes in the East Jerusalem neighborhood of Sheikh Jarrah; Israeli moves to crack down on Palestinian assembly.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Ongoing tensions over the fate of East Jerusalem and the future of Palestinian residents; frustrations with Israeli settler policy. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Palestinians.',
		        timespan: '1.5 years',
		        timespan_text: '',
		        outcomes: 'The Supreme Court delayed its ruling in the case.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Israel coronavirus protests</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Free',
		        triggers: 'Prime Minister Benjamin Netanyahu’s expansion of authority during the coronavirus outbreak, such as using phone surveillance technology, shutting down the court system, adjourning parliament, and planning to annex parts of the West Bank.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Perceptions of Netanyahu’s actions as antidemocratic, corrupt, or authoritarian.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '14 months ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Nation-state law protests</span>',
		        date: '2018-08-01',
		        date_text: "August 2018",
		        freedom: 'Free',
		        triggers: 'Passage of a nation-state law that would affirm Israel’s Jewish character and potentially marginalize non-Jewish citizens.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anti-Arab discrimination.',
		        size: 30000,
		        size_text: '30,000',
		        participants: 'Jews, Palestinians.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. The High Court agreed to hear cases against the law, but hearings have been repeatedly postponed.',
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IL",
		        arrayItem: true,
		        country: 'Israel',
		        protest_name: '<span class="protest">Recommendations bill protests</span>',
		        date: '2017-12-01',
		        date_text: "December 2017",
		        freedom: 'Free',
		        triggers: 'Draft law that would end the existing practice of having police recommend to prosecutors whether to indict suspects, while also preventing police recommendations from being made public and penalizing leaks from investigations. Suspicions that the law’s implications could affect an ongoing investigation into Prime Minister Benjamin Netanyahu.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Government corruption and lack of transparency.',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'General public.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'Netanyahu called for reforms to the draft bill to exclude him from its purview.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ILCapitalDataActive,
		        inactiveCapitalData: ILCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Protest against gender-based violence</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'International Day for the Elimination of Violence.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce gender-based violence.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women’s rights organizations, women',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">"No Meloni Day" protests</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'Growing discontent with Prime Minister Giorgia Meloni’s government and its policies.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over spending cuts for schools and universities. Criticize various government officials including Meloni and Giuseppe Valditara (the education minister). Some demonstrations also expressed anger over the government’s stance regarding the conflict in Gaza.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Students',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. At least fifteen police officers were hospitalized after protesters launched a rudimentary bomb.',
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Free',
		        triggers: 'EU environmental regulations which farmers say undercut their ability to compete with foreign imports made using cheaper inputs and under fewer regulations. New EU legislation requires 4 percent of farmland to be left fallow to receive subsidies. High energy costs. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call on Prime Minister Giorgia Meloni—whose coalition receives key support from farmers—to offer more help for farmers and block EU regulations that the farmers see as detrimental to their industry.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Meloni told a delegation of farmers in early February 2024 that she would reintroduce a partial tax break for farmers. In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Green Grand Canal protest</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'COP28 was held in Dubai and left many climate activists dissatisfied.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters affiliated with Extinction Rebellion poured green dye into Venice’s Grand Canal (as well as other waterways nationwide) to protest the lack of progress made at COP28 to address climate change. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Extinction Rebellion',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Violence against women protest</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: '22-year-old student Giulia Cecchettin was killed, allegedly by her former boyfriend. On average, one woman is killed every three days in Italy. International Day for the Elimination of Violence against Women. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for change to end gendered violence and patriarchal attitudes in Italy.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Women, Non Una Di Meno feminist movement.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">2024 budget protests</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'The government proposed a budget for 2024 which includes 24 billion euros in tax cuts and spending increases projected to raise the deficit by 15.7 billion euros. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize the proposed budget, which protesters claim threatens workers’ well-being, and call for fiscal reforms to reduce inequality. Express discontent with Prime Minister Giorgia Meloni.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Transport workers, unions, teachers, taxi drivers, healthcare workers, CGIL, UIL.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Cost of living protest</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices, especially of fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations over the government’s support for Ukraine and inability to curb prices.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Access to abortion protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'The victory of far-right parties in the country’s general election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that a right-wing government would restrict access to abortion.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Ultima Generazione protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Free',
		        triggers: 'Perceived government inaction on climate change.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of catastrophic consequences of climate change.',
		        size: 99,
		        size_text: '<100',
		        participants: 'Climate activists.',
		        timespan: 'Active, intermittently',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Free',
		        triggers: 'The imposition of vaccine mandates, public health restrictions, and the enactment of a rule requiring employees to have a &ldquo;Green Pass,&rdquo; which shows vaccination or negative coronavirus test status, in order to enter workplaces.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over vaccine and testing requirements and other restrictive public health measures. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, including right-wing groups.',
		        timespan: '1 year',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Prison protest</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Free',
		        triggers: 'Overcrowding in prisons during the coronavirus outbreak. Government decision to suspend or limit family visits to more than two dozen prisons for the next two weeks, due to coronavirus concerns.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Prison overcrowding, lack of government support for the prison system, and poor conditions in prisons.',
		        size: 1,
		        size_text: 'Unknown',
		        participants: 'Prisoners.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "IT",
		        arrayItem: true,
		        country: 'Italy',
		        protest_name: '<span class="protest">Union-led economic protests</span>',
		        date: '2019-02-01',
		        date_text: "February 2019",
		        freedom: 'Free',
		        triggers: 'Announced investment and pension reform that unions viewed as inadequate to combat economic stagnation and high unemployment.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic stagnation, lack of employment opportunities, and government’s failure to address challenges such as corruption, climate change, and sexism. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Trade unions.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "IT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "IT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ITCapitalDataActive,
		        inactiveCapitalData: ITCapitalDataInctive
		    },
		    {
		        id: "CI",
		        arrayItem: true,
		        country: 'Ivory Coast',
		        protest_name: '<span class="protest">Third-term protests</span>',
		        date: '2020-08-01',
		        date_text: "August 2020",
		        freedom: 'Partly free',
		        triggers: 'Announcement that President Alassane Ouattara would seek a third term in office, despite a two-term limit.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Democratic backsliding, entrenchment of Ouattara’s Rally of Houphouëtists for Democracy and Peace (RHDP) party, and manipulation of the constitution for political gain. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: false,
		        mapData: {
		        	"id": "CI", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CI", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CICapitalDataActive,
		        inactiveCapitalData: CICapitalDataInctive
		    },
		    {
		        id: "JP",
		        arrayItem: true,
		        country: 'Japan',
		        protest_name: '<span class="protest">Protest against Cambodia-Laos-Vietnam Development Triangle Area (CLV-DTA)</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Free',
		        triggers: 'Arrest of three Cambodian activists who shared concerns over the CLV-DTA, an economic agreement between Cambodia, Laos, and Vietnam.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Overseas Cambodians are demanding that Cambodia withdraw from the CLV-DTA, which they believe will cause Cambodia to lose territorial sovereignty to Vietnamese or Lao investors. They are also concerned about potential influx of immigrants from Vietnam, which Cambodia has historically had negative relations with.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Cambodians in Japan',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The Cambodian government threatened further arrests of activists in Cambodia and blocked a similar protest from occuring in Phnom Penh, Cambodia. On September 20, Prime Minister Hun Manet announced that Cambodia had withdrawn from the CLV-DTA.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "JP", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JP", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JPCapitalDataActive,
		        inactiveCapitalData: JPCapitalDataInctive
		    },
		    {
		        id: "JP",
		        arrayItem: true,
		        country: 'Japan',
		        protest_name: '<span class="protest">Protest against amendment of immigration and refugee law</span>',
		        date: '2024-06-01',
		        date_text: "June 2024",
		        freedom: 'Free',
		        triggers: 'The Japanese Diet proposed an amendment on the Immigration Control and Refugee Recognition Law that would allow the permanent residency status of foreigners to be more easily revoked.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesting against the passage of the amendment.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Ethnic minorities, including ethnic Koreans and ethnic Chinese',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. The Diet passed the amendment on June 6.',
		        multiple: true,
		        mapData: {
		        	"id": "JP", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JP", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JPCapitalDataActive,
		        inactiveCapitalData: JPCapitalDataInctive
		    },
		    {
		        id: "JP",
		        arrayItem: true,
		        country: 'Japan',
		        protest_name: '<span class="protest">Police brutality protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Free',
		        triggers: 'The death of George Floyd in the United States, sparking protests against local police brutality and racism in Japan, most recently manifesting in police violence against a Kurdish man. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Police brutality, systemic racism, and lack of government recognition of systemic racism. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 weeks  ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "JP", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JP", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JPCapitalDataActive,
		        inactiveCapitalData: JPCapitalDataInctive
		    },
		    {
		        id: "JO",
		        arrayItem: true,
		        country: 'Jordan',
		        protest_name: '<span class="protest">Protest against peace treaty with Israel</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Not free',
		        triggers: 'Ongoing Israel-Hamas war in Gaza, which particularly resonates with Jordan’s Palestinian diaspora.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand an end to Jordan’s 1994 peace treaty with Israel. Protesters are also upset that the government helped intercept Iranian missiles headed for Israel in April 2024. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Palestinians, general public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. The government has ramped up arrests and harassment of demonstrators protesting the ongoing Israel-Hamas war, and police have clashed with demonstrators opposed to the peace treaty.  ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "JO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JOCapitalDataActive,
		        inactiveCapitalData: JOCapitalDataInctive
		    },
		    {
		        id: "JO",
		        arrayItem: true,
		        country: 'Jordan',
		        protest_name: '<span class="protest">Dignity strike and protest</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Not free',
		        triggers: 'An increase in fuel prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'A rise in unemployment, debt, inflation, and food and energy prices.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Truck drivers.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "JO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JOCapitalDataActive,
		        inactiveCapitalData: JOCapitalDataInctive
		    },
		    {
		        id: "JO",
		        arrayItem: true,
		        country: 'Jordan',
		        protest_name: '<span class="protest">Water deal protest</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Not free',
		        triggers: 'The announcement of a deal between Israel and Jordan that would provide Israeli firms access to Jordanian solar energy resources in exchange for water from Israel.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with Jordan’s policy toward Israel.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "JO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JOCapitalDataActive,
		        inactiveCapitalData: JOCapitalDataInctive
		    },
		    {
		        id: "JO",
		        arrayItem: true,
		        country: 'Jordan',
		        protest_name: '<span class="protest">Teacher pay protests</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Not free',
		        triggers: 'The government’s failure to abide by a pay raise agreement between the education ministry and the Teachers’ Syndicate union.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Insufficient pay for public sector workers amid rising costs of living and anger over the government’s repression of the Teachers’ Syndicate.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially teachers.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "JO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JOCapitalDataActive,
		        inactiveCapitalData: JOCapitalDataInctive
		    },
		    {
		        id: "JO",
		        arrayItem: true,
		        country: 'Jordan',
		        protest_name: '<span class="protest">Tax law protests</span>',
		        date: '2018-05-01',
		        date_text: "May 2018",
		        freedom: 'Not free',
		        triggers: 'Tax hikes and price increases for electricity and fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Government corruption, lack of political rights, and a stagnant economy.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Youth movement, civil society groups, professional associations, labor unions.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Then prime minister Hani Mulki stepped down, and his successor, Omar Razzaz, rescinded the proposed changes.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "JO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "JO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: JOCapitalDataActive,
		        inactiveCapitalData: JOCapitalDataInctive
		    },
		    {
		        id: "KZ",
		        arrayItem: true,
		        country: 'Kazakhstan',
		        protest_name: '<span class="protest">Protest against incarceration of Kazakhs in Xinjiang</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Not free',
		        triggers: 'China has been accused of human rights violations against Kazakhs, Uyghurs, and other Turkic-speaking ethnic groups in Xinjiang. Protesters say many are detained "simply because they are practicing Muslims."',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Relatives of several ethnic Kazakhs protested near the Chinese consulate in Almaty, Kazakhstan, to demand their loved ones imprisoned in Xinjiang be released. ',
		        size: 11,
		        size_text: '>10',
		        participants: 'Ethnic Kazakhs, Muslims, relatives of those detained in Xinjiang',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "KZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KZCapitalDataActive,
		        inactiveCapitalData: KZCapitalDataInctive
		    },
		    {
		        id: "KZ",
		        arrayItem: true,
		        country: 'Kazakhstan',
		        protest_name: '<span class="protest">Bloody January protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Not free',
		        triggers: 'Two-year anniversary of anti-government protests that spiraled into riots. Security forces fired on demonstrators, and at least 238 people were killed. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand punishment for those who killed protesters in 2022.',
		        size: 11,
		        size_text: '>10',
		        participants: 'Family and friends of those killed in January 2022 protests. ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "KZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KZCapitalDataActive,
		        inactiveCapitalData: KZCapitalDataInctive
		    },
		    {
		        id: "KZ",
		        arrayItem: true,
		        country: 'Kazakhstan',
		        protest_name: '<span class="protest">Fuel price protests</span>',
		        date: '2022-01-01',
		        date_text: "January 2022",
		        freedom: 'Not free',
		        triggers: 'The lifting of price caps on liquid petroleum gas.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over rising cost of living, economic stagnation and inequality, lack of political pluralism, and intolernace of dissent.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '10 days',
		        timespan_text: '',
		        outcomes: 'The prime minister and his cabinet resigned, former president Nursultan Nazarbayev was removed as chairman of the country’s Security Council, and the fuel price caps were restored.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "KZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KZCapitalDataActive,
		        inactiveCapitalData: KZCapitalDataInctive
		    },
		    {
		        id: "KZ",
		        arrayItem: true,
		        country: 'Kazakhstan',
		        protest_name: '<span class="protest">Election protests</span>',
		        date: '2019-06-01',
		        date_text: "June 2019",
		        freedom: 'Not free',
		        triggers: 'Recent presidential election results that were seen as undemocratic/illegitimate.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Undemocratic elections and lack of meaningful political choice and change.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '5 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KZCapitalDataActive,
		        inactiveCapitalData: KZCapitalDataInctive
		    },
		    {
		        id: "KZ",
		        arrayItem: true,
		        country: 'Kazakhstan',
		        protest_name: '<span class="protest">House fire protests</span>',
		        date: '2019-02-01',
		        date_text: "February 2019",
		        freedom: 'Not free',
		        triggers: 'The deaths of five children in a house fire that occurred while both parents were working overnight shifts.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of sufficient social support for working families, inadequate housing infrastructure, and the high cost of living.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public, especially women.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The government of prime minister Bakytzhan Sagintayev was fired by President Nursultan Nazarbayev. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "KZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KZCapitalDataActive,
		        inactiveCapitalData: KZCapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">Protest against abductions of government critics</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Partly free',
		        triggers: 'The Kenya National Commission on Human Rights reported that eighty-two government critics had allegedly been abducted by security forces since the finance bill protests in June 2024.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce the extrajudicial arrests. Demand that Kenyan authorities release those who were abducted.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Human rights groups, youth',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Though President William Ruto acknowledged that there had been instances of extrajudicial actions by authorities, he did not provide further details. A Kenyan court ruled that the police must release five men who had allegedly been abducted; however, police did not abide by the ruling.',
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">Protest against femicide</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Partly free',
		        triggers: 'Long-standing issue of femicide in Kenya. According to Kenyan police, ninety-seven women were murdered from August 2024 to October 2024.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express frustration over government’s handling of femicide. Demand that President William Ruto declare femicide a national crisis and that Parliament implement stricter laws to address gender-based violence.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Women’s rights organizations, women',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Police fired tear gas to disperse protesters. On December 19, the Kenyan government announced that gender-based violence was the nation’s biggest security issue. The Cabinet also approved the formation of a presidential working group to provide recommendations on how to combat gender-based violence. ',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">Finance Bill protests</span>',
		        date: '2024-06-01',
		        date_text: "June 2024",
		        freedom: 'Partly free',
		        triggers: 'Approval of the Kenya Finance Bill 2024 by the parliament, which proposed a new tax system to raise $2.7 billion in additional taxes.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protesters intially called for the government to reject the bill. They have since called for the resignation of President William Ruto. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, youth',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Since the start of the protest, at least fifty people have been killed. On June 26, Ruto announced that he would not sign the finance bill. On July 25, the National Assembly rejected the bill in its entirety. Ruto fired his cabinet members on July 11, then announced new cabinet nominations on July 24. These nominations include members of the opposition. On August 19, Kenya’s new finance minister announced that the government would reintroduce some of the previously proposed taxes in a new tax amendment bill.',
				significant: true,
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">Protest for the rule of law</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Partly free',
		        triggers: 'President William Ruto declared his intention to disregard court orders, asserting that some justices have colluded with the opposition to obstruct the administration’s agenda. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand Ruto respect the rule of law, and call for impeachment if he does not. ',
		        size: 11,
		        size_text: '>10',
		        participants: 'Law Society of Kenya',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">End Femicide Now protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Partly free',
		        triggers: 'Recent rise in violence against women and femicide. At least 500 women have been killed in acts of femicide since 2016.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand an end to sexual and gender-based violence. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests other than the Directorate of Criminal Investigations rolling out new regulations requiring short-term rental properties to register with the Tourism Regulatory Authority, as two recent femicides occurred in unregistered short-term rentals. ',
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">2023 inflation protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'Inflation rose to 9.2% in February 2023 despite the president’s promises to bring down costs of living; on April 28, 2023, the Kenya Treasury Cabinet Secretary presented Parliament a proposed finance bill that would hike taxes on fuel and housing.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the rising cost of staple goods; dissatisfaction over the results of the 2022 presidential election and the Supreme Court’s dismissal of fraud allegations related to that vote; anger over police brutality in suppressing the protests.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the opposition.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'On June 22, Kenya’s parliament approved the finance bill; President William Ruto signed it into law on June 26. But in a reversal, the government announced on August 14 that it had partially reinstated fuel subsidies in an effort to lower fuel prices.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">2022 election protest</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Partly free',
		        triggers: 'Independent Electoral and Boundaries Commission declared William Ruto winner of the presidential election despite some claims of "opaque" vote verification.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceptions of unfairness in elections, especially against opposition candidate Raila Odinga.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially supporters of Raila Odinga.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. On September 5, the Kenyan Supreme Court unanimously rejected the opposition challenge to the results of the presidential elections.',
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">Njaa ("hunger") Revolution protests</span>',
		        date: '2022-05-01',
		        date_text: "May 2022",
		        freedom: 'Partly free',
		        triggers: 'Increasing prices of staple foods.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Worsening economic conditions, accelerated by the war in Ukraine. ',
		        size: 1701,
		        size_text: '1,700',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">National Youth Service corruption protests</span>',
		        date: '2018-05-01',
		        date_text: "May 2018",
		        freedom: 'Partly free',
		        triggers: 'Revelations that over $100 million had been embezzled from Kenya’s National Youth Service.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over official corruption, the lack of youth opportunity, and high unemployment. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially youth.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Thirty officials associated with the National Youth Service were charged in relation to the scandal.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KE",
		        arrayItem: true,
		        country: 'Kenya',
		        protest_name: '<span class="protest">2017 election protests</span>',
		        date: '2017-08-01',
		        date_text: "August 2017",
		        freedom: 'Partly free',
		        triggers: 'The publication of electoral results that showed that incumbent President Uhuru Kenyatta had been reelected.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over a lack of genuine political pluralism, allegations of electoral manipulation, and the entrenchment of Kenyatta. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Though the Supreme Court nullified the election’s results and called for a new contest, opposition leader Raila Odinga withdrew in protests and Kenyatta was proclaimed the victor.',
		        multiple: true,
		        mapData: {
		        	"id": "KE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "XK",
		        arrayItem: true,
		        country: 'Kosovo',
		        protest_name: '<span class="protest">Protest against opening Mitrovica bridge</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'Prime Minister Albin Kurti told Western diplomats that he would reopen the main bridge in the city of Mitrovica for vehicle traffic. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Claim that reopening the bridge will provoke conflict and place the Serbs in northern Kosovo at risk of ethnic cleansing. Protesters have specified that they are criticizing Kurti’s government, not the Albanian people (with whom they want to maintain peace).',
		        size: 101,
		        size_text: '>100',
		        participants: 'Serbs in northern Kosovo',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. International actors (e.g., NATO, Serbia, the United States) have called for Kosovo to refrain from opening the bridge, as it may create further tension in the region. ',
		        multiple: true,
		        mapData: {
		        	"id": "XK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "XK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: XKCapitalDataActive,
		        inactiveCapitalData: XKCapitalDataInctive
		    },
		    {
		        id: "XK",
		        arrayItem: true,
		        country: 'Kosovo',
		        protest_name: '<span class="protest">Protest to keep the Serbian dinar</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'The Kosovo government announced that the Serbian dinar would no longer be used as legal tender, with all future payments in the country done in euros. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Serbs in northern Kosovo receive pensions and financial assistance from Serbia in dinar, and thus demand the dinar be accepted as legal tender. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Kosovo Serbs',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "XK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "XK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "XK",
		        arrayItem: true,
		        country: 'Kosovo',
		        protest_name: '<span class="protest">Femicide protest</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Partly free',
		        triggers: 'A woman was killed at the hands of an alleged hitman hired by her husband. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against femicide and gender based violence.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Women',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest. ',
		        multiple: true,
		        mapData: {
		        	"id": "XK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "XK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "XK",
		        arrayItem: true,
		        country: 'Kosovo',
		        protest_name: '<span class="protest">UCK ICC protest</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Partly free',
		        triggers: 'A special war crimes court in The Hague is prosecuting former Kosovo Liberation Army (UCK) guerillas who are accused of committing war crimes against Serbia. The former president Hashim Thaçi was indicted for murder, torture, and persecution.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Members of the Social Democratic Party (PSD) protested the ICC prosecuting Kosovar leaders, claiming it is persecution. ',
		        size: 99,
		        size_text: '<100',
		        participants: 'Social Democratic Party',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Multiple arrests were made as protesters clashed with police in Pristina.  ',
		        multiple: true,
		        mapData: {
		        	"id": "XK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "XK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "XK",
		        arrayItem: true,
		        country: 'Kosovo',
		        protest_name: '<span class="protest">Mayoral election protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Partly free',
		        triggers: 'Kosovo held mayoral elections to fill four vacancies in Serb-majority municipalities, which Serbian parties and candidates by and large boycotted. Ethnic Albanian candidates won all four races without competition. Serb citizens of these municipalities sought to prevent the Albanian officials from taking office. Kosovar police escalated the situation by taking control of municipal buildings and escorting the elected mayors inside',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Resentment over Kosovar rule of Serb-majority municipalities; desire to create an association of majority-Serb municipalities operating with considerable autonomy.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Serbs in northern Kosovo.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'NATO deployed 700 additional troops after protesters injured thirty NATO peacekeepers. Kosovar Prime Minister Albin Kurti proposed a solution to the crisis on June 13 that would involve early mayoral elections in the four Serb-majority municipalities and a reduction in police presence there. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "XK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "XK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "XK",
		        arrayItem: true,
		        country: 'Kosovo',
		        protest_name: '<span class="protest">Feminist protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Partly free',
		        triggers: 'The rape of an 11-year-old girl by five men in Pristina.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with government inaction to combat patriarchial violence.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'Samedin Mehmeti, the director of the Kosovo Police, and Ali Gashi, the head of the Directorate for the Treatment of Prisoners and Minors, both resigned.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "XK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "XK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "XK",
		        arrayItem: true,
		        country: 'Kosovo',
		        protest_name: '<span class="protest">License plate protests</span>',
		        date: '2022-07-01',
		        date_text: "July 2022",
		        freedom: 'Partly free',
		        triggers: 'The announcement of new laws that would require ethnic Serbs crossing into Kosovo to show identity cards and license plates provided by the Kosovar government instead of the special status documents provided by the Serbian government.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceived mistreatment and persecution of ethnic Serbs by the Kosovar government. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Ethnic Serbs in Kosovo.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'The Kosovar government delayed imposing this law several times to avert ethnic strife but began a gradual implementation on November 1. Representatives from Kosovo and Serbia reached an agreement on November 23: Kosovo agreed to end further actions related to the re-registration of cars while Serbia agreed to stop issuing car license plates with the names of Kosovar cities.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "XK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "XK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KECapitalDataActive,
		        inactiveCapitalData: KECapitalDataInctive
		    },
		    {
		        id: "KW",
		        arrayItem: true,
		        country: 'Kuwait',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Partly free',
		        triggers: 'Allegations of corruption against parliament speaker Marzouq al-Ghanim.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration over entrenched corruption.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, including former and current members of parliament.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: false,
		        mapData: {
		        	"id": "KW", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KW", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KWCapitalDataActive,
		        inactiveCapitalData: KWCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Protest for the release of border-delimitation protesters</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Not free',
		        triggers: 'Eleven Kyrgyz politicians, journalists, and activists were arrested in October 2022 for their protest against the 2022 Kyrgyz-Uzbek border delimitation deal, in which Kyrgyzstan handed over the Kempir-Abad water reservoir to Uzbekistan. Sixteen other protesters were placed under house arrest for their involvement in the demonstrations.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the immediate release of the eleven jailed protesters.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Residents of villages in the Naryn, Talas, and Jalal-Abad regions. ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Protest against underage sexual violence</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Not free',
		        triggers: 'Police in Kyrgyzstan are investigating a case in which an underage girl was allegedly raped by numerous men near Bishkek. The parents of the girl allege that as many as ten men systematically raped their daughter for months and threatened her into silence. It is believed that many rape victims in the country do not come forward for fear of shame by socially conservative members of their communities. The protests occurred on March 8, International Women’s Day. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize the government for failing to address sexual violence against children.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Parents of victims of sexual violence, women’s rights activists, general public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Vendors’ tax protest</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Not free',
		        triggers: 'The government planned to introduce a new tax system that will lead to an overall increase in taxes. The plan will also require vendors to use electronic cash registers and pay taxes on each item sold. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce the new tax system, which vendors say will hurt their business. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Vendors',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'President Sadyr Japarov announced the implementation of the new tax system (slated for early 2024) would be delayed by six months. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Flag change protests</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Not free',
		        triggers: 'President Sadyr Japarov, who has expanded his office’s powers and reduced those of Parliament, proposed a change to the state flag to make the center emblem look more like a sun instead of a sunflower in an effort to project strength and independence. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters claim the flag change is an effort to distract from the greater issues which the government is not addressing.',
		        size: 99,
		        size_text: '<100',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The flag change was enacted in late December despite the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Electoral protests</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Not free',
		        triggers: 'Allegations of vote-rigging and intimidation amid parliamentary elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over the shrinking political space and lack of faith in electoral processes under President Sooronbai Jeenbekov.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'President Jeenbekov and his government resigned and were succeeded by an interim government led by opposition leader Sadyr Japarov.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Internet freedom protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Not free',
		        triggers: 'The proposal of a law that would allow authorities to block “fake news” online.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over state repression of independent online journalism and the media space more generally.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'President Sooronbay Jeenbekov sent the law back to parliament for further legislative review.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Corruption report protests</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Not free',
		        triggers: 'Circulation of a report that alleged that over $700 million in public funds had been misappropriated in a money laundering operation.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over corruption and poor infrastructure.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "KG",
		        arrayItem: true,
		        country: 'Kyrgyzstan',
		        protest_name: '<span class="protest">Tekebayev arrest protests</span>',
		        date: '2017-02-01',
		        date_text: "February 2017",
		        freedom: 'Not free',
		        triggers: 'The arrest of opposition leader Omurbek Tekebayev on charges, which protesters alleged were politically motivated.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over a lack of genuine political pluralism, and recurring crackdowns on political opposition.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public, especially supporters of Tekebayev.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "KG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KGCapitalDataActive,
		        inactiveCapitalData: KGCapitalDataInctive
		    },
		    {
		        id: "LB",
		        arrayItem: true,
		        country: 'Lebanon',
		        protest_name: '<span class="protest">Beirut blast investigation protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Partly free',
		        triggers: 'The investigation into the 2020 Beirut blast remained frozen for more than one year.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over the lack of accountability for those responsible for the 2020 Beirut port explosion.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Family members of those killed in the 2020 Beirut blast.',
		        timespan: '1 week in January 2023,<br />1 day in August 2023',
		        timespan_text: '',
		        outcomes: 'Judge Tarek Bitar unexpectedly reopened the investigation that had been frozen. In response, Lebanon’s top prosecutor, Ghassan Oweidat, blocked the move, filed charges against Bitar, and ordered the release of all detainees related to the case, sparking a judicial crisis.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "LB", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LB", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LBCapitalDataActive,
		        inactiveCapitalData: LBCapitalDataInctive
		    },
		    {
		        id: "LB",
		        arrayItem: true,
		        country: 'Lebanon',
		        protest_name: '<span class="protest">Prison protest</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Partly free',
		        triggers: 'Overcrowding in prisons during the coronavirus outbreak.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Overcrowding in Lebanese prisons.',
		        size: 1,
		        size_text: 'Unknown',
		        participants: 'Prisoners.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "LB", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LB", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LBCapitalDataActive,
		        inactiveCapitalData: LBCapitalDataInctive
		    },
		    {
		        id: "LB",
		        arrayItem: true,
		        country: 'Lebanon',
		        protest_name: '<span class="protest">October Movement</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Introduction of taxes on tobacco, petrol, and WhatsApp voice calls. In August 2020, the protests were reinvigorated by the explosion at the Port of Beirut.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'High youth unemployment and a currency crisis, income inequality, poor service delivery, and political nepotism and corruption due to sectarianism.',
		        size: 1000001,
		        size_text: '>1,000,000',
		        participants: 'General public.',
		        timespan: '4 years',
		        timespan_text: '',
		        outcomes: 'In October 2019, the government of Prime Minister Saad Hariri resigned. Protests continued under his successor, Hassan Diab, whose government resigned after the Beirut blast of 2020. In September 2021, Najib Mikati became prime minister. Protests have not occurred since the war between Israel and Hamas began.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "LB", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LB", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LBCapitalDataActive,
		        inactiveCapitalData: LBCapitalDataInctive
		    },
		    {
		        id: "LR",
		        arrayItem: true,
		        country: 'Liberia',
		        protest_name: '<span class="protest">Protest against Speaker Koffa’s removal</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Partly free',
		        triggers: 'The House of Representatives attempted to remove Speaker Jonathan Fonati Koffa, an opposition politician facing corruption allegations, from office. The decision came after months of tension between the majority and the opposition over Koffa’s allegations.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over decision to remove Koffa as speaker, which protesters believe went against the constitution and the rule of law.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Koffa supporters',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. On the second day of the protests, the parliament building caught fire. Though there was no proven connection between the protests and the fire, the police brought in several politicians, including Koffa, for questioning.',
		        multiple: true,
		        mapData: {
		        	"id": "LR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LRCapitalDataActive,
		        inactiveCapitalData: LRCapitalDataInctive
		    },
		    {
		        id: "LR",
		        arrayItem: true,
		        country: 'Liberia',
		        protest_name: '<span class="protest">Antigovernment protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Partly free',
		        triggers: 'Rising costs of food and fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with government inaction to reduce economic hardships; frustration with President George Weah’s 48-day absence from the country.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "LR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LRCapitalDataActive,
		        inactiveCapitalData: LRCapitalDataInctive
		    },
		    {
		        id: "LR",
		        arrayItem: true,
		        country: 'Liberia',
		        protest_name: '<span class="protest">Economic protests</span>',
		        date: '2019-06-01',
		        date_text: "June 2019",
		        freedom: 'Partly free',
		        triggers: 'Protest organized by the opposition to highlight rising prices and allegations of corruption.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Stagnant economy, high levels of poverty and inflation, and corruption.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition parties, civil society activists.',
		        timespan: 'Sporadic',
		        timespan_text: '7 months',
		        outcomes: 'The government called for a roundtable to discuss how to revive the economy.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "LR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LRCapitalDataActive,
		        inactiveCapitalData: LRCapitalDataInctive
		    },
		    {
		        id: "LY",
		        arrayItem: true,
		        country: 'Libya',
		        protest_name: '<span class="protest">Derna flood protest</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Not free',
		        triggers: 'In the town of Derna, two dams broke on September 11, causing flash floods that killed thousands of people.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Grief over the devastastion; anger over perceived corruption and neglect that contributed to the dams’ collapse.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Residents of Derna',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "LY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LYCapitalDataActive,
		        inactiveCapitalData: LYCapitalDataInctive
		    },
		    {
		        id: "LY",
		        arrayItem: true,
		        country: 'Libya',
		        protest_name: '<span class="protest">Anti-Israel protests</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Not free',
		        triggers: 'Israeli Foreign Minister Eli Cohen announced that he had met secretly with Najla el-Mangoush, foreign minister for Libya’s Tripoli-based government, in Italy the week prior. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger and betrayal over the prospect of Libya normalizing relations with Israel.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'Prime Minister Abdulhamid Dbeibah suspended Mangoush and referred her for internal investigation. Mangoush fled to Türkiye.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "LY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LYCapitalDataActive,
		        inactiveCapitalData: LYCapitalDataInctive
		    },
		    {
		        id: "LY",
		        arrayItem: true,
		        country: 'Libya',
		        protest_name: '<span class="protest">Standard of living protests</span>',
		        date: '2020-08-01',
		        date_text: "August 2020",
		        freedom: 'Not free',
		        triggers: 'Extended cuts to power and water services and gasoline supply disruptions. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Ongoing rampant corruption, the state’s failure to meet even basic needs, and lack of infrastructure.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Prime Minister Fayez al-Sarraj of the internationally recognized Government of National Accord announced a cabinet reshuffle and called for elections to be held in March 2021. In the east, the non-recognized government affiliated with Khalid Haftar resigned on September 14, 2020.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "LY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LYCapitalDataActive,
		        inactiveCapitalData: LYCapitalDataInctive
		    },
		    {
		        id: "LT",
		        arrayItem: true,
		        country: 'Lithuania',
		        protest_name: '<span class="protest">Anti-Nemunas Dawn protest</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'The Social Democratic Party announced that it would form a coalition government with Nemunas Dawn and For Lithuania. The leader of Nemunas Dawn, Remigijus Žemaitaitis, is currently on trial for antisemitism. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Condemn Žemaitaitis for his antisemitism. Call for the Social Democrats to exclude Nemunas Dawn from the coalition government.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '2 days (November 15 and 18)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        mapData: {
		        	"id": "LT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LTCapitalDataActive,
		        inactiveCapitalData: LTCapitalDataInctive
		    },
		    {
		        id: "MG",
		        arrayItem: true,
		        country: 'Madagascar',
		        protest_name: '<span class="protest">Anti-Rajoelina protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Partly free',
		        triggers: 'President Andry Rajoelina resigned from his position as head of state to launch his reelection campaign on October 10 (as is required for an incumbent candidate by the state’s constitutions). Ten of 13 candidates called for a boycott of the election, accusing Rajoelina of seeking out unfair advantages to ensure his victory. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Opposition members denounce an &ldquo;institutional coup&rdquo; to keep Rajoelina, who came to power through a coup, in power.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Collectif des 10, members of the opposition.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The High Constitutional Court delayed elections by a week. Rajoelina won a third term as president, and the opposition denounced irregularities in the election. Rajoelina started his new term in December 2023.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "MG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MGCapitalDataActive,
		        inactiveCapitalData: MGCapitalDataInctive
		    },
		    {
		        id: "MG",
		        arrayItem: true,
		        country: 'Madagascar',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2022-07-01',
		        date_text: "July 2022",
		        freedom: 'Partly free',
		        triggers: 'Rising cost of living and rolling blackouts.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Worsening economic conditions.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "MG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MGCapitalDataActive,
		        inactiveCapitalData: MGCapitalDataInctive
		    },
		    {
		        id: "MG",
		        arrayItem: true,
		        country: 'Madagascar',
		        protest_name: '<span class="protest">Electoral law protests</span>',
		        date: '2018-04-01',
		        date_text: "April 2018",
		        freedom: 'Partly free',
		        triggers: 'Passage of new electoral laws that opposition candidates saw as preventing them from running for elections, with additional unfavorable provisions on campaign financing and media access.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Obstacles to opposition candidates’ participation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition candidates.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'In May 2018, the court ruled part of the electoral law to be unconstitutional, specifically provisions on the revision of electoral lists and the duration of campaigns. Demonstrations continued after this initial ruling, and weeks after the first decision, the court ordered the dissolution of the government and appointment of a new prime minister. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "MG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MGCapitalDataActive,
		        inactiveCapitalData: MGCapitalDataInctive
		    },
		    {
		        id: "MW",
		        arrayItem: true,
		        country: 'Malawi',
		        protest_name: '<span class="protest">Anti-LGBT protest</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'A Dutch NGO worker and a Malawian transgender woman separately faced charges under colonial-era legislation outlawing sodomy. Malawi’s High Court was set to hear their case on July 17; the pair was seeking to have bans on same-sex marriage and gay sex overturned. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear over the advance of LGBT rights in Malawi.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'Religious leaders.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MW", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MW", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MWCapitalDataActive,
		        inactiveCapitalData: MWCapitalDataInctive
		    },
		    {
		        id: "MW",
		        arrayItem: true,
		        country: 'Malawi',
		        protest_name: '<span class="protest">Cost of living and corruption protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Partly free',
		        triggers: 'Rapidly rising cost of living, a scandal in which the government paid to procure fertilizer that never materialized.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Perceptions of high corruption, mismanagement of pandemic relief funds, and government incapacity to combat poverty and inflation.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MW", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MW", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MWCapitalDataActive,
		        inactiveCapitalData: MWCapitalDataInctive
		    },
		    {
		        id: "MW",
		        arrayItem: true,
		        country: 'Malawi',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2021-11-01',
		        date_text: "November 2021",
		        freedom: 'Partly free',
		        triggers: 'Price hikes on fuel and other commodities; call for protest by a human rights CSO.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with growing economic insecurity, rising cost of living, and a perceived failure by President Lazarus Chakwera to fulfill pledges to improve the economy.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Chakwera introduced an economic recovery plan in December 2021.',
		        multiple: true,
		        mapData: {
		        	"id": "MW", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MW", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MWCapitalDataActive,
		        inactiveCapitalData: MWCapitalDataInctive
		    },
		    {
		        id: "MW",
		        arrayItem: true,
		        country: 'Malawi',
		        protest_name: '<span class="protest">Vendor protest</span>',
		        date: '2020-04-01',
		        date_text: "April 2020",
		        freedom: 'Partly free',
		        triggers: 'Enactment of strict lockdown measures, including restrictions on social interaction and business operations.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Loss of revenue for small vendors dependent on in-person markets.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Street vendors.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "MW", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MW", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MWCapitalDataActive,
		        inactiveCapitalData: MWCapitalDataInctive
		    },
		    {
		        id: "MW",
		        arrayItem: true,
		        country: 'Malawi',
		        protest_name: '<span class="protest">Election fraud protests</span>',
		        date: '2019-05-01',
		        date_text: "May 2019",
		        freedom: 'Partly free',
		        triggers: 'Suspicions that the May 2019 election, which President Peter Mutharika won by only 159,000 votes, was rigged.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Election fraud and bribery.',
		        size: 100000,
		        size_text: '100,000',
		        participants: 'Opposition party leaders and their supporters.',
		        timespan: '10 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. The opposition challenged the election results in the court for the first time in Malawi’s history. Despite attempts by Mutharika’s supporters to bribe judges on the Constitutional Court, the court nullified the results of the presidential elections, citing irregularities. Electoral commissioners have refused to step down.  ',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MW", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MW", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MWCapitalDataActive,
		        inactiveCapitalData: MWCapitalDataInctive
		    },
		    {
		        id: "MY",
		        arrayItem: true,
		        country: 'Malaysia',
		        protest_name: '<span class="protest">Black Flag protests</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Partly free',
		        triggers: 'Perceived government mismanagement of rising coronavirus cases.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with former prime minister Muhyiddin Yassin’s efforts to stay in power amid declining parliamentary confidence; rising coronavirus cases; declining economic opportunity for young people.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Youth and general public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Yassin resigned in mid-August after losing parliamentary confidence. ',
		        significant: true,
		        covid: true,
		        multiple: false,
		        mapData: {
		        	"id": "MY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MYCapitalDataActive,
		        inactiveCapitalData: MYCapitalDataInctive
		    },
		    {
		        id: "MV",
		        arrayItem: true,
		        country: 'Maldives',
		        protest_name: '<span class="protest">Maldives protests</span>',
		        date: '2018-02-01',
		        date_text: "February 2018",
		        freedom: 'Partly free',
		        triggers: 'Order by the Supreme Court for the government to release opposition leaders from prison, which the government disobeyed. Security forces later arrested two out of five Supreme Court justices, blocked off parliament, and arrested several opposition leaders.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Imprisonment of political opponents and attacks on judicial independence.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: false,
		        mapData: {
		        	"id": "MV", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MV", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MVCapitalDataActive,
		        inactiveCapitalData: MVCapitalDataInctive
		    },
		    {
		        id: "ML",
		        arrayItem: true,
		        country: 'Mali',
		        protest_name: '<span class="protest">Ethnic violence protest</span>',
		        date: '2019-04-01',
		        date_text: "April 2019",
		        freedom: 'Not free',
		        triggers: 'Killing of 160 villagers in an interethnic conflict.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Failure of government to quell/respond to interethnic violence.',
		        size: 11000,
		        size_text: '12,500',
		        participants: 'Muslim religious leaders, representatives of the Fulani herding community, opposition parties, civil society groups.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Then prime minister Soumeylou Boubèye Maïga and his entire government stepped down.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ML", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ML", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MLCapitalDataActive,
		        inactiveCapitalData: MLCapitalDataInctive
		    },
		    {
		        id: "ML",
		        arrayItem: true,
		        country: 'Mali',
		        protest_name: '<span class="protest">Movement of June 5</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Not free',
		        triggers: 'Worsening of the security situation in Mali’s northern and central regions.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Lack of political reform, economic stagnation, government corruption, and regime’s inability to address the worsening conflict.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The military coup on August 19, 2020, overthrew president Ibrahim Boubacar Keita, fulfilling a key protestor demand.  ',
		        multiple: true,
		        mapData: {
		        	"id": "ML", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ML", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MLCapitalDataActive,
		        inactiveCapitalData: MLCapitalDataInctive
		    },
		    {
		        id: "MT",
		        arrayItem: true,
		        country: 'Malta',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Free',
		        triggers: 'EU environmental regulations, including new subsidy rules which require 4 percent of farmland to be fallow, which farmers say undercuts their ability to compete with foreign imports. Lowering of trade barriers, including renewed negotiations for a trade deal between the EU and the South American Mercosur bloc. High energy prices. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand protection and support for farmers faced with extensive EU environmental regulations and competition from imports from outside the bloc. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "MT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MTCapitalDataActive,
		        inactiveCapitalData: MTCapitalDataInctive
		    },
		    {
		        id: "MT",
		        arrayItem: true,
		        country: 'Malta',
		        protest_name: '<span class="protest">Abortion bill protest</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Free',
		        triggers: 'The Maltese parliament introduced a bill that would permit legal abortion in cases where the mother’s life is at risk. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that liberalization of the country’s abortion laws will lead to wider acceptance of abortion.',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "MT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MTCapitalDataActive,
		        inactiveCapitalData: MTCapitalDataInctive
		    },
		    {
		        id: "MT",
		        arrayItem: true,
		        country: 'Malta',
		        protest_name: '<span class="protest">“Mafia state” protests</span>',
		        date: '2019-11-01',
		        date_text: "November 2019",
		        freedom: 'Free',
		        triggers: 'Developments in the investigation of the 2017 death of a journalist.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Then prime minister Joseph Muscat’s influence over law enforcement and judicial systems, and corruption among political leaders.',
		        size: 4000,
		        size_text: '4,000',
		        participants: 'Civil society.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Muscat resigned. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "MT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MTCapitalDataActive,
		        inactiveCapitalData: MTCapitalDataInctive
		    },
		    {
		        id: "MR",
		        arrayItem: true,
		        country: 'Mauritania',
		        protest_name: '<span class="protest">Presidential election protest</span>',
		        date: '2024-07-01',
		        date_text: "July 2024",
		        freedom: 'Partly free',
		        triggers: 'Reelection of President Mohamed Ould Ghazouani.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protesters believe the election results were falsified—a claim also supported by the opposition candidate, Biram Dah Abeid—and reject Ghazouani’s reelection. Ghazouani’s opponents have accused him of corruption and mismanagement, though others view him as a beacon of stability.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Opposition, general public',
		        timespan: '3 days',
		        timespan_text: '',
		        outcomes: 'Authorities blocked mobile internet access on the second day of the protests and detained an unknown number of protestors. Three protestors were killed. The elections results have been upheld.',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "MR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MRCapitalDataActive,
		        inactiveCapitalData: MRCapitalDataInctive
		    },
		    {
		        id: "MR",
		        arrayItem: true,
		        country: 'Mauritania',
		        protest_name: '<span class="protest">Election protests</span>',
		        date: '2019-06-01',
		        date_text: "June 2019",
		        freedom: 'Partly free',
		        triggers: 'Call by opposition leaders for protests after government-backed presidential candidate Mohamed Ould Ghazouani was proclaimed the winner. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns about electoral fraud and the political manipulation of votes.',
		        size: 501,
		        size_text: '>500',
		        participants: 'Supporters of the political opposition.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MRCapitalDataActive,
		        inactiveCapitalData: MRCapitalDataInctive
		    },
		    {
		        id: "MU",
		        arrayItem: true,
		        country: 'Mauritius',
		        protest_name: '<span class="protest">Tanker spill protests</span>',
		        date: '2020-08-01',
		        date_text: "August 2020",
		        freedom: 'Free',
		        triggers: 'The spillage of over 1,000 tons of oil from the wreckage of the oil tanker MV Wakashio. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger at the government over insufficient information about the incident, and general frustration about the degradation of environmental resources in Mauritius.',
		        size: 75001,
		        size_text: '>75,000',
		        participants: 'General public, particularly environmental activists.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: false,
		        mapData: {
		        	"id": "MU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MUCapitalDataActive,
		        inactiveCapitalData: MUCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Judicial reform protest</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'Former president Andrés Manuel López Obrador’s proposed judicial reform bill, which would require all judges to be elected by popular vote. The reform also lowers the number of Supreme Court justices to eleven, shortens their terms to twelve years, and reduces the required years of experience to five.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against passage of reform bill, which many believe threatens Mexico’s judicial independence and checks and balances system. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Judicial employees, law students',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'The bill was passed in the lower house of Mexico’s legislature on September 6, then passed in the Senate on September 11. The reforms formally took effect on September 15. On October 28, the Supreme Court published a proposal to invalidate certain aspects of the reform, which was eventually dismissed in November. On October 30, eight of the eleven Supreme Court judges resigned from their positions and refused to participate in the upcoming judicial elections.',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Anti-AMLO protest</span>',
		        date: '2024-05-01',
		        date_text: "May 2024",
		        freedom: 'Partly free',
		        triggers: 'President Andrés Manuel López Obrador (AMLO) has called to defund the country’s independent electoral agencies ahead of the presidential election on June 2, 2024. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Oppose AMLO and former Mexico City mayor Claudia Sheinbaum, the candidate running for president under AMLO’s Morena party. Express support for opposition candidate Xóchitl Gálvez. Call for the defense of the country’s independent electoral agencies.  ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition supporters',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest. ',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Ayotzinapa students’ protest for justice</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Partly free',
		        triggers: 'In 2014, forty-three male students at a teachers’ college in Iguala, Guerrero, were kidnapped and—as later revealed—killed after they commandeered buses for an annual drive to Mexico City to mark the 1968 Tlatelolco massacre. A 2022 truth commission concluded that the disappearance was a "state crime" which involved local, state, and federal authorities. Another student was killed in March 2024 during a confrontation with police. The police officers responsible for that student’s death are under investigation. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand justice for the forty-three students kidnapped in 2014 and accountability for their slain classmates. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Students at the Ayotzinapa teachers’ college',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Justice for Ayotzinapa kidnapping protests</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Partly free',
		        triggers: 'In 2014, forty-three male students at a teachers’ college in Iguala, Guerrero, were kidnapped and apparently killed after they commandeered buses as part of an annual tradition to drive to Mexico City to mark the 1968 Tlatelolco massacre. A 2022 truth commission concluded that the disappearance was a "state crime" which involved local, state, and federal authorities. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand justice and answers concerning the 2014 kidnapping. ',
		        size: 11,
		        size_text: '>10',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Protesters knocked down the door to the presidential palace. President Andrés Manuel López Obrador described the demonstration as a "very clear plan of provocation," and said that the government will not respond violently. ',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">International Safe Abortion Day protest</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Partly free',
		        triggers: 'International Safe Abortion Day was September 6.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Hope that the movement for safe access to abortion could continue its positive momentum and win legalization, not just decriminalization, of abortion at the federal level.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Women.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Farmer protests</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Partly free',
		        triggers: 'The international prices for corn, wheat, and sorghum fell.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic anxiety caused by the fall in crop prices.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'The governor of Sinaloa State agreed to meet with the protesters.',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Protect Democracy protest</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'President Andrés Manuel López Obrador introduced a package of constitutional reforms to Congress that would overhaul the nation’s electoral authority and the National Electoral Institute, and would require judges to be elected via popular vote. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters decried the legislation, which they say undermines Mexican democracy. ',
		        size: 699999,
		        size_text: '<700,000',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Truckers’ strike against highway lawlessness</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'Rise in robberies and extortion on Mexican highways, with about two truck drivers killed every month on the roads. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'The transport group Coalition of United Motor Transport Organizations (AMOTAC) demands that the government ensure truckers’ safety. They also call for an end to tolls for certain highways. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Truckers, AMOTAC',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The government announced an agreement to improve safety on the roads by strengthening federal inspections of double-articulated tractor trailers and deploying 600 National Guard officials along with 2,000 radio patrol cars. ',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Migrant protests</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Partly free',
		        triggers: 'A fire at a migrant processing center in Ciudad Juárez killed 40 migrants.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the unsafe condition of some migrant detention centers and harassment from Mexico’s National Immigration Institute; frustration over the difficulty in procuring paperwork from the government that would allow migrants to travel to the U.S.-Mexico border.',
		        size: 3000,
		        size_text: '3,000',
		        participants: 'Migrants.',
		        timespan: '4 weeks',
		        timespan_text: '',
		        outcomes: 'A judge ordered the head of the National Immigration Institute to stand trial on charges that he failed to protect those in his custody at the migrant processing detention center in Ciudad Juárez on the day of the fire.',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Electoral reform protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Partly free',
		        triggers: 'The president introduced a plan to overhaul the National Electoral Institute (INE), the country’s agency responsible for organizing federal elections. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that proposed changes would concentrate power in the hands of the government.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '3.5 months',
		        timespan_text: '',
		        outcomes: 'In February 2023, Mexico’s senate approved the reform. On May 8, the country’s Supreme Court voted to strike down the reform’s push to rein in the INE’s ability to police political communications. On June 22, the Supreme Court invalidated another part of the reform that would have significantly shrunk Mexico’s INE and cut its budget.',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">AMLO protests</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Partly free',
		        triggers: 'Perception that the government of President Andres Manuel Lopez Obrador has mismanaged the public health and economic crises stemming from the corronavirus pandemic.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic frustration and broad-based opposition to the Lopez Obrador government, especially from conservatives.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially conservatives and adherents of the FRENAAA, or National Anti-AMLO Front, movement.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MX",
		        arrayItem: true,
		        country: 'Mexico',
		        protest_name: '<span class="protest">Femicide protests</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Partly free',
		        triggers: 'In March 2020, triggered by the murder of a twenty-five-year-old woman and seven-year-old girl. In March 2022, protests were triggered by rising femicide rates and commemorations of International Women’s Day. In March 2023, protests were triggered again by commemorations of International Women’s Day.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger at violence against women, and lack of a government focus on addressing gender-based violence.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Women.',
		        timespan: '3 weeks in March 2020;<br />1 day in March 2022;<br />1 day in March 2023',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MX", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MX", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MXCapitalDataActive,
		        inactiveCapitalData: MXCapitalDataInctive
		    },
		    {
		        id: "MD",
		        arrayItem: true,
		        country: 'Moldova',
		        protest_name: '<span class="protest">Fuel price protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Partly free',
		        triggers: 'Worsening inflation, especially in fuel prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with perceived missteps in gas negotiations with Russia; anger over the government’s pro-Western policies.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially members of the opposition Shor party.',
		        timespan: '1 year',
		        timespan_text: '',
		        outcomes: 'In February 2023, Moldova’ government resigned. President Maia Sandu accepted Prime Minister Natalia Gavrilita’s resignation and nominated former interior minister Dorin Recean to replace her. On June 19, Moldova’s Constitutional Court banned the Shor Party and said the protests led by the group were “unconstitutional.&rdquo;',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MDCapitalDataActive,
		        inactiveCapitalData: MDCapitalDataInctive
		    },
		    {
		        id: "MD",
		        arrayItem: true,
		        country: 'Moldova',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2018-06-01',
		        date_text: "June 2018",
		        freedom: 'Partly free',
		        triggers: 'Supreme Court’s decision to annul the results of the mayoral election in the capital.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Lack of judicial independence. ',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'Opposition parties and their supporters.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MDCapitalDataActive,
		        inactiveCapitalData: MDCapitalDataInctive
		    },
		    {
		        id: "MN",
		        arrayItem: true,
		        country: 'Mongolia',
		        protest_name: '<span class="protest">Coal theft protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Free',
		        triggers: 'Allegations that corrupt officials stole and sold 385,000 tons of coal from the national stockpile for private gain.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration over high rates of poverty, inequality, and inflation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'Mongolian authorities created an investigative committee, arrested several officers, and announced plans to reform the state-owned mining firm and placed it under state supervision.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "MN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MNCapitalDataActive,
		        inactiveCapitalData: MNCapitalDataInctive
		    },
		    {
		        id: "MN",
		        arrayItem: true,
		        country: 'Mongolia',
		        protest_name: '<span class="protest">“Do Your Job” protests</span>',
		        date: '2022-04-01',
		        date_text: "April 2022",
		        freedom: 'Free',
		        triggers: 'High inflation, food shortages, and rising fuel prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over persistent corruption, governmental inefficiency, and state debt.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Youth.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MNCapitalDataActive,
		        inactiveCapitalData: MNCapitalDataInctive
		    },
		    {
		        id: "MN",
		        arrayItem: true,
		        country: 'Mongolia',
		        protest_name: '<span class="protest">Coronavirus response protests</span>',
		        date: '2021-01-01',
		        date_text: "January 2021",
		        freedom: 'Free',
		        triggers: 'The circulation of a video of a mother and her newborn being given substandard care during a transfer to a coronavirus quarantine facility.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the state’s aggressive coronavirus policies; desire for more press freedom and assembly rights; and concern over the economic fallout of coronavirus mitigation measures.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Prime Minister Khurelsukh Ukhnaa and his cabinet resigned.',
		        significant: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "MN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MNCapitalDataActive,
		        inactiveCapitalData: MNCapitalDataInctive
		    },
		    {
		        id: "MN",
		        arrayItem: true,
		        country: 'Mongolia',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2018-12-01',
		        date_text: "December 2018",
		        freedom: 'Free',
		        triggers: 'Corruption allegations against the speaker of parliament and other political figures.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Corruption allegations against political elites, falling foreign investment and currency values, and democratic backslide. ',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'General public.',
		        timespan: '6 months',
		        timespan_text: '',
		        outcomes: 'The speaker of parliament, Enkhbold Miyegombo, was removed from office.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MNCapitalDataActive,
		        inactiveCapitalData: MNCapitalDataInctive
		    },
		    {
		        id: "ME",
		        arrayItem: true,
		        country: 'Montenegro',
		        protest_name: '<span class="protest">Protest against mass shooting</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Partly Free',
		        triggers: 'Mass shooting on January 1 that killed twelve people, including two children.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the shooting and the inadequate government protections against gun violence. Demand the resignation of government officials, including Interior Minister Danilo Šaranović and Deputy Prime Minister for Security and Defense Aleksa Bečić.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, students',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'On January 3, Prime Minister Milojko Spajic announced a newly proposed gun-control law that will require existing weapons licenses to be reverified and will impose severe penalties for those who do not surrender illegal weapons. Spajic also acknowledge the staff shortages in the nation’s law enforcement and announced a plan to hire 200 additional police officers.',
				active: true,
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ME", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ME", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MECapitalDataActive,
		        inactiveCapitalData: MECapitalDataInctive
		    },
		    {
		        id: "ME",
		        arrayItem: true,
		        country: 'Montenegro',
		        protest_name: '<span class="protest">Anti-Mandić protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Free',
		        triggers: 'Formation of government with both pro-European parties and  Andrija Mandić as speaker, despite his ties to Serbian nationalists and pro-Kremlin views. Fear Mandić will block EU accession.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce Mandić’s pro-Russian views and Serbian nationalist leanings, demand he is removed from position as partliament’s speaker. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Opposition members',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ME", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ME", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MECapitalDataActive,
		        inactiveCapitalData: MECapitalDataInctive
		    },
		    {
		        id: "ME",
		        arrayItem: true,
		        country: 'Montenegro',
		        protest_name: '<span class="protest">“We are many” protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Partly free',
		        triggers: 'The country’s parliament introduced and passed a controversial law that strips the president of his decisive role in appointing the prime minister. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Rising tensions between the country’s pro-Serbia/pro-Russia and pro-West factions. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Supporters of the opposition.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ME", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ME", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MECapitalDataActive,
		        inactiveCapitalData: MECapitalDataInctive
		    },
		    {
		        id: "ME",
		        arrayItem: true,
		        country: 'Montenegro',
		        protest_name: '<span class="protest">Minority government protest</span>',
		        date: '2022-02-01',
		        date_text: "February 2022",
		        freedom: 'Partly free',
		        triggers: 'Efforts by the Democratic Party of Socialists to form a minority government. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over political dealmaking and a belief that new elections should be held in order to determine the composition of the government. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "ME", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ME", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MECapitalDataActive,
		        inactiveCapitalData: MECapitalDataInctive
		    },
		    {
		        id: "ME",
		        arrayItem: true,
		        country: 'Montenegro',
		        protest_name: '<span class="protest">Religion law protests</span>',
		        date: '2020-12-01',
		        date_text: "December 2020",
		        freedom: 'Partly free',
		        triggers: 'Government plans to rewrite a law in order to keep hundreds of Serbian Orthodox monasteries under church ownership rather than transferring them to the state. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Tensions between ethnic and religious groups in Montenegro; concerns over the relationship between the government and the Serbian Orthodox Church; and feelings of Montenegrin nationalism. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ME", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ME", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MECapitalDataActive,
		        inactiveCapitalData: MECapitalDataInctive
		    },
		    {
		        id: "ME",
		        arrayItem: true,
		        country: 'Montenegro',
		        protest_name: '<span class="protest">“Religion law” protests</span>',
		        date: '2019-12-01',
		        date_text: "December 2019",
		        freedom: 'Partly free',
		        triggers: 'Passage of a law that would mandate the creation of a register for all religious buildings and sites owned by the independent kingdom of Montenegro before 1918, when Montenegro became part of the Kingdom of Serbs, Croats and Slovenes, which was later renamed Yugoslavia. Any faith group that could not provide evidence that they owned the property could lose rights to the property.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns among supporters of the Serbian Orthodox Church that the government is attempting to seize the church’s assets and distance Montenegro from Serbia.',
		        size: 60000,
		        size_text: '60,000',
		        participants: 'Serbian Orthodox Church clergy and their supporters, pro-Russian and pro-Serbian political movements.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ME", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ME", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MECapitalDataActive,
		        inactiveCapitalData: MECapitalDataInctive
		    },
		    {
		        id: "ME",
		        arrayItem: true,
		        country: 'Montenegro',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2019-03-01',
		        date_text: "March 2019",
		        freedom: 'Partly free',
		        triggers: 'Allegations of corruption against President Milo Đukanović.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Democratic backslide and corruption.',
		        size: 10000,
		        size_text: '10,000',
		        participants: 'Civic activists, students, academics, journalists.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ME", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ME", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MECapitalDataActive,
		        inactiveCapitalData: MECapitalDataInctive
		    },
		    {
		        id: "MA",
		        arrayItem: true,
		        country: 'Morocco',
		        protest_name: '<span class="protest">IMF/World Bank Counter Summit</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Partly free',
		        triggers: 'Annual meeting of the IMF and World Bank in Marrakech.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for countries’ debts on IMF/World Bank loans be canceled, claiming the IMF and World Bank are guilty of colluding with the financial sector and powerful multinational corporations. Demand reparations for the Global South due to Western exploitation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MACapitalDataActive,
		        inactiveCapitalData: MACapitalDataInctive
		    },
		    {
		        id: "MA",
		        arrayItem: true,
		        country: 'Morocco',
		        protest_name: '<span class="protest">Abortion access protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Partly free',
		        triggers: 'The death of a fourteen-year-old girl named Meriem who had been raped; she died on September 6 of an unsafe, illegal abortion.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over lack of access to safe abortion in Morocco.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Women.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MACapitalDataActive,
		        inactiveCapitalData: MACapitalDataInctive
		    },
		    {
		        id: "MA",
		        arrayItem: true,
		        country: 'Morocco',
		        protest_name: '<span class="protest">Rising cost of living protests</span>',
		        date: '2022-02-01',
		        date_text: "February 2022",
		        freedom: 'Partly free',
		        triggers: 'Increasing prices of basic goods, including grain and fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration at inflation and the perception that the government’s response to rising prices has been insufficient.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'The government acknowledged that its policies had been insufficient to address rising prices.',
		        multiple: true,
		        mapData: {
		        	"id": "MA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MACapitalDataActive,
		        inactiveCapitalData: MACapitalDataInctive
		    },
		    {
		        id: "MA",
		        arrayItem: true,
		        country: 'Morocco',
		        protest_name: '<span class="protest">Teachers’ protests</span>',
		        date: '2019-02-01',
		        date_text: "February 2019",
		        freedom: 'Partly free',
		        triggers: 'Anniversary of the Arab Spring uprising. Discontent over fixed-term contracts for teachers, limited opportunities for low-ranking teachers, and temporary government contracts that provide limited healthcare or pension benefits. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Inadequate compensation and benefits for teachers. ',
		        size: 15000,
		        size_text: '15,000',
		        participants: 'Teachers, teachers’ unions.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The Moroccan government and public unions made a deal to increase salaries for public sector employees and establish a new minimum wage for national education sector employees. However, contracted teachers did not receive benefits.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "MA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MACapitalDataActive,
		        inactiveCapitalData: MACapitalDataInctive
		    },
		    {
		        id: "MA",
		        arrayItem: true,
		        country: 'Morocco',
		        protest_name: '<span class="protest">Political prisoner protests</span>',
		        date: '2018-04-01',
		        date_text: "April 2018",
		        freedom: 'Partly free',
		        triggers: 'Arrest of leaders of the Hirak Rif protest movement.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of genuine political pluralism, and restrictions on civil society.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MACapitalDataActive,
		        inactiveCapitalData: MACapitalDataInctive
		    },
		    {
		        id: "MA",
		        arrayItem: true,
		        country: 'Morocco',
		        protest_name: '<span class="protest">Jerada mine protests</span>',
		        date: '2017-12-01',
		        date_text: "December 2017",
		        freedom: 'Partly free',
		        triggers: 'The death of scavengers in an abandoned coal mine in the Jerada region.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the lack of economic opportunity, poor infrastructure, unsafe working conditions, and slow development of noncoastal areas.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '7 months',
		        timespan_text: '',
		        outcomes: 'The government announced a series of development initiatives for the Jerada region.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MACapitalDataActive,
		        inactiveCapitalData: MACapitalDataInctive
		    },
		    {
		        id: "MZ",
		        arrayItem: true,
		        country: 'Mozambique',
		        protest_name: '<span class="protest">2024 general elections protest</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'The opposition candidate, Venâncio Mondlane, called for a nationwide strike to dispute the provisional election results, which showed that the ruling party, Frelimo, was likely to win. Two days before the planned demonstration, two opposition figures were killed.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protest the election results, which many believe were rigged by the ruling party. Protesters are angry at the continuation of Frelimo’s forty-nine-year rule, which has been marked by corruption and economic decline. They are also angry over the death of the two opposition figures.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. In November 2024, the government imposed a ban on demonstrations and deployed the army to assist the police in quelling the protests. On December 23, 2024, the Constitutional Council upheld the results of the election, prompting another wave of protests. Over 200 people have been killed from the clashes between protestors and security forces. In January 2025, the new parliament and president were sworn in despite ongoing protests.',
		        active: true,
				long: true,
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "MZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MZCapitalDataActive,
		        inactiveCapitalData: MZCapitalDataInctive
		    },
		    {
		        id: "MZ",
		        arrayItem: true,
		        country: 'Mozambique',
		        protest_name: '<span class="protest">Municipal election fraud protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Partly free',
		        triggers: 'Accusations of voter fraud levied against the ruling party, which was declared the winner in most towns. The U.S. Embassy noted "many credible reports of irregularities on voting day and during the vote tabulation process."',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Denounce election fraud.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Renamo political party.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Police fired on protesters, killing at least two people. No policy/leadership change in response to protests. International watchdog groups are criticizing the suppression of protesters.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "MZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MZCapitalDataActive,
		        inactiveCapitalData: MZCapitalDataInctive
		    },
		    {
		        id: "MZ",
		        arrayItem: true,
		        country: 'Mozambique',
		        protest_name: '<span class="protest">Azagaia protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'The death of rapper Edson da Luz, known as &ldquo;Azagaia,&rdquo; an anti-government critic, from an epileptic seizure. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustrations with the ruling party, high poverty, and perceived corruption, themes about which Azagaia rapped in his songs.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MZCapitalDataActive,
		        inactiveCapitalData: MZCapitalDataInctive
		    },
		    {
		        id: "MM",
		        arrayItem: true,
		        country: 'Myanmar',
		        protest_name: '<span class="protest">Airbus protest</span>',
		        date: '2024-10-01',
		        date_text: "October 2021",
		        freedom: 'Not free',
		        triggers: 'Continued air strikes in northern and central regions of Myanmar.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for Airbus, a European aerospace company, to end its investments in the Aviation Industry Corporation of China, a major weapons and aircraft supplier to Myanmar’s military junta.',
		        size: 11,
		        size_text: '>10',
		        participants: 'General public.',
		        timespan: '2 days (October 29, November 5)',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "MM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MMCapitalDataActive,
		        inactiveCapitalData: MMCapitalDataInctive
		    },
		    {
		        id: "MM",
		        arrayItem: true,
		        country: 'Myanmar',
		        protest_name: '<span class="protest">Coup protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Not free',
		        triggers: 'The February 1 coup d’etat orchestrated by the Burmese military against the National League for Democracy (NLD) and civilian government.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition to military rule and its rejection of the overwhelmingly pro-NLD election results. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '1.5 years',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MMCapitalDataActive,
		        inactiveCapitalData: MMCapitalDataInctive
		    },
		    {
		        id: "MM",
		        arrayItem: true,
		        country: 'Myanmar',
		        protest_name: '<span class="protest">Journalist jailing protests</span>',
		        date: '2018-09-01',
		        date_text: "September 2018",
		        freedom: 'Not free',
		        triggers: 'The arrest of two Reuters journalists by Burmese authorities for allegedly violating the Official Secrets Act.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over a lack of press freedom and a government crackdown on free expression.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'The reporters were released after a few months in custody.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MMCapitalDataActive,
		        inactiveCapitalData: MMCapitalDataInctive
		    },
		    {
		        id: "MM",
		        arrayItem: true,
		        country: 'Myanmar',
		        protest_name: '<span class="protest">Aung San statue protests</span>',
		        date: '2018-07-01',
		        date_text: "July 2018",
		        freedom: 'Not free',
		        triggers: 'The announcement of government plans to construct statues of state founder Aung San.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over discriminatory policies toward ethnic minorities, and the lack of autonomy for minority ethnic groups.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Ethnic minorities.',
		        timespan: '8 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "MM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MMCapitalDataActive,
		        inactiveCapitalData: MMCapitalDataInctive
		    },
		    {
		        id: "NA",
		        arrayItem: true,
		        country: 'Namibia',
		        protest_name: '<span class="protest">Genocide recognition deal protests</span>',
		        date: '2021-09-01',
		        date_text: "September 2021",
		        freedom: 'Free',
		        triggers: 'A parliamentary debate over an agreement with Germany to recognize the genocide during its colonial occupation. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Belief that reconciliation efforts were insufficient and that the government needed to do more to reckon with the country’s colonial era.',
		        size: 401,
		        size_text: '>400',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: false,
		        mapData: {
		        	"id": "NA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NACapitalDataActive,
		        inactiveCapitalData: NACapitalDataInctive
		    },
		    {
		        id: "NP",
		        arrayItem: true,
		        country: 'Nepal',
		        protest_name: '<span class="protest">Restore the monarchy protests</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Partly free',
		        triggers: 'The monarchy was abolished in 2008. Demonstrators perceive the democratic government as corrupt and inadequate.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Supporters of the former king demanded the return of the monarchy. Protesters also called for Nepal to be turned back into a Hindu state.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Supporters of the former king',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "NP", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NP", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NPCapitalDataActive,
		        inactiveCapitalData: NPCapitalDataInctive
		    },
		    {
		        id: "NP",
		        arrayItem: true,
		        country: 'Nepal',
		        protest_name: '<span class="protest">Parliament dissolution protests</span>',
		        date: '2020-12-01',
		        date_text: "December 2020",
		        freedom: 'Partly free',
		        triggers: 'Prime Minister Khadga Prasad Sharma Oli’s move to dissolve parliament and call early elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over increasing partisanship; concerns over the constitutionality of the dissolution of parliament; and the breakdown of a power-sharing agreement between the prime minister and members of the ruling Nepal Communist Party. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "NP", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NP", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NPCapitalDataActive,
		        inactiveCapitalData: NPCapitalDataInctive
		    },
		    {
		        id: "NP",
		        arrayItem: true,
		        country: 'Nepal',
		        protest_name: '<span class="protest">“Guthi bill” protests</span>',
		        date: '2019-06-01',
		        date_text: "June 2019",
		        freedom: 'Partly free',
		        triggers: 'Proposal of a bill that would affect a historical trust system (the guthi system) that helps maintain temples and other traditional public spaces, while helping organize public events. The bill would have established an authority governing all guthis and would have ended the current system of ownership by communities or families.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns for Sanatan Hindu traditions.  ',
		        size: 50000,
		        size_text: '50,000',
		        participants: 'Trustees, cultural experts, activists, civil society, members of the Newar community.',
		        timespan: '10 days',
		        timespan_text: '',
		        outcomes: 'The government withdrew the guthi bill.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NP", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NP", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NPCapitalDataActive,
		        inactiveCapitalData: NPCapitalDataInctive
		    },
		    {
		        id: "NL",
		        arrayItem: true,
		        country: 'Netherlands',
		        protest_name: '<span class="protest">Together for Solidarity protest</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'Geert Wilders’ anti-Islam Freedom Party (PVV) won the most seats in the November 22 general election.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demonstrators affirmed their commitment to defending the rule of law and ensuring the country works "for everyone."',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Greenpeace, Amnesty International, LGBTQ rights groups, artists, refugees, anti-racism activists, left-leaning political parties.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "NL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NLCapitalDataActive,
		        inactiveCapitalData: NLCapitalDataInctive
		    },
		    {
		        id: "NL",
		        arrayItem: true,
		        country: 'Netherlands',
		        protest_name: '<span class="protest">Amsterdam climate march</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'Perceptions that the Dutch government has not done enough to address climate change. General election the following week.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for greater action to tackle climate change.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Climate Crisis Coalition, Fridays for Future, doctors and medical professionals.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "NL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NLCapitalDataActive,
		        inactiveCapitalData: NLCapitalDataInctive
		    },
		    {
		        id: "NL",
		        arrayItem: true,
		        country: 'Netherlands',
		        protest_name: '<span class="protest">Extinction Rebellion protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Free',
		        triggers: 'The climate activist group Extinction Rebellion called a protest in The Hague.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the Dutch government’s subsidies of fossil fuels.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Members of the group Extinction Rebellion.',
		        timespan: 'Active, intermittently',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "NL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NLCapitalDataActive,
		        inactiveCapitalData: NLCapitalDataInctive
		    },
		    {
		        id: "NL",
		        arrayItem: true,
		        country: 'Netherlands',
		        protest_name: '<span class="protest">Farmers’ protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Free',
		        triggers: 'The government’s announcement of plans to reduce emissions of nitrogen oxide and ammonia, which are both byproducts of agricultural processes.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over the regulations’ impacts on agriculture and farmers’ livelihoods.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Farmers.',
		        timespan: '1 year;<br />1 month in February 2024',
		        timespan_text: '',
		        outcomes: 'Henk Staghouwer, minister of agriculture, nature and food quality, resigned. In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In July 2024, the EU approved a €105 million subsidy to encourage Dutch livestock farmers to reduce nitrogen pollution. In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "NL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NLCapitalDataActive,
		        inactiveCapitalData: NLCapitalDataInctive
		    },
		    {
		        id: "NL",
		        arrayItem: true,
		        country: 'Netherlands',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-01-01',
		        date_text: "January 2021",
		        freedom: 'Free',
		        triggers: 'Ongoing pandemic-related restrictions.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the economic and social impacts of pandemic-related restrictions. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '14 months ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. ',
		        violent: true,
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "NL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NLCapitalDataActive,
		        inactiveCapitalData: NLCapitalDataInctive
		    },
		    {
		        id: "NZ",
		        arrayItem: true,
		        country: 'New Zealand',
		        protest_name: '<span class="protest">Māori rights protest </span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'The new center-right coalition government has reversed policies which gave prominence to the Māori language, claiming these policies afford preferential treatment on the basis of race. The government also shuttered the Māori Health Authority, which was established to address health inequity. In November 2024, the government also introduced the Treaty Principles Bill, which would reinterpret the Treaty of Waitangi, the founding document that established British and Māori governance of New Zealand. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Promote Māori rights and protections. Oppose the passage of the Treaty Principles Bill.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Māori people, opposition ',
		        timespan: '1 day in December 2023;<br />recurring since February 2024',
		        timespan_text: '',
		        outcomes: 'The Treaty Principles Bill passed its first reading on November 14 due to a political deal. However, most of the lawmakers who initially supported the bill will likely not vote in favor of it again due its unpopularity across the political spectrum, as well as the massive wave of protests against it. ',
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "NZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NZCapitalDataActive,
		        inactiveCapitalData: NZCapitalDataInctive
		    },
		    {
		        id: "NZ",
		        arrayItem: true,
		        country: 'New Zealand',
		        protest_name: '<span class="protest">Farmer protest</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Free',
		        triggers: 'The government introduced plans to tax greenhouse gas emissions from livestock. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over future economic viability of farming profession; frustrations with perceived unfair targeting of agriculture in climate policy.  ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers. ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'In June 2024, the New Zealand government scrapped the proposal and announced that they would remove agriculture from their plans to lower greenhouse gas emissions.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NZCapitalDataActive,
		        inactiveCapitalData: NZCapitalDataInctive
		    },
		    {
		        id: "NZ",
		        arrayItem: true,
		        country: 'New Zealand',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-10-01',
		        date_text: "October 2021",
		        freedom: 'Free',
		        triggers: 'The imposition of vaccine mandates and public health restrictions across New Zealand.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with ongoing coronavirus restrictions and mandates. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially unvaccinated populations. ',
		        timespan: '10 months, intermittently',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "NZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NZCapitalDataActive,
		        inactiveCapitalData: NZCapitalDataInctive
		    },
		    {
		        id: "NZ",
		        arrayItem: true,
		        country: 'New Zealand',
		        protest_name: '<span class="protest">Racial equality protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Free',
		        triggers: 'Death of George Floyd in the United States, sparking protests against local police brutality and racism in New Zealand.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Systemic racism and police violence against indigenous people. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The police commissioner announced that the New Zealand Police would do away with its armed response teams. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NZ", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NZ", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NZCapitalDataActive,
		        inactiveCapitalData: NZCapitalDataInctive
		    },
		    {
		        id: "NI",
		        arrayItem: true,
		        country: 'Nicaragua',
		        protest_name: '<span class="protest">Social security reform protests</span>',
		        date: '2018-04-01',
		        date_text: "April 2018",
		        freedom: 'Partly free',
		        triggers: 'Proposal of reforms to the social security system.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Violent repression of initial protests and broader democratic backslide. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'University students, business sector, general public.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'President Daniel Ortega revoked the changes, but protests did not stop. Police violently repressed the protests.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: false,
		        mapData: {
		        	"id": "NI", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NI", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NICapitalDataActive,
		        inactiveCapitalData: NICapitalDataInctive
		    },
		    {
		        id: "NE",
		        arrayItem: true,
		        country: 'Niger',
		        protest_name: '<span class="protest">Protest against U.S. military presence</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Partly free',
		        triggers: 'The United States has an air base in the northern city of Agadez and over a thousand troops in Niger. The United States has provided training to Niger’s forces for fighting insurgents and the countries have undertaken multiple joint counterinsurgency operations. The United States had already said it would begin plans to withdraw troops from the country before the protests began. Russian troops arrived the week before protests broke out to provide security to the country’s junta. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express frustrations with the U.S. military’s security provisions. Demand U.S. forces leave the country immediately. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'The United States is currently withdrawing troops, with plans to be out of the country by mid-September 2024. Since the United States began to withdraw, Russian troops have arrived in Niger.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NECapitalDataActive,
		        inactiveCapitalData: NECapitalDataInctive
		    },
		    {
		        id: "NE",
		        arrayItem: true,
		        country: 'Niger',
		        protest_name: '<span class="protest">Anti-France protests</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Partly free',
		        triggers: 'France refused to withdraw its nearly 1,500 soldiers from a military base in Niger or its ambassador from Niamey despite the Nigerian junta’s request that France do so.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over continued French presence in Niger.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'French troops withdrew from Niger on October 23.  ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NECapitalDataActive,
		        inactiveCapitalData: NECapitalDataInctive
		    },
		    {
		        id: "NE",
		        arrayItem: true,
		        country: 'Niger',
		        protest_name: '<span class="protest">Coup protest</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'On July 26, soldiers from Niger’s presidential guard deposed President Mohamed Bazoum in a coup d&#39;etat. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger and fear over the loss of political rights implied by the coup.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'On July 28, Spokesman Colonel Major Amadou Abdramane said on state television that the constitution was suspended and General Abdourahmane Tchiani was in charge. ',
		        multiple: true,
		        mapData: {
		        	"id": "NE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NECapitalDataActive,
		        inactiveCapitalData: NECapitalDataInctive
		    },
		    {
		        id: "NE",
		        arrayItem: true,
		        country: 'Niger',
		        protest_name: '<span class="protest">Electoral protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Partly free',
		        triggers: 'Announcement of electoral results that declared ruling party candidate Mohamed Bazoum the victor over former president Mahamane Ousmane. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over lack of genuine political pluralism and perceived electoral manipulation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "NE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NECapitalDataActive,
		        inactiveCapitalData: NECapitalDataInctive
		    },
		    {
		        id: "NE",
		        arrayItem: true,
		        country: 'Niger',
		        protest_name: '<span class="protest">Finance law protests</span>',
		        date: '2017-10-01',
		        date_text: "October 2017",
		        freedom: 'Partly free',
		        triggers: 'The introduction of a finance law that imposed taxes on consumer goods while subsidizing utility companies.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the high cost of living and restrictions on civil society activity.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "NE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NECapitalDataActive,
		        inactiveCapitalData: NECapitalDataInctive
		    },
		    {
		        id: "NG",
		        arrayItem: true,
		        country: 'Nigeria',
		        protest_name: '<span class="protest">Mohbad protest</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Partly free',
		        triggers: 'A popular Afrobeats star known as Mohbad died under suspicious circumstances on September 12.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Grief over the death of Mohbad, suspicion over the cause of the singer’s death. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Fans of Mohbad',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The government launched an investigation into the death of Mohbad and exhumed his body to conduct an autopsy.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NGCapitalDataActive,
		        inactiveCapitalData: NGCapitalDataInctive
		    },
		    {
		        id: "NG",
		        arrayItem: true,
		        country: 'Nigeria',
		        protest_name: '<span class="protest">End Bad Governance protest</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'Inspired by the finance bill protests in Kenya. Growing economic hardship; inflation rate in July 2024 was the highest it had been in Nigeria in twenty-eight years.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesting President Bola Tinubu’s economic reforms, which have included ending currency controls, higher interest rates, and partial removal of electricity and fuel subsidies. Protesters view Tinubu’s reforms as imposing unnecessary economic hardship on ordinary citizens.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public',
		        timespan: 'Ten days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Tinubu made a televised address calling for an end to the protests and offering to open up dialogue with demonstrators. According to Amnesty International, twenty-two protestors were killed. On September 2, ten protestors were charged with treason.',
		        large: true,
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "NG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NGCapitalDataActive,
		        inactiveCapitalData: NGCapitalDataInctive
		    },
		    {
		        id: "NG",
		        arrayItem: true,
		        country: 'Nigeria',
		        protest_name: '<span class="protest">Fuel subsidy strike and protest</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Partly free',
		        triggers: 'The government stopped subsidizing fuel, which caused the price of fuel to rise dramatically.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Belief that the government was not taking sufficient action to support citizens burdened by the fuel subsidy removal. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Members of the Nigeria Labour Congress union',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The day before the demonstration, the government announced a $650 million relief package. President Bola Ahmed Tinubu met with NLC representatives and agreed to continue negotiations.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NGCapitalDataActive,
		        inactiveCapitalData: NGCapitalDataInctive
		    },
		    {
		        id: "NG",
		        arrayItem: true,
		        country: 'Nigeria',
		        protest_name: '<span class="protest">2023 election protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'Nigeria’s Independent National Electoral Commission declared ruling party candidate Bola Ahmed Tinubu the winner of the country’s presidential election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perceived irregularities in the vote. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of opposition candidate Atiku Abubakar.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "NG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NGCapitalDataActive,
		        inactiveCapitalData: NGCapitalDataInctive
		    },
		    {
		        id: "NG",
		        arrayItem: true,
		        country: 'Nigeria',
		        protest_name: '<span class="protest">Cash shortage protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Partly free',
		        triggers: 'The central bank introduced new currency and planned to phase out the old banknotes, creating cash shortages during the transition.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anxieties related to the inaccessibility of the new banknotes and anger over long queues at banks and ATMs. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'The president extended the deadline to use the old currency from January 31 to February 10, then extended it again to April 10. In early March 2023, the Supreme Court ordered the central bank to extend the deadline to December 31.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "NG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NGCapitalDataActive,
		        inactiveCapitalData: NGCapitalDataInctive
		    },
		    {
		        id: "NG",
		        arrayItem: true,
		        country: 'Nigeria',
		        protest_name: '<span class="protest">Democracy Day protest</span>',
		        date: '2021-06-01',
		        date_text: "June 2021",
		        freedom: 'Partly free',
		        triggers: 'Call for protest by activists, imposition of  the ban on Twitter, and rising insecurity across the country.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with rising insecurity, poor governance, and crackdowns on free expression.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "NG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NGCapitalDataActive,
		        inactiveCapitalData: NGCapitalDataInctive
		    },
		    {
		        id: "NG",
		        arrayItem: true,
		        country: 'Nigeria',
		        protest_name: '<span class="protest">SARS protests</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Partly free',
		        triggers: 'Circulation of a video of police brutality. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Widespread belief that the police, especially the elite Special Anti-Robbery Squad (SARS), was involved in gross violations of human rights.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, especially youth.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'The government disbanded the SARS police squad and promised investigations into alleged human rights abuses.',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "NG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NGCapitalDataActive,
		        inactiveCapitalData: NGCapitalDataInctive
		    },
		    {
		        id: "MK",
		        arrayItem: true,
		        country: 'North Macedonia',
		        protest_name: '<span class="protest">Cancer-drug theft protests</span>',
		        date: '2023-09-01',
		        date_text: "Septemeber 2023",
		        freedom: 'Partly free',
		        triggers: 'Media reported that organized crime networks had for years been stealing cancer drugs from the country’s leading oncology treatment center and selling them on the black market. On September 1, authorities raided the clinic to gather evidence.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Outrage over the loss of life caused by the theft of vital cancer drugs.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "MK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MKCapitalDataActive,
		        inactiveCapitalData: MKCapitalDataInctive
		    },
		    {
		        id: "MK",
		        arrayItem: true,
		        country: 'North Macedonia',
		        protest_name: '<span class="protest">Gender identity and equality bills protest</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Partly free',
		        triggers: 'North Macedonia’s Parliament was considering two draft bills: one on gender identity and the other on gender equality. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that liberal legislation related to LGBTQ rights would erode family values.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Members of the Catholic Church.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "MK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MKCapitalDataActive,
		        inactiveCapitalData: MKCapitalDataInctive
		    },
		    {
		        id: "MK",
		        arrayItem: true,
		        country: 'North Macedonia',
		        protest_name: '<span class="protest">EU membership compromise protests</span>',
		        date: '2022-07-01',
		        date_text: "July 2022",
		        freedom: 'Partly free',
		        triggers: 'The government considered a proposal that involved several concessions to North Macedonia’s Bulgarian population as part of a compromise to overcome Bulgarian objections to the country’s EU membership. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Nationalist, anti-EU, and anti-Bulgarian sentiment.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially those affiliated with nationalist and leftist parties.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. The government approved the proposal.',
		        multiple: true,
		        mapData: {
		        	"id": "MK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MKCapitalDataActive,
		        inactiveCapitalData: MKCapitalDataInctive
		    },
		    {
		        id: "MK",
		        arrayItem: true,
		        country: 'North Macedonia',
		        protest_name: '<span class="protest">Opposition leader imprisonment protest</span>',
		        date: '2021-04-01',
		        date_text: "April 2021",
		        freedom: 'Partly free',
		        triggers: 'Call by the opposition VMRO-DPMNE party for protests in response to over a dozen former party officials being sentenced to prison time for their role in a mob invasion of parliament in 2017.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'A belief that the legal proceedings had been politically motivated; demands for a retrial.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, including family members of imprisoned leaders. ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "MK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "MK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: MKCapitalDataActive,
		        inactiveCapitalData: MKCapitalDataInctive
		    },
		    {
		        id: "NO",
		        arrayItem: true,
		        country: 'Norway',
		        protest_name: '<span class="protest">Wind farm protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Free',
		        triggers: 'Two wind farms in central Norway continued to operate despite an earlier Supreme Court ruling that the wind farms violated the rights of Indigenous people.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Disruptions to Indigenous ways of life, especially reindeer herding.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Sami people, Greta Thunberg. ',
		        timespan: '9 days',
		        timespan_text: '',
		        outcomes: 'The government apologized to Sami groups for the construction of the turbines, calling it a &ldquo;human rights violation,&rdquo; but did not commit to shutting down the wind farms.',
		        multiple: false,
		        mapData: {
		        	"id": "NO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "NO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: NOCapitalDataActive,
		        inactiveCapitalData: NOCapitalDataInctive
		    },
		    {
		        id: "OM",
		        arrayItem: true,
		        country: 'Oman',
		        protest_name: '<span class="protest">Economic protests</span>',
		        date: '2021-05-01',
		        date_text: "May 2021",
		        freedom: 'Not free',
		        triggers: 'A spate of mass layoffs, rising unemployment, and the imposition of new taxes. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Increasing economic insecurity, especially for young people, amid declining oil revenues.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public, especially youth.',
		        timespan: '1 week',
		        timespan_text: '.',
		        outcomes: 'Sultan Haitham opened hiring for thousands of government jobs and unveiled a plan to drive growth in private sector employment.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "OM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "OM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: OMCapitalDataActive,
		        inactiveCapitalData: OMCapitalDataInctive
		    },
		    {
		        id: "OM",
		        arrayItem: true,
		        country: 'Oman',
		        protest_name: '<span class="protest">Unemployment protest</span>',
		        date: '2018-01-01',
		        date_text: "January 2018",
		        freedom: 'Not free',
		        triggers: 'Failure of government job programs to adequately reduce unemployment among citizens.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over chronically high levels of unemployment among Omani youth and feelings of economic insecurity amid declining oil revenues.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public, especially youth.',
		        timespan: '1 year',
		        timespan_text: 'Intermittently.',
		        outcomes: 'The government announced a job creation program and the establishment of a national center to address unemployment.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "OM", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "OM", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: OMCapitalDataActive,
		        inactiveCapitalData: OMCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">PECA amendment protests</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Partly free',
		        triggers: 'New amendments to the Prevention of Electronic Crimes Act (PECA) that target the spread of false information online. The amendments include the establishment of new regulatory and investigative bodies, as well as harsher punishments for spreading false information.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over PECA amendments, which many protesters believe significantly threatens free speech. Concern that amendments may potentially target journalists and activists.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Journalists',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Protest against ban on Pashtun Tahafuz Movement</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'On October 6, the Pakistani government banned the Pashtun Tahafuz Movement (PTM), a social rights group for the Pashtun ethnic minority, on the grounds that the PTM’s activities threatened the security of the country. The ban was imposed just days ahead of the Pashtun National Jirga (a traditional Pashtun assembly), which was scheduled for October 11.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest the government ban of the PTM. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'PTM supporters',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. At least three people were killed during the clashes between protesters and the police. Despite the ban, the PTM was able to hold the jirga from October 11–13.',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Protest against killing of blasphemy suspect</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Partly free',
		        triggers: 'The killing of Dr. Shahnawaz Kumbhar, who was in custody after being accused of blasphemy. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Condemn the killing of Kumbhar and demand a judicial inquiry into the situation. Call for the officers involved to be arrested and charged. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Civil rights groups, including the Sindh Rawadari March Action Committee',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'To prevent further demonstrations, the Karachi government imposed Section 144 of the Code of Criminal Procedure, which bans public assemblies for a limited period. At least one person was killed during a clash between counterprotesters and the police. On October 31, the Federal Investigation Agency filed a report to investigate the incident.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Protest against e-transfer policy</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Partly free',
		        triggers: 'The Higher Education Department (HED) announced an e-transfer policy on August 6, 2024. According to the HED, the goal of this policy is to create transparency during the HED’s efforts to transfer professors to understaffed schools in remote regions.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize the new transfer system, the relocation of assistant professors, and the filling of vacant teaching positions. Arguing that promotions should be within the same school and separated from the e-transfers. Also protest how poorly the HED handled various issues reported by professors.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'College professors and lecturers',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. The HED and Punjab Professors and Lecturers Association (PPLA) formed a six-person committee to address the dislocation of assistant professors.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Protest against counterrerrorism operation</span>',
		        date: '2024-07-01',
		        date_text: "July 2024",
		        freedom: 'Partly free',
		        triggers: 'In June 2024, the Pakistan government approved military plans against extremist violence and terrorism in the area.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesting against counterterrorism military operations and lack of  security provided by the authorities. Protesters are opposed to the plans because they fear it will cause disruptions to their lives and their businesses.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Swat valley residents',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Bannu peace march</span>',
		        date: '2024-07-01',
		        date_text: "July 2024",
		        freedom: 'Partly free',
		        triggers: 'Militant attack in Bannu on July 15, which killed eight people.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Initially, protesting against Pakistan’s military operation to root out militants along Afghan border. Protesters are demanding peace after twenty years of military operations in the region. Also protesting the actions of the Pakistani military after they fired on protesters.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public',
		        timespan: '1 week in July 2024;<br />1 day in October 2024',
		        timespan_text: '',
		        outcomes: 'The Pakistani military opened fire on protesters, leading to the death of at least one person. On July 26, Khyber Pakhtunkhwa Chief Minister Ali Amin Gandapur announced that there would be no military operation in the region. Following protesters’ requests, the regional government also requested a judicial inquiry into the shooting incident that occurred during the protests.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Protest by Baloch ethnic group</span>',
		        date: '2024-07-01',
		        date_text: "July 2024",
		        freedom: 'Partly free',
		        triggers: 'Arrests of Baloch people and growing exploitation of resources.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesting the oppression and mistreatment of the Baloch ethnic minority group by Pakistani government. Also protesting the exploitation of resources in the region of Balochistan, as the city of Gwadar has been used as a key port city for China’s Belt and Road Initiative.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Baloch community, Baloch Yakjehti Committee (BYC)',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'At least three protestors and one Pakistani solider were killed. The Balochistan government agreed to meet the protestors’ demands, which included the release of detained activists.',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Protest for the release of Imran Khan</span>',
		        date: '2024-05-01',
		        date_text: "May 2024",
		        freedom: 'Partly free',
		        triggers: 'Former prime minister Imran Khan has been in jail since January 2024 on charges of corruption. His party, Pakistan Tehreek-e-Insaf, remains popular. His supporters and legal team claim that his rights were violated during his trial. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the release of Imran Khan.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Pakistan Tehreek-e-Insaf supporters',
		        timespan: 'Active (intermittently since May 2024)',
		        timespan_text: '',
		        outcomes: 'Though Pakistan’s courts had acquitted Khan of his charges by July, authorities issued new arrest warrants relating to the May 2023 protests to keep Khan in jail. In November 2024, the government deployed the army against protestors entering Islamabad. At least six security forces and four civilians were killed. In January 2024, Khan was sentenced to fourteen years in prison for corruption.',
				active: true,
				long: true,
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Anti-Isa protests</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'Chief Justice of Pakistan Qazi Faez Isa dismissed charges of blasphemy against a member of the Ahmadi community jailed for distributing Islamic literature.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters claim Isa deviated from the constitutional definition of Muslim, which excludes Ahmadis, in his ruling. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Muslims, Tehreek-e-Labbaik Pakistan, supporters of Islamist parties',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The court released a statement saying the charges against Isa are "absolutely wrong" and deploring the "vicious campaign" against him. ',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">2024 election protests</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'Imran Khan’s Pakistan Tehreek-e Insaf (PTI) party won a plurality of seats in the February 8 parliamentary elections, but two rival parties—Nawaz Sharif’s Pakistan Muslim League-Nawaz and Bilawal Bhutto Zardari’s Pakistan People’s Party—are working to reach an agreement to form a coalition government. Khan could not run in the election due to criminal convictions against him, which he claims are politically motivated. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters from Khan’s party say the election results were manipulated—though the Election Commission of Pakistan denies these allegations. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'PTI supporters, PTI officials',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. Despite protests, the new parliament swore in on February 29, 2024. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">March against extrajudicial killings and excessive arrests of Baluch men</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Partly free',
		        triggers: 'Balaach Mola Bakhsh, a man in a remote town of Balochistan Province, was arrested for alleged possession of explosives and was killed in a shootout between security officials and insurgents—which Bakhsh’s family claims is a false flag.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce the extrajudicial killings and excessive arrests of Baluch men by marching hundreds of miles from Balochistan to Islamabad. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Baluch',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'On December 20, protesters were met with water cannons and batons as they approached Islamabad. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Passport-free travel protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Partly free',
		        triggers: 'Citing security concerns and mass immigration from Afghanistan, Pakistan ended "easement rights," which had allowed residents living along the Durand Line border to cross between the two countries without passports.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the government allow passport-free travel between Pakistan and Afghanistan, citing violation of the Durand Line Treaty, which allows Afghan and Pashtun tribes on either side to travel across the border. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Members of Afghan and Pashtun tribes along the border, unions',
		        timespan: '9 months (on pause from July 2024)',
		        timespan_text: '',
		        outcomes: 'After discussions with protest leaders and military leaders, local tribesmen were given permission to cross the border with a national identity card. The Pakistani government has not issued an official statement.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Electricity price protests</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Partly free',
		        triggers: 'Pakistani people started receiving higher electricity bills because Pakistan had agreed to levy an electricity tariff as part of an IMF bailout.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the jump in electricity prices amid already high inflation and other economic woes.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'In 2023, Interim Prime Minister Anwaar ul Haq Kakar held an emergency ministers’ meeting, during which he directed authorities to take “concrete steps” within forty-eight hours to cut electricity bills. The new government under Prime Minister Shehbaz Sharif, however, further raised the electricity prices and added additional taxes to the increased prices.',
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Quran desecration protests</span>',
		        date: '2023-07-01',
		        date_text: "July 2023",
		        freedom: 'Partly free',
		        triggers: 'On June 28, Salwan Momika, an Iraqi immigrant living in Sweden, tore pages out of a Quran and burned them in front of a mosque in Stockholm. The incident took place on Eid al-Adha, a major Islamic holiday. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the burning of the Quran, which is deeply offensive to Muslim people.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Anti-violence protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Partly free',
		        triggers: 'Assailants shot and killed the driver of a school bus and injured two children in October 2022; two explosions at a compound for counterterrorism police killed at least 15 people and wounded dozens more in April 2023.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of &ldquo;growing militant activities&rdquo; in the district.',
		        size: 15000,
		        size_text: '15,000',
		        participants: 'Residents of Swat District.',
		        timespan: '2 days in October 2022;<br />2 days in April 2023',
		        timespan_text: '',
		        outcomes: 'The government promised to arrest the culprits and take up custody of the children of the van driver who had been killed.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Kissan Ittehad protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Partly free',
		        triggers: 'A government hike in electricity tariffs; high inflation.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of financial ruin for farmers in the wake of catastrophic flooding.',
		        size: 25000,
		        size_text: '25,000',
		        participants: 'Farmers.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The government conceded to protester demands, agreeing to cancel fuel adjustment charges and delay the payment of electricity bills.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Fuel price protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Partly free',
		        triggers: 'The government removed fuel subsidies to try to salvage an IMF agreement, increasing prices. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over worsening economic conditions and rising inflation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Imran Khan protests</span>',
		        date: '2022-04-01',
		        date_text: "April 2022",
		        freedom: 'Partly free',
		        triggers: 'Imran Khan was ousted in a no-confidence vote on April 10. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Khan’s supporters protested the new government’s efforts to remove him from politics, the charges levied against Khan that protesters viewed as politically motivated, the entrenched role of the military in the country’s politics, and the crackdown of dissent.  ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Supporters of Imran Khan.',
		        timespan: '13 months',
		        timespan_text: '',
		        outcomes: 'The Election Commission of Pakistan banned Imran Khan from holding office for five years on October 21. Imran Khan was shot in an apparent assassination attempt on November 3. Pakistan’s paramilitary police arrested Khan on corruption charges on May 9, 2023. A trial court sentenced Imran Khan to three years in prison on August 5.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">No-confidence motion protests</span>',
		        date: '2022-03-01',
		        date_text: "March 2022",
		        freedom: 'Partly free',
		        triggers: 'The introduction and approval of a no-confidence motion against Prime Minister Imran Khan. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Supporters of the no-confidence motion viewed the Khan government as corrupt and blamed government leaders including Khan for the country’s economic problems.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opponents of Imran Khan',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Imran Khan was ousted in a no-confidence vote on April 10. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Tehreek-e-Labbaik protests</span>',
		        date: '2021-10-01',
		        date_text: "October 2021",
		        freedom: 'Partly free',
		        triggers: 'A call by the leadership of Tehreek-e-Labbaik Pakistan (TLP), an outlawed far-right group, for a “long march” from Lahore to Islamabad.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the imprisonment of TLP leader Saad Rizvi and the ongoing presence of the French ambassador.  ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'Members of TLP.',
		        timespan: '10 days',
		        timespan_text: '',
		        outcomes: 'The government and the TLP reached a negotiated settlement, which has yet to be made public. ',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Youth killing protests</span>',
		        date: '2021-03-01',
		        date_text: "March 2021",
		        freedom: 'Partly free',
		        triggers: 'Discovery of the bodies of four young men in a ditch outside the city of Bannu and accusations that the security services had tortured and murdered them.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Impunity for violence by the security forces. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Opposition protests</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Partly free',
		        triggers: 'Call by a coalition of opposition parties for protests against the government of Prime Minister Imran Khan.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Corruption, influence of the military in domestic politics, and suspicion that the 2018 general election had been manipulated.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, especially adherents of opposition political parties.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Islamabad sit-in protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Partly free',
		        triggers: 'Antigovernment march from Karachi to Islamabad to hold a sit-in on the capital’s main highway.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic crisis, rising inflation, and suspicion that the 2018 election was rigged.',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'Conservative Islamist party, teachers and students from religious schools.',
		        timespan: '2 weeks ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Blasphemy protests</span>',
		        date: '2018-11-01',
		        date_text: "November 2018",
		        freedom: 'Partly free',
		        triggers: 'The acquittal of a Christian woman in a highly publicized blasphemy case.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Religious tensions between Christians and Muslims, and a belief that the government was inadequately enforcing blasphemy laws.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially conservative Muslims.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Pashtun protection movement protests</span>',
		        date: '2018-02-01',
		        date_text: "February 2018",
		        freedom: 'Partly free',
		        triggers: 'The extrajudicial killing of ethnic Pashtun Naqeebullah Mehsud by police.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over persistent human rights violations by security forces in minority areas, especially Pashtun regions.',
		        size: 50001,
		        size_text: '>50,000',
		        participants: 'Ethnic Pashtuns.',
		        timespan: '1 year ',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PK",
		        arrayItem: true,
		        country: 'Pakistan',
		        protest_name: '<span class="protest">Oath protests</span>',
		        date: '2017-11-01',
		        date_text: "November 2017",
		        freedom: 'Partly free',
		        triggers: 'A proposal to change Pakistan’s electoral oath that would alter a reference to the Prophet Muhammad.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the perceived secularization of the Pakistani government.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially conservative Muslims.',
		        timespan: '1 month ',
		        timespan_text: '',
		        outcomes: 'The proposed changes were quickly revoked, a key Islamic leader was released from prison, and the law minister was forced out.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PKCapitalDataActive,
		        inactiveCapitalData: PKCapitalDataInctive
		    },
		    {
		        id: "PA",
		        arrayItem: true,
		        country: 'Panama',
		        protest_name: '<span class="protest">Anti-mining protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Free',
		        triggers: 'Canadian mining company First Quantum Minerals was awarded a contract by President Laurentino Cortizo that allowed the company to operate a copper mine in Panama for at least 20 years. Opponents alleged corruption. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for the contract to be nullifed for fear of deforestation, enviornmental damage, and the ramifications of relinquishing control over critical minerals to a foreign coorporation. Claims the contract was overly generous and  forged through corruption. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'SUNTRACS trade union.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'The Supreme Court ruled the twenty-year contract was unconstitutional, and  Cortizo directed First Quantum to stop mining operations in late November 2023. Protests have continued sporadically since the ruling.  ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PACapitalDataActive,
		        inactiveCapitalData: PACapitalDataInctive
		    },
		    {
		        id: "PA",
		        arrayItem: true,
		        country: 'Panama',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Free',
		        triggers: 'Rising prices for key goods including fuel and food staples.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with worsening economic conditions; anger over poverty, inequality, and government corruption. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'President Laurentino Cortizo announced a series of price control measures including subsidies to freeze the cost of fuel at $3.25 per gallon and regulations on 72 food items with the goal of reducing food costs by 30%.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PACapitalDataActive,
		        inactiveCapitalData: PACapitalDataInctive
		    },
		    {
		        id: "PG",
		        arrayItem: true,
		        country: 'Papua New Guinea',
		        protest_name: '<span class="protest">Pay cut protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Partly free',
		        triggers: 'The government had a dispute with police officers and other public services regarding wages, which protesters claim resulted in a pay cut. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand a pay raise and insist the government not raise taxes. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Police officers, public servants',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The government declared a state of emergency and ordered the military to  restore order amid rioting. Prime Minister James Marape said that the wage cut was the result of fixing a computer glitch which accidentally paid out an extra $100 in the paychecks of public servants. Marape also affirmed that the government has no intention of raising taxes.',
		        violent: true,
		        multiple: false,
		        mapData: {
		        	"id": "PG", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PG", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PGCapitalDataActive,
		        inactiveCapitalData: PGCapitalDataInctive
		    },
		    {
		        id: "PY",
		        arrayItem: true,
		        country: 'Paraguay',
		        protest_name: '<span class="protest">2023 election protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Partly free',
		        triggers: 'Paraguayo Cubas came in third place in the country’s April 2023 presidential election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters perceived the vote to be marred by fraud and demanded a recount.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Supporters of Paraguayo Cubas.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PYCapitalDataActive,
		        inactiveCapitalData: PYCapitalDataInctive
		    },
		    {
		        id: "PY",
		        arrayItem: true,
		        country: 'Paraguay',
		        protest_name: '<span class="protest">Coronavirus response protests</span>',
		        date: '2021-03-01',
		        date_text: "March 2021",
		        freedom: 'Partly free',
		        triggers: 'A surge in coronavirus infections, strains on the health system, and a slow vaccine rollout.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with corruption in the public health system and an ineffectual response to the coronavirus pandemic. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, including medical professionals.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'Three ministers resigned in response to the protests.',
		        significant: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "PY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PYCapitalDataActive,
		        inactiveCapitalData: PYCapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Strike against extortion</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Partly free',
		        triggers: 'Rising number of deaths in attacks against transportation workers, construction workers, and business owners connected to extortion from gangs.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over the government’s indifference to the gang attacks and the government’s inaction against growing crime.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Bus drivers. Later, construction workers, transportation workers, and other trade unions also joined.',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Following the first wave of demonstrations, the government declared a sixty-day state of emergency in twelve districts around Lima and dispatched armed forces to address the gang crimes.',
				active: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">"Dina is a murderer" protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Partly free',
		        triggers: 'President Dina Boluarte visited the southern region of Ayacucho, where ten people were killed in anti-government protests in 2022.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce Boluarte’s visit to the region and demand accountability. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. Protests continue as Boluarte faces a corruption probe. ',
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Dina Boluarte protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Partly free',
		        triggers: 'Congress removed Pedro Castillo from the presidency on December 7, 2022. He was then arrested and sentenced to eighteen months of pretrial detention on rebellion charges. Vice President Dina Boluarte was sworn in as president that same day.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over Pedro Castillo’s arrest and Dina Boluarte’s ascension to the presidency.',
		        size: 20001,
		        size_text: '>20,000',
		        participants: 'Opponents of President Boluarte.',
		        timespan: '3.5 months beginning December 2022;<br />1 month in July 2023',
		        timespan_text: '',
		        outcomes: 'On December 16, 2022, Education Minister Patricia Correa and Culture Minister Jair Perez resigned.',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Pedro Castillo protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Prosecutors opened six criminal investigations into President Pedro Castillo for alleged corruption.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over perceived corruption and economic crisis.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Peru’s Congress summoned Castillo to respond to accusations of “moral incapacity” to govern. Facing impeachment, Castillo attempted to dissolve Congress and install an emergency government. The Constitutional Court rejected this dissolution. Congress impeached Castillo. Police then arrested Castillo. Dina Boluarte, who was serving as vice president, was sworn in as president. One week later, Boluarte declared a national state of emergency. ',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Trucker and farmer protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices of fuel and shortages of fertilizer.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of economic hardship for workers dependent on the price of fuel and fertilizer.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Truckers and farmers.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Illegal mining sector protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Partly free',
		        triggers: 'National police seized and destroyed equipment and supplies used in illegal mining operations.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Lack of economic opportunity for those involved in the illegal mining sector.',
		        size: 1000,
		        size_text: '1,000',
		        participants: 'Miners.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Rising price protests</span>',
		        date: '2022-04-01',
		        date_text: "April 2022",
		        freedom: 'Partly free',
		        triggers: 'Rapidly rising prices for key goods including fuel and food staples.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic discontent as inflation makes many goods unaffordable; anger at the Pedro Castillo administration and Congress for failing to adequately address rising prices and poverty. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Electoral protests</span>',
		        date: '2021-06-01',
		        date_text: "June 2021",
		        freedom: 'Partly free',
		        triggers: 'Announcement of very close electoral results between presidential candidates Keiko Fujimori and Pedro Castillo in the June 6 presidential election.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns about electoral manipulation, and high levels of political polarization. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'Supporters of Castillo and Fujimori.',
		        timespan: '6 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Farm protests</span>',
		        date: '2020-12-01',
		        date_text: "December 2020",
		        freedom: 'Partly free',
		        triggers: 'An Agrarian Promotion Law that gave exporting companies tax benefits but led to cuts in labor rights and low wages for farm workers.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the rights of big companies being prioritized over the rights of workers who were receiving salaries as low as 39 soles ($11) per day. ',
		        size: 301,
		        size_text: '>300',
		        participants: 'Farm workers. ',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'Congress repealed the law, but protests resumed when Congress could not reach a consensus on the law’s replacement, specifically on a clause for higher base salaries for workers.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Impeachment protests</span>',
		        date: '2020-11-01',
		        date_text: "November 2020",
		        freedom: 'Partly free',
		        triggers: 'A surprise vote by Peru’s opposition-led Congress to impeach and remove incumbent president Martin Vizcarra on the grounds of “moral incapacity.” ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger that Congress would remove the popular president on dubious grounds in the midst of a national crisis. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Interim president Manuel Merino, a key supporter of the impeachment, resigned after only five days in office. He was succeeded by Francisco Sagasti.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PE",
		        arrayItem: true,
		        country: 'Peru',
		        protest_name: '<span class="protest">Fujimori pardon protests</span>',
		        date: '2017-12-01',
		        date_text: "December 2017",
		        freedom: 'Partly free',
		        triggers: 'Then president Pedro Pablo Kuczynski’s pardon of former president Alberto Fujimori, who was convicted for corruption and human rights violations.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Controversy over Fujimori’s authoritarian legacy, and concerns that Kuczynski had pardoned him as part of a deal to survive an impeachment effort over Kuczynski’s connections to the Brazilian construction company Odebrecht.',
		        size: 60000,
		        size_text: '60,000',
		        participants: 'General public.',
		        timespan: '3 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PECapitalDataActive,
		        inactiveCapitalData: PECapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">“Stop Patowładzy” protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'Growing rifts between the current government and the nationalist-conservative opposition party, Law and Justice (PiS).',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Assert that Prime Minister Donald Tusk’s government is violating the rule of law. Allege that the government is "representing foreign interests" and not acting in the interests of the Polish people.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'PiS party and supporters',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Farmers’ protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'The EU waived duties and quotas for imports from Ukraine, which put pressure on prices for EU farmers, who must follow EU environmental standards. Ongoing negotiations for a trade deal between the EU and the South American Mercosur bloc. New EU environmental regulations, including new subsidy rules which require 4 percent of farmland to be fallow.  ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against EU environmental regulations and cheap food imports, specifically from Ukraine. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Farmers',
		        timespan: '4 months;<br />1 day in Jan 2025',
		        timespan_text: '',
		        outcomes: 'Prime Minister Donald Tusk said on February 27, 2024, that he could not rule out widening a national ban on Ukrainian grain imports if the EU does not act to protect the EU market. Tusk and President Andrzej Duda also spoke to protesters in Prague, where they said the EU must implement policy changes to protect EU farmers.  On March 15, the EU proposed legislation which, if passed, will ease the environmental rules of the Common Agricultural Policy. The Polish government plans to pay $522 million in subsidies to farmers to compensate them for low market prices for grain.  ',
		        significant: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Anti-Tusk march</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'The former PiS government’s Interior Minister Mariusz Kamiński and Deputy Interior Minister Maciej Wasik were arrested after being convicted of abuse of power. The Polish court barred Prime Minister Donald Tusk’s government from probing the Central Bank Head. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protest against changes to state media and imprisonment of ministers under Tusk. Express dissatisfaction with and opposition to Tusk and the Civic Platform. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'PiS supporters',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">State media sit-in</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'The Law and Justice party (PiS) failed to win a majority of seats in the October 2023 parliamentary elections. Prime Minister Donald Tusk and the Civic Coalition party formed a coalition government, and in so doing assumed control over the public broadcasting stations, which were highly politicized when controlled by PiS. Tusk has announced significant changes, including suspending the channel TVP Info, which was a bullhorn for PiS.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'PiS sit-in at state broadcasting office to protest their loss of control over state media.  ',
		        size: 99,
		        size_text: '<100',
		        participants: 'Law and Justice politicians',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest. ',
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Trucker border blockade</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'The EU waved entry restrictions on Ukrainian lorries following the Russian invasion of Ukraine.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration among Polish truck drivers who feel the markets they once supplied are being taken over by Ukrainian truck drivers due to their low prices. Demand a reintroduction of the permit system to cap the number of Ukrainian trucks entering Poland. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Truckers, farmers',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The truckers reached a deal with the Polish government to stop the blockade in exchange for talks between the Polish government regarding easing the situation for EU-registered drivers in Ukraine, talks with the EU regarding its agreement with Ukraine, and talks with the European Commission on financial support for Polish truckers. ',
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">March of a Million Hearts</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Free',
		        triggers: '2023 Polish parliamentary elections on October 15.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Mobilization of voters hoping to defeat the Law and Justice Party in upcoming election.',
		        size: 1000000,
		        size_text: '1,000,000',
		        participants: 'Donald Tusk, Civic Platform ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'PiS lost its majority in the Sejm, though they maintain a plurality. President Andrzej Duda (PiS) is allowing his party to attempt to form a government, though this will likely prove unsuccessful. ',
		        significant: true,
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Abortion restriction protest</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Free',
		        triggers: 'Dorota Lalik, 33, died on May 24 of sepsis related to a miscarriage in her fifth month of pregnancy. Her family believed that she could have survived if she had been offered a abortion.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over laws in Poland that severely restrict access to legal abortion.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Abortion rights activists.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Anti-Law and Justice Party protest</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Free',
		        triggers: 'The government proposed a bill that would establish a commission to investigate anyone suspected of being subject to Russian influence and potentially bar them from running for public office.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the authoritarian direction in which the ruling Law and Justice Party was taking the country.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Supporters of the opposition.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'President Andrzej Duda, an ally of the Law and Justice Party, said he would propose amendments to the Russian influence law.',
		        significant: true,
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Ukraine grain protests</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Free',
		        triggers: 'European Commission policies to allow Ukrainian farmers to export grains through EU countries—intended to counteract the reduction in grain exports caused by Russian blockades of Ukrainian Black Sea ports—resulted in a glut of Ukrainian grains in Polish markets. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over lowered prices of domestically produced grains and lower availability of local warehouses.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The European Commission announced an agreement with Hungary, Slovakia, Romania, Bulgaria, and Poland to provide €100 million in compensation to their local farmers and require that certain Ukrainian exports can only enter those five countries if they are already bound for another destination. When the deal expired in September, Poland continued to bar Ukrainian grain.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Education bill protests</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Free',
		        triggers: 'The parliament resumed efforts to pass a law centralizing control over schools. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of greater top-down control from the conservative ruling party over matters such as sex education.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Students, teachers, parents, and NGOs.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'On December 15, President Andrzej Duda announced that he would veto the bill.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">EU Protest</span>',
		        date: '2021-10-01',
		        date_text: "October 2021",
		        freedom: 'Free',
		        triggers: 'A ruling by Poland’s Constitutional Tribunal that contested the supremacy of EU law. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern that the ruling Law and Justice Party government would push for Poland to leave the European Union; frustration with the rising tensions between the Polish government and the EU. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Media law protest</span>',
		        date: '2021-08-01',
		        date_text: "August 2021",
		        freedom: 'Free',
		        triggers: 'PiS party-led efforts to enact a law restricting foreign ownership of media outlets in Poland.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with declining media freedom under the PiS government. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public.',
		        timespan: '1 day<br />in August 2021;<br />1 week<br />in December 2021',
		        timespan_text: '',
		        outcomes: 'The governing coalition collapsed as a result of disagreements over tax policy and the media bill; in December 2021, President Andrzej Duda vetoed the media ownership bill. ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Abortion rights protests</span>',
		        date: '2020-10-01',
		        date_text: "October 2020",
		        freedom: 'Free',
		        triggers: 'A ruling by Poland’s Constitutional Court that deemed abortion illegal in all cases except those involving rape, incest, or a threat to the mother’s life.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger at perceived restrictions on women’s rights and concern over political capture of the judiciary.',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, especially women.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">“Muzzle law” protests</span>',
		        date: '2019-12-01',
		        date_text: "December 2019",
		        freedom: 'Free',
		        triggers: 'Draft law that would permit government sanctions against judges who question the legitimacy of the ruling party’s judicial reforms and that would prohibit judges from engaging in any political activity.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Erosion of judicial independence. ',
		        size: 30000,
		        size_text: '30,000',
		        participants: 'Polish judges and lawyers, judges from across Europe.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PL",
		        arrayItem: true,
		        country: 'Poland',
		        protest_name: '<span class="protest">Judicial reform protests</span>',
		        date: '2018-07-01',
		        date_text: "July 2018",
		        freedom: 'Free',
		        triggers: 'Signing of a law that forced many Supreme Court justices into early retirement and created a judicial disciplinary chamber. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Erosion of judicial independence and democratic backslide. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PLCapitalDataActive,
		        inactiveCapitalData: PLCapitalDataInctive
		    },
		    {
		        id: "PT",
		        arrayItem: true,
		        country: 'Portugal',
		        protest_name: '<span class="protest">Police brutality protest</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Free',
		        triggers: 'On October 21, police shot and killed Odair Moniz, a forty-three-year-old Black man. While the police initially claimed that Moniz had been holding a knife, Portuguese media later clarified that Moniz had not been holding a weapon.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over long-standing issues of police brutality and racism. Demand justice for Moniz. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '5 days',
		        timespan_text: '',
		        outcomes: 'Counterprotesters, most of whom were supporters of the Chega party, demonstrated in defense of the police. On October 25, the officer who killed Moniz was indicted on murder charges.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "PT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PTCapitalDataActive,
		        inactiveCapitalData: PTCapitalDataInctive
		    },
		    {
		        id: "PT",
		        arrayItem: true,
		        country: 'Portugal',
		        protest_name: '<span class="protest">Housing crisis protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'Growing housing and rent prices caused by factors such as a shortage of affordable housing, the influx of wealthy foreigners seeking tax breaks, and a tourism boom.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over unaffordable housing prices. While Prime Minister Luís Montenegro’s government has announced a €2 billion package to build 33,000 homes by 2030, demonstrators are doubtful that the government will be able to deliver on its promises.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "PT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PTCapitalDataActive,
		        inactiveCapitalData: PTCapitalDataInctive
		    },
		    {
		        id: "PT",
		        arrayItem: true,
		        country: 'Portugal',
		        protest_name: '<span class="protest">Police protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'In November 2023, Prime Minister António Costa approved hazard pay raises for the criminal investigation police. The raise amounts to 700 euros a month, but was not given to he Public Security Police or the National Republican Guard. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Public Security Police and National Republican Guard demand the same hazard pay raise given to the criminal investigation police. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Public Security Police, National Republican Guard',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests. ',
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PTCapitalDataActive,
		        inactiveCapitalData: PTCapitalDataInctive
		    },
		    {
		        id: "PT",
		        arrayItem: true,
		        country: 'Portugal',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices in conjunction with stagnating wages.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic anxieties related to inflation.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 months beginning in February 2023,<br />active as of September 2024',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PTCapitalDataActive,
		        inactiveCapitalData: PTCapitalDataInctive
		    },
		    {
		        id: "PT",
		        arrayItem: true,
		        country: 'Portugal',
		        protest_name: '<span class="protest">Animal rights protest</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'Portugal’s public prosecutors had asked the constitutional court to declare unconstitutional a law that criminalizes the mistreatment of pets.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fear that the decision deeming the law unconstitutional would result in inadequate protections for pets.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, animal rights activists.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PTCapitalDataActive,
		        inactiveCapitalData: PTCapitalDataInctive
		    },
		    {
		        id: "PT",
		        arrayItem: true,
		        country: 'Portugal',
		        protest_name: '<span class="protest">Teacher wage protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'Negotiations over wages and working conditions between teachers unions and the Ministry of Education broke down.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anxieties about stagnating wages amid rising prices.',
		        size: 20001,
		        size_text: '>20,000',
		        participants: 'Teachers.',
		        timespan: '3.5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "PT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PTCapitalDataActive,
		        inactiveCapitalData: PTCapitalDataInctive
		    },
		    {
		        id: "PT",
		        arrayItem: true,
		        country: 'Portugal',
		        protest_name: '<span class="protest">Police brutality protest</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Free',
		        triggers: 'Death of George Floyd in the United States, sparking protests against local police brutality and racism in Portugal.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Police brutality, systemic racism, and lack of government recognition of systemic racism.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "PT", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "PT", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: PTCapitalDataActive,
		        inactiveCapitalData: PTCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Election annulment protest</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Free',
		        triggers: 'The Romanian Constitutional Court annulled the 2024 presidential elections because of alleged election interference.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over election annullment, which presidential candidate Călin Georgescu and his supporters claimed was an "officialized coup." Demand that run-off elections be held on the originally scheduled date.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Georgescu supporters, general public',
		        timespan: '1 day in December 2024;<br />1 day in January 2025',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Anti-Georgescu protests</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'Călin Georgescu, a far-right presidential candidate, won the first round of Romania’s presidential elections.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce Georgescu for his extremism, which includes antisemitic and pro-Russian/anti-NATO views. Accuse Georgescu of being a fascist or a fascist sympathizer.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, youth',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'On December 4, the Supreme Council of National Defense declassified documents regarding alleged election interference. On December 6, the Constitutional Court annulled the elections entirely. New presidential elections are set to be held in May 2025.',
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Farmers’ and truck drivers’ protest 2024</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'High insurance rates and slow subsidy payments for farmers and truckers. Frustration with Ukrainian lorries, which truckers claim undercut their market status, and the cheap import of Ukrainian agricultural goods, which is hurting Romanian farmers.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the government address high insurance premiums, excise taxes on diesel, and enact loan moratoriums. Also demand a shorter wait time at border crossings. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'Prime Minister Marcel Ciolacu promised a package including compensation of 100 euros (up to 280,000 euros) per hectare to farmers for losses due to the war in Ukraine and some reforms to address truckers’ demands. In early February, the government also announced that it would take steps to increase subsidies for diesel, address high insurance rates, expedite subsidy payments, and reduce wait times at border crossings.  ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Teacher strike and protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Free',
		        triggers: 'Last-minute negotiations between education unions and the government failed.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic anxieties related to low teacher pay, frustrations over low investment in the country’s education system.',
		        size: 15001,
		        size_text: '>15,000',
		        participants: 'Teachers.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'On June 12, the government announced that it will raise teachers’ pay by 25 percent from June 2023 through an emergency decree. It will also give teaching staff one-off European Union-funded annual bonuses until 2027. Prime Minister Nicolae Ciucă resigned as part of a ruling coalition deal for a rotating premiership.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Ukraine grain protests</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Free',
		        triggers: 'European Commission policies to allow Ukrainian farmers to export grains through EU countries—intended to counteract the reduction in grain exports caused by Russian blockades of Ukrainian Black Sea ports—resulted in a glut of Ukrainian grains in Romanian markets.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over lowered prices of domestically produced grains and lower availability of local warehouses. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'On April 28, the European Commission announced an agreement in principle with Hungary, Slovakia, Romania, Bulgaria, and Poland to provide €100 million in compensation to their local farmers and require that Ukrainian exports of wheat, maize, oilseed, and sunflower seed can only enter those five countries if it is already bound for another destination.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Cost of living protest</span>',
		        date: '2022-10-01',
		        date_text: "October 2022",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices, especially of fuel and food. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with perceived government inaction to combat inflation. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public. ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Covid restriction protest</span>',
		        date: '2021-10-01',
		        date_text: "October 2021",
		        freedom: 'Free',
		        triggers: 'The imposition of coronavirus restrictions, including mandatory masking and vaccine orders. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over vaccine and testing requirements. ',
		        size: 5001,
		        size_text: '>5,000',
		        participants: 'General public, including right-wing groups. ',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RO",
		        arrayItem: true,
		        country: 'Romania',
		        protest_name: '<span class="protest">Corruption protests</span>',
		        date: '2017-01-01',
		        date_text: "January 2017",
		        freedom: 'Free',
		        triggers: 'Government attempts to decriminalize corruption offenses, limit judicial independence through reforms, and obstruct anticorruption investigations. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Government corruption, subversion of democratic processes, and erosion of judicial independence.',
		        size: 500000,
		        size_text: '500,000',
		        participants: 'Key participants varied by protest. Included urban young people, Romanian diaspora, magistrates.',
		        timespan: 'Sporadic',
		        timespan_text: '2 years',
		        outcomes: 'In some (but not all) cases, the government reversed decrees in response to the protests. ',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ROCapitalDataActive,
		        inactiveCapitalData: ROCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Power cut protest</span>',
		        date: '2024-07-01',
		        date_text: "July 2024",
		        freedom: 'Not free',
		        triggers: 'Southern Russia has been increasingly experiencing power cuts and water supply interruptions during the summer.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters are demanding that the government restore their utilities and provide electricity for at least three hours a day.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Local governor Veniamin Kondratyev promised to ensure the supply of water and explained that the power outages were due to the heatwaves.',
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Protest for Ural flood relief</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Not free',
		        triggers: 'A dam broke in the southwestern Ural region, flooding over 10,000 homes. The regional government is providing monthly payments of about $100 to flood victims for the next six months, which protesters say is insufficient. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Residents demanded compensation for the flooding of their homes. Protesters chanted "Putin help us" and labeled the government a "shame" for not providing adequate financial support.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Ural region residents',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests. ',
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Noon Against Putin protest</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Not free',
		        triggers: 'The 2024 election was neither free nor fair and served as a rubber stamp of approval for Putin. As such, prior to his death earlier in February 2024, political prisoner Alexei Navalny urged supporters to vote at noon on the last of three days of voting, Sunday, March 17, to display opposition to Putin, given most other avenues for dissent and protest are no longer accessible following authoritarian hardening in Russia after its invasion of Ukraine in February 2024. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest the rubber stamp process certain to keep Putin in power. Display opposition to Putin and his authoritarianism. Given escalated repression, simply appearing at polling stations at noon was the only way to safely express discontent.  ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Yulia Galyamina, Alexei Navalny, Yulia Navalnaya',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. Putin was declared the winner of the presidential election.   ',
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Alexei Navalny funeral demonstration</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Not free',
		        triggers: 'Alexei Navalny died in an Arctic prison on February 16, 2024. Navalny’s widow, Yulia Navalnaya, alleged that Navalny was murdered and Putin was responsible. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesters defied state orders barring protests to mourn and bid farewell to Navalny on March 1, 2024. Protesters, blocked by riot police from reaching the Moscow church where Navalny’s funeral was held, chanted "No to war," "Russia without Putin," and "Russia will be free," in defiance of Putin’s hardening authoritarianism and crackdown on basic rights following the 2022 invasion of Ukraine.  ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Bashkortostan protest for the release of Fail Alsynov</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Not free',
		        triggers: 'Activist Fail Alsynov was found guilty of inciting ethnic hatred and sentenced to four years in a penal colony.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand the release of Fail Alsynov and denounce the court’s ruling. ',
		        size: 3001,
		        size_text: '>3,000',
		        participants: 'Ethnic Bashkirs',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Riot police were deployed to disperse and arrest those participating in the unsanctioned demonstration. Fail Alsynov remains in custody.  ',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Protesting the crackdown on independent journalism</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Not free',
		        triggers: 'A Radio Free Europe/Radio Liberty reporter with the Tatar-Bashkir Service, Alsu Kurmasheva, has been in police custody since October 11 for failing to register as a foreign agent because she holds dual citizenship in the U.S. and Russia. Broader crackdown on independent media in Russia since the invasion of Ukraine in 2022. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for the release of Alsu Kurmasheva and for freedom of the press. ',
		        size: 99,
		        size_text: '<100',
		        participants: 'Journalists',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest. ',
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Protest by soldiers’ loved ones</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Not free',
		        triggers: 'Partial mobilization of Russian troops in September 2022 to fight in Ukraine. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Wives and loved ones of mobilized soldiers gathered in Moscow to demand their loved ones be either rotated out of Ukraine or fully demobilized. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Women, family of soldiers',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'The government has repeatedly shut down these protests, but the movement remains active and has produced a manifesto calling for a one-year maximum term for conscription. Russia detained about twenty journalists, including a video journalist from Reuters, who were covering protests in February 2024, but they were released after several hours. ',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Ukraine invasion protests</span>',
		        date: '2022-02-01',
		        date_text: "February 2022",
		        freedom: 'Not free',
		        triggers: 'The invasion of Ukraine by the Russian military.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over decision by Russian President Vladimir Putin to go to war; concern over economic fallout; solidarity with Ukrainians resisting invasion. In September 2022, protests reignited over the Russian government’s announcement of a "partial mobilization" of up to 300,000 reservists. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '2 months following invasion;<br />1 week in September 2022 following partial mobilization.',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Aleksei Navalny arrest protests</span>',
		        date: '2021-01-01',
		        date_text: "January 2021",
		        freedom: 'Not free',
		        triggers: 'Arrest of opposition leader and anticorruption activist Aleksei Navalny.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration over a lack of genuine political pluralism, widespread corruption, and economic stagnation.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public.',
		        timespan: '4 months beginning January 2021,<br />1 day in June 2023.',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Khabarovsk protests</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Not free',
		        triggers: 'Arrest of the popular regional governor Sergei Furgal on murder charges.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Increasing resentment among the outer regions of Russia toward the Kremlin for intruding in regional affairs, anger among opposition parties that feel they are powerless in the restrictive political system, and concern over political repression. ',
		        size: 50000,
		        size_text: '50,000',
		        participants: 'General public.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Coronavirus restrictions protest</span>',
		        date: '2020-04-01',
		        date_text: "April 2020",
		        freedom: 'Not free',
		        triggers: 'Economic implications of the shutdown and self-isolation measures established in response to the coronavirus pandemic.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over the economic effects of the shutdown, particularly in poorer areas that have lower infection rates.',
		        size: 2000,
		        size_text: '2,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Constitutional changes protests</span>',
		        date: '2020-02-01',
		        date_text: "February 2020",
		        freedom: 'Not free',
		        triggers: 'Constitutional changes that could allow President Vladimir Putin to stay in power past 2024, as well as the five-year anniversary of the death of an opposition leader. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Opposition to constitutional changes, falling popularity of Putin.',
		        size: 22000,
		        size_text: '22,000',
		        participants: 'Political opposition.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Moscow election protests</span>',
		        date: '2019-07-01',
		        date_text: "July 2019",
		        freedom: 'Not free',
		        triggers: 'Banning of some opposition politicians from running in Moscow city council elections. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Growing authoritarianism under President Vladimir Putin.',
		        size: 50000,
		        size_text: '50,000',
		        participants: 'Opposition groups with various political affiliations, young people.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "RU",
		        arrayItem: true,
		        country: 'Russia',
		        protest_name: '<span class="protest">Corruption protest</span>',
		        date: '2017-03-01',
		        date_text: "March 2017",
		        freedom: 'Not free',
		        triggers: 'Publication of a video by opposition politician Alexei Navalny, alleging that then prime minister Dmitry Medvedev had accumulated luxury mansions, yachts, and vineyards.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Economic downturn and corruption.',
		        size: 60000,
		        size_text: '60,000',
		        participants: 'Opposition leaders, activists.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "RU", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RU", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RUCapitalDataActive,
		        inactiveCapitalData: RUCapitalDataInctive
		    },
		    {
		        id: "SN",
		        arrayItem: true,
		        country: 'Senegal',
		        protest_name: '<span class="protest">Election delay protests</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Partly free',
		        triggers: 'President Macky Sall abruptly postponed elections scheduled for February 2024 until December 2024.  This was done after police expelled opposition members from the National Assembly, prohibiting them from voting against the postponement of the election, which passed by a vote of 105 to 1.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce what is seen by many as an institutional coup initiated by an unpopular president in an attempt to stay in power. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, opposition',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'On February 15, 2024, Senegal’s Constitutional Council ruled that the election delay was unlawful. The election was held on March 24, 2024, and won by opposition candidate Bassirou Diomaye Faye. ',
		        significant: true,
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "SN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SNCapitalDataActive,
		        inactiveCapitalData: SNCapitalDataInctive
		    },
		    {
		        id: "SN",
		        arrayItem: true,
		        country: 'Senegal',
		        protest_name: '<span class="protest">Gendarmerie protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Partly free',
		        triggers: 'The gendarmes of Dakar’s Ngor district planned to construct a gendarmerie post on a 6,000 square meter parcel of land.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Local residents wished to construct a high school on the same parcel of land.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Residents of Dakar’s Ngor district.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'President Macky Sall personally intervened and compelled the sides to split the parcel of land “equally.” ',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SNCapitalDataActive,
		        inactiveCapitalData: SNCapitalDataInctive
		    },
		    {
		        id: "SN",
		        arrayItem: true,
		        country: 'Senegal',
		        protest_name: '<span class="protest">Ousmane Sonko protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'Opposition leader Ousmane Sonko faced trial for libel charges.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceptions that the charges against Sonko were politically motivated; fears over a potential guilty verdict, which would invalidate Sonko’s candidacy for the country’s 2024 presidential election, coupled with fears that President Macky Sall would run for an unconstitutional third term; anger over declining tolerance for political pluralism and a crackdown on dissent.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Supporters of Ousmane Sonko.',
		        timespan: '6 months',
		        timespan_text: '',
		        outcomes: 'On June 1, Sonko was acquitted on charges of rape but found guilty for &ldquo;corrupting youth&rdquo; and sentenced to two years in prison, thus invalidating Sonko’s candidacy for the country’s 2024 election. On July 3, President Macky Sall announced that he would not seek a third term. ',
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SNCapitalDataActive,
		        inactiveCapitalData: SNCapitalDataInctive
		    },
		    {
		        id: "SN",
		        arrayItem: true,
		        country: 'Senegal',
		        protest_name: '<span class="protest">Opposition candidacy protests</span>',
		        date: '2022-06-01',
		        date_text: "June 2022",
		        freedom: 'Partly free',
		        triggers: 'The government’s decision to invalidate the Yewwi Askan Wi opposition coalition’s candidate list for upcoming parliamentary elections; call for protest by coalition leader Ousmane Sonko.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceived repression of political opposition and entrenchment of incumbent president Macky Sall; frustration with lack of political pluralism. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "SN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SNCapitalDataActive,
		        inactiveCapitalData: SNCapitalDataInctive
		    },
		    {
		        id: "SN",
		        arrayItem: true,
		        country: 'Senegal',
		        protest_name: '<span class="protest">Opposition leader arrest protests</span>',
		        date: '2021-03-01',
		        date_text: "March 2021",
		        freedom: 'Partly free',
		        triggers: 'Arrest of opposition leader Ousmane Sonko.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with decreasing political pluralism and the perception that incumbent President Macky Sall is suppressing political opposition. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SN", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SN", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SNCapitalDataActive,
		        inactiveCapitalData: SNCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">Protest over train station roof collapse</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'On November 1, a concrete roof above the entrance of a train station in Novi Sad collapsed, killing 15 people.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Express anger over government corruption, which protesters believe led to sloppy renovations in the train station and ultimately caused the roof collapse. Demand the arrests of those responsible for the incident. Call for the resignation of Prime Minister Miloš Vučević and his government.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public, opposition, students',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'Goran Vesić, the minister of construction, transportation, and infrastructure, resigned from his position on November 4, but did not accept responsibility for the collapse. Tomislav Momirović, the minister of internal and foreign trade, and Jelena Tanasković, the minister of agriculture, forestry and water management, also resigned following the incident. The Novi Sad Higher Public Prosecutor’s Office launched an investigation and arrested eleven officials, though opposition politicians have expressed skepticism over the investigation. In January 2024, Vučević resigned from his position.',
				active: true,
				significant: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">LGBTQ protest against police abuse</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Free',
		        triggers: 'Two LGBTQ individuals were reportedly subjected to abuse, torture, and sexual harassment in their apartment by police on February 26, 2024. LGBTQ people report facing discrimination and some fear targeted violence.   ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand police education on policing the already marginalized LGBTQ community. Raise public awareness on issues facing the LGBTQ community. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'LGBTQ community, LGBTQ activists, LGBTQ allies',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">Anti-Russian Supreme Court protests</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'The Russian Supreme Court declared the "international LGBT movement" to be extremist.  ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest outside Russian embassy in Belgrade in solidarity with the LGBTQ+ community and express discontent with the decision, given Russian influence in Serbia.  ',
		        size: 101,
		        size_text: '>100',
		        participants: 'LGBTQ+, general public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">2023 election protests</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'The December 2023 parliamentary election, won by the Serbian Progressive Party, was called unfair by multiple international observers due to media bias favoring the ruling party, improper influence exerted by President Aleksandar Vučić, and irregularities such as vote buying. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for the election to be annulled due to irregularities. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Students, opposition',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'Serbia’s parliament convened for the first time since the election on February 4, while the opposition continued to protest. Opposition lawmakers blew whistles and traded insults with Serbian Progressive Party lawmakers, claiming they stole the election. The Belgrade local elections will be rerun later in 2024.  ',
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">Mass shootings protests</span>',
		        date: '2023-05-01',
		        date_text: "May 2023",
		        freedom: 'Free',
		        triggers: 'Two mass shootings in two days left eighteen people dead.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Grief over the mass shootings; anger over the perceived glorification of violence on private television stations with state broadcast licenses; and anger against the country’s president, whom protesters blamed for creating an atmosphere of hopelessness and division in Serbia that they said indirectly led to the mass shootings.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Serbian President Aleksandar Vucic promised an “almost total disarmament” of the country. He declared a one-month amnesty for Serbians to hand over unregistered weapons and not face prison sentences. Ten days after the second mass shooting, authorities announced that Serbians had handed over 13,500 weapons. On May 27, President Vucic stepped down as leader of the ruling Serbian Progressive Party.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">Anti-normalization protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Free',
		        triggers: 'The president of Serbia said that he would consider a plan that EU and U.S. officials proposed for normalizing relations between Serbia and Kosovo.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the proposal would require Serbia to recognize Kosovo and not object to Kosovo’s membership in international institutions.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Right-wing partisans.',
		        timespan: '5 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">Environmental protests</span>',
		        date: '2021-09-01',
		        date_text: "September 2021",
		        freedom: 'Free',
		        triggers: 'Call for protest by thirty environmental groups over plans to develop a lithium mine; introduction of laws to ease expropriation of land and the holding of referenda on environmental issues.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concerns over worsening pollution and the environmental impact of mining. Call for legislators to permanently ban lithium and boron mining.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '4 months in 2021–2022;<br />2 months starting December 2022;<br />intermittently since July 2024',
		        timespan_text: '',
		        outcomes: 'In December 2021, the government suspended laws and plans that would have allowed the project to go forward, and in January 2022, it announced that the project had been canceled altogether. The government revoked the mining corporation’s lithium exploration licenses in January 2023 after renewed protests. In July 2024, the Constitutional Court ruled that it was unconstitutional for the government to revoke the licenses. The government announced that it would move forward with a mining project. In October 2024, Parliament rejected a law proposed by the opposition that would have banned lithium and borate mining and exploration.',
				active: true,
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">Coronavirus protests</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Free',
		        triggers: 'Announcement that the government would reimpose a curfew and other restrictions amid a surge in COVID-19 cases—just two weeks after the country’s parliamentary elections that opposition leaders decried as illegitimate.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Public perceptions that President Aleksandar Vucic’s decision to lift lockdown measures in May was only made to give his party the advantage in the upcoming parliamentary elections (by acting as if he had defeated the outbreak), concerns over the true extent of the outbreak, and exasperation with underlying issues of government corruption and authoritarianism.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition parties.',
		        timespan: '4 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "RS",
		        arrayItem: true,
		        country: 'Serbia',
		        protest_name: '<span class="protest">“1 Out of 5 Million” protests</span>',
		        date: '2018-12-01',
		        date_text: "December 2018",
		        freedom: 'Free',
		        triggers: 'Violent attack on an opposition leader, and President Aleksandar Vučić’s mockery of the ensuing protests.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Corruption, nepotism, government incompetence, state control of media, and rising authoritarianism.',
		        size: 37500,
		        size_text: '37,500',
		        participants: 'Opposition parties.',
		        timespan: '1 year, 2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "RS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "RS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: RSCapitalDataActive,
		        inactiveCapitalData: RSCapitalDataInctive
		    },
		    {
		        id: "SL",
		        arrayItem: true,
		        country: 'Sierra Leone',
		        protest_name: '<span class="protest">2023 election protests</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Partly free',
		        triggers: 'Samura Kamara, the leader of Sierra Leone’s leading opposition All People’s Congress party, called for the country’s electoral commissioners to resign after the commission did not release a detailed voter registry. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over the electoral commission’s perceived pro-ruling party bias; perceived unfairness of the country’s June 2023 presidential election.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Supporters of the opposition All People’s Congress party.',
		        timespan: '5 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SLCapitalDataActive,
		        inactiveCapitalData: SLCapitalDataInctive
		    },
		    {
		        id: "SL",
		        arrayItem: true,
		        country: 'Sierra Leone',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Partly free',
		        triggers: 'Rising prices of food and fuel',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Worsening economic conditions; perceived failure of government to combat inflation; and crackdowns against protests.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "SL", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SL", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SLCapitalDataActive,
		        inactiveCapitalData: SLCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Protests against pro-Russia policies</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Free',
		        triggers: 'Prime Minister Robert Fico took an unexpected trip to Moscow to meet with Russian President Vladimir Putin in December 2024.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Denounce Fico’s pro-Russia policies, which protesters claim are undermining Slovakia’s EU and NATO membership.',
		        size: 99999,
		        size_text: '<100,000',
		        participants: 'General public, opposition',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Fico’s government accused opposition groups of planning to organize a coup, which the opposition denied.',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Anti-Fico protest</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'Thirty-fifth anniversary of the Velvet Revolution.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Show opposition to the policies of Prime Minister Robert Fico, which protesters see as a threat to Slovakia’s democracy. Call for opposition to protect freedom and democracy.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Protests against warming Russian relations</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Free',
		        triggers: 'Prime Minister Robert Fico’s government has criticized Europe’s military aid to Ukraine and pushed to renew ties with Russia. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Show support for Ukraine and oppose Fico’s efforts to renew ties with Russia. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition members',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Protest against public broadcasting takeover</span>',
		        date: '2024-03-01',
		        date_text: "March 2024",
		        freedom: 'Free',
		        triggers: 'Prime Minister Robert Fico’s government has planned to replace the current public radio and television outlet with a new organization led by a seven-member council whose members are appointed by the government. The current broadcaster’s director was elected by Parliament with a term set to end in 2027, but the plan (drafted by "ultra-nationalist" Culture Minister Martina Šimkovičová) is seen by many—including President Zuzana Čaputová, opposition parties, local journalists, international media organizations, and the European Commission—as an effort to politicize state media to the government’s benefit. Fico has labeled several private media outlets as his enemies. Šimkovičová said the takeover is needed because  the current broadcaster is biased, giving space only to mainstream views and censoring the rest. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demonstrators formed a human chain around the Bratislava building that houses the country’s public television and radio outlets on March 27, 2024, to protest against the planned takeover. Protesters expressed the need for freedom of the press and denounced the politicization of public media. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Opposition members',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Free',
		        triggers: 'The EU waived duties and quotas for imports from Ukraine, which put pressure on prices for EU farmers who must follow EU environmental standards. Ongoing negotiations for a trade deal between the EU and the South American Mercosur bloc. New EU environmental regulations, including new subsidy rules which require 4 percent of farmland to be fallow. High energy prices are further undercutting farmers’ profits.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest against EU environmental regulations, cheap food imports from Ukraine, high energy costs, and red tape. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Farmers',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'Prime Minister Robert Fico said Slovakia will maintain a ban on selected products from Ukraine. On March 15, the EU proposed legislation which, if passed, will ease the environmental rules of the Common Agricultural Policy. In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Truckers’ border blockade</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'The EU waived entry restrictions on Ukrainian lorries following the Russian invasion of Ukraine. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration among Slovakian truck drivers who feel the markets they once supplied are being taken over by Ukrainian truck drivers due to their low prices. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Truckers',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Protest against Fico’s criminal law changes</span>',
		        date: '2023-12-01',
		        date_text: "December 2023",
		        freedom: 'Free',
		        triggers: 'Prime Minister Robert Fico has fast-tracked criminal law changes, including abolishing a special prosecutor’s office dedicated to high-profile corruption, limiting protection for whistleblowers, and reducing criminal sentences for financial crimes. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Protesters claim these changes will threaten democracy and justice.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'The European Commission warned it will take action if Slovakia violates EU laws. The president urged lawmakers to reconsider the reforms in January 2024.',
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Fuel price protest</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'Rising prices, especially of fuel.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Discontent with government support for Ukraine; fears of energy insecurity resulting from war in Ukraine.',
		        size: 5000,
		        size_text: '5,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">Coronavirus restrictions protest</span>',
		        date: '2020-11-01',
		        date_text: "November 2020",
		        freedom: 'Free',
		        triggers: 'Ongoing pandemic-related restrictions and the commemoration of Slovenia’s national Fight for Freedom and Democracy Day.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with ongoing coronavirus restrictions.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially far-right activists.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy or leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SK",
		        arrayItem: true,
		        country: 'Slovakia',
		        protest_name: '<span class="protest">“For a Decent Slovakia” protests</span>',
		        date: '2018-03-01',
		        date_text: "March 2018",
		        freedom: 'Free',
		        triggers: 'Murder of a journalist who was at the time investigating connections between the Italian mafia and high-ranking Slovakian politicians.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Continued government corruption and popular distrust in the current government.',
		        size: 65000,
		        size_text: '65,000',
		        participants: 'Young people.',
		        timespan: '1 year, 6 months',
		        timespan_text: '',
		        outcomes: 'Then prime minister Robert Fico resigned and was succeeded by a deputy.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SKCapitalDataActive,
		        inactiveCapitalData: SKCapitalDataInctive
		    },
		    {
		        id: "SI",
		        arrayItem: true,
		        country: 'Slovenia',
		        protest_name: '<span class="protest">Pension protest</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Free',
		        triggers: 'Prices continued to rise and the government was not increasing pensions commesurate with the inflation rate. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Economic disempowerment caused by rising prices and fixed pensions.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Pensioners.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: true,
		        mapData: {
		        	"id": "SI", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SI", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SICapitalDataActive,
		        inactiveCapitalData: SICapitalDataInctive
		    },
		    {
		        id: "SI",
		        arrayItem: true,
		        country: 'Slovenia',
		        protest_name: '<span class="protest">Anti-Jansa protests</span>',
		        date: '2020-03-01',
		        date_text: "March 2020",
		        freedom: 'Free',
		        triggers: 'Allegations of corruption, illiberalism, and declining freedom under the government of Prime Minister Janez Jansa. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with rising illiberalism and poor governance under Jansa’s government.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '16 months, intermittently',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SI", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SI", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SICapitalDataActive,
		        inactiveCapitalData: SICapitalDataInctive
		    },
		    {
		        id: "SB",
		        arrayItem: true,
		        country: 'Solomon Islands',
		        protest_name: '<span class="protest">Malaita protests</span>',
		        date: '2021-11-01',
		        date_text: "November 2021",
		        freedom: 'Free',
		        triggers: 'Call for protests by Malaita for democracy amid growing tensions between the government of Malaita Province and the national government over relations with China and the United States.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over unequal distribution of resources between Guadalcanal and Malaita and the government’s decision in 2019 to recognize the People’s Republic of China instead of Taiwan.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Residents of Malaita.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        multiple: false,
		        mapData: {
		        	"id": "SB", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SB", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SBCapitalDataActive,
		        inactiveCapitalData: SBCapitalDataInctive
		    },
		    {
		        id: "SO",
		        arrayItem: true,
		        country: 'Somalia',
		        protest_name: '<span class="protest">Somaliland port access protest</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Not free',
		        triggers: 'Ethiopia and Somaliland signed a pact giving Ethiopia access to Somaliland’s ports on the Red Sea in exchange for eventual Ethiopian recognition of Somaliland.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern among Somalis regarding sovereignty over the disputed territory.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "SO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SOCapitalDataActive,
		        inactiveCapitalData: SOCapitalDataInctive
		    },
		    {
		        id: "SO",
		        arrayItem: true,
		        country: 'Somalia',
		        protest_name: '<span class="protest">Somaliland election protest</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Not free',
		        triggers: 'Talks between the Somaliland autonomous region’s government and opposition regarding the November 2022 presidential election broke down.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that Somaliland’s ruling party would delay the election.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "SO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SOCapitalDataActive,
		        inactiveCapitalData: SOCapitalDataInctive
		    },
		    {
		        id: "SO",
		        arrayItem: true,
		        country: 'Somalia',
		        protest_name: '<span class="protest">Police violence protests</span>',
		        date: '2020-04-01',
		        date_text: "April 2020",
		        freedom: 'Not free',
		        triggers: 'The fatal shooting of two civilians by security forces enforcing coronavirus lockdown restrictions.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Recurring police brutality, especially during the enforcement of coronavirus restrictions.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'The perpetrator was arrested and charged with murder.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SOCapitalDataActive,
		        inactiveCapitalData: SOCapitalDataInctive
		    },
		    {
		        id: "SO",
		        arrayItem: true,
		        country: 'Somalia',
		        protest_name: '<span class="protest">Examination protest</span>',
		        date: '2019-15-01',
		        date_text: "May 2019",
		        freedom: 'Not free',
		        triggers: 'The leakage of information on the unified national secondary school exam on social media, and an announcement that the exams would be rescheduled.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over poor service delivery and the failure to administer the exam.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public, especially students.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SOCapitalDataActive,
		        inactiveCapitalData: SOCapitalDataInctive
		    },
		    {
		        id: "SO",
		        arrayItem: true,
		        country: 'Somalia',
		        protest_name: '<span class="protest">Soldier pay protests</span>',
		        date: '2017-03-01',
		        date_text: "March 2017",
		        freedom: 'Not free',
		        triggers: 'Government failure to give fifteen months of back pay owed to soldiers.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over corruption and poor working conditions for soldiers.',
		        size: 2001,
		        size_text: '>2,000',
		        participants: 'Soldiers.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'Officials promised that soldiers would get back pay.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SO", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SO", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SOCapitalDataActive,
		        inactiveCapitalData: SOCapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Anti-Dutch Royalty protest</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Free',
		        triggers: 'Dutch King Willem-Alexander and Queen Maxima traveled to South Africa for the first time and visited the Slave Lodge in South Africa, where Dutch colonists had enslaved thousands. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand reparations from Dutch royal family for colonization, exploitation, and slavery. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'Indigenous people, South Africa’s First Nation groups',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Minibus strikes and protests</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Free',
		        triggers: 'Cape Town authorities impounded many minibus taxis for violations of a municipal bylaw that prohibits driving without a license, failing to display number plates, and overloading.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations over what the taxi drivers perceived to be heavy-handed tactics by Cape Town authorities to regulate their vital sector of the economy.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Taxi drivers ',
		        timespan: '8 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership changes in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Service delivery protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'State-owned energy firm Eskom was unable to deliver sufficient power to avert prolonged outages.  ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Frustration with persistent power cuts; anger with the ruling African National Congress party’s failure to deliver services and create jobs.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the Economic Freedom Fighters party. ',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'The president declared a &ldquo;state of disaster&rdquo; and pledged to end the energy crisis.',
		        significant: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Public sector employee strikes and protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Public sector unions rejected a government offer to increase civil servant wages by 3%; union leaders had demanded an increase of 10%.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Concern over rapidly rising prices.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Public sector workers.',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Water shortage protest</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'Water shortages across the country. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations over high poverty and inequality; fears of consequences of climate change. ',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '3 days',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Cost of living strike and protest</span>',
		        date: '2022-08-01',
		        date_text: "August 2022",
		        freedom: 'Free',
		        triggers: 'Rising inflation and power cuts. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with both high unemployment and the world’s highest level of economic inequlity. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Zuma arrest protests</span>',
		        date: '2021-07-01',
		        date_text: "July 2021",
		        freedom: 'Free',
		        triggers: 'Imprisonment of former president Jacob Zuma after he was found guilty of contempt of court.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Fragmentation within the African National Congress, strong loyalty to Zuma, concerns that the judicial process had been politicized, and frustrations with long-standing poverty and inequality. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Violence against women protests</span>',
		        date: '2019-09-01',
		        date_text: "September 2019",
		        freedom: 'Free',
		        triggers: 'Spate of femicides.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Violence against women and lack of institutional protections for women.',
		        size: 4000,
		        size_text: '4,000',
		        participants: 'Gender rights activist organizations.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'In November 2019, President Cyril Ramphosa launched a “16 days of activism” campaign to raise awareness around the rights of women and girls. In his 2020 State of the Union, Ramphosa announced plans to help curb violence against women.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "ZA",
		        arrayItem: true,
		        country: 'South Africa',
		        protest_name: '<span class="protest">Anti-Zuma protest</span>',
		        date: '2017-04-01',
		        date_text: "April 2017",
		        freedom: 'Free',
		        triggers: 'Ouster of popular African National Congress finance minister Pravin Gordhan. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Economic challenges, inequality, corruption, political cronyism, and growing disapproval of then president Jacob Zuma.',
		        size: 30000,
		        size_text: '30,000',
		        participants: 'General public.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ZA", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ZA", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ZACapitalDataActive,
		        inactiveCapitalData: ZACapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Pro-Yoon protests</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Free',
		        triggers: 'On December 14, President Yoon Suk-yeol was impeached by the National Assembly.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize the National Assembly’s actions. Show support for Yoon and call for his reinstatement.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Yoon supporters',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. On January 19, Yoon was formally arrested, and on January 26, Yoon was indicted on rebellion charges.',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },

		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Martial law protests</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Free',
		        triggers: 'On the night of December 3, President Yoon Suk-yeol declared martial law in the country, accusing the opposition party, the Democractic Party of Korea, of engaging in anti-state activities and threatening the state of democracy.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over Yoon’s declaration of martial law, which many viewed as a severe overreach of authority and an attempted self-coup. Demand the end of martial law and call for Yoon’s impeachment and arrest.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public, opposition',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'On December 4, only a few hours after Yoon’s announcement, 190 legislators gathered at the National Assembly and unanimously voted to lift martial law, which Yoon and his cabinet subsequently agreed to. The National Assembly voted to impeach Yoon on December 14 and Prime Minister (and acting president) Han Duck-soo on December 27th. On January 19, Yoon was formally arrested, and on January 26, Yoon was indicted on rebellion charges.',
				active: true,
				significant: true,
				large: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Protest against Lee Jae-myung’s conviction</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'Lee Jae-myung, the leader of the Democratic Party of Korea and a presidential candidate in the 2022 elections, was convicted of violating the Public Official Election Act and sentenced to a suspended prison term. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Show support for Lee and criticize the conviction, which protesters believe is politically motivated.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Counterprotesters gathered on the same day to call for Lee’s arrest.',
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Political scandal protest</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'Political scandal involving first lady Kim Keon-hee, surrounding various allegations such as accepting political bribes and interfering in the candidate nominations for the People Power Party (PPP), the party of President Yoon Suk-yeol.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Call for a special counsel investigation into the allegations against Kim. Denounce the misuse of government authority by Kim and Yoon, as well as the PPP’s alleged complicity in their actions. Demand Yoon’s impeachment.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Yoon issued a formal apology for Kim’s actions and agreed to appoint a special inspector general to investigate the allegations. However, he rejected an opposition-led bill to launch a special counsel investigation against Kim.',
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Christian protest against LGBTQ+ rights</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Free',
		        triggers: 'In July, the South Korean Supreme Court upheld a ruling from the Seoul High Court that acknowledged the right for same-sex couples to receive spousal benefits from state health insurance.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Criticize the Supreme Court ruling, which protesters believe to be unconstitutional since same-sex marriage is not legal. Protesters are also opposed to the LGBTQ+ community and argue that anti-discriminatory laws will result in reverse discrimination.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Christian groups',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Protest against Cambodia-Laos-Vietnam Development Triangle Area (CLV-DTA)</span>',
		        date: '2024-08-01',
		        date_text: "August 2024",
		        freedom: 'Free',
		        triggers: 'Arrest of three Cambodian activists who shared concerns over the CLV-DTA, an economic agreement between Cambodia, Laos, and Vietnam.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Overseas Cambodians are demanding that Cambodia withdraw from the CLV-DTA, which they believe will cause Cambodia to lose territorial sovereignty to Vietnamese or Lao investors. They are also concerned about potential influx of immigrants from Vietnam, which Cambodia has historically had negative relations with.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Cambodians in South Korea',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The Cambodian government threatened further arrests of activists in Cambodia and blocked a similar protest from occuring in Phnom Penh, Cambodia. On September 20, Prime Minister Hun Manet announced that Cambodia had withdrawn from the CLV-DTA.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Doctors’ protests</span>',
		        date: '2024-02-01',
		        date_text: "February 2024",
		        freedom: 'Free',
		        triggers: 'President Yoon Suk Yeol’s government announced that they would increase medical school admissions by about 2,000 in the 2025 academic year, with the aim of eventually adding 10,000 new spots by 2035 in order to remedy doctor shortages. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Two-thirds of the nation’s residents and medical interns walked off the job in February 2024, claiming that the change is unnecessary and will undermine the finances of the national health insurance plan. They also criticized the government for not consulting existing doctors when making this plan. ',
		        size: 9001,
		        size_text: '>9,000',
		        participants: 'Doctors, trainee doctors, medical interns',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'The government announced it will send physicians from the nation’s military to hospitals affected by the walkout. It also announced it will suspend the medical licenses of those who do not return to work by February 29. In early March 2024, the government began the process to suspend the medical licenses of thousands of doctors who walked off the job. The prime minister announced on March 8, 2024, that the government plans to improve pay and working conditions for young doctors. On July 2024, the government announced that it would not suspend the license of striking junior doctors to encourage them to return to work.',
		        significant: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Dog meat ban protest</span>',
		        date: '2023-11-01',
		        date_text: "November 2023",
		        freedom: 'Free',
		        triggers: 'The ruling party plans to pass a bill that will enforce a ban on dog meat.  ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'South Korean farmers and restaurant workers say the ban will adversely affect their livelihoods. The Korean Association of Edible Dogs claims 3,500 farms and 3,000 restraints will have to shut down as a result of the ban. ',
		        size: 201,
		        size_text: '>200',
		        participants: 'Farmers, food service workers.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. Demonstrators are threatening to release 2 million dogs near the presidential office if the ban is enacted. Parliament passed a ban on the production and sale of dog meat in January 2024. ',
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">2022 Halloween crush protests</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Free',
		        triggers: 'One-year anniversary of when 159 young people were killed in a Seoul nightlife district.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand special investigation into the causes of the deadly crush.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Relatives of those killed in 2022 Halloween crush',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Teacher protests</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'A teacher who claimed to have suffered at the hands of abusive parents died by suicide in July 2023.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'The immense pressure that South Korean teachers feel from parents who make excessive or impossible demands of them.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Teachers',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'South Korea’s government launched a task force to explore new education-related laws.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Fukushima wastewater protests</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Free',
		        triggers: 'The Japanese government announced plans to start releasing wastewater from the Fukushima nuclear power plant into the Pacific Ocean within weeks.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears over environmental damage caused by the release of the wastewater.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Trucker strike and protest</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Talks between the government and the truckers union failed to resolve disputes over pay.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger with insufficient pay for truckers.',
		        size: 7000,
		        size_text: '7,000',
		        participants: 'Truckers. ',
		        timespan: '9 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Justice minister protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Free',
		        triggers: 'Allegations of corruption against the then justice minister, Cho Kuk, who had pushed for prosecutorial reforms.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Government corruption and public distrust of prosecutors.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Anti-Cho protesters: evangelical Christian activists, conservatives, older citizens; pro-Cho protesters: younger, urban, white-collar workers. ',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'Then justice minister Cho resigned.',
		        significant: true,
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "KR",
		        arrayItem: true,
		        country: 'South Korea',
		        protest_name: '<span class="protest">Candlelight demonstrations</span>',
		        date: '2016-10-01',
		        date_text: "October 2016",
		        freedom: 'Free',
		        triggers: 'Revelations that Choi Soon-sil, the confidante and old friend of then president Park Geun-hye, exercised undue influence. Choi allegedly urged businesses to donate to her own foundations in return for political favors, accessed classified government documents despite lacking the necessary authorization, and played a role in selecting presidential aides.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Improper influence of outside individuals, including friends of politicians as well as business elites and family-run conglomerates; increasing class divisions and inequality and lack of social mobility and economic opportunity, especially for younger citizens; and patriarchy and sexism in South Korea.',
		        size: 1500000,
		        size_text: '1,500,000',
		        participants: 'Trade unions, students, feminist groups, families, representatives from parties on the political right and left.',
		        timespan: '5 months',
		        timespan_text: '',
		        outcomes: 'Park was impeached.',
		        significant: true,
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "KR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "KR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: KRCapitalDataActive,
		        inactiveCapitalData: KRCapitalDataInctive
		    },
		    {
		        id: "SS",
		        arrayItem: true,
		        country: 'South Sudan',
		        protest_name: '<span class="protest">Anti-Sudan riots</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Not free',
		        triggers: 'Videos on social media depicted the alleged killings of South Sudanese civilians by Sudan’s military and allied forces in El Gezira, Sudan.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the alleged killings. Growing tensions between South Sudan and Sudan.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Youth',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. In response to the unrest, the government temporarily imposed a nationwide curfew. Additionally, the government suspended access to social media for thirty days starting January 22.',
		        multiple: false,
		        mapData: {
		        	"id": "SS", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SS", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SSCapitalDataActive,
		        inactiveCapitalData: SSCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Flood disaster response protest</span>',
		        date: '2024-11-01',
		        date_text: "November 2024",
		        freedom: 'Free',
		        triggers: 'On October 29, catastrophic floods struck eastern and central Spain (Valencia, Andalusia, Castile–La Mancha, and Catalonia). More than 200 people died, making it Spain’s deadliest natural disaster in decades.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger at both central and regional governments for their slow and disorganized responses to the disaster. In Valencia, protesters are also calling for the resignation of the regional president, Carlos Mazón.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Mazón apologized for the mistakes in his government’s flood response. However, he refused to resign from his position, choosing to reshuffle his cabinet instead. To assist the disaster relief efforts, the Spanish government sent 7,500 soldiers to severely impacted areas and issued a €10.6 billion aid package.',
				significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Anti-Sánchez protest</span>',
		        date: '2024-10-01',
		        date_text: "October 2024",
		        freedom: 'Free',
		        triggers: 'Plataforma por la España Constitucional, a group of around one hundred right-wing and far-right organizations, called for a demonstration in Madrid against Prime Minister Pedro Sánchez.',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand that Sánchez resign and that the government hold early elections. Protesters are angry at the government for "betraying" citizens and failing to solve issues such as inflation and high rent costs.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Opposition, right-wing and far-right organizations',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Protests against mass tourism to Spanish islands</span>',
		        date: '2024-04-01',
		        date_text: "April 2024",
		        freedom: 'Free',
		        triggers: 'Growing environmental and economic harm caused by over-tourism. ',
		        active: true,
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protest Spain’s tourism policies. Demand the authorities reduce the rate of tourism to the country, introduce policies to help the environment, and address the massive housing crisis caused by gentrification and vacation rentals.',
		        size: 57001,
		        size_text: '>57,000',
		        participants: 'General public',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'In July 2024, the Spanish government announced that it would crack down on short-term and holiday rental homes and investigate listings on platforms such as Airbnb and Booking.com for licenses. ',
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Farmers’ protest 2024</span>',
		        date: '2024-01-01',
		        date_text: "January 2024",
		        freedom: 'Free',
		        triggers: 'Farmers claim EU environmental regulations, such as a new EU requirement that 4 percent of farmland to be left fallow to receive subsidies, undercut their ability to compete with external prices. They also object to renewed negotiations to establish a trade deal between the EU and the South American Mercosur bloc. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand that environmental regulations (and other regulations) that raise costs for Spanish farmers be relaxed. Also demand a general reduction of red tape and taxes. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers, Asaja, COAG, UPA',
		        timespan: '4 months',
		        timespan_text: '',
		        outcomes: 'In April 2024, the EU approved legislation that eased the environmental rules of the Common Agricultural Policy (CAP). In September 2024, the European Commission announced that EU member states could increase the CAP funds provided to farmers.',
		        significant: true,
		        violent: true,
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Catalan separatist amnesty protest</span>',
		        date: '2023-10-01',
		        date_text: "October 2023",
		        freedom: 'Free',
		        triggers: 'The socialists are backing an unpopular mass pardon of hundreds of Catalan separatists in a bid to gain the backing of the region’s parties to get back in government. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Oppose amnesty deal which they say subverts the judiciary and prioritizes forming a government at the cost of democracy and the rule of law.  ',
		        size: 170000,
		        size_text: '170,000',
		        participants: 'Anti-separatists, Societat Civil Catalana, Vox',
		        timespan: '8 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests. Prime Minister Pedro Sánchez extended amnesty to Catalan separatists and in return received their political support, which allowed him to remain in office. Parliament voted against the amnesty bill for Catalan separatists in January 2024. On May 30, 2024, parliament voted to approve legislation granting amnesty to Catalan separatists. ',
		        large: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Water restriction protest</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'The government introduced plans to reduce the amount of water taken from the Tagus river to irrigate agricultural land in the country’s southeast. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that the plans would result in the loss of tens of thousands of farm-related jobs.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Farmers.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Public healthcare protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Policies implemented by the conservative regional government in Madrid, which protesters said was dismantling the public health system in favor of private providers. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Madrid’s healthcare system was struggling to accommodate the high numbers of patients with many unable to access treatment elsewhere',
		        size: 250001,
		        size_text: '>250,000',
		        participants: 'General public.',
		        timespan: '3 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        large: true,
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Right-wing antigovernment protests</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'Policies implemented by the socialist government, including a modified criminal code which eliminated the crime of sedition and a law against sexual violence that increased punishment for rape but lowered sentences for other sexual crimes. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears that these laws will encourage further separatism in Catalonia and higher rates of sexual assault. ',
		        size: 30000,
		        size_text: '30,000',
		        participants: 'General public, especially supporters of the Vox party.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The government committed to amend the sexual consent law to close the loophole through which sex offenders’ prison sentences were significantly reduced.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Anti-violence against women protest</span>',
		        date: '2022-11-01',
		        date_text: "November 2022",
		        freedom: 'Free',
		        triggers: 'High rates of gender-based violence and femicide; the passage of a law against sexual violence that increased punishment for rape but lowered sentences for other sexual crimes.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration at inadequate response to violence against women. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'The government committed to amend the sexual consent law to close the loophole through which sex offenders’ prison sentences were significantly reduced.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Cost of living protests</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'Rapidly rising prices.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of economic harship as wage increases were failing to compensate for price increases. ',
		        size: 17001,
		        size_text: '>17,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Prostitution bill protest</span>',
		        date: '2022-09-01',
		        date_text: "September 2022",
		        freedom: 'Free',
		        triggers: 'Parliament introduced a bill that would penalize prostitution customers and sex club owners or pimps with sentences of up to four years in prison.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of losing livelihood among sex workers. ',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women, especially sex workers and brothel owners.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Rising price protests</span>',
		        date: '2022-03-01',
		        date_text: "March 2022",
		        freedom: 'Free',
		        triggers: 'Rising prices for food, fuel, and energy.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with rising economic pressures and the perceived failure of the government to address rising prices. ',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'Farmers, truckers.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Rapper arrest protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Free',
		        triggers: 'The arrest of rapper Pablo Hasél, a supporter of Catalonian independence who was convicted of terrorism-related offenses in the wake of the Catalan independence movement. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Ongoing tensions between advocates of Catalonian independence and the Spanish government and concerns over suppression of free expression. ',
		        size: 1001,
		        size_text: '> 1,000',
		        participants: 'General public, especially supporters of Catalonian independence.',
		        timespan: '3 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Coronavirus protest</span>',
		        date: '2020-05-01',
		        date_text: "May 2020",
		        freedom: 'Free',
		        triggers: 'Protesters’ claims that the government misrepresented the extent of the health crisis, violated citizens’ rights by confining them to their homes and ruling by decree, and damaged the economy.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic effects of the lockdown, and concerns of government overreach.',
		        size: 1001,
		        size_text: '> 1,000',
		        participants: 'Supporters of the far-right Vox party.',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        covid: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "ES",
		        arrayItem: true,
		        country: 'Spain',
		        protest_name: '<span class="protest">Catalan independence protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Free',
		        triggers: 'Sentencing of Catalan separatist leaders to prison.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Catalan independence, dissatisfaction with the government response to the independence movement, and the belief that Catalonia should retain more of its own tax revenues.',
		        size: 600000,
		        size_text: '600,000',
		        participants: 'Pro-independence groups and their supporters. ',
		        timespan: '2 months',
		        timespan_text: 'Most recent protests in January 2020.',
		        outcomes: 'No policy/leadership change in response to the protests. After winning support from Catalan separatist party, Prime Minister Pedro Sánchez promised to resolve the Catalan dispute through dialogue.',
		        large: true,
		        multiple: true,
		        mapData: {
		        	"id": "ES", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "ES", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: ESCapitalDataActive,
		        inactiveCapitalData: ESCapitalDataInctive
		    },
		    {
		        id: "LK",
		        arrayItem: true,
		        country: 'Sri Lanka',
		        protest_name: '<span class="protest">Easter bombing justice protest</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Partly free',
		        triggers: 'Sri Lankan officials had not convicted or sentenced anyone in connection with the April 2019 Easter bombings that killed 269 people and wounded some 500 more.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the slow pace of the investigation; grief over the human toll caused by the terrorist attacks.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "LK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LKCapitalDataActive,
		        inactiveCapitalData: LKCapitalDataInctive
		    },
		    {
		        id: "LK",
		        arrayItem: true,
		        country: 'Sri Lanka',
		        protest_name: '<span class="protest">Public sector employee strikes and protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Partly free',
		        triggers: 'The government implemented several measures, including an income tax hike, to secure a bailout from the International Monetary Fund to address the country’s economic crisis.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anxieties related to the economic crisis and anger over rising taxes.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Public sector workers.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: true,
		        mapData: {
		        	"id": "LK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LKCapitalDataActive,
		        inactiveCapitalData: LKCapitalDataInctive
		    },
		    {
		        id: "LK",
		        arrayItem: true,
		        country: 'Sri Lanka',
		        protest_name: '<span class="protest">Election cancellation protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Partly free',
		        triggers: 'The government postponed local elections, claiming that the country’s economic crisis strapped authorities of funds to hold the vote.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over voters’ inability to express their preferences through democratic means.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Supporters of the opposition.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "LK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LKCapitalDataActive,
		        inactiveCapitalData: LKCapitalDataInctive
		    },
		    {
		        id: "LK",
		        arrayItem: true,
		        country: 'Sri Lanka',
		        protest_name: '<span class="protest">Economic crisis protests</span>',
		        date: '2022-03-01',
		        date_text: "March 2022",
		        freedom: 'Partly free',
		        triggers: 'Rapid depreciation of the Sri Lankan rupee, soaring prices, and widespread shortages of goods. In January 2024, citizen outrage over taxes, the rise of fuel and electric costs, and a general rise in price levels reactivated protests.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the government’s economic policies, including the perceived mismanagement of debt. ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'United People’s Power party and members of the opposition; general public.',
		        timespan: '8 months;<br />recurred 1 month January 2024',
		        timespan_text: '',
		        outcomes: 'All 26 ministers of then president Gotabaya Rajapaksa’s cabinet resigned in April 2022. In May 2022, the prime minister, Mahinda Rajapaksa, resigned. Gotabaya Rajapaksa named Ranil Wickremesinghe prime minister. In July 2022, protesters stormed Gotabaya Rajapaksa’s residence, forcing him to flee and resign. Parliament elected Ranil Wickremesinghe as president and then elected Dinesh Gunawardena as prime minister. ',
		        significant: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "LK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LKCapitalDataActive,
		        inactiveCapitalData: LKCapitalDataInctive
		    },
		    {
		        id: "LK",
		        arrayItem: true,
		        country: 'Sri Lanka',
		        protest_name: '<span class="protest">Justice protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Partly free',
		        triggers: 'Lack of accountability for abuses during the twenty-six-year civil war.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Perceived government discrimination against Tamils, centralization of power under Prime Minister Mahinda Rajapaksa’s government, economic discontent, and lack of justice for minority groups and labor groups.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 year',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protest.',
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "LK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LKCapitalDataActive,
		        inactiveCapitalData: LKCapitalDataInctive
		    },
		    {
		        id: "LK",
		        arrayItem: true,
		        country: 'Sri Lanka',
		        protest_name: '<span class="protest">Constitutional crisis protests</span>',
		        date: '2018-10-01',
		        date_text: "October 2018",
		        freedom: 'Partly free',
		        triggers: 'Then president Maithripala Sirisena’s dismissal of then prime minster Ranil Wickremesinghe and the suspension of parliament.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of majoritarianism and return of minority repression.',
		        size: 62500,
		        size_text: '62,500',
		        participants: 'Supporters of the former prime minister.',
		        timespan: '2 days',
		        timespan_text: '',
		        outcomes: 'Wickremasinghe was reappointed as prime minister for a time.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "LK", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "LK", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: LKCapitalDataActive,
		        inactiveCapitalData: LKCapitalDataInctive
		    },
		    {
		        id: "SD",
		        arrayItem: true,
		        country: 'Sudan',
		        protest_name: '<span class="protest">Darfur insecurity protests</span>',
		        date: '2020-07-01',
		        date_text: "July 2020",
		        freedom: 'Not free',
		        triggers: 'Recurring militia attacks in the Darfur region.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the state’s failure to keep citizens safe from militia attacks.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Residents of Darfur.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'The government dismissed the regional chief of police and his deputy.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SDCapitalDataActive,
		        inactiveCapitalData: SDCapitalDataInctive
		    },
		    {
		        id: "SD",
		        arrayItem: true,
		        country: 'Sudan',
		        protest_name: '<span class="protest">“Million-man March”</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Not free',
		        triggers: 'Slow transition from military elite control to civilian control, the repeal of Islamic legal restrictions, and a lack of accountability for anti-protester violence. From October 2021, the major trigger was the military’s seizure of power on October 25.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Discontent with the military elite’s continued control and influence, including through undermining the country’s democratic transition; the slow pace of reforms; and the repeal of Islamic legal restrictions introduced while former president Omar al-Bashir was in power.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public, professional organizations, resistance committees; Islamic groups and supporters of Bashir.',
		        timespan: '3 years',
		        timespan_text: '',
		        outcomes: 'In November 2021, Abdalla Hamdok was reinstalled as prime minister, though he resigned after mass protests in January 2022. In December 2022, Sudan’s junta leaders and opposition groups, including Forces of Freedom and Change, signed a framework deal that called for a two-year transition to a civilian-led government. Other opposition groups rejected the deal and called for further protests. In April 2023, conflict broke out between two rival junta leaders, Abdel Fattah al-Burhan and Mohamed Dagalo.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SDCapitalDataActive,
		        inactiveCapitalData: SDCapitalDataInctive
		    },
		    {
		        id: "SD",
		        arrayItem: true,
		        country: 'Sudan',
		        protest_name: '<span class="protest">National Congress Party protests</span>',
		        date: '2019-10-01',
		        date_text: "October 2019",
		        freedom: 'Not free',
		        triggers: 'Lack of punitive action against members of former president Omar Bashir’s inner circle.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration over the ongoing influence of members of Bashir’s National Congress Party and impunity for perpetrators of protest crackdowns.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'General public.',
		        timespan: '2 months',
		        timespan_text: '',
		        outcomes: 'The government dissolved the National Congress Party.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SDCapitalDataActive,
		        inactiveCapitalData: SDCapitalDataInctive
		    },
		    {
		        id: "SD",
		        arrayItem: true,
		        country: 'Sudan',
		        protest_name: '<span class="protest">Sudanese revolution</span>',
		        date: '2018-12-01',
		        date_text: "December 2018",
		        freedom: 'Not free',
		        triggers: 'Hike in bread prices during a period of high inflation. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Discontent with the ruling party and political and economic elites, economic downturn, and the heavy-handed response to initial protests.',
		        size: 100001,
		        size_text: '>100,000',
		        participants: 'General public, trade union of professionals, women.',
		        timespan: '1 year',
		        timespan_text: '',
		        outcomes: 'Then president Omar al-Bashir was forced out of office and his regime was dismantled. Bashir was convicted by Sudanese prosecutors of corruption and money laundering and sentenced to two years in prison. Sudanese leaders agreed to hand over Bashir to the International Criminal Court to face charges of crimes against humanity. A transitional government composed of both military and civilian was put in place.',
		        significant: true,
		        large: true,
		        violent: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SD", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SD", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SDCapitalDataActive,
		        inactiveCapitalData: SDCapitalDataInctive
		    },
		    {
		        id: "SR",
		        arrayItem: true,
		        country: 'Suriname',
		        protest_name: '<span class="protest">Fuel and electricity price protests</span>',
		        date: '2023-02-01',
		        date_text: "February 2023",
		        freedom: 'Free',
		        triggers: 'The president ended state subsidies for fuel and electricity on the recommendation of the International Monetary Fund.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over hikes in fuel and electricity prices; fears that the president was seeking to postpone the 2025 general election.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        multiple: false,
		        mapData: {
		        	"id": "SR", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SR", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SRCapitalDataActive,
		        inactiveCapitalData: SRCapitalDataInctive
		    },
		    {
		        id: "SE",
		        arrayItem: true,
		        country: 'Sweden',
		        protest_name: '<span class="protest">Quran burning protest</span>',
		        date: '2023-09-01',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'Anti-Islam activist Salwan Momika burned a copy of the Quran in Malmo on September 3.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the burning of the Quran, which is deeply offensive to Muslim people.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Muslims',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protest.',
		        multiple: true,
		        mapData: {
		        	"id": "SE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SECapitalDataActive,
		        inactiveCapitalData: SECapitalDataInctive
		    },
		    {
		        id: "SE",
		        arrayItem: true,
		        country: 'Sweden',
		        protest_name: '<span class="protest">Restore Wetlands protests</span>',
		        date: '2023-06-01',
		        date_text: "June 2023",
		        freedom: 'Free',
		        triggers: 'The Swedish government continued to fall short of its climate targets.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Fears of the impacts of climate change.',
		        size: 99,
		        size_text: '<100',
		        participants: 'Climate activists.',
		        timespan: '2 weeks',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SECapitalDataActive,
		        inactiveCapitalData: SECapitalDataInctive
		    },
		    {
		        id: "SE",
		        arrayItem: true,
		        country: 'Sweden',
		        protest_name: '<span class="protest">Anti-Turkish government protests</span>',
		        date: '2023-01-01',
		        date_text: "January 2023",
		        freedom: 'Free',
		        triggers: 'Türkiye held up Sweden’s accession to NATO, citing Sweden’s refusal to extradite to Türkiye dozens of individuals whom Türkiye linked to the Kurdistan Workers’ Party (PKK) terrorist group.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over Sweden’s stymied NATO accession on account of Türkiye’s lack of support, as well as Islamophobia.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Kurds, Rasmus Paludan.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'Turkish President Recep Tayyip Erdogan made explicitly clear that Sweden cannot expect Türkiye’s support for NATO membership.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SE", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SE", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SECapitalDataActive,
		        inactiveCapitalData: SECapitalDataInctive
		    },
		    {
		        id: "CH",
		        arrayItem: true,
		        country: 'Switzerland',
		        protest_name: '<span class="protest">2024 Women’s Strike</span>',
		        date: '2024-06-01',
		        date_text: "June 2024",
		        freedom: 'Free',
		        triggers: 'Anniversary of Women’s Strike Day on June 14.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demanding equal pay and equal rights for women. Also protesting against gender discrimination, harrassment, sexual violence, and racism.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Women, general public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "CH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CHCapitalDataActive,
		        inactiveCapitalData: CHCapitalDataInctive
		    },
		    {
		        id: "CH",
		        arrayItem: true,
		        country: 'Switzerland',
		        protest_name: '<span class="protest">Climate protests</span>',
		        date: '2021/09/03',
		        date_text: "September 2023",
		        freedom: 'Free',
		        triggers: 'Elections were scheduled for under one month later.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustrations with the pace at which the Swiss government was implementing policies to curb the worst effects of climate change.',
		        size: 60001,
		        size_text: '>60,000',
		        participants: 'General public.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "CH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CHCapitalDataActive,
		        inactiveCapitalData: CHCapitalDataInctive
		    },
		    {
		        id: "CH",
		        arrayItem: true,
		        country: 'Switzerland',
		        protest_name: '<span class="protest">Coronavirus restriction protests</span>',
		        date: '2021-02-01',
		        date_text: "February 2021",
		        freedom: 'Free',
		        triggers: 'The imposition of vaccine mandates and public health restrictions across Switzerland.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with ongoing coronavirus restrictions and mandates; demands for a national referendum on COVID-19 policy.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'General public.',
		        timespan: '1 year',
		        timespan_text: '',
		        outcomes: 'Switzerland held a referendum on legislation enabling coronavirus restrictions, including a health pass in November 2021, which passed. ',
		        significant: true,
		        covid: true,
		        long: true,
		        multiple: true,
		        mapData: {
		        	"id": "CH", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "CH", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: CHCapitalDataActive,
		        inactiveCapitalData: CHCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Tishreen Dam protests</span>',
		        date: '2025-01-01',
		        date_text: "January 2025",
		        freedom: 'Not free',
		        triggers: 'Intensified fighting between the Turkish-backed Syrian National Army (SNA) and the Kurdish-led Syrian Democratic Forces (SDF).',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Call for an end to Turkish airstrikes in the northeast region of Syria, particularly near the Tishreen Dam (a key source of water and electricity).',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Kurdish residents',
		        timespan: 'Active',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. Turkish forces launched airstrikes in the area admist ongoing protests, leading to the deaths of at least five protestors.',
				active: true,
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Protest for religious freedom</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Not free',
		        triggers: 'A video posted on social media showed masked individuals burning down a Christmas tree. The video was taken in Suqaylabiyah, a Christian-majority town. Hayat Tahrir al-Sham (HTS), the main Islamist group that led the uprising against former president Bashar al-Assad, announced that they had detained the foreign fighters responsible for the arson attack.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Express anger over burning of the Christmas tree. Demand that non-Syrians leave the country. Demand rights and protection of Christians (and other religious minorities) in Syria.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Christians',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Protest for political prisoners</span>',
		        date: '2024-12-01',
		        date_text: "December 2024",
		        freedom: 'Not free',
		        triggers: 'Funeral procession of Mazen al-Hamada, a Syrian activist who was imprisoned and recently found dead in a hospital in Damascus.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand justice for Al-Hamada and many others who had been imprisoned during former president Bashar al-Assad’s rule. Some also called for Assad to be tried or executed.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change.',
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Anti-Türkiye protest</span>',
		        date: '2024-07-01',
		        date_text: "July 2024",
		        freedom: 'Not free',
		        triggers: 'Anti-Syrian and anti-refugee protests in Türkiye, where Syrian houses, businesses, and cars were destroyed or vandalized.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Protesting against Turkish military presence in Syria and against anti-Syrian protests in Türkiye.',
		        size: 101,
		        size_text: '>100',
		        participants: 'General public',
		        timespan: '1 day',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change. In the Türkiye-controlled region of northern Syria, seven people were killed by the Turkish military.  Türkiye closed its main border along northern Syria.',
				violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Anti-Assad protests</span>',
		        date: '2023-08-01',
		        date_text: "August 2023",
		        freedom: 'Not free',
		        triggers: 'The government slashed fuel subsidies, nearly tripling the price of petrol. ',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
		        motivation_text: 'Anger over the country’s economic woes, poor delivery of public goods and services, government repression, the continued imprisonment of political prisoners, rampant corruption, and sectarianism.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Druze in Sweida',
		        timespan: '1 year',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
				long: true,
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Anti-normalization protests</span>',
		        date: '2023-04-01',
		        date_text: "April 2023",
		        freedom: 'Not free',
		        triggers: 'A number of Arab states moved to normalize relations with Syria and proposed returning Syria to the Arab League.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over the lack of accountability for the Assad regime’s war crimes during the Syrian civil war, fear of heightened repression and conflict that would result from wider normalization of ties with Syria.',
		        size: 101,
		        size_text: '>100',
		        participants: 'Opposition forces in the northwest of Syria.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to the protests.',
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Nowruz mass shooting protests</span>',
		        date: '2023-03-01',
		        date_text: "March 2023",
		        freedom: 'Not free',
		        triggers: 'Assailants belonging to Jaish al-Sharqiya, a splinter group of Ahrar Sharqiya, shot and killed four Kurdish civilians celebrating the Nowruz holiday.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Anger over perceived mistreatment of and discrimination against Kurdish residents of Jinderis by Turkish-backed opposition groups that have controlled the town since 2018.',
		        size: 1001,
		        size_text: '>1,000',
		        participants: 'Kurds in Jinderis',
		        timespan: '3 days',
		        timespan_text: '',
		        outcomes: 'An official with the Harakat al-Tahrir wa al-Binaa, a faction within the Syrian National Army, announced that the suspects in the killing had been arrested and promised that local authorities will “deal firmly and forcefully with all perpetrators of crimes and violations against innocent people.”',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">2022 economic crisis protests</span>',
		        date: '2022-12-01',
		        date_text: "December 2022",
		        freedom: 'Not free',
		        triggers: 'The country’s deteriorating economic situation.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Frustration with government ineffectiveness in combating rising prices, power cuts, and food and fuel shortages.',
		        size: 200,
		        size_text: '200',
		        participants: 'Druze citizens of Sweida Province.',
		        timespan: '1 week',
		        timespan_text: '',
		        outcomes: 'No policy/leadership change in response to protests.',
		        violent: true,
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "SY",
		        arrayItem: true,
		        country: 'Syria',
		        protest_name: '<span class="protest">Economic crisis protests</span>',
		        date: '2020-06-01',
		        date_text: "June 2020",
		        freedom: 'Not free',
		        triggers: 'Imposition of U.S. sanctions that decreased the value of the Syrian pound by two-thirds.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Economic insecurity stemming from the rapid devaluation of the Syrian pound.',
		        size: 501,
		        size_text: '>500',
		        participants: 'General public.',
		        timespan: '1 month',
		        timespan_text: '',
		        outcomes: 'Prime minister Imad Khamis was fired from his post.',
		        significant: true,
		        multiple: true,
		        mapData: {
		        	"id": "SY", 
			  		settings: { fill: trackedCountry },
		        },
		        inactiveMapData: {
		        	"id": "SY", 
			  		settings: { fill: inactiveCountry },
		        },
		        capitalData: SYCapitalDataActive,
		        inactiveCapitalData: SYCapitalDataInctive
		    },
		    {
		        id: "TW",
		        arrayItem: true,
		        country: 'Taiwan',
		        protest_name: '<span class="protest">"Little Sprouts" protest</span>',
		        date: '2024-09-01',
		        date_text: "September 2024",
		        freedom: 'Free',
		        triggers: 'Ko Wen-je, the former mayor of Taipei, was re-arrested as part of an ongoing investigation over allegations of corruption. His political party, the Taiwan People’s Party (TPP), called for a rally in support of Ko.',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Demand Ko’s release. Protesters proclaim Ko’s innocence and believe that President Lai Ching-te’s administration is prosecuting Ko for political reasons.',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'TPP supporters (called "Little Sprouts")',
		        timespan: '2 days',
		        timespan_text: '',
				outcomes: 'No policy/leadership change.',
				multiple: true,
				mapData: {
					"id": "TW", 
					settings: { fill: trackedCountry },
				},
				inactiveMapData: {
					"id": "TW", 
					settings: { fill: inactiveCountry },
				},
				capitalData: TWCapitalDataActive,
				inactiveCapitalData: TWCapitalDataInctive
			},
		    {
		        id: "TW",
		        arrayItem: true,
		        country: 'Taiwan',
		        protest_name: '<span class="protest">Bluebird Movement against parliamentary reforms</span>',
		        date: '2024-05-01',
		        date_text: "May 2024",
		        freedom: 'Free',
		        triggers: 'The Kuomintang and Taiwan People’s Party passed legislation in Parliament giving lawmakers power to ask the military, private companies, or individuals to disclose information deemed relevant by parliamentarians. The law gives Parliament power to punish those who fail to comply with these requests. It also criminalizes contempt of Parliament by government officials and requires the president to give regular reports to Parliament and answer lawmakers’ questions. Several Kuomintang senior officials have visited China this year to keep open lines of communication as China refuses to talk with President Lai Ching-te or the Democratic Progressive Party, claiming they are "separatists." Members and supporters of the Democratic Progressive Party fear collusion between the Kuomintang and China—although the Kuomintang denies being pro-China. ',
		        motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'Supporters of the Democratic Progressive Party protested against the bill, which they say was railroaded through the voting process by lawmakers and lacks adequate checks and balances to prevent abuse. They say the bill could undermine national security, as it would force government officials to disclose critical information of value to China.  ',
		        size: 10001,
		        size_text: '>10,000',
		        participants: 'Democratic Progressive Party supporters',
		        timespan: '2 weeks',
		        timespan_text: '',
				outcomes: 'Lai and the cabinet rejected the bill and sent it back for constitutional review. The opposition-led Taiwanese parliament pushed the bill forward and passed it on May 28, 2024.',
				multiple: true,
				mapData: {
					"id": "TW", 
					settings: { fill: trackedCountry },
				},
				inactiveMapData: {
					"id": "TW", 
					settings: { fill: inactiveCountry },
				},
				capitalData: TWCapitalDataActive,
				inactiveCapitalData: TWCapitalDataInctive
			},
		    {
		        id: "TW",
		        arrayItem: true,
		        country: 'Taiwan',
		        protest_name: '<span class="protest">Taiwan “Yellow Vest” protests</span>',
		        date: '2018-12-01',
		        date_text: "December 2018",
		        freedom: 'Free',
		        triggers: 'High taxes and the unfair handling of tax disputes.',
		        motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
		        motivation_text: 'An unfair and confusing taxation system.',
		        size: 20000,
		        size_text: '20,000',
		        participants: 'Activist groups, general public.',
		        timespan: '2 weeks',
		        timespan_text: '',
				outcomes: 'No policy/leadership change in response to the protests.',
				multiple: true,
				mapData: {
					"id": "TW", 
					settings: { fill: trackedCountry },
				},
				inactiveMapData: {
					"id": "TW", 
					settings: { fill: inactiveCountry },
				},
				capitalData: TWCapitalDataActive,
				inactiveCapitalData: TWCapitalDataInctive
		},
		{
			id: "TW",
			arrayItem: true,
			country: 'Taiwan',
			protest_name: '<span class="protest">Pension reform protests</span>',
			date: '2017-01-01',
			date_text: "January 2017",
			freedom: 'Free',
			triggers: 'Pension reform plans.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Discontent with the government, slowing economic growth, and concerns over the pension system’s solvency with a rapidly aging population.',
			size: 12000,
			size_text: '12,000',
			participants: 'Public employees such as military personnel, teachers, police, civil servants.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "TW", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TW", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TWCapitalDataActive,
			inactiveCapitalData: TWCapitalDataInctive
		},
		{
			id: "TJ",
			arrayItem: true,
			country: 'Tajikistan',
			protest_name: '<span class="protest">Gorno-Badakhshan protests</span>',
			date: '2022-05-01',
			date_text: "May 2022",
			freedom: 'Not free',
			triggers: 'The police killing of Gulbiddin Ziyobekov in November 2021 and the government’s subsequent rejection of demands to investigate the death and address other grievances.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration at perceived government efforts to reduce the Gorno-Badakhshan region’s autonomy and repress activism; persistent police violence.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Residents of the Gorno-Badakhshan Autonomous Region.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			violent: true,
			multiple: false,
			mapData: {
				"id": "TJ", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TJ", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TJCapitalDataActive,
			inactiveCapitalData: TJCapitalDataInctive
		},
		{
			id: "TZ",
			arrayItem: true,
			country: 'Tanzania',
			protest_name: '<span class="protest">Protest by Maasai people</span>',
			date: '2024-08-01',
			date_text: "August 2024",
			freedom: 'Partly Free',
			triggers: 'The National Electoral Commission announced a new National Election Plan for the upcoming October 2025 elections, which dissolved various districts, villages, and hamlets, including villages in the Ngorongoro regions.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Protest the dissolvement of Ngorongoro villages, which demonstrators claim is a way for the government to disenfranchise the Maasai people and to pass laws that will force the community out of the region. The protest is part of a larger series of demonstrations for land rights and basic services, brought on by the government’s ongoing plan to relocate the Maasai people out of Ngorongoro.',
			size: 101,
			size_text: '>100',
			participants: 'Maasai people in Ngorongoro, Tanzania',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'On August 22, the High Court in Arusha issued an injunction against the dissolution of districts, villages, and hamlets. On September 16, the minister of state, Mohamed Mchengerwa, announced the new electoral boundaries for the local government elections, which reinstated the status of Ngorongoro’s villages.',
			significant: true,
			multiple: false,
			mapData: {
				"id": "TZ", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TZ", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TZCapitalDataActive,
			inactiveCapitalData: TZCapitalDataInctive
		},
		{
			id: "TH",
			arrayItem: true,
			country: 'Thailand',
			protest_name: '<span class="protest">Move Forward protests</span>',
			date: '2023-07-01',
			date_text: "July 2023",
			freedom: 'Not free',
			triggers: 'Thailand’s parliament voted to prevent Pita Limjaroenrat of the opposition Move Forward Party from standing for election for the premiership for a second time. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over the perceived anti-democratic actions of the parliament and Supreme Court.',
			size: 101,
			size_text: '>100',
			participants: 'Supporters of the Move Forward Party',
			timespan: '2 weeks',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TH", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TH", 
				settings: { fill: inactiveCountry },
			},
			capitalData: THCapitalDataActive,
			inactiveCapitalData: THCapitalDataInctive
		},
		{
			id: "TH",
			arrayItem: true,
			country: 'Thailand',
			protest_name: '<span class="protest">Term limit protests</span>',
			date: '2022-08-01',
			date_text: "August 2022",
			freedom: 'Not free',
			triggers: 'Prime Minister Prayuth Chan-ocha refused to step down upon the date which activists considered to be his term limit.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with the crackdown on dissent and erosion of freedoms since the country’s 2014 coup; discontent with the government’s mishandling of economic and pandemic-related issues. ',
			size: 200,
			size_text: '200',
			participants: 'General public.',
			timespan: '2 weeks',
			timespan_text: '',
			outcomes: 'Thailand’s Constitutional Court suspended Prayuth Chan-ocha from official duties while it considered the opposition’s case that Prayuth reached his term limit. Deputy prime minister Prawit Wongsuwan became caretaker prime minister. In September 2022, the country’s Constitutional Court ruled that Prayuth Chan-ocha’s term began in 2017, not 2014, and thus allowed him to stay in power.',
			significant: true,
			multiple: true,
			mapData: {
				"id": "TH", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TH", 
				settings: { fill: inactiveCountry },
			},
			capitalData: THCapitalDataActive,
			inactiveCapitalData: THCapitalDataInctive
		},
		{
			id: "TH",
			arrayItem: true,
			country: 'Thailand',
			protest_name: '<span class="protest">Antigovernment protests</span>',
			date: '2020-07-01',
			date_text: "July 2020",
			freedom: 'Not free',
			triggers: 'Government failure to boost the economy during the pandemic, kidnapping of a leading political activist, and protesters’ desire to repeal Thailand’s strict royal defamation law.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Lack of employment options for recent graudates, laws that shrink freedoms, and discontent with the military-written constitution.',
			size: 10001,
			size_text: '>10,000',
			participants: 'Youth groups, general public.',
			timespan: '1.5 years',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			long: true,
			multiple: true,
			mapData: {
				"id": "TH", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TH", 
				settings: { fill: inactiveCountry },
			},
			capitalData: THCapitalDataActive,
			inactiveCapitalData: THCapitalDataInctive
		},
		{
			id: "TH",
			arrayItem: true,
			country: 'Thailand',
			protest_name: '<span class="protest">Party ban protest</span>',
			date: '2019-12-01',
			date_text: "December 2019",
			freedom: 'Not free',
			triggers: 'Move by judicial and electoral authorities to disband the pro-democracy Future Forward Party.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration over a lack of genuine political pluralism, and repression by the military junta.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TH", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TH", 
				settings: { fill: inactiveCountry },
			},
			capitalData: THCapitalDataActive,
			inactiveCapitalData: THCapitalDataInctive
		},
		{
			id: "TH",
			arrayItem: true,
			country: 'Thailand',
			protest_name: '<span class="protest">Housing project protests</span>',
			date: '2018-04-01',
			date_text: "April 2018",
			freedom: 'Not free',
			triggers: 'Construction of a luxury apartment complex on forested land in Chiang Mai.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concern over environmental degradation and frustration over privileged treatment for public officials.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TH", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TH", 
				settings: { fill: inactiveCountry },
			},
			capitalData: THCapitalDataActive,
			inactiveCapitalData: THCapitalDataInctive
		},
		{
			id: "TG",
			arrayItem: true,
			country: 'Togo',
			protest_name: '<span class="protest">Protest against constitutional reform</span>',
			date: '2024-03-01',
			date_text: "March 2024",
			freedom: 'Partly free',
			triggers: 'Ruling party lawmakers—many of whom are serving beyond when their term was set to expire—passed a new constitution in late March 2024, which awaits President Faure Gnassingbé’s final approval. The new constitution grants parliament the power to choose the president, doing away with direct elections. ',
			active: true,
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Demand a referendum be held to approve the new constitution. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'Opposition members',
			timespan: 'Active',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests. On March 27, 2024, police broke up a news conference called by the opposition in Lomé, forcing opposition leaders and journalists out of the venue and out of the capital. ',
			violent: true,
			multiple: true,
			mapData: {
				"id": "TG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TGCapitalDataActive,
			inactiveCapitalData: TGCapitalDataInctive
		},
		{
			id: "TG",
			arrayItem: true,
			country: 'Togo',
			protest_name: '<span class="protest">Term limit protests</span>',
			date: '2017-09-01',
			date_text: "September 2017",
			freedom: 'Partly free',
			triggers: 'Demands for presidential term limits on President Faure Gnassingbé.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concerns over dynastic politics, poor living conditions, and high youth unemployment.',
			size: 800000,
			size_text: '800,000',
			participants: 'Coalition of opposition parties; protesters from varying age groups, social classes, ethnicities.',
			timespan: '7 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			large: true,
			violent: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "TG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TGCapitalDataActive,
			inactiveCapitalData: TGCapitalDataInctive
		},
		{
			id: "",
			arrayItem: true,
			country: 'Transnistria',
			protest_name: '<span class="protest">Moldova duties protest</span>',
			date: '2024-01-01',
			date_text: "January 2024",
			freedom: 'Not free',
			triggers: 'Chisinau refused to scrap newly introduced duties on imports and exports to and from the region.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Opposition to duties and ongoing territorial dispute with Moldova. ',
			size: 101,
			size_text: '>100',
			participants: 'Residents of Transnistria',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: false,
			mapData: {},
			inactiveMapData: {},
			capitalData: {},
			inactiveCapitalData: {}
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Protest against election manipulation</span>',
			date: '2024-10-01',
			date_text: "October 2024",
			freedom: 'Partly free',
			triggers: 'The electoral commission rejected a ruling by the Administrative Court that reinstated three prominent candidates for the October 6 presidential election. Weeks later, Parliament introduced a bill to take away the Administrative Court’s jurisdiction over electoral disputes.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Denounce actions of both the electoral commission and Parliament. Protesters believe President Kais Saied (who appointed members of the election commission) is trying to rig the presidential elections. Call for the end of Saied’s authoritarian rule and the reinstatement of free and fair elections.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Opposition, general public',
			timespan: '5 weeks',
			timespan_text: '',
			outcomes: 'No policy/leadership change. On September 27, the Parliament approved the bill removing the Administrative Court’s election authority. Saied was re-elected to a second term, winning over ninety percent of the vote.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Anti-immigration protests</span>',
			date: '2024-05-01',
			date_text: "May 2024",
			freedom: 'Partly free',
			triggers: 'Migrants from southern Africa hoping to reach Europe by boat have used the coastal towns of Tunisia as launching pads and pitched encampments in El Amra and Jebeniana, disrupting agricultural production. ',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Demand the government take action to assist agricultural communities dealing with thousands of migrants living in encampments along their olive groves. Express opposition to settling migrants in Tunisia. ',
			size: 101,
			size_text: '>100',
			participants: 'Coastal Tunisians; residents of El Amra and Jebeniana; agricultural workers',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			violent: true,
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Pro-democracy protests</span>',
			date: '2024-05-01',
			date_text: "May 2024",
			freedom: 'Partly free',
			triggers: 'Nearly three years after President Kais Saied’s 2021 "self-coup," the economy has stagnated. Additionally, a wave of arrests has targeted journalists, lawyers, activists, and members of the opposition, including Sonia Dahmani, a lawyer popular for her fierce criticism of Saied. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Demand the release of imprisoned journalists, activists, and opposition members and that a date be set for fair presidential elections. As of September 2024, also protest electoral commission’s decision to reject a court ruling that reinstated three prominent candidates for the upcoming presidential elections. Accuse Saied (who appointed members of the election commission) of trying to rig the presidential elections. ',
			size: 101,
			size_text: '>100',
			participants: 'Opposition; general public',
			timespan: 'Two weeks',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest. Hundreds of Saied’s supporters protested in Tunis on May 18, 2024, to denounce what they say is foreign influence driving the anti-government protests. In July 2024, Saied announced his candidacy for the upcoming presidential elections. ',
			violent: true,
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">2024 police brutality protest</span>',
			date: '2024-05-01',
			date_text: "May 2024",
			freedom: 'Partly free',
			triggers: 'Facing protests against President Kais Saied and demands for a free and fair election, police stormed the bar association’s headquarters on May 13, 2024, and arrested Mahdi Zagrouba—who has criticized Saied—after arresting another lawyer, Sonia Dahmani, days prior. The bar association and human rights groups reported that Zagrouba was tortured during his detention and that he was suffering from severe bruises and traces of violence. The Interior Ministry denies the accusations of torture. Two journalists were also arrested. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Lawyers protested against the government and police brutality, called for democracy, and demanded, "take your hands off journalists and lawyers."',
			size: 101,
			size_text: '>100',
			participants: 'Lawyers',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Mohamad Zouari memorial protests</span>',
			date: '2023-12-01',
			date_text: "December 2023",
			freedom: 'Partly free',
			triggers: 'Seventh anniversary of the assassination of Hamas executive Mohamad Zouari in Tunisia. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Mourn the death of Zouari, seen by many as a martyr, and denounce the ongoing Israel-Hamas war. ',
			size: 101,
			size_text: '>100',
			participants: 'General public.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Nizar Issaoui protests</span>',
			date: '2023-04-01',
			date_text: "April 2023",
			freedom: 'Partly free',
			triggers: 'Nizar Issaoui, a Tunisian soccer player, died by self-immolation to protest abuse by Tunisian police officers. Issaoui claimed that police accused him of terrorism when he complained about the price of bananas.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over police abuse.',
			size: 101,
			size_text: '>100',
			participants: 'General public.',
			timespan: '2 days',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Garbage collection protest</span>',
			date: '2022-11-01',
			date_text: "November 2022",
			freedom: 'Partly free',
			triggers: 'A fire at a landfil in the city of Sfax created dangerous smoke.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger with state failure. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'Residents of Sfax.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Migrant shipwreck burial protests</span>',
			date: '2022-10-01',
			date_text: "October 2022",
			freedom: 'Partly free',
			triggers: 'After 18 Tunisian migrants traveling to Europe died in a shipwreck, authorities buried four in a cemetary for recovered bodies of sub-Saharan migrants without attempting to identify the bodies. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger with state failure. ',
			size: 3001,
			size_text: '>3,000',
			participants: 'General public.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'President Kais Saied ordered the Ministry of Justice to open an investigation.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">2022 police brutality protests</span>',
			date: '2022-09-01',
			date_text: "September 2022",
			freedom: 'Partly free',
			triggers: 'Authorities shot and killed Moshen Zeyani, 23, on the suspicion that he was smuggling cigarettes; a month later, Malek Selimi, 24, died from injuries sustained during a police chase. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over police tactics that demonstrators find to be brutal and excessive. ',
			size: 101,
			size_text: '>100',
			participants: 'General public.',
			timespan: '2 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Economic crisis protests</span>',
			date: '2022-09-01',
			date_text: "September 2022",
			freedom: 'Partly free',
			triggers: 'The government introduced price hikes on gas cooking bottles and fuel as part of a plan to reduce energy subsidies.  ',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over food shortages and rising inflation; frustrations with consolidation of power by President Kais Saied.  ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '2 weeks',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Power grab protests</span>',
			date: '2021-09-01',
			date_text: "September 2021",
			freedom: 'Free',
			triggers: 'President Kais Saied’s efforts to seize governing power by suspending constitutional provisions, ruling by decree, and dismissing parliament. ',
			active: true,
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concerns that Saied is undermining Tunisian democracy and moving toward authoritarian rule, anger over Saied’s crackdown on dissent, and discontent over economic mismanagement, unemployment, and poverty.',
			size: 2001,
			size_text: '>2,0000',
			participants: 'General public.',
			timespan: 'Active',
			timespan_text: '',
			outcomes: 'Saied appointed a new prime minister, Najla Bouden Romdhan, and in December 2021 announced that a constitutional referendum and new elections would be held in 2022.',
			significant: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Coronavirus crisis protests</span>',
			date: '2021-07-01',
			date_text: "July 2021",
			freedom: 'Free',
			triggers: 'A surge in coronavirus infections that has devastated Tunisia’s public health infrastructure and spurred economic insecurity.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with the govenrment’s ineffectual coronavirus response, sluggish vaccine rollouts, declining state services, and persistent economic inequality and insecurity. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'President Kais Saied dismissed prime minister Hichem Mechichi and suspended parliament.',
			covid: true,
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">2021 Police brutality protests</span>',
			date: '2021-06-01',
			date_text: "June 2021",
			freedom: 'Free',
			triggers: 'Death of Ahmed Ben Ammar in police custody on June 8.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with ongoing police brutality. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '2 weeks',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Youth protests</span>',
			date: '2021-01-01',
			date_text: "January 2021",
			freedom: 'Free',
			triggers: 'High youth unemployment and dire economic prospects for young people, especially amid the coronavirus pandemic.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration over the lack of economic opportunity for young people.',
			size: 10001,
			size_text: '>10,000',
			participants: 'Youth.',
			timespan: '3 weeks ',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			covid: true,
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TN",
			arrayItem: true,
			country: 'Tunisia',
			protest_name: '<span class="protest">Unemployment protests</span>',
			date: '2020-06-01',
			date_text: "June 2020",
			freedom: 'Free',
			triggers: 'High unemployment, failing infrastructure, the killing of a young man by police, and the underdevelopment of southern Tunisia’s economy and private sector.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Government’s failure to honor its 2017 pledge to invest millions in southern Tunisia’s economy and generate jobs. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'Prime Minister Elyes Fakhfakh resigned.',
			significant: true,
			violent: true,
			multiple: true,
			mapData: {
				"id": "TN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TNCapitalDataActive,
			inactiveCapitalData: TNCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Minimum wage protest</span>',
			date: '2024-12-01',
			date_text: "December 2024",
			freedom: 'Not free',
			triggers: 'The Turkish government raised the monthly minimum wage for 2025 to 22,104 Turkish lira, which was a 30 percent increase from the 2024 minimum wage.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Demand greater increase in minimum wage. Frustration over cost-of-living crisis and high inflation rate, as well as government’s inefficient response to the crisis. Call on government to resign.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public, workers',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Protest against gender-based violence</span>',
			date: '2024-11-01',
			date_text: "November 2024",
			freedom: 'Not free',
			triggers: 'International Day for the Elimination of Violence. More than 400 women have been murdered in Türkiye in 2024.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Denounce gender-based violence. ',
			size: 101,
			size_text: '>100',
			participants: 'Women',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change. Dozens of protesters were arrested.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Protest against removal of mayors</span>',
			date: '2024-10-01',
			date_text: "October 2024",
			freedom: 'Not free',
			triggers: 'Ahmet Özer, the mayor of Istanbul’s Esenyurt district and a member of the Republican People’s Party (CHP), was arrested over an alleged connection to the Kurdistan Workers’ Party (PKK), a banned Kurdish militant group. Istanbul’s deputy governor replaced Özer as mayor. In November 2024, three more pro-Kurdish mayors across Türkiye were removed on terrorism charges.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
			motivation_text: 'Denounce the mayors’ removal from office as an attack on the opposition and the Kurdish people. Call for the resignation of President Recep Tayyip Erdoğan’s government. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'Opposition',
			timespan: '1 week;<br />1 day in January 2025',
			timespan_text: '',
			outcomes: 'No policy/leadership change. The three mayors were replaced by government-appointed officials. Since October 2024, Erdoğan’s government has continuously various different opposition mayors, including Ekrem Imamoglu, a key opposition figure and a potential challenger to Erdoğan.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Anti-Syrian riots</span>',
			date: '2024-07-01',
			date_text: "July 2024",
			freedom: 'Not free',
			triggers: 'Turkish authorities arrested a Syrian man accused of sexually abusing a seven-year-old girl.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anti-Syrian sentiments; protesting against immigration and Syrian refugees; demanding Syrian refugees be expelled from Türkiye.',
			size: 101,
			size_text: '>100',
			participants: 'General public',
			timespan: '3 days',
			timespan_text: '',
			outcomes: 'No policy/leadership change. Over 470 people were arrested for violence against the Syrian community during the protests.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">International Women’s Day protest</span>',
			date: '2023-03-01',
			date_text: "March 2023",
			freedom: 'Not free',
			triggers: 'The commemoration of International Women’s Day.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over continued femicide in Türkiye and perceived inadequate protections provided by the Turkish government; anger over the high death toll from Türkiye’s February 6 earthquake.',
			size: 2000,
			size_text: '2,000',
			participants: 'Women.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protest.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Earthquake protests</span>',
			date: '2023-02-01',
			date_text: "February 2023",
			freedom: 'Not free',
			triggers: 'Türkiye experienced a magnitude 7.8 earthquake on February 6 that killed more than 40,000 people in the country.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over what protesters believe was a slow and ineffective response to the earthquake, and over government construction policies that the protesters believed contributed to the high death toll.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Fans of the Besiktas and Fenerbahce soccer clubs.',
			timespan: '2 days',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Ekrem Imamoglu protests</span>',
			date: '2022-12-01',
			date_text: "December 2022",
			freedom: 'Not free',
			triggers: 'A criminal court sentenced Istanbul Mayor Ekrem Imamoglu to two years in prison and banned him from politics for insulting public officials in a speech he made after he won Istanbul’s election in 2019. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with a lack of political pluralism and the suppression of dissent in Türkiye.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public, especially supporters of the opposition.',
			timespan: '2 days',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Opposition leader conviction protest </span>',
			date: '2022-05-01',
			date_text: "May 2022",
			freedom: 'Not free',
			triggers: 'A call for protest by the opposition Republican People’s Party (CHP) after the conviction and sentencing of Canan Kaftancıoğlu, leader of the Istanbul branch of the CHP.  ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with rising autocratization under Recep Tayyip Erdoğan’s government and the repression of opposition parties. ',
			size: 10001,
			size_text: '>10,000',
			participants: 'General public, especially CHP supporters.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Treaty withdrawal protests</span>',
			date: '2021-03-01',
			date_text: "March 2021",
			freedom: 'Not free',
			triggers: 'Turkish govenrment’s announcement that it would withdraw from the 2011 Istanbul Convention on violence against women.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over widespread gender-based violence and discrimination against LGBT communities.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public, especially women.',
			timespan: '9 months, intermittently',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">University rector protests</span>',
			date: '2021-01-01',
			date_text: "January 2021",
			freedom: 'Not free',
			triggers: 'President Recep Tayyip Erdogan’s appointment of Melih Bulu as rector of Bogazici University. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concern that the Erdogan govenrment is undermining academic freedom at one of Türkiye’s leading universities.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Students.',
			timespan: '2 months',
			timespan_text: '',
			outcomes: 'In July 2021, Bulu was dismissed as rector.',
			significant: true,
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Gender-based violence protest</span>',
			date: '2020-08-01',
			date_text: "August 2020",
			freedom: 'Not free',
			triggers: 'Reports that President Recep Tayyip Erdogan was considering withdrawing Türkiye from the Istanbul Convention, which put forward principles to fight violence against women.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over the government’s failure to address persistent violence against women.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Women.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Mayoral appointment protests</span>',
			date: '2019-09-01',
			date_text: "September 2019",
			freedom: 'Not free',
			triggers: 'Government move to replace elected Kurdish mayors with state-appointed officials.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over the government’s move to reduce Kurdish autonomy.',
			size: 10001,
			size_text: '>10,000',
			participants: 'Kurds.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">Economic protest</span>',
			date: '2018-12-01',
			date_text: "December 2018",
			freedom: 'Not free',
			triggers: 'Rising cost of living, high inflation, and “Yellow Vests” movement in France.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Rising inflation, slowing economic growth, and high cost of living. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'Public service workers’ unions.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			violent: true,
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">State of emergency protests</span>',
			date: '2018-04-01',
			date_text: "April 2018",
			freedom: 'Not free',
			triggers: 'Announcement that the government would extend a nationwide state of emergency a seventh time. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concerns over authoritarian consolidation under President Recep Tayyip Erdogan in the wake of the 2016 coup attempt.',
			size: 5001,
			size_text: '>5,000',
			participants: 'General public.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TR",
			arrayItem: true,
			country: 'Türkiye',
			protest_name: '<span class="protest">“March for Justice” protest</span>',
			date: '2017-07-01',
			date_text: "July 2017",
			freedom: 'Not free',
			triggers: 'End of twenty-five-day “March for Justice” against President Recep Tayyip Erdoğan.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Government crackdown against critics and civil servants and the expansion of executive power. ',
			size: 100001,
			size_text: '>100,000',
			participants: 'Türkiye’s largest opposition party.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			large: true,
			multiple: true,
			mapData: {
				"id": "TR", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TR", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TRCapitalDataActive,
			inactiveCapitalData: TRCapitalDataInctive
		},
		{
			id: "TM",
			arrayItem: true,
			country: 'Turkmenistan',
			protest_name: '<span class="protest">Storm damage protest</span>',
			date: '2020-05-01',
			date_text: "May 2020",
			freedom: 'Not free',
			triggers: 'Government failure to provide aid after a severe storm hit the city of Turkmenabat.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration over poor service delivery and the government’s failure to address reconstruction needs after the storm.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: false,
			mapData: {
				"id": "TM", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "TM", 
				settings: { fill: inactiveCountry },
			},
			capitalData: TMCapitalDataActive,
			inactiveCapitalData: TMCapitalDataInctive
		},
		{
			id: "UG",
			arrayItem: true,
			country: 'Uganda',
			protest_name: '<span class="protest">Anti-corruption protest</span>',
			date: '2024-07-01',
			date_text: "July 2024",
			freedom: 'Not free',
			triggers: 'Allegations of corruption against Parliament Speaker, Anita Among. Also inspired by the Finance Bill protests in Kenya.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
			motivation_text: 'Protesting against government corruption and alleged human rights abuses. President Yoweri Museveni has long been accused of failing to prosecute senior officials over corrupt activities such as embezzlement and misuse of government funds.',
			size: 101,
			size_text: '>100',
			participants: 'General public, youth',
			timespan: '2 days (July 23 and July 25)',
			timespan_text: '',
			outcomes: 'No policy/leadership change. Security forces were deployed to surround the headquarters of the main opposition party before the protests began. Amensty International reported that over 100 protesters were arrested.',
			multiple: true,
			mapData: {
				"id": "UG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UGCapitalDataActive,
			inactiveCapitalData: UGCapitalDataInctive
		},
		{
			id: "UG",
			arrayItem: true,
			country: 'Uganda',
			protest_name: '<span class="protest">Police brutality protest</span>',
			date: '2023-04-01',
			date_text: "April 2023",
			freedom: 'Not free',
			triggers: 'According to protesters, Ugandan police used excessive force to disperse various events organized by female lawmakers in their local constituencies in the weeks prior.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over perceived police brutality and excessive force, especially against women.',
			size: 99,
			size_text: '<100',
			participants: 'Female opposition lawmakers. ',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "UG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UGCapitalDataActive,
			inactiveCapitalData: UGCapitalDataInctive
		},
		{
			id: "UG",
			arrayItem: true,
			country: 'Uganda',
			protest_name: '<span class="protest">Cost of living protests</span>',
			date: '2022-05-01',
			date_text: "May 2022",
			freedom: 'Not free',
			triggers: 'Rising prices of food and fuel.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Worsening economic conditions, accelerated by the war in Ukraine.',
			size: 101,
			size_text: '>100',
			participants: 'General public. ',
			timespan: '2 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "UG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UGCapitalDataActive,
			inactiveCapitalData: UGCapitalDataInctive
		},
		{
			id: "UG",
			arrayItem: true,
			country: 'Uganda',
			protest_name: '<span class="protest">Electoral protests</span>',
			date: '2021-01-01',
			date_text: "January 2021",
			freedom: 'Not free',
			triggers: 'Declaration by the Ugandan electoral commission that incumbent President Yoweri Museveni had won reelection amid widespread allegations of electoral fraud and manipulation.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concerns over lack of electoral integrity and meaningful political pluralism.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Supporters of opposition leader and presidential candidate Bobi Wine.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			violent: true,
			multiple: true,
			mapData: {
				"id": "UG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UGCapitalDataActive,
			inactiveCapitalData: UGCapitalDataInctive
		},
		{
			id: "UG",
			arrayItem: true,
			country: 'Uganda',
			protest_name: '<span class="protest">Opposition leader arrest protests</span>',
			date: '2020-11-01',
			date_text: "November 2020",
			freedom: 'Not free',
			triggers: 'Arrest of opposition presidential candidates Bobi Wine and Patrick Amuriat.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Opposition to the entrenchment of incumbent president Yoweri Museveni, lack of genuine political pluralism, and recurring crackdowns on political opposition. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public, especially supporters of Bobi Wine and Patrick Amuriat.',
			timespan: '3 months',
			timespan_text: '',
			outcomes: 'No policy or leadership change in response to the protests.',
			violent: true,
			multiple: true,
			mapData: {
				"id": "UG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UGCapitalDataActive,
			inactiveCapitalData: UGCapitalDataInctive
		},
		{
			id: "UG",
			arrayItem: true,
			country: 'Uganda',
			protest_name: '<span class="protest">Internet tax protests</span>',
			date: '2018-07-01',
			date_text: "July 2018",
			freedom: 'Not free',
			triggers: 'Announcement of government plans to introduce a tax on social media and online communication platforms.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concern over the government’s stifling of dissent and raising of taxes on commonly used services.',
			size: 501,
			size_text: '>500',
			participants: 'General public.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "UG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UGCapitalDataActive,
			inactiveCapitalData: UGCapitalDataInctive
		},
		{
			id: "UG",
			arrayItem: true,
			country: 'Uganda',
			protest_name: '<span class="protest">Age limit protests</span>',
			date: '2017-09-01',
			date_text: "September 2017",
			freedom: 'Not free',
			triggers: 'Introduction of a legislative proposal to remove the age limit on the presidency, empowering President Yoweri Museveni to stay in power beyond 2021.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concern over the entrenchment of Museveni’s regime and the removal of constitutional safeguards.',
			size: 501,
			size_text: '>500',
			participants: 'Students.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			violent: true,
			multiple: true,
			mapData: {
				"id": "UG", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UG", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UGCapitalDataActive,
			inactiveCapitalData: UGCapitalDataInctive
		},
		{
			id: "UA",
			arrayItem: true,
			country: 'Ukraine',
			protest_name: '<span class="protest">Money for the AFU protest</span>',
			date: '2023-12-01',
			date_text: "December 2023",
			freedom: 'Partly free',
			triggers: 'Discontent with spending by the Kyiv City Council on urban renewal projects during the war. Allegations of corruption in Ukraine  ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Protesters gathered outside Kyiv City Hall on December 14 to denounce "wasteful" spending by municipal officials and demand that money for projects in Kyiv be diverted to the war effort.  ',
			size: 101,
			size_text: '>100',
			participants: 'Money for the AFU',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest. ',
			multiple: true,
			mapData: {
				"id": "UA", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UA", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UACapitalDataActive,
			inactiveCapitalData: UACapitalDataInctive
		},
		{
			id: "UA",
			arrayItem: true,
			country: 'Ukraine',
			protest_name: '<span class="protest">Protest by soldiers’ loved ones</span>',
			date: '2023-10-01',
			date_text: "October 2023",
			freedom: 'Partly free',
			triggers: '26,000 Ukrainians missing since Russia launched its invasion in February 2022. General mobilization of men aged 25-60 since the invasion. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Demand answers for whereabouts of the missing people. Also demand an 18-month limit on mandatory conscription.',
			size: 101,
			size_text: '>100',
			participants: 'Women',
			timespan: '3 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests. ',
			multiple: true,
			mapData: {
				"id": "UA", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UA", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UACapitalDataActive,
			inactiveCapitalData: UACapitalDataInctive
		},
		{
			id: "UA",
			arrayItem: true,
			country: 'Ukraine',
			protest_name: '<span class="protest">Peace plan protests</span>',
			date: '2019-10-01',
			date_text: "October 2019",
			freedom: 'Partly free',
			triggers: 'President Volodymyr Zelensky’s decision to grant special status to a separatist region in eastern Ukraine as part of a larger deal with Moscow.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Opposition to policy changes regarding Russia. ',
			size: 20000,
			size_text: '20,000',
			participants: 'War veterans, moderate critics of Zelensky, nationalist and far-right activists.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "UA", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UA", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UACapitalDataActive,
			inactiveCapitalData: UACapitalDataInctive
		},
		{
			id: "AE",
			arrayItem: true,
			country: 'United Arab Emirates',
			protest_name: '<span class="protest">COP28 protests</span>',
			date: '2023-12-01',
			date_text: "December 2023",
			freedom: 'Not free',
			triggers: 'COP28 was held in Dubai.  ',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
			motivation_text: 'Protesters rallied against migrant worker abuse, climate change and the use of fossil fuels, and the Israel-Hamas war. Protesters also denounced killings of indigenous people.  ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public',
			timespan: '2 weeks',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: false,
			mapData: {
				"id": "AE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "AE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: AECapitalDataActive,
			inactiveCapitalData: AECapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Inheritance tax protest</span>',
			date: '2024-11-01',
			date_text: "November 2024",
			freedom: 'Free',
			triggers: 'Rachel Reeves, the Chancellor of the Exchequer (finance minister), presented major budget reforms to Parliament in October 2024. One of the reforms proposed a 20 percent inheritance tax for farms worth more than 1 million pounds. Since 1992, farms have been exempt from inheritance taxes under the agricultural property relief scheme.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Express anger over the proposed inheritance tax, which farmers say will put severe financial pressure on family farms and force them to sell land or farming assets. Express frustration with government for not understanding the financial struggles of rural farming communities, particularly since Brexit. ',
			size: 10001,
			size_text: '>10,000',
			participants: 'Farmers, National Farmers’ Union (NFU)',
			timespan: 'Active',
			timespan_text: '',
			outcomes: 'No policy/leadership change. Both Reeves and Prime Minister Keir Starmer have reaffirmed their commitment to the proposed budget.',
			active: true,
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Farmers’ protest 2024</span>',
			date: '2024-03-01',
			date_text: "March 2024",
			freedom: 'Free',
			triggers: 'Farmers’ frustration with trade deals signed since the country’s exit from the European Union in 2020, which lack import checks and allow poorer quality food to enter the UK from countries with less regulation, undercutting domestic prices. Additionally, farmers have seen rising costs and a decrease in seasonal workers since Brexit. ',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Protest against post-Brexit trade deals and demand support for British farmers. ',
			size: 101,
			size_text: '>100',
			participants: 'Save British Farming',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests. ',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Rwanda plan protests</span>',
			date: '2023-12-01',
			date_text: "December 2023",
			freedom: 'Free',
			triggers: 'The government approved new immigration policies on December 12 that include sending asylum seekers to Rwanda via boat for permanent resettlement. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Demonstrators believe the law is racist and xenophobic. ',
			size: 101,
			size_text: '>100',
			participants: 'Opposition, Labour',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Greenpeace protests</span>',
			date: '2023-08-01',
			date_text: "August 2023",
			freedom: 'Free',
			triggers: 'On July 31, the UK government announced that it would issue new licenses for oil and gas exploration in the North Sea.',
			active: true,
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over perceived government inaction to address climate change.',
			size: 99,
			size_text: '<100',
			participants: 'Climate activists',
			timespan: 'Active',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests. Three protesters who climbed Prime Minister Rishi Sunak’s North Yorkshire home in August 2023 were charged with criminal damage in February 2024.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">This Is Rigged protests</span>',
			date: '2023-07-01',
			date_text: "July 2023",
			freedom: 'Free',
			triggers: 'Policy decisions of the UK government that protesters believed were insufficient to address climate change.',
			active: true,
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Fears related to the effects of climate change.',
			size: 99,
			size_text: '<100',
			participants: 'Climate activists',
			timespan: 'Active',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Coronation protests</span>',
			date: '2023-05-01',
			date_text: "May 2023",
			freedom: 'Free',
			triggers: 'The coronation of King Charles III.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustrations with the high public expenditures on the coronation ceremony; anger that the United Kingdom’s head of state is not democratically elected.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Republicans.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'The Metropolitan police arrested fifty-two protesters, drawing condemnation from the UK director of Human Rights Watch.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Extinction Rebellion protests</span>',
			date: '2023-04-01',
			date_text: "April 2023",
			freedom: 'Free',
			triggers: 'Movement organizers called for a mass demonstration to coincide with Earth Day 2023.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Fears that an environmental catastrophe would result from a lack of drastic action to combat climate change.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Climate activists.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Pro-doctors protest</span>',
			date: '2023-03-01',
			date_text: "March 2023",
			freedom: 'Free',
			triggers: 'The British Medical Association, a union representing doctors in the UK, called a strike to put pressure on the government to increase wages for junior doctors.',
			active: true,
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Fears over the consequences of the junior doctors’ strike, sympathy with the junior doctors’ cause, and anger with the British government for insufficiently paying doctors. Doctors started a three-day strike—the eighth since March 2023—for junior doctors and consultants after the government offered a small raise that would amount to only a 1 percent raise when accounting for inflation.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Healthcare workers, general public.',
			timespan: 'Active',
			timespan_text: '',
			outcomes: 'In late November 2023, the government and medical trade unions announced they had reached a deal that could end the strike, including reforms to the pay structure for consultants. ',
			long: true,
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">March of the Mummies</span>',
			date: '2022-10-01',
			date_text: "October 2022",
			freedom: 'Free',
			triggers: 'High and increasing costs of childcare in the United Kingdom.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Fears of economic hardship against the backdrop of soaring inflation in the United Kingdom, structural economic barriers for women.',
			size: 15001,
			size_text: '>15,000',
			participants: 'Women.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Rising price protests</span>',
			date: '2022-04-01',
			date_text: "April 2022",
			freedom: 'Free',
			triggers: 'Rising prices for many goods, including fuel and food; call for protest by labor unions.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with rising prices and economic insecurity. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '7 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Just Stop Oil protests</span>',
			date: '2022-03-01',
			date_text: "March 2022",
			freedom: 'Free',
			triggers: 'The government issued new licenses for the exploration, development, and production of fossil fuels.',
			active: true,
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Fears of the impacts of climate change.',
			size: 99,
			size_text: '<100',
			participants: 'Climate activists.',
			timespan: 'Active',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			long: true,
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Kill the bill protests</span>',
			date: '2021-03-01',
			date_text: "March 2021",
			freedom: 'Free',
			triggers: 'Introduction of a crime bill that broadens police powers to regulate protests, and aggressive police response to violence against women protests.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concerns over limits on the right to assembly. ',
			size: 5001,
			size_text: '>5,000',
			participants: 'General public.',
			timespan: '2 weeks',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Violence against women protest</span>',
			date: '2021-03-01',
			date_text: "March 2021",
			freedom: 'Free',
			triggers: 'Widespread sexual harassment and the murder of Sarah Everard. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over pervasive violence and discrimination against women.',
			size: 5001,
			size_text: '>5,000',
			participants: 'General public, especially women.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protest.',
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Black Lives Matter protests</span>',
			date: '2020-06-01',
			date_text: "June 2020",
			freedom: 'Free',
			triggers: 'Death of George Floyd in the United States, sparking protests against local police brutality and racism in the UK.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Systemic racism in the UK and the country’s imperial legacy. ',
			size: 10001,
			size_text: '>10,000',
			participants: 'General public.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'The establishment of a government commission to examine racial inequality.',
			significant: true,
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">Coronavirus lockdown protests</span>',
			date: '2020-05-01',
			date_text: "May 2020",
			freedom: 'Free',
			triggers: 'Enactment of lockdown measures to combat the spread of the coronavirus.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Skepticism about the coronavirus and vaccines, and concerns over the balance between public health restrictions and freedom.',
			size: 10001,
			size_text: '>10,000',
			participants: 'General public, especially far-left and far-right activists, anti-vaxxers, and conspiracy theorists.',
			timespan: '15 months, intermittently',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			covid: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "GB",
			arrayItem: true,
			country: 'United Kingdom',
			protest_name: '<span class="protest">People’s Vote protests</span>',
			date: '2018-06-01',
			date_text: "June 2018",
			freedom: 'Free',
			triggers: 'Discontent with the state of Brexit negotiations between the British government and the EU, and popular demands for a second Brexit referendum.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concern over Brexit and its implications.',
			size: 1000000,
			size_text: '1 million',
			participants: 'People’s Vote campaign, pro-EU groups, general public.',
			timespan: '1 year, 4 months (sporadic)',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			large: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "GB", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "GB", 
				settings: { fill: inactiveCountry },
			},
			capitalData: GBCapitalDataActive,
			inactiveCapitalData: GBCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">"Take Our Border Back" convoy protest</span>',
			date: '2024-01-01',
			date_text: "January 2024",
			freedom: 'Free',
			triggers: 'Ongoing constitutional crisis triggered by the standoff between the federal government and Texas Governor Greg Abbott at Eagle Pass over the unfurling of miles of concertina wire and buoy barriers in the Rio Grande to deter unauthorized border crossings. In early 2024, a bipartisan immigration bill supported by the White House fell apart. The White House blamed the bill’s collapse on political pressure from former president Donald Trump, who said he would fight to ensure it was not passed. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'The convoy’s main aim is to draw attention to border security and oppose illegal immigration. They also display support for former president Trump. ',
			size: 101,
			size_text: '>100',
			participants: 'Truckers, Trump supporters',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">APEC San Francisco protests</span>',
			date: '2023-11-01',
			date_text: "November 2023",
			freedom: 'Free',
			triggers: 'Chinese President Xi Jinping’s visit to the APEC summit in San Francisco for his first trip to the U.S. in six years. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Critics of China expressed frustration with human rights abuses against Uyghurs and Tibetans, attempts to subvert Taiwanese sovereignty, and efforts to further consolidate authoritarianism in China. Those protesting Xi clashed with supporters of the CCP.',
			size: 101,
			size_text: '>100',
			participants: 'Tibetan Americans, Uyghur Americans, and Hong Kong democracy activists, Chinese democracy activists, pro-CCP demonstrators.',
			timespan: '3 days',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protest.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">UNGA climate protests</span>',
			date: '2023-09-01',
			date_text: "September 2023",
			freedom: 'Free',
			triggers: 'The United Nations General Assembly was scheduled to hold its High-Level Week from September 18–22 in New York City.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Perceived insufficient action to combat the climate crisis.',
			size: 10001,
			size_text: '>10,000',
			participants: 'Climate activists',
			timespan: '2 days',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Tennessee gun reform protests</span>',
			date: '2023-03-01',
			date_text: "March 2023",
			freedom: 'Free',
			triggers: 'A shooting at a private elementary school in Tennessee killed six people including three students.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over the unwillingness of the Tennessee legislature to pass legislation that makes it harder to purchase guns in the state.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Democrats, supporters of gun control.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'The Tennessee House of Representatives voted to expel two Democratic lawmakers for attending the protest. Within four days, the body voted to reinstate both members.',
			significant: true,
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Stop Cop City protests</span>',
			date: '2023-01-01',
			date_text: "January 2023",
			freedom: 'Free',
			triggers: 'The Atlanta City Council voted to approve plans for a $90 million, 85-acre Public Safety Training Center to train police officers, firefighters, and emergency phone operators, to be situated within a 300-acre forest in southwest unincorporated DeKalb County; in January 2023, police shot and killed protester Manuel Esteban Paez Teran, 26.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Fears over environmental damage caused by the construction of the facility and the possible consequences of the facility’s use on the militarization of Atlanta’s police; anger over the significant cost to taxpayers of the facility’s construction.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '3 months, intermittently with significant protests in November 2023.  ',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests. On June 6, the Atlanta City Council voted overwhelmingly to approve $31 million in funding for the construction of the $90 million police and fire training complex. Georgia Attorney General Chris Carr obtained an indictment to charge 61 protesters in August using the state’s anti-racketeering law. ',
			violent: true,
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Abortion access protests</span>',
			date: '2023-01-01',
			date_text: "January 2023",
			freedom: 'Free',
			triggers: 'The 50th anniversary of the Supreme Court decision in Roe v. Wade, which found a constitutional right to abortion in the United States.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger with Supreme Court rulings and state legislation that chipped away at or eliminated access to legal abortion in many states.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Women.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Abortion ruling protests</span>',
			date: '2022-05-01',
			date_text: "May 2022",
			freedom: 'Free',
			triggers: 'The circulation of a leaked Supreme Court draft opinion that would overturn the court’s prior ruling in Roe v. Wade, which guaranteed abortion rights; reignited in June 2022 by the court overturing Roe v. Wade in a decision issued on June 24.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over the decision’s implications for women’s rights and the perceived politicization of the judiciary.',
			size: 10001,
			size_text: '>10,000',
			participants: 'General public.',
			timespan: '3 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Electoral protests</span>',
			date: '2020-11-01',
			date_text: "November 2020",
			freedom: 'Free',
			triggers: 'Allegations leveled by President Donald Trump and his allies that vote counts were inaccurate and that there had been significant electoral fraud.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Belief of Trump supporters that Democrats would steal the election, and the call of President-elect Joe Biden supporters for respecting the electoral process and the will of voters.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Supporters of Trump and supporters of Biden.',
			timespan: '3 months',
			timespan_text: '',
			outcomes: 'No policy or leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Police brutality protests</span>',
			date: '2020-05-01',
			date_text: "May 2020",
			freedom: 'Free',
			triggers: 'Excessive use of lethal force by police officers against unarmed Black civilians.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Ongoing police brutality toward communities of Black people, and broader issues of systemic racism.',
			size: 1000001,
			size_text: '>1,000,000',
			participants: 'General public.',
			timespan: '15 months',
			timespan_text: '',
			outcomes: 'Multiple local governments have reduced funding for the police. Some of the officers involved in the deaths of George Floyd and Breonna Taylor have been arrested. ',
			significant: true,
			large: true,
			violent: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Prison protest</span>',
			date: '2020-04-01',
			date_text: "April 2020",
			freedom: 'Free',
			triggers: 'Rising number of coronavirus cases in Arizona immigrant detention facilities.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Risk of the coronavirus spreading within prisons or other detention facilities.',
			size: 701,
			size_text: '>700',
			participants: 'Immigration activists.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			covid: true,
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Coronavirus protests</span>',
			date: '2020-04-01',
			date_text: "April 2020",
			freedom: 'Free',
			triggers: 'Extended business closures due to stay-at-home orders, conspiracy theories about vaccines, as well as restrictions on movement.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Economic impacts of statewide shutdowns and political contestation over the threat posed by the virus.',
			size: 10001,
			size_text: '>10,000',
			participants: 'Conservative organizers, grassroots groups.',
			timespan: '14 months',
			timespan_text: '',
			outcomes: 'Some states, such as Florida and Texas, have begun to partially reopen or plan on doing so soon.',
			significant: true,
			covid: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">“Telegramgate” protests</span>',
			date: '2019-07-01',
			date_text: "July 2019",
			freedom: 'Free',
			triggers: 'Leak of Puerto Rico government officials’ racist and homophobic messages.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
			motivation_text: 'Continued economic challenges, government mismanagement, and corruption.',
			size: 500000,
			size_text: '500,000',
			participants: 'Puerto Ricans.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'Then Puerto Rican governor Ricardo Rosselló resigned.',
			significant: true,
			large: true,
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">“Lights for Liberty” protest</span>',
			date: '2019-07-01',
			date_text: "July 2019",
			freedom: 'Free',
			triggers: 'Conditions in migrant detention camps.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Opposition to President Donald Trump’s migration policies.',
			size: 100000,
			size_text: '>100,000',
			participants: 'General public (including protests in all fifty states, with a significant number occurring in regions that had voted for Trump in 2016).',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			large: true,
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">“Keep Families Together” protest</span>',
			date: '2018-06-01',
			date_text: "June 2018",
			freedom: 'Free',
			triggers: 'President Donald Trump’s “zero tolerance” immigration policy, which separated children from their parents at the U.S. border with Mexico.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Government immigration policy.',
			size: 10001,
			size_text: '>10,000',
			participants: 'General public.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "US",
			arrayItem: true,
			country: 'United States',
			protest_name: '<span class="protest">Immigration ban protests</span>',
			date: '2017-01-01',
			date_text: "January 2017",
			freedom: 'Free',
			triggers: 'President Donald Trump’s executive order preventing travelers from seven Muslim-majority nations from entering the country.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concerns over discrimination against Muslims.',
			size: 10001,
			size_text: '>10,000',
			participants: 'General public.',
			timespan: '3 days',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			multiple: true,
			mapData: {
				"id": "US", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "US", 
				settings: { fill: inactiveCountry },
			},
			capitalData: USCapitalDataActive,
			inactiveCapitalData: USCapitalDataInctive
		},
		{
			id: "UY",
			arrayItem: true,
			country: 'Uruguay',
			protest_name: '<span class="protest">Farmers’ protest</span>',
			date: '2018-01-01',
			date_text: "January 2018",
			freedom: 'Free',
			triggers: 'Rising taxes for farmers and rising fuel prices.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Lack of government support for farmers and increasing agriculture costs.',
			size: 10001,
			size_text: '>10,000',
			participants: 'Farmers’ organizations.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'Then president Tabaré Vázquez met with representatives and announced measures such as reductions in fuel prices and electricity rates.',
			significant: true,
			multiple: false,
			mapData: {
				"id": "UY", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UY", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UYCapitalDataActive,
			inactiveCapitalData: UYCapitalDataInctive
		},
		{
			id: "UZ",
			arrayItem: true,
			country: 'Uzbekistan',
			protest_name: '<span class="protest">Karakalpakstan region protests</span>',
			date: '2022-07-01',
			date_text: "July 2022",
			freedom: 'Not free',
			triggers: 'The government announced plans to reduce the Karakalpakstan region’s autonomy.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Discontent in the region with the national government’s management of the region’s access to water and jobs.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Residents of Karakalpakstan.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'The government withdrew the controversial amendments regarding Karakalpakstan’s autonomy.',
			significant: true,
			violent: true,
			multiple: false,
			mapData: {
				"id": "UZ", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "UZ", 
				settings: { fill: inactiveCountry },
			},
			capitalData: UZCapitalDataActive,
			inactiveCapitalData: UZCapitalDataInctive
		},
		{
			id: "VE",
			arrayItem: true,
			country: 'Venezuela',
			protest_name: '<span class="protest">Presidential election protest</span>',
			date: '2024-07-01',
			date_text: "July 2024",
			freedom: 'Not free',
			triggers: 'National Electoral Council (NEC) announced that President Nicolás Maduro has been reelected to a third consecutive term.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
			motivation_text: 'Protesting the election results, which many people—both within and outside Venezuela—believe were falsified. Calling for the NEC to release voting records and demanding that Maduro be removed from power. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'Opposition, general public',
			timespan: '1 month;<br />1 day in January 2025',
			timespan_text: '',
			outcomes: 'No policy/leadership change. Authorities have cracked down on the protests, leading to the death of at least twenty-three protesters or bystanders and one soldier, according to Human Rights Watch. As of December 2024, the Venezuelan government reportedly released 533 people who had been detained during the protests.',
			violent: true,
			multiple: true,
			mapData: {
				"id": "VE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "VE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: VECapitalDataActive,
			inactiveCapitalData: VECapitalDataInctive
		},
		{
			id: "VE",
			arrayItem: true,
			country: 'Venezuela',
			protest_name: '<span class="protest">Public sector workers protests</span>',
			date: '2023-01-01',
			date_text: "January 2023",
			freedom: 'Not free',
			triggers: 'Rapidly rising prices.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Economic hardships resulting from insufficient wages. ',
			size: 101,
			size_text: '>100',
			participants: 'Public sector workers, especially teachers.',
			timespan: '2 weeks',
			timespan_text: '',
			outcomes: 'The government gave teachers a $30 bonus.',
			multiple: true,
			mapData: {
				"id": "VE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "VE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: VECapitalDataActive,
			inactiveCapitalData: VECapitalDataInctive
		},
		{
			id: "VE",
			arrayItem: true,
			country: 'Venezuela',
			protest_name: '<span class="protest">Service delivery protests</span>',
			date: '2020-10-01',
			date_text: "September 2020",
			freedom: 'Not free',
			triggers: 'Collapse of gasoline supply chains and degradation of water and energy infrastructure.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name active">Corruption</div></div>',
			motivation_text: 'Frustration over the lack of meaningful state action in response to the economic collapse, government corruption, and dilapidated infrastructure. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public, especially those outside of the capital.',
			timespan: '4 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			large: true,
			multiple: true,
			mapData: {
				"id": "VE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "VE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: VECapitalDataActive,
			inactiveCapitalData: VECapitalDataInctive
		},
		{
			id: "VE",
			arrayItem: true,
			country: 'Venezuela',
			protest_name: '<span class="protest">January 2019 protests</span>',
			date: '2019-01-01',
			date_text: "January 2019",
			freedom: 'Not free',
			triggers: 'Opposition leader Juan Guaidó’s self-declared claim to be the president of Venezuela. ',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name active">Corruption</div></div>',
			motivation_text: 'Economic collapse, corruption, food shortages, brutal government repression of dissidents, and political authoritarianism.',
			size: 100001,
			size_text: '>100,000',
			participants: 'Working-class citizens who once represented one of the regime’s bastions of support.',
			timespan: '1 year, 3 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			large: true,
			violent: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "VE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "VE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: VECapitalDataActive,
			inactiveCapitalData: VECapitalDataInctive
		},
		{
			id: "VE",
			arrayItem: true,
			country: 'Venezuela',
			protest_name: '<span class="protest">“Mother of All Marches” protests</span>',
			date: '2017-04-01',
			date_text: "April 2017",
			freedom: 'Not free',
			triggers: 'Attempt by Venezuelan Supreme Court to dissolve the opposition-controlled legislature.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Growing authoritarianism, economic crisis, and ensuing food and medicine shortages.',
			size: 200000,
			size_text: '200,000',
			participants: 'Political opposition.',
			timespan: '3 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protests.',
			large: true,
			violent: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "VE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "VE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: VECapitalDataActive,
			inactiveCapitalData: VECapitalDataInctive
		},
		{
			id: "VN",
			arrayItem: true,
			country: 'Vietnam',
			protest_name: '<span class="protest">Cybersecurity / special economic zones protests</span>',
			date: '2018-06-01',
			date_text: "June 2018",
			freedom: 'Not free',
			triggers: 'A bill that would allow foreign investors to occupy land in three special economic areas, and a cybersecurity law that would require tech companies to store data of Vietnam-based users in the country.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Fear of growing Chinese influence through special economic zones, concerns over citizens’ privacy and limited freedoms of speech, and the effects of the cybersecurity law on the digital economy’s growth.',
			size: 50000,
			size_text: '50,000',
			participants: 'General public.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'The vote on the bill involving special economic zones was delayed until May 2019.',
			significant: true,
			multiple: false,
			mapData: {
				"id": "VN", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "VN", 
				settings: { fill: inactiveCountry },
			},
			capitalData: VNCapitalDataActive,
			inactiveCapitalData: VNCapitalDataInctive
		},
		{
			id: "PS",
			arrayItem: true,
			country: 'West Bank',
			protest_name: '<span class="protest">Abdul Fattah Kharushah funeral protest</span>',
			date: '2023-03-01',
			date_text: "March 2023",
			freedom: 'Not free',
			triggers: 'Israeli forces Hamas militant killed Abdul Fattah Kharushah, 49, in a raid in Jenin.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustrations with the ruling Palestinian Authority, specifically its security cooperation with Israel.',
			size: 101,
			size_text: '>100',
			participants: 'Supporters of Hamas',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to the protest.',
			multiple: true,
			mapData: {
				"id": "PS", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "PS", 
				settings: { fill: inactiveCountry },
			},
			capitalData: PSCapitalDataActive,
			inactiveCapitalData: PSCapitalDataInctive
		},
		{
			id: "PS",
			arrayItem: true,
			country: 'West Bank',
			protest_name: '<span class="protest">Israeli occupation protests</span>',
			date: '2021-05-01',
			date_text: "May 2021",
			freedom: 'Not free',
			triggers: 'A court decision on whether to evict six Palestinian families from their homes in the East Jerusalem neighborhood of Sheikh Jarrah; Israeli moves to crack down on Palestinian assembly.',
			active: true,
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over Israeli raids and settlements in the West Bank; fear that Israel will change the status quo of the al-Aqsa Mosque.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: 'Active, intermittently',
			timespan_text: '',
			outcomes: 'The Supreme Court delayed its ruling in the case.',
			significant: true,
			violent: true,
			long: true,
			multiple: true,
			mapData: {
				"id": "PS", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "PS", 
				settings: { fill: inactiveCountry },
			},
			capitalData: PSCapitalDataActive,
			inactiveCapitalData: PSCapitalDataInctive
		},
		{
			id: "PS",
			arrayItem: true,
			country: 'West Bank',
			protest_name: '<span class="protest">Cost of living protests</span>',
			date: '2022-02-01',
			date_text: "February 2022",
			freedom: 'Not free',
			triggers: 'Rapidly rising prices and security chaos.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'High cost of living and perceived failure by the Palestinian Authority to alleviate crisis.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '4 months, intermittently',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "PS", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "PS", 
				settings: { fill: inactiveCountry },
			},
			capitalData: PSCapitalDataActive,
			inactiveCapitalData: PSCapitalDataInctive
		},
		{
			id: "PS",
			arrayItem: true,
			country: 'West Bank',
			protest_name: '<span class="protest">Dissident death protests</span>',
			date: '2021-06-01',
			date_text: "June 2021",
			freedom: 'Not free',
			triggers: 'Death of Nizar Banat, a critic of the Palestinian Authority, while in police custody.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with the rule of the Palestinian Authority (PA) and its president, Mahmoud Abbas; allegations of corruption; and perceived PA closeness with the Israeli government.',
			size: 5001,
			size_text: '>5,000',
			participants: 'General public.',
			timespan: '3 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "PS", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "PS", 
				settings: { fill: inactiveCountry },
			},
			capitalData: PSCapitalDataActive,
			inactiveCapitalData: PSCapitalDataInctive
		},
		{
			id: "YE",
			arrayItem: true,
			country: 'Yemen',
			protest_name: '<span class="protest">Quran desecration protests</span>',
			date: '2023-07-01',
			date_text: "July 2023",
			freedom: 'Not free',
			triggers: 'On July 20, 2023, two men kicked and partially destroyed a Quran in front of the Iraqi embassy in Stockholm, Sweden. On July 24, two other men burned a Quran outside the Iraqi embassy in Copenhagen, Denmark. ',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over the desecration of the Quran, which is deeply offensive to Muslim people.',
			size: 1001,
			size_text: '>1,000',
			participants: 'Followers of the Houthi movement.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'Houthi leaders implemented a ban on the import of Swedish goods.',
			multiple: true,
			mapData: {
				"id": "YE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "YE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: YECapitalDataActive,
			inactiveCapitalData: YECapitalDataInctive
		},
		{
			id: "YE",
			arrayItem: true,
			country: 'Yemen',
			protest_name: '<span class="protest">Economic crisis protests</span>',
			date: '2021-09-01',
			date_text: "September 2021",
			freedom: 'Not free',
			triggers: 'A rapid drop in the value of the Yemeni rial.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with declining economic conditions and rising insecurity amid ongoing conflict between the govenrment and Houthi rebels.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public, especially residents of the southern regions.',
			timespan: '2 months',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protest.',
			multiple: true,
			mapData: {
				"id": "YE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "YE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: YECapitalDataActive,
			inactiveCapitalData: YECapitalDataInctive
		},
		{
			id: "YE",
			arrayItem: true,
			country: 'Yemen',
			protest_name: '<span class="protest">Salary payment protest</span>',
			date: '2021-03-01',
			date_text: "March 2021",
			freedom: 'Not free',
			triggers: 'Government’s failure to pay salaries of public sector employees.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustration with economic mismanagement and shortfalls in public services.',
			size: 501,
			size_text: '>500',
			participants: 'Retired soldiers and public workers.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "YE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "YE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: YECapitalDataActive,
			inactiveCapitalData: YECapitalDataInctive
		},
		{
			id: "YE",
			arrayItem: true,
			country: 'Yemen',
			protest_name: '<span class="protest">Peace plan protests</span>',
			date: '2018-09-01',
			date_text: "September 2018",
			freedom: 'Not free',
			triggers: 'A decline in the value of the Yemeni rial.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Anger over the rising cost of living, rapid inflation, and economic mismanagement. ',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			multiple: true,
			mapData: {
				"id": "YE", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "YE", 
				settings: { fill: inactiveCountry },
			},
			capitalData: YECapitalDataActive,
			inactiveCapitalData: YECapitalDataInctive
		},
		{
			id: "ZW",
			arrayItem: true,
			country: 'Zimbabwe',
			protest_name: '<span class="protest">Fuel price protest</span>',
			date: '2019-01-01',
			date_text: "January 2019",
			freedom: 'Not free',
			triggers: 'End of government fuel subsidies, and a subsequent price increase of 130 percent.',
			motivation_icons: '<div class="motivations"><div class="name active">Economic</div><div class="name inactive">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Frustrations over the rising cost of living and the declining living standards.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 month',
			timespan_text: '',
			outcomes: '',
			violent: true,
			multiple: true,
			mapData: {
				"id": "ZW", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "ZW", 
				settings: { fill: inactiveCountry },
			},
			capitalData: ZWCapitalDataActive,
			inactiveCapitalData: ZWCapitalDataInctive
		},
		{
			id: "ZW",
			arrayItem: true,
			country: 'Zimbabwe',
			protest_name: '<span class="protest">Electoral protest</span>',
			date: '2018-08-01',
			date_text: "August 2018",
			freedom: 'Not free',
			triggers: 'Delay in the announcement of presidential election results.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Concerns over a lack of genuine political pluralism and the political manipulation of the electoral process.',
			size: 1001,
			size_text: '>1,000',
			participants: 'General public.',
			timespan: '1 week',
			timespan_text: '',
			outcomes: 'No policy/leadership change in response to protests.',
			violent: true,
			multiple: true,
			mapData: {
				"id": "ZW", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "ZW", 
				settings: { fill: inactiveCountry },
			},
			capitalData: ZWCapitalDataActive,
			inactiveCapitalData: ZWCapitalDataInctive
		},
		{
			id: "ZW",
			arrayItem: true,
			country: 'Zimbabwe',
			protest_name: '<span class="protest">Mugabe protest</span>',
			date: '2017-11-01',
			date_text: "November 2017",
			freedom: 'Not free',
			triggers: 'Military takeover of power, after which protesters began demanding that then president Robert Mugabe step down.',
			motivation_icons: '<div class="motivations"><div class="name inactive">Economic</div><div class="name active">Political</div><div class="name inactive">Corruption</div></div>',
			motivation_text: 'Desire for political reforms and repression under Mugabe.',
			size: 10001,
			size_text: '>10,000',
			participants: 'General public, including war veterans.',
			timespan: '1 day',
			timespan_text: '',
			outcomes: 'Mugabe stepped down and was replaced by Emmerson Mnangagwa.',
			significant: true,
			multiple: true,
			mapData: {
				"id": "ZW", 
				settings: { fill: trackedCountry },
			},
			inactiveMapData: {
				"id": "ZW", 
				settings: { fill: inactiveCountry },
			},
			capitalData: ZWCapitalDataActive,
			inactiveCapitalData: ZWCapitalDataInctive
		}
];

let mapDataArray = dataArray;

// desktop results
function desktopResults(html, actualData, activeFilter) {
    if (activeFilter !== 'arrayItem') {
        usedDataArray = dataArray;
    } else {
        usedDataArray = actualData;
    }
    for (let e in usedDataArray) {
        let filterThing = usedDataArray[e].arrayItem;
        if (activeFilter == 'active') {
            filterThing = usedDataArray[e].active;
        } else if (activeFilter == 'large') {
            filterThing = usedDataArray[e].large;
        } else if (activeFilter == 'violent') {
            filterThing = usedDataArray[e].violent;
        } else if (activeFilter == 'long') {
            filterThing = usedDataArray[e].long;
        } else if (activeFilter == 'significant') {
            filterThing = usedDataArray[e].significant;
        } else if (activeFilter == 'covid') {
            filterThing = usedDataArray[e].covid;
        }
        if (filterThing === true) {
            let timespanInfo = '';
            if (actualData[e].timespan_text.length > 1) {
                timespanInfo =
                    '<div class="triggers details"><strong>Time span</strong>' + actualData[e].timespan_text + '</div>';
            }
            html +=
                '<tr class="primary">' +
                '<td class="cols"><a class="ceip-icon col col-20 content-toggle">+<span class="icon-plus-circle"></span></a><div class="col col-80">' +
                actualData[e].country +
                '</div></td>' +
                '<td>' +
                actualData[e].protest_name +
                '</td>' +
                '<td>' + 
				actualData[e].date_text +
                '</td>' +
                '<td>' +
                actualData[e].size_text +
                '</td>' +
                '<td>' +
                actualData[e].motivation_icons +
                '</td>' +
                '<td>' +
                actualData[e].timespan +
                '</td>' +
                '<td>' +
                actualData[e].freedom +
                '</td>' +
                '<td><input type="checkbox" class="compare-checkbox"></td>' +
                '</tr>' +
                '<tr class="secondary hidden--new"><td>&nbsp;</td><td>&nbsp;</td><td colspan="6">' +
                timespanInfo +
                '<div class="triggers details"><strong>Trigger</strong>' +
                actualData[e].triggers +
                '</div>' +
                '<div class="motivation details"><strong>Motivation</strong>' +
                actualData[e].motivation_text +
                '</div>' +
                '<div class="participants details"><strong>Key participants</strong>' +
                actualData[e].participants +
                '</div>' +
                '<div class="outcomes details"><strong>Outcomes</strong>' +
                actualData[e].outcomes +
                '</div></div>';
            +'<div class="freedom details"><strong>Freedom in the World ranking</strong>' +
                actualData[e].freedom +
                '</div>' +
                '</td></tr>';
        }
        document.querySelector('#table-results').innerHTML = html;
    }

    // document.querySelector('#table-results').innerHTML = html;
    // $('#table-results').html('test');
    // $('#table-results').addClass('test-class');
    // $(".old-class").switchClass("old-class", "new-class", 5000);
    // $(function () {
    //     $("#datepicker").datepicker();
    // });
}

// responsive results
function responsiveResults(html, actualData, activeFilter) {
    if (activeFilter !== 'arrayItem') {
        usedDataArray = dataArray;
    } else {
        usedDataArray = actualData;
    }
    for (var e in usedDataArray) {
        if (activeFilter == 'active') {
            var filterThing = usedDataArray[e].active;
        } else if (activeFilter == 'large') {
            var filterThing = usedDataArray[e].large;
        } else if (activeFilter == 'violent') {
            var filterThing = usedDataArray[e].violent;
        } else if (activeFilter == 'long') {
            var filterThing = usedDataArray[e].long;
        } else if (activeFilter == 'significant') {
            var filterThing = usedDataArray[e].significant;
        } else if (activeFilter == 'covid') {
            var filterThing = usedDataArray[e].covid;
        } else {
            var filterThing = usedDataArray[e].arrayItem;
        }
        if (filterThing === true) {
            if (actualData[e].timespan_text.length > 1) {
                var timespanInfo =
                    '<div class="triggers details"><strong>Time span</strong>' + actualData[e].timespan_text + '</div>';
            } else {
                var timespanInfo = '';
            }
            html +=
                '<tr class="primary">' +
                '<td class="cols"><a class="ceip-icon col col-20 content-toggle"><span class="icon-plus-circle"></span></a><div class="col col-80">' +
                actualData[e].country +
                actualData[e].protest_name +
                '</div></td>' +
                '<td>' +
                actualData[e].date_text +
                '</td>' +
                '<td>' +
                actualData[e].size_text +
                '</td>' +
                '<td>' +
                actualData[e].timespan +
                '</td>' +
                '<td><input type="checkbox" class="compare-checkbox"></td>' +
                '</tr>' +
                '<tr class="secondary hidden--new"><td>&nbsp;</td><td colspan="4"><div class="">' +
                timespanInfo +
                '<div class="triggers details"><strong>Trigger</strong>' +
                actualData[e].triggers +
                '</div>' +
                '<div class="motivation details"><strong>Motivation</strong>' +
                actualData[e].motivation_text +
                '</div>' +
                '<div class="participants details"><strong>Key participants</strong>' +
                actualData[e].participants +
                '</div>' +
                '<div class="outcomes details"><strong>Outcomes</strong>' +
                actualData[e].outcomes +
                '</div></div>';
            +'<div class="freedom details"><strong>Freedom in the World ranking</strong>' +
                actualData[e].freedom +
                '</div>' +
                '</div></td></tr>';
        }
    }
    document.querySelector('#table-results').innerHTML = html;
}

// mobile results
function mobileResults(html, actualData, activeFilter) {
    if (activeFilter !== 'arrayItem') {
        usedDataArray = dataArray;
    } else {
        usedDataArray = actualData;
    }
    for (var e in usedDataArray) {
        if (activeFilter == 'active') {
            var filterThing = usedDataArray[e].active;
        } else if (activeFilter == 'large') {
            var filterThing = usedDataArray[e].large;
        } else if (activeFilter == 'violent') {
            var filterThing = usedDataArray[e].violent;
        } else if (activeFilter == 'long') {
            var filterThing = usedDataArray[e].long;
        } else if (activeFilter == 'significant') {
            var filterThing = usedDataArray[e].significant;
        } else if (activeFilter == 'covid') {
            var filterThing = usedDataArray[e].covid;
        } else {
            var filterThing = usedDataArray[e].arrayItem;
        }
        if (filterThing === true) {
            if (actualData[e].timespan_text.length > 1) {
                var timespanInfo = '. ' + actualData[e].timespan_text;
            } else {
                var timespanInfo = '';
            }
            html +=
                '<tr class="primary">' +
                '<td class="cols"><a class="ceip-icon col col-20 content-toggle"><span class="icon-plus-circle"></span></a><div class="col col-80">' +
                actualData[e].country +
                actualData[e].protest_name +
                '</div></td>' +
                '<td>' +
                actualData[e].date_text +
                '</td>' +
                '<td>' +
                actualData[e].size_text +
                '</td>' +
                '</tr>' +
                '<tr class="secondary hidden--new"><td colspan="5"><div class="gutter-left">' +
                '<div class="triggers details"><strong>Time span</strong>' +
                actualData[e].timespan +
                timespanInfo +
                '</div>' +
                '<div class="triggers details"><strong>Trigger</strong>' +
                actualData[e].triggers +
                '</div>' +
                '<div class="motivation details"><strong>Motivation</strong>' +
                actualData[e].motivation_text +
                '</div>' +
                '<div class="participants details"><strong>Key participants</strong>' +
                actualData[e].participants +
                '</div>' +
                '<div class="outcomes details"><strong>Outcomes</strong>' +
                actualData[e].outcomes +
                '</div></div>';
            +'<div class="freedom details"><strong>Freedom in the World ranking</strong>' +
                actualData[e].freedom +
                '</div>' +
                '</div></td></tr>';
        }
    }
    document.querySelector('#table-results').innerHTML = html;
}

function renderResults() {
    let html = '';
    if (responsive) {
        responsiveResults(html, mapDataArray, 'arrayItem');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'arrayItem');
    } else {
        desktopResults(html, mapDataArray, 'arrayItem');
    }
}

function changeButtonOnClick(event) {
    let elList = document.querySelectorAll('.pub-type-label');
    elList.forEach((el) => el.classList.remove('active'));
    event.currentTarget.parentElement.classList.add('active');
    document.querySelector('#compare-reset').classList.add('inactive');
    document.querySelector('#compare-button').classList.remove('useable');
}

function resetFilter() {
    // updating the table
    let html = '';
    mapDataArray = dataArray;
    if (responsive) {
        responsiveResults(html, mapDataArray, 'arrayItem');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'arrayItem');
    } else {
        desktopResults(html, mapDataArray, 'arrayItem');
    }
    // creating a new array to replace dataArray for theader sorting purposes
    mapDataArray = dataArray;
}

// filter table by currently active protest
function activeMotivationFilter(value) {
    // reset data
    mapDataArray = dataArray;
    // updating the table
    let html = '';
    if (responsive) {
        responsiveResults(html, mapDataArray, 'active');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'active');
    } else {
        desktopResults(html, mapDataArray, 'active');
    }
    // creating a new array to replace dataArray for theader sorting purposes
    const activeDataArray = dataArray.filter((data) => data.active);
    mapDataArray = activeDataArray;
}

// filter table by large protest
function largeMotivationFilter() {
    // reset data
    mapDataArray = dataArray;
    // updating the table
    let html = '';
    if (responsive) {
        responsiveResults(html, mapDataArray, 'large');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'large');
    } else {
        desktopResults(html, mapDataArray, 'large');
    }
    // creating a new array to replace dataArray for theader sorting purposes
    const largeDataArray = dataArray.filter((data) => data.large);
    mapDataArray = largeDataArray;
}

// filter table by violent protest
function violentMotivationFilter() {
    // reset data
    mapDataArray = dataArray;
    // updating the table
    let html = '';
    if (responsive) {
        responsiveResults(html, mapDataArray, 'violent');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'violent');
    } else {
        desktopResults(html, mapDataArray, 'violent');
    }
    // creating a new array to replace dataArray for theader sorting purposes
    const violentDataArray = dataArray.filter((data) => data.violent);
    mapDataArray = violentDataArray;
}

// filter table by long protest
function longMotivationFilter() {
    // reset data
    mapDataArray = dataArray;
    // updating the table
    let html = '';
    if (responsive) {
        responsiveResults(html, mapDataArray, 'long');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'long');
    } else {
        desktopResults(html, mapDataArray, 'long');
    }
    // creating a new array to replace dataArray for theader sorting purposes
    const longDataArray = dataArray.filter((data) => data.long);
    mapDataArray = longDataArray;
}

// filter table by significant protest
function significantMotivationFilter() {
    // reset data
    mapDataArray = dataArray;
    // updating the table
    let html = '';
    if (responsive) {
        responsiveResults(html, mapDataArray, 'significant');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'significant');
    } else {
        desktopResults(html, mapDataArray, 'significant');
    }
    // creating a new array to replace dataArray for theader sorting purposes
    const significantDataArray = dataArray.filter((data) => data.significant);
    mapDataArray = significantDataArray;
}

// filter table by covid protest
function covidMotivationFilter() {
    // reset data
    mapDataArray = dataArray;
    // updating the table
    let html = '';
    if (responsive) {
        responsiveResults(html, mapDataArray, 'covid');
    } else if (mobile) {
        mobileResults(html, mapDataArray, 'covid');
    } else {
        desktopResults(html, mapDataArray, 'covid');
    }
    // creating a new array to replace dataArray for theader sorting purposes
    const covidDataArray = dataArray.filter((data) => data.covid);
    mapDataArray = covidDataArray;
}

// buttons

function activeMotivationInput(event) {
    activeMotivationFilter();
    changeButtonOnClick(event);
}

function largeMotivationInput(event) {
    largeMotivationFilter();
    changeButtonOnClick(event);
}

function violentMotivationInput(event) {
    violentMotivationFilter();
    changeButtonOnClick(event);
}

function longMotivationInput(event) {
    longMotivationFilter();
    changeButtonOnClick(event);
}

function significantMotivationInput(event) {
    significantMotivationFilter();
    changeButtonOnClick(event);
}

function covidMotivationInput(event) {
    covidMotivationFilter();
    changeButtonOnClick(event);
}

function allMotivationInput(event) {
    resetFilter();
    changeButtonOnClick(event);
}

//compare tools

function compareFilter(event) {
    // updating the table
    let elList = document.querySelectorAll('.compare-checkbox');
    elList.forEach((el) => {
        if (el.checked) {
            el.parentElement.parentElement.style.display = 'table-row';
            el.parentElement.parentElement.classList.add('open');
            el.parentElement.parentElement.nextSibling.classList.add('hidden--new--active');
            el.parentElement.parentElement.firstElementChild.firstElementChild.classList.add('active');
        } else {
            el.parentElement.parentElement.style.display = 'none';
            el.parentElement.parentElement.nextSibling.classList.remove('hidden--new--active');
            el.parentElement.parentElement.firstElementChild.firstElementChild.classList.remove('active');
            el.parentElement.parentElement.classList.remove('open');
        }
    });
}

function compareTool(event) {
    if (document.querySelector('#compare-button').classList.contains('useable')) {
        document.querySelector('#compare-button').classList.toggle('useable');
        document.querySelector('#compare-reset').classList.remove('inactive');
        compareFilter();
    }
}
// this is called on the actual compare button
function compareClick() {
    document.querySelector('.compare-checkbox').onclick = function (e) {
        compareFunction(e);
    };
    // making compare inactive until you check a box
    function compareFunction(e) {
        e.preventDefault();
        let anyChecked = false;
        let elList = document.querySelectorAll('.compare-checkbox');
        elList.forEach((el) => {
            if (el.checked) {
                anyChecked = true;
            }
        });
        if (anyChecked) {
            document.querySelector('#compare-button').classList.add('useable');
        } else {
            document.querySelector('#compare-button').classList.remove('useable');
        }
        if (document.querySelector('#compare-button').classList.contains('inactive')) {
            document.querySelector('#compare-reset').classList.toggle('inactive');
            document.querySelector('#compare-button').classList.toggle('inactive');
        }
    }
}

// this is called on the actual reset button
function compareReset() {
    document.querySelector('#compare-reset').classList.toggle('inactive');
    document.querySelector('#compare-button').classList.remove('useable');
    let elList = document.querySelectorAll('.pub-type-label');
    elList.forEach((el) => el.classList.remove('active'));
    document.querySelector('#all.pub-type-label').classList.add('active');
    resetFilter();
}

// this is for sorting
function sortFunction(event) {
    let attr = event.target.dataset.attr;
    let elList = document.querySelectorAll('#headings th.sortable');
    elList.forEach((el) => {
        el.classList.remove('active');
        el.classList.remove('reverse');
    });
    event.target.classList.add('active');
    let asc = true;
    if (event.target.dataset.asc) {
        asc = false;
        event.target.classList.remove('reverse');
    } else {
        event.target.classList.add('reverse');
    }

    // clear any previous sort order indicators
    let elList2 = document.querySelectorAll('#headings th');
    elList2.forEach((el) => {
        el.removeAttribute('data-asc');
    });
    // set sort order indicator
    if (asc) {
        event.target.setAttribute('data-asc', 'asc');
    }

    sortResults(attr, asc);
}

function sortResults(prop, asc) {
    mapDataArray.sort(function (a, b) {
        if (asc) {
            return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
        } else {
            return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
        }
    });
    renderResults();
}

// opening rows for the secondary content
function openNextRow(event) {
    event.preventDefault();
    let nextRow = event.target.parentElement.parentElement.nextSibling;

    event.target.classList.toggle('active');
    event.target.parentElement.classList.toggle('open');

    if (nextRow.classList.contains('hidden--new')) {
        nextRow.classList.toggle('hidden--new--active');
    }
}

//search function -- this auto-closes all secondary rows and resets them to untoggled
function searchProtests() {
    document.getElementById('tableSearch').onkeyup = function (event) {
        searchProtestsFunction(event);
    };

    function searchProtestsFunction(event) {
        let elList = document.querySelectorAll('tr.secondary');
        elList.forEach((el) => el.classList.remove('hidden--new--active'));
        let elList2 = document.querySelectorAll('tr.primary');
        elList2.forEach((el) => el.classList.remove('open'));
        let elList3 = document.querySelectorAll('.content-toggle');
        elList3.forEach((el) => el.classList.remove('active'));
        let value = event.target.value.toLowerCase();
        if (value.length < 1) {
            for (let row of protestTable.rows) {
                row.style.removeProperty('display');
            }
        } else {
            for (let row of protestTable.rows) {
                let showRow = false;
                for (let cell of row.cells) {
                    let val = cell.innerText.toLowerCase();
                    if (val.indexOf(value) !== -1) {
                        showRow = true;
                    }
                }
                if (!showRow) {
                    row.style.display = 'none';
                }
            }
        }
    }
}

function aboutTab() {
    document.querySelector('#aboutTabClick').onclick = function (e) {
        document.getElementById('aboutTabContent').classList.add('active');
    };
    document.querySelector('#close-about').onclick = function (e) {
        document.getElementById('aboutTabContent').classList.remove('active');
    };
}
function openTab(tabName, event) {
    let i, tabcontent, tablinks, activeTab;
    tabcontent = document.getElementsByClassName('tab-pane');
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].classList.remove('active');
    }
    document.getElementById(tabName).classList.add('active');

    tablinks = document.getElementsByClassName('tab-clicks');
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].parentElement.classList.remove('active');
    }
    activeTab = event.target.parentElement;
    activeTab.classList.add('active');
}

function printWindow() {
    window.print();
}

function callMap() {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new('chartdiv');

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    let chart = root.container.children.push(
        am5map.MapChart.new(root, {
            panX: 'none',
            panY: 'none',
            wheelX: 'none',
            wheelY: 'none',
            projection: am5map.geoMercator(),
            homeZoomLevel: 2,
            homeGeoPoint: { longitude: 0, latitude: 20 },
        }),
    );
    // adding the zoom controls and making them teal
    const zoomControl = chart.set('zoomControl', am5map.ZoomControl.new(root, {}));
    const zoomButtons = ['minusButton', 'plusButton'];

    zoomButtons.forEach((button) => {
        zoomControl[button].get('background').setAll({
            fill: am5.color(0x00a79d),
        });
        zoomControl[button]
            .get('background')
            .states.create('hover', {})
            .setAll({
                fill: am5.color(0x99dcd8),
            });
    });

    // Create series for background fill
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
    let backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
    backgroundSeries.mapPolygons.template.setAll({
        fill: am5.color('#cdd4d9'),
        fillOpacity: 1,
        strokeOpacity: 0,
    });

    // Add background polygon
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
    backgroundSeries.data.push({
        geometry: am5map.getGeoRectangle(90, 180, -90, -180),
    });

    // Create main polygon series for countries
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
    let polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_worldLow,
            exclude: ['AQ'],
        }),
    );

    //country list country code
    let countryAbbreviations = [
        'AO',
        'AF',
        'AL',
        'DZ',
        'AR',
        'AM',
        'AU',
        'AT',
        'AZ',
        'BE',
        'BH',
        'BD',
        'BY',
        'BJ',
        'BO',
        'BA',
		'BW',
        'BR',
        'BG',
        'BF',
        'CM',
        'CA',
        'CM',
        'CL',
        'CF',
        'CN',
        'CU',
        'CO',
        'CR',
        'TD',
        'HR',
        'CZ',
        'CD',
        'CY',
        'KM',
        'DO',
        'DK',
        'EC',
        'EG',
        'SV',
        'SZ',
        'ET',
        'FR',
        'GA',
        'GT',
        'GM',
        'PS',
        'GE',
        'DE',
        'GN',
        'GH',
        'HT',
        'HN',
        'HK',
        'HU',
        'IN',
        'ID',
        'IR',
        'IQ',
        'IL',
        'IT',
        'CI',
        'JP',
        'JO',
        'KG',
		'KH',
        'KZ',
        'KE',
        'KW',
		'LT',
        'LB',
        'LR',
        'LY',
        'MG',
        'MW',
        'MR',
        'MM',
        'MV',
        'MY',
        'ML',
        'MX',
        'MT',
        'MD',
        'MN',
        'MK',
        'MU',
        'ME',
        'MA',
        'MZ',
        'NE',
        'NP',
        'NL',
        'NG',
        'NA',
        'NZ',
        'NI',
        'NO',
        'OM',
        'PA',
        'PK',
        'PL',
        'PT',
        'PE',
        'PY',
        'RO',
        'RU',
        'RS',
        'SN',
        'SO',
        'SB',
        'SK',
        'SI',
        'CH',
        'ZA',
        'KR',
        'ES',
        'LK',
        'SE',
        'SS',
        'SD',
        'SR',
        'SL',
        'SY',
		'TZ',
        'TW',
        'TH',
        'TG',
        'TJ',
        'TM',
        'TN',
        'TR',
        'UA',
        'GB',
        'UG',
        'US',
        'UY',
        'VE',
        'VN',
        'YE',
        'ZW',
        'XK',
        'UZ',
    ];
    let multipleCountryAbbreviations = [
        'AF',
        'AL',
        'AR',
        'AO',
        'AM',
        'AT',
        'AU',
        'AZ',
        'BA',
        'BY',
        'BR',
        'BE',
        'BO',
        'BJ',
        'BG',
        'BD',
        'BR',
        'BF',
        'CA',
        'CL',
        'CM',
        'CF',
        'CO',
        'CN',
        'CD',
        'CZ',
        'CU',
        'CM',
        'TD',
        'KM',
        'HR',
        'EC',
        'ET',
        'SZ',
        'EG',
        'FR',
        'GE',
        'GH',
        'GT',
        'GR',
        'GN',
        'DE',
        'HT',
        'HN',
        'HK',
        'HU',
        'IN',
        'ID',
        'IR',
        'IL',
        'IT',
        'IQ',
        'JO',
        'JP',
        'KZ',
        'KE',
        'KG',
        'LB',
        'LR',
        'MR',
        'MW',
        'MG',
        'MA',
        'ME',
        'ML',
        'MN',
        'MX',
        'MT',
        'MO',
        'NK',
        'MM',
        'NE',
        'NL',
        'NG',
        'NZ',
        'NP',
        'OM',
        'PL',
        'PK',
        'PS',
        'PE',
        'PY',
        'RU',
        'RO',
        'RS',
        'LK',
        'SO',
        'SK',
        'ES',
        'SE',
        'SD',
        'SN',
        'SY',
        'SI',
        'SL',
        'KR',
        'TN',
        'TW',
        'TH',
        'TR',
        'GB',
        'UG',
        'US',
        'VE',
        'YE',
        'ZA',
        'ZW',
    ];

    // Configure series
    polygonSeries.mapPolygons.template.setAll({
        tooltipText: '{name}',
        interactive: true,
        fill: am5.color('#ffffff'),
        templateField: 'settings',
    });

    polygonSeries.events.on('datavalidated', function () {
        chart.goHome();
    });

    // Create point series for markers
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-point-series/
    let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

    pointSeries.bullets.push(function () {
        let circle = am5.Circle.new(root, {
            radius: 4,
            tooltipY: 0,
            fill: am5.color(0xfbb040),
            tooltipText: '{title}',
        });

        return am5.Bullet.new(root, {
            sprite: circle,
        });
    });

    polygonSeries.mapPolygons.template.states.create('active', {
        fill: clickedCountry,
    });

    function closeButton() {
        // close button in info
        document.querySelector('#closeInfo').onclick = function (e) {
            document.getElementById('info').classList.remove('active');
            countryAbbreviations.forEach((value) => {
                let countryDataItem = polygonSeries.getDataItemById(value);
                let polygon = countryDataItem.get('mapPolygon');
                polygon.states.apply('default');
            });
            let elList = document.querySelectorAll('#protestTable tr.primary');
            elList.forEach((el) => {
                el.classList.remove('hidden--new');
            });
        };
    }

    polygonSeries.mapPolygons.template.events.on('click', function (ev) {
        let data = ev.target.dataItem.dataContext;
        let info = document.getElementById('info');
        let html = '';
        // only look into the prefiltered version -- we are also using this so certain actions (activating info box, activating country, filtering table) only happen for a 'positive' result
        for (let e in mapDataArray) {
            if (mapDataArray[e].country == data.name) {
                // making info box visible
                document.getElementById('info').classList.add('active');

                //gathering data
                html +=
                    mapDataArray[e].protest_name +
                    '<span class="protest">, ' +
                    mapDataArray[e].date_text +
                    '</span>' +
                    '<div><strong>Trigger:</strong> ' +
                    mapDataArray[e].triggers +
                    '</div>';

                // change table as well as map
                let countryValue = data.name.toLowerCase();
                let elList = document.querySelectorAll('#protestTable tr.primary');
                elList.forEach((el) => {
                    if (el.firstChild.childNodes[1].innerHTML.toLowerCase().indexOf(countryValue) == -1) {
                        el.classList.add('hidden--new');
                    }
                });

                // making clicked country active coolor
                countryAbbreviations.forEach((value) => {
                    let countryDataItem = polygonSeries.getDataItemById(value);
                    let polygon = countryDataItem.get('mapPolygon');
                    if (value !== data.id) {
                        polygon.states.apply('default');
                    } else {
                        polygon.states.apply('active');
                    }
                });
            }
        }
        info.innerHTML =
            "<div class='close-panel' id='closeInfo'><span class='ceip-icon icon-cancel-circle'>X</span></div>";
        if (html) {
            info.innerHTML +=
                '<div class="description"><div class="title">' +
                data.name +
                '</div><div class="info-text">' +
                html +
                '</div></div>';
        } else {
            info.innerHTML +=
                "<div class='close-panel' id='closeInfo'><span class='ceip-icon icon-cancel-circle'></span></div><div class='description'><p><i>No description provided.</i></p></div>";
        }

        // close button in info
        closeButton();
    });

    //reconfigure based on filters

    function buildMapFromButton(inactiveResults, results) {
        // create array that is only the negative results inactiveMapData
        let inactiveMapDataArray = [];
        let index = 0;
        for (let e in inactiveResults) {
            inactiveMapDataArray[index] = inactiveResults[e].inactiveMapData;
            index++;
        }
        // push to new polygonSeries the array with positive results as normal
        let activeMapColors = [];
        for (let e in results) {
            activeMapColors.push(results[e].mapData);
        }
        // combine the inactive array for light color inactive countries and active array for bright active countries
        const fullMap = inactiveMapDataArray.concat(activeMapColors);
        polygonSeries.data.setAll(fullMap);
        // adjust cities so inactive are invisible
        let activeCitiesArray = [];
        for (let e in results) {
            activeCitiesArray.push(results[e].capitalData);
        }
        pointSeries.data.setAll({
            opacity: 0,
        });
        for (let i = 0; i < activeCitiesArray.length; i++) {
            let city = activeCitiesArray[i];
            pointSeries.data.push({
                geometry: { type: 'Point', coordinates: [city.longitude, city.latitude] },
                title: city.title,
                opactity: 1,
            });
        }
    }

    //All protests
    document.querySelector('#radio-all').onclick = function (e) {
        //create array with positive results
        const results = dataArray.filter((data) => data.arrayItem);
        // push to new polygonSeries the array with positive results as normal
        let activeMapColors = [];
        for (let e in results) {
            activeMapColors.push(results[e].mapData);
        }
        polygonSeries.data.setAll(activeMapColors);
        // adjust cities so inactive are invisible
        let activeCitiesArray = [];
        for (let e in results) {
            activeCitiesArray.push(results[e].capitalData);
        }
        for (let i = 0; i < activeCitiesArray.length; i++) {
            let city = activeCitiesArray[i];
            pointSeries.data.push({
                geometry: { type: 'Point', coordinates: [city.longitude, city.latitude] },
                title: city.title,
                opactity: 1,
            });
        }
    };

    //Active protests
    document.querySelector('#radio-active').onclick = function (e) {
        //create array with positive results
        const results = dataArray.filter((data) => data.active);
        // create an array of all of the country ID that are in the results
        let resultsIDList = [];
        let index = 0;
        results.forEach((e) => {
            resultsIDList[index] = e.id;
            index++;
        });
        // if positive results array is missing a multiple, we need to add it back in to inactve
        let multipleResultsPositive = [];
        // compare list of countries with multiple results to list of countries in positive results
        multipleCountryAbbreviations.forEach((el) => {
            if (resultsIDList.includes(el)) {
                multipleResultsPositive.push(el);
            }
        });
        let inactiveResults = [];
        // if no countries are in BOTH positive and multiple results, return simple list of inactive countries (negative results)
        if (multipleResultsPositive.length < 1) {
            inactiveResults = dataArray.filter((data) => !data.active);
        }
        // if any countries are in positive AND multiple, filter them out of the negative results list -- so if a country is in the multiple results positive array, we skip adding them to the inactiveResults array.  We only add an item to inactiveResults if it is not in the multiple positive array AND it is not whatever variable we are testing for
        else {
            dataArray.forEach((e) => {
                if (!multipleResultsPositive.includes(e.id)) {
                    if (!e.active) {
                        inactiveResults.push(e);
                    }
                }
            });
        }
        buildMapFromButton(inactiveResults, results);
    };
    //Large protests
    document.querySelector('#radio-large').onclick = function (e) {
        //create array with positive results
        const results = dataArray.filter((data) => data.large);
        // create an array of all of the country ID that are in the results
        let resultsIDList = [];
        let index = 0;
        results.forEach((e) => {
            resultsIDList[index] = e.id;
            index++;
        });
        // if positive results array is missing a multiple, we need to add it back in to inactve
        let multipleResultsPositive = [];
        // compare list of countries with multiple results to list of countries in positive results
        multipleCountryAbbreviations.forEach((el) => {
            if (resultsIDList.includes(el)) {
                multipleResultsPositive.push(el);
            }
        });
        let inactiveResults = [];
        // if no countries are in BOTH positive and multiple results, return simple list of inactive countries (negative results)
        if (multipleResultsPositive.length < 1) {
            inactiveResults = dataArray.filter((data) => !data.large);
        }
        // if any countries are in positive AND multiple, filter them out of the negative results list -- so if a country is in the multiple results positive array, we skip adding them to the inactiveResults array.  We only add an item to inactiveResults if it is not in the multiple positive array AND it is not whatever variable we are testing for
        else {
            dataArray.forEach((e) => {
                if (!multipleResultsPositive.includes(e.id)) {
                    if (!e.large) {
                        inactiveResults.push(e);
                    }
                }
            });
        }
        buildMapFromButton(inactiveResults, results);
    };
    //Violent protests
    document.querySelector('#radio-violent').onclick = function (e) {
        //create array with positive results
        const results = dataArray.filter((data) => data.violent);
        // create an array of all of the country ID that are in the results
        let resultsIDList = [];
        let index = 0;
        results.forEach((e) => {
            resultsIDList[index] = e.id;
            index++;
        });
        // if positive results array is missing a multiple, we need to add it back in to inactve
        let multipleResultsPositive = [];
        // compare list of countries with multiple results to list of countries in positive results
        multipleCountryAbbreviations.forEach((el) => {
            if (resultsIDList.includes(el)) {
                multipleResultsPositive.push(el);
            }
        });
        let inactiveResults = [];
        // if no countries are in BOTH positive and multiple results, return simple list of inactive countries (negative results)
        if (multipleResultsPositive.length < 1) {
            inactiveResults = dataArray.filter((data) => !data.violent);
        }
        // if any countries are in positive AND multiple, filter them out of the negative results list -- so if a country is in the multiple results positive array, we skip adding them to the inactiveResults array.  We only add an item to inactiveResults if it is not in the multiple positive array AND it is not whatever variable we are testing for
        else {
            dataArray.forEach((e) => {
                if (!multipleResultsPositive.includes(e.id)) {
                    if (!e.violent) {
                        inactiveResults.push(e);
                    }
                }
            });
        }
        buildMapFromButton(inactiveResults, results);
    };
    //Long protests
    document.querySelector('#radio-long').onclick = function (e) {
        //create array with positive results
        const results = dataArray.filter((data) => data.long);
        // create an array of all of the country ID that are in the results
        let resultsIDList = [];
        let index = 0;
        results.forEach((e) => {
            resultsIDList[index] = e.id;
            index++;
        });
        // if positive results array is missing a multiple, we need to add it back in to inactve
        let multipleResultsPositive = [];
        // compare list of countries with multiple results to list of countries in positive results
        multipleCountryAbbreviations.forEach((el) => {
            if (resultsIDList.includes(el)) {
                multipleResultsPositive.push(el);
            }
        });
        let inactiveResults = [];
        // if no countries are in BOTH positive and multiple results, return simple list of inactive countries (negative results)
        if (multipleResultsPositive.length < 1) {
            inactiveResults = dataArray.filter((data) => !data.long);
        }
        // if any countries are in positive AND multiple, filter them out of the negative results list -- so if a country is in the multiple results positive array, we skip adding them to the inactiveResults array.  We only add an item to inactiveResults if it is not in the multiple positive array AND it is not whatever variable we are testing for
        else {
            dataArray.forEach((e) => {
                if (!multipleResultsPositive.includes(e.id)) {
                    if (!e.long) {
                        inactiveResults.push(e);
                    }
                }
            });
        }
        buildMapFromButton(inactiveResults, results);
    };
    //Significant protests
    document.querySelector('#radio-significant').onclick = function (e) {
        //create array with positive results
        const results = dataArray.filter((data) => data.significant);
        // create an array of all of the country ID that are in the results
        let resultsIDList = [];
        let index = 0;
        results.forEach((e) => {
            resultsIDList[index] = e.id;
            index++;
        });
        // if positive results array is missing a multiple, we need to add it back in to inactve
        let multipleResultsPositive = [];
        // compare list of countries with multiple results to list of countries in positive results
        multipleCountryAbbreviations.forEach((el) => {
            if (resultsIDList.includes(el)) {
                multipleResultsPositive.push(el);
            }
        });
        let inactiveResults = [];
        // if no countries are in BOTH positive and multiple results, return simple list of inactive countries (negative results)
        if (multipleResultsPositive.length < 1) {
            inactiveResults = dataArray.filter((data) => !data.significant);
        }
        // if any countries are in positive AND multiple, filter them out of the negative results list -- so if a country is in the multiple results positive array, we skip adding them to the inactiveResults array.  We only add an item to inactiveResults if it is not in the multiple positive array AND it is not whatever variable we are testing for
        else {
            dataArray.forEach((e) => {
                if (!multipleResultsPositive.includes(e.id)) {
                    if (!e.significant) {
                        inactiveResults.push(e);
                    }
                }
            });
        }
        buildMapFromButton(inactiveResults, results);
    };
    //Covid protests
    document.querySelector('#radio-covid').onclick = function (e) {
        //create array with positive results
        const results = dataArray.filter((data) => data.covid);
        // create an array of all of the country ID that are in the results
        let resultsIDList = [];
        let index = 0;
        results.forEach((e) => {
            resultsIDList[index] = e.id;
            index++;
        });
        // if positive results array is missing a multiple, we need to add it back in to inactve
        let multipleResultsPositive = [];
        // compare list of countries with multiple results to list of countries in positive results
        multipleCountryAbbreviations.forEach((el) => {
            if (resultsIDList.includes(el)) {
                multipleResultsPositive.push(el);
            }
        });
        let inactiveResults = [];
        // if no countries are in BOTH positive and multiple results, return simple list of inactive countries (negative results)
        if (multipleResultsPositive.length < 1) {
            inactiveResults = dataArray.filter((data) => !data.covid);
        }
        // if any countries are in positive AND multiple, filter them out of the negative results list -- so if a country is in the multiple results positive array, we skip adding them to the inactiveResults array.  We only add an item to inactiveResults if it is not in the multiple positive array AND it is not whatever variable we are testing for
        else {
            dataArray.forEach((e) => {
                if (!multipleResultsPositive.includes(e.id)) {
                    if (!e.covid) {
                        inactiveResults.push(e);
                    }
                }
            });
        }
        buildMapFromButton(inactiveResults, results);
    };

    // this needs to happen at the end after all the click events are declared
    let mapColors = [];
    for (let e in mapDataArray) {
        mapColors.push(mapDataArray[e].mapData);
    }
    polygonSeries.data.setAll(mapColors);

    let citiesArray = [];
    for (let e in mapDataArray) {
        citiesArray.push(mapDataArray[e].capitalData);
    }

    for (let i = 0; i < citiesArray.length; i++) {
        let city = citiesArray[i];
        addCity(city.longitude, city.latitude, city.title);
    }

    function addCity(longitude, latitude, title) {
        pointSeries.data.push({
            geometry: { type: 'Point', coordinates: [longitude, latitude] },
            title: title,
        });
    }

    // Make stuff animate on load
    chart.appear(1000, 100);
}
</script>

<style lang="scss">
//I needed to remove scoped for it to apply on dynamic elements ...
//layout
$gutter: 30px;
$site-width: 1000px;

$gray-light: #e4e3dd;
$gold: #a28556;
$black: #000;
$white: #fff;
$teal: #00a79d;
$dark-gray-new: #6e6e71;
$light-gray: #f5f5f5;
$dark-gray: #929497;
$yellow: #fbb040;
$primary-light-blue: #3183b1;
$primary-dark-blue: #072a42;
$teal-light: #99dcd8; // 40% $teal
$primary-dark-blue-light: #9caab3; // 40% $primary-dark-blue
$yellow-light: #fee7c5; //40% $yellow

$roman-normal-book: 'Apercu-Medium', Arial, sans-serif;
$roman-italic-book: 'Apercu-Medium-Italic', Arial, sans-serif;
$roman-normal-bold: 'Apercu-Bold', 'Arial Black', sans-serif;

%bold-sans-text {
    font-family: $roman-normal-bold;
    font-weight: 700;
}

%italic-sans-text {
    font-family: $roman-normal-book;
    font-style: italic;
}

@mixin all-transition($time) {
    transition: all $time;
    -webkit-transition: all $time;
}

.global-protest-tracker {
    //layout basics
    .foreground {
        position: relative;
        margin: 0 auto;
        box-sizing: content-box;
        max-width: $site-width;
        padding-left: calc($gutter / 2);
        padding-right: calc($gutter / 2);
    }

    .section {
        display: block;
        clear: both;
        margin-top: $gutter;
        margin-bottom: $gutter;
    }

    .cols {
        clear: both;
		//use a .no-gutters class to undo these default styles
        margin-left: calc($gutter / 2) * -1;
        margin-right: calc($gutter / 2) * -1;

        &:before,
        &:after {
            content: ' ';
            display: table;
            height: 0;
            clear: both;
            font-size: 1px;
            line-height: 0px;
            visibility: hidden;
        }

        &--gutters-tablet {
            // @include bp(tablet) {
            //     margin-left: calc($gutter / 2) * -1 !important;
            //     margin-right: calc($gutter / 2) * -1 !important;
            // }
        }

        // @include bp(mobile) {
        //     margin-left: 0 !important;
        //     margin-right: 0 !important;
        // }
    }

    .cols--equal-heights {
        flex-wrap: wrap;

        &,
        > .col {
            display: flex;

            // @include bp(mobile) {
            //     display: block;
            // }
        }

        > .col {
            flex-direction: column;
        }

        .banded {
            flex: 1 0 auto;
        }
    }

    .cols {
        clear: both;
    }

    .col {
        float: left;

        // @include bp(mobile) {
        //     float: none;
        //     width: 100% !important;

        //     > .gutters {
        //         margin-left: 0;
        //         margin-right: 0;
        //     }
        // }
    }

    .col-80 {
        width: 80%;
    }

    .col-70 {
        width: 70%;
    }

    //two across
    .col-50 {
        width: 50%;
    }

    .col-30 {
        width: 30%;
    }

    //four across
    .col-25 {
        width: 25%;
    }

    //five across
    .col-20 {
        width: 20%;
    }

    .padded {
        padding: $gutter;
    }

    .nav-tabs {
        div {
            border: 0 !important;
            text-transform: uppercase;
        }

        > li {
            margin-bottom: 0;
            float: left;
            position: relative;
            display: block;

            > div {
                line-height: 1;
                border-radius: 0;
            }
        }
    }

    .gutter-right {
        margin-right: 30px;
    }

    .gutter-left {
        margin-left: 30px;
    }

    .zone-title {
        background-color: $black;
        background-image: url(https://ceipimg.s3.amazonaws.com/static/protestTracker-backgroundImage.jpg);
        background-repeat: no-repeat;
        text-align: center;

        .container-title {
            margin: 20px auto 0 auto;
            padding-top: 180px;
            width: 50%;
            
            .interactive-dek .rich-text p {margin-left: auto; margin-right: auto}
        }

        h2 {
            background-color: $teal;
            color: $white;
            display: inline-block;
            font-family: $roman-normal-bold;
            font-size: 3rem;
            margin: $gutter 0;
            padding: calc($gutter / 5) 20px 12px 20px;
        }

        h3 {
            background-color: $white;
            color: $black;
            display: inline-block;
            // font-size: 3rem;
            margin: $gutter 0;
            padding: calc($gutter / 5) 20px 12px 20px;
			@include type-h3;
        }

        p {
            color: $white;
            // font-family: $roman-normal-book;
            @include type-body;

            &.uppercase {
                font-family: $roman-normal-bold;
                margin-top: calc($gutter * 2);
            }

            > strong {
                display: block;
                font-family: $roman-normal-bold;
                font-size: 0.8rem;
                font-weight: normal;
                margin-top: calc($gutter / 3);
                text-transform: uppercase;
            }
        }

        .cols {
            margin: 0 auto;
            padding: $gutter;
        }

        .col-25 {
            border: 1px solid $white;
            border-radius: 5px;
            margin: $gutter;
            width: 20%;

            .padded {
                color: $white;
                // font-family: $roman-normal-bold;
                // @include font-weight(bold);
                line-height: normal;
            }

            strong {
                color: $yellow;
                display: block;
                // font-family: $roman-normal-bold;
                // @include font-weight(bold);
                font-size: 4rem;
                font-weight: normal;
                line-height: 1.1em;

                > span {
                    color: $white;
                    display: inline-block;
                    font-size: 1rem;
                    padding: 0 0.25em;
                    position: relative;
                    top: 5px;
                    vertical-align: super;
                }
            }
        }
    }

    .results-table {
        table {
            margin: calc($gutter / 2);
            width: 95%;

            thead {
                position: sticky;
                position: -webkit-sticky;
                top: 65px;
            }

            td,
            th {
                padding: calc($gutter / 4) calc($gutter / 3);
            }

            th {
                background-color: $white;
				text-transform: uppercase;
                @include type-body;
                // @include font-weight(bold);

                &.sortable {
                    color: $teal;
                    cursor: pointer;

                    &.active {
                        &:after {
                            border-top: 10px solid $teal;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            content: '';
                            display: inline-block;
                            margin: 5px 0 0 5px;
                            padding-left: 2px;
                        }

                        &.reverse:after {
                            -webkit-transform: rotate(180deg);
                            -ms-transform: rotate(180deg);
                            transform: rotate(180deg);
                        }
                    }
                }

                &.compare {
                    padding-right: 0;

                    a {
                        background-color: $teal-light;
                        border-radius: 3px;
                        color: $white;
                        cursor: pointer;
                        // font-size: 0.8rem;
                        padding: 3px 6px;
                        text-decoration: none;

                        &:hover {
                            text-decoration: none;
                        }

                        &.useable {
                            background-color: $teal;
                        }

                        &.reset {
                            background-color: $teal;
                            background-image: url(https://ceipimg.s3.amazonaws.com/static/icon-refresh.png);
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: 65%;
                            bottom: -6px;
                            cursor: pointer;
                            display: inline-block;
                            height: 24px;
                            margin-left: 5px;
                            padding: 0;
                            position: relative;
                            width: 24px;
                        }

                        &.inactive {
                            display: none;
                        }
                    }
                }
            }

            tr {
                &:hover,
                &.open,
                &.secondary {
                    background-color: $light-gray;
                }
            }

            .protest-name {
                min-width: 10%;
            }

            .motivation {
                min-width: 16%;
            }

            td {
                border-bottom: none;
                border-top: 1px solid $gray-light;
                // font-size: 1.4rem;
                // line-height: 1.3em;
                vertical-align: middle;
				@include type-body;

                .protest {
                    display: block;
                    font-style: italic;
                }

                .motivations {
                    display: block;

                    .name {
                        border-radius: 5px;
                        // color: $white;
                        display: inline-block;
                        font-size: 1.1rem;
                        margin-bottom: 5px;
                        margin-right: 7px;
                        padding: 3px;

                        &:nth-child(3) {
                            margin-right: 0;
                        }
                    }

                    .inactive {
                        background-color: $yellow-light;
                        color: $dark-gray-new;
                    }

                    .active {
                        background-color: $yellow;
                        color: $black;
                    }
                }

                .active .icon-plus-circle:before {
                    // @extend %ceip-icon;
                    // @extend %icon-minus-circle:before;
                }

                a.ceip-icon {
                    color: $teal;
                    line-height: 1.4em;

                    &:hover {
                        text-decoration: none;
                    }
                }

                input {
                    display: block;
                    margin: 0 auto;
                }

                &.cols > div {
					@include type-body;
					& {
						@include font-weight(bold);
					}
                }

                &:nth-child(4) {
                    padding-right: $gutter;
                    text-align: right;
                }

                input {
                    appearance: checkbox;
                }
            }

            tr.primary {
                &.hidden--new {
                    display: none;
                }
            }

            tr.secondary {
                strong {
                    display: block;
                    font-family: $roman-normal-bold;
                    font-weight: normal;
                    margin-bottom: calc($gutter / 6);
                }

                .details {
                    padding-bottom: calc($gutter / 2);
                    padding-right: $gutter;
                }

                td {
                    border-top: none;
                }

                &.hidden--new {
                    display: none;
                }

                &.hidden--new--active {
                    display: table-row;
                }
            }
        }
    }

    .download-buttons {
        margin: $gutter 0;

        .foreground {
            text-align: center;
        }

        .button {
            background-color: $teal-light;
            color: $dark-gray-new;
            cursor: pointer;
            font-size: 1.5rem;
            margin: 0 calc($gutter / 2);
            padding: calc($gutter / 2);
            text-transform: uppercase;

            &:after {
                content: none;
            }

            &:hover {
                background-color: $teal;
                color: $white;
            }
        }
    }

    .filter-area {
        background-color: $white;
        height: 65px;
        padding-top: 5px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
    }

    .filters {
        display: table;
        font-size: 1rem;
        margin: $gutter auto;
        text-align: center;

        form {
            float: left;
            margin-bottom: 0;

            fieldset {
                padding-left: calc($gutter / 2);

                label {
                    background-color: $teal-light;
                    border-radius: 5px;
                    cursor: pointer;
                    float: left;
                    margin: 0 calc($gutter / 5);
                    -moz-outline: 1px solid $black;
                    padding: 5px 10px;

                    input {
                        display: none;
                    }

                    span.name {
                        color: $dark-gray-new;
                        font-size: 1.3rem;
                        // padding-right: 5px;
                        text-transform: uppercase;
						@include type-body;
						& {
							@include font-weight(bold);
						}
                    }

                    &.special {
                        background-color: $yellow-light;

                        &.active {
                            background-color: $teal;
                            color: $white;
                        }
                    }

                    &:hover {
                        background-color: $teal;

                        span.name {
                            color: $white;
                        }
                    }

                    &.active {
                        background-color: $teal;

                        span.name {
                            color: $white;
                        }

                        &:hover {
                            background-color: $teal;

                            span.name {
                                color: $white;
                            }
                        }
                    }
                }

                strong {
                    display: block;
                    float: left;
                    // @include font-weight(bold);
                    margin: auto calc($gutter / 3) 0 $gutter;
					@include type-body;
					& {
						font-size: 1.5rem;
						font-weight: normal;
						line-height: 2.1em;
					}
                }
            }
        }

        label {
            float: left;
            margin-right: 15px;

            &.table-search {
                background-color: $white;
                border: 1px solid $primary-dark-blue-light;
                border-radius: 2px;
                box-shadow: none;
                length: 20vw;
                padding: 1px;

                &:hover {
                    background-color: $white;
                }

                &:before {
                    // @extend %ceip-icon;
                    // @extend %icon-search:before;
                    color: $primary-dark-blue;
                    text-transform: none;
                    line-height: 1;
                }

                input {
                    border: none;
                    display: inline-block;

                    &:hover {
                        background-color: $white;
                    }
                }

                input[type='text'] {
                    color: $primary-dark-blue;
                    font-variant: none;
                    width: 215px;
					@include type-body;
					& {
						font-size: 1.5rem;
					}
                }
            }
        }
    }

    .map {
        background-color: #cdd4d9;
        margin: 0;
        position: relative;

        #aboutTab {
            @keyframes move {
                0% {
                    transform: translateX(90vw);
                }

                50% {
                    transform: translateX(90vw);
                }

                100% {
                    transform: translateX(0);
                }
            }

            h3 {
                background-color: $teal;
                border-radius: 5px 0 0 5px;
                color: $white;
                cursor: pointer;
                display: none;
                padding: 20px 0;
                position: fixed;
                right: 0;
                top: 40vh;
                writing-mode: vertical-lr;
                text-orientation: mixed;
                text-transform: uppercase;
                z-index: 1;
				@include type-h3;
				& {
					font-size: 1.5rem;
                	line-height: 2.3em;
				}

                &.active {
                    display: block;
                }

                span {
                    display: inline;
                }
            }

            #aboutTabContent {
                box-shadow: -5px 0px 8px -3px rgba(0, 0, 0, 0.69);
                display: none;
                background-color: $white;
                width: 90vw;
                height: 65vh;
                cursor: auto;
                overflow-y: scroll;
                position: fixed;
                right: 0;
                top: 20vh;
                -webkit-border-radius: 5px 0 0 5px;
                -moz-border-radius: 5px 0 0 5px;
                border-radius: 5px 0 0 5px;
                z-index: 9;

                &.active {
                    animation-name: move;
                    animation-duration: 0.75s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    display: block;
                }

                h3 {
                    border-radius: 0;
                    display: block;
                    height: 100%;
                    top: 0;
                    display: none;
					@include type-h3;
                }

                .section {
                    padding: 0 15px 15px 0;

                    &.about-overlay {
                        &.section {
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                        .foreground {
                            padding-left: 0;
                        }
                    }
                }

                .top-section {
                    background-color: $white;
                    display: block;
                    padding: calc($gutter / 2) 0 calc($gutter * 2) 0;
                    position: sticky;
                    position: webkit-sticky;
                    top: 0;
                    width: 100%;
                    z-index: 9;

                    .foreground {
                        float: left;
                        position: relative;
                        left: calc($gutter * 2);
                    }
                }

                .nav {
                    margin-left: calc($gutter * 2);
                }

                .nav-tabs {
                    border-bottom: none;
                    width: 100%;

                    div {
                        background: none;
                        color: $teal-light !important;
                        padding-left: 0;
                        padding-right: calc($gutter * 1.5);
                        cursor: pointer;
						@include type-body;
						& {
							font-size: 1.5rem;
							@include font-weight(bold);
						}

                        &:hover {
                            color: $teal !important;
                        }
                    }

                    li.active div {
                        color: $teal !important;
                    }

                    li {
                        &:last-child {
                            div {
                                padding-right: 0;
                            }
                        }
                    }
                }

                .tab-content {
                    font-size: 1.2rem;
                    line-height: normal;

                    .component {
                        margin: 0 !important;

                        .cols {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }

                    .tab-pane {
                        display: none;
                        margin: $gutter 0 0 0;

                        &.active {
                            display: block;
                        }

                        h4 {
                            color: $primary-dark-blue;
                            margin-bottom: 1em;
                            text-transform: uppercase;
							@include type-h4;
                        }

                        p,
                        li,
                        .col {
                            @include type-body;
                        }

                        p {
                            margin-bottom: $gutter;
                        }

                        // em {
                        //     @include italic;
                        // }

                        strong {
                            display: block;
                            padding-bottom: calc($gutter / 3);
							@include font-weight(bold);
                        }

                        li.gutter-bottom {
                            margin-bottom: calc($gutter / 2);
                        }
                    }
                }

                .close-button {
                    button.close-panel {
                        background-color: transparent;
                        color: $teal;
                        margin-bottom: $gutter;
                        font-family: $roman-normal-bold;
                        font-size: 2rem;
                        font-weight: normal;
                        padding: 0;
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        text-transform: uppercase;

                        &:after {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    #countrySelect {
        position: absolute;
        left: 6%;
        top: 10px;
    }

    #info {
        display: none;

        &.active {
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 0;
            box-shadow: -5px 0px 8px -3px rgba(0, 0, 0, 0.69);
            position: absolute;
            display: block;
            width: 20%;
            max-height: 300px;
            top: 45px;
            left: 45px;

            .description {
                font-family: $roman-normal-book;
                font-size: 0.9rem;
                overflow-y: scroll;
                padding: 0 15px 0 15px;
                max-height: 280px;

                .title {
                    background-color: $white;
                    font-family: $roman-normal-bold;
                    font-size: 1.2rem;
                    margin: 0 (-(calc($gutter / 2))) calc($gutter / 6) (-(calc($gutter / 2)));
                    padding: $gutter 0 0 calc($gutter / 2);
                    position: sticky;
                    top: 0;
                    width: 100%;
                    z-index: 6;
                }

                .info-text {
                    padding-top: calc($gutter * 1.5);
                }

                .protest {
                    font-family: $roman-italic-book;
                }

                div {
                    margin-bottom: calc($gutter / 2);
                }

                strong {
                    display: inline-block;
                    font-family: $roman-normal-bold;
                    font-weight: normal;
                }

                p {
                    padding-left: 15px;

                    i {
                        font-family: $roman-italic-book;
                        font-variant: none;
                    }
                }
            }
        }

        .close-panel {
            color: $teal;
            cursor: pointer;
            font-family: $roman-normal-bold;
            font-size: 2rem;
            font-weight: normal;
            top: -15px;
            left: -9px;
            position: absolute;
            text-transform: uppercase;
            z-index: 7;

            &:after {
                border: none;
            }

            .icon-cancel-circle {
                // padding-left: 1em;
            }
        }
    }

    #chartdiv {
        // border: 1px solid $black;
        float: none;
        margin: 0 auto;
        max-width: 1600px;
        height: 550px;
    }

    .learn-more {
        p,
        li {
            font-family: $roman-normal-book;
        }

        .button-big {
            margin: $gutter auto calc($gutter * 4) auto;
            width: 20%;
        }
    }

    // @include media-query(tablet) {
    //     .zone-title .col-25 {
    //         width: 19%
    //     }
    // }

    @include media-query(tablet) {
        .zone-title {
            .container-title {
                width: 65%;
            }

            .col-25 {
                margin: calc($gutter / 2);
                width: 21%;

                &:nth-child(3) {
                    // .padded {
                    //     margin-top: -(calc($gutter / 3));
                    // }

                    strong {
                        // font-size: 3.2rem;
                        padding-bottom: 0.25em;

                        span {
                            // display: block;
                            font-size: 0.7rem;
                            // line-height: 0.9em;
                        }
                    }
                }

                &:last-child {
                    // .padded {
                    //     font-size: 0.9rem;
                    // }

                    strong {
                        line-height: 1em;
                        padding-bottom: 0.2em;
                    }
                }
            }
        }

        #chartdiv {
            height: 550px;
        }

        .map #aboutTab #aboutTabContent {
            background-color: $white;

            .about-overlay {
                .foreground {
                    margin: 0 $gutter;
                }
            }

            .top-section {
                height: 70px;
                padding: calc($gutter / 2) 0;
            }

            // .nav {margin-left: $gutter*2;}

            .nav-tabs {
                margin-left: 0;
                width: auto;

                li {
                    a {
                        font-size: 1.1rem;
                        line-height: 1.1em;
                        padding-right: $gutter;
                    }

                    &:last-child {
                        a {
                            padding-right: 0;
                        }
                    }
                }
            }

            .tab-content {
                font-size: 1.1rem;

                .tab-pane {
                    margin: 10px 0 0;

                    &:first-child {
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .close-button {
                display: block;
                float: left;
            }
        }

        .results-table table {
            thead th {
                &.responsive-hide {
                    display: none;
                }

                &.motivation {
                    min-width: 0;
                }

                &.protest-name {
                    min-width: 35%;
                }
            }

            td {
                &:nth-child(3) {
                    padding-right: $gutter;
                    text-align: right;
                }

                &:nth-child(4) {
                    padding-right: 0;
                    text-align: left;
                }
            }
        }

        .filters {
            form {
                padding-left: 0;

                fieldset {
                    padding-left: 0;

                    label {
                        margin: 5px 10px 0 0;
                    }

                    strong {
                        line-height: 2.7em;
                    }
                }
            }
        }

        #info {
            &.active {
                width: 30%;
            }
        }
    }

    @include media-query(tablet-portrait) {
        .zone-title {
            .col-25 {
                width: 45%;
            }
        }

        #chartdiv {
            height: 450px;
        }

        .map {
            #aboutTab {
                #aboutTabContent {
                    .top-section {
                        width: 100%;
                        height: 0;

                        .foreground {
                            display: inline-block;
                            float: none;
                            left: 10px;
                            top: 60px;
                            width: 22vw;
                        }
                    }

                    .tab-content {
                        .tab-pane {
                            .col {
                                clear: none;
                                float: none;
                                width: 100%;

                                .gutter-left,
                                .gutter-right {
                                    margin: 0;
                                }

                                &.col-30 {
                                    position: relative;
                                    top: 280px;
                                }

                                &.col-70 {
                                    position: relative;
                                    top: -170px;
                                }
                            }
                        }
                    }

                    .section {
                        &.about-overlay {
                            &.section {
                                margin-left: 22vw;
                                width: 68vw;
                            }
                        }
                    }

                    .close-button {
                        button.close-panel {
                        }
                    }
                }
            }
        }

        .filter-area {
            height: 125px;
        }

        .filters {
            .table-search {
                float: none;
                margin: 0 auto calc($gutter / 3) auto;
                width: 50%;
            }
        }

        .results-table {
            table {
                width: 90%;

                thead {
                    top: 167px;
                }

                td:nth-child(4) {
                    width: 1px;
                }
            }
        }
    }

    @include media-query(phone) {
        .hide-mobile {
            display: none;
        }

        .zone-title {
            .container-title {
                width: 70%;
            }

            .cols .col {
                width: auto !important;
            }
        }

        .filters {
            margin: 0 auto;

            form {
                padding-left: calc($gutter / 2);

                fieldset {
                    padding-left: $gutter;
                    padding-right: $gutter;

                    .control-group {
                        text-align: center;
                    }

                    strong {
                        display: none;
                        float: none;
                        margin: 0;
                    }

                    label.special {
                        margin-top: calc($gutter / 2);
                    }
                }
            }

            label.table-search {
                width: auto;
            }
        }

        .filter-area {
            height: 190px;
            top: 60px;

            &.section {
                margin-top: 0;
            }
        }

        .reponsive-mobile-hide {
            display: none;
        }

        #chartdiv {
            height: 280px;
        }

        #countrySelect {
            left: 25%;
        }

        #info {
            &.active {
                margin: $gutter auto;
                position: relative;
                right: 0;
                width: 90%;
            }
        }

        .map #aboutTab {
            h3 {
                top: 20vh;

                span {
                    display: none;
                }
            }

            #aboutTabContent {
                width: 98vw;

                .top-section {
                    float: left;
                    // left: $gutter*1.5;
                    padding: calc($gutter * 1.5) 0;
                    width: 100%;

                    .foreground {
                        left: calc($gutter * 2);
                        top: -25px;
                        width: 74vw;
                    }
                }

                .nav-tabs li a {
                    font-size: 0.8rem;
                    padding: 0px 15px 5px 15px;
                }

                .about-overlay .foreground {
                    margin-right: 5px;
                }

                .section.about-overlay.section {
                    clear: both;
                    margin-left: 0;
                    float: none;
                    width: 100%;
                }

                .tab-content {
                    padding-top: 0;

                    .tab-pane {
                        .col.col-30 {
                            top: 0;
                        }

                        .col.col-70 {
                            margin-top: $gutter;
                            top: 0;
                        }

                        p {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .results-table table {
            margin: 0;
            width: 100%;

            thead {
                top: 250px;
            }

            td:nth-child(3) {
                padding-right: 15px;
            }

            td.cols {
                .col.col-20 {
                    width: 20% !important;
                    float: left;
                }

                .col.col-80 {
                    width: 80% !important;
                    float: left;
                }
            }
        }

        .learn-more .button-big {
            width: 60%;
        }

        .download-buttons .button:first-child {
            margin-bottom: calc($gutter / 2);
        }
    }
}
</style>
